import React  , {useEffect, useState}from 'react';
import './styles.scss';
import history from './../../utilities/history';
import { connect } from 'react-redux';
import Modal from './../Landing/Modal';
import { PURGE } from 'redux-persist';
import { ProfileActionCreators } from '../../actions/candidate/profileAction';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import down from './../../assets/outhire/down.svg';
import up from './../../assets/outhire/up.svg';

function OuthireMobileMenu(props) {

    const [signoutModal, setSignoutModal] = useState(false);
    const [jobExpand, setJobExpand] = useState(false);
    const pathname = window.location.pathname;
    console.log(pathname);

    const signout = (e) => {
     // e.PreventDefault();
       const {dispatch} = props;

       dispatch({
         type: PURGE,
         key: 'root',
         result: () => null
       })

       props.dispatch(ProfileActionCreators.updateUser(null));
      history.push('/');
    }

    useEffect(()=> {
      if(props.user == null || props.user == undefined){
        history.push('/signin');
        console.log(props.user)
      }


      const onPageLoad = () => {
        setJobExpand(true)
      }

      if(document.readyState === 'complete' && pathname.includes('job')){
        onPageLoad();
      }
      // else{
      //   window.addEventListener('load', onPageLoad);
      //   return ()=> window.removeEventListener('load', onPageLoad)
      // }
    }, [])

    return (
        <div className='side-bar'>
            <div className='menu-logo-wrap'>
              <img src={logo} alt="" className='menu-logo' /></div>
            <div className='side-menu'>
              <div className={pathname=='/candidate'? 'menu-list active-menu' : 'menu-list'} onClick={() => history.push('/candidate')}>
                <div className='icon-wrap'>
                  <img src={profile} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Profile</div>
              </div>
              <div className={pathname.includes('job')? 'menu-list active-menu' : 'menu-list'} onClick={()=> setJobExpand(!jobExpand)}>
                <div className='icon-wrap'>
                  <img src={job} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Job Postings <span><img src={jobExpand?up: down} alt='' className='menu-after-icon'/></span></div>
              </div>
              {jobExpand && (
                <div className='sub-menu-wrap'>
                <div className='sub-menu menu-list' onClick={() => history.push('/myjobs')}>
                  <div className='icon-wrap'>
                  </div>
                  <div className={(pathname.includes('/myjob'))? ' menu-txt sub-menu-txt active-sub' : 'menu-txt sub-menu-txt'}>My Jobs</div>
                </div>
                <div className='sub-menu menu-list' onClick={() => history.push('/alljobs')}>
                  <div className='icon-wrap'>
                  </div>
                  <div  className={pathname=='/alljobs'? ' menu-txt sub-menu-txt active-sub' : 'menu-txt sub-menu-txt'}>All Jobs</div>
                </div>
                </div>
              )}
              <div className={pathname=='/referrals'? 'menu-list active-menu' : 'menu-list'} onClick={() => history.push('/referrals')}>
                <div className='icon-wrap'>
                  <img src={referrals} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Referrals</div>
              </div>
              <div className='menu-list' onClick={()=>setSignoutModal(true)}>
                <div className='icon-wrap'>
                  <img src={logout} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Logout</div>
              </div>
            </div>
            <Modal  show={signoutModal} handleClose={() => setSignoutModal(false)}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Are you sure?</div>
            </div>
            <div className='n-modal-txt'>You will be signed out and taken back to outhire.ca
             </div>
             <br/>

              <button className='btn-save' onClick={signout}>Sign Out</button>
          </div>
        </Modal>
          </div>
    )
};

  //map stored state to props access
  const mapStateToProps = (state, ownProps) => {
    return {
        user: state.candidate.outhire_user,
        state
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(OuthireMobileMenu);