import React from 'react';
import './styles.scss';
import logo from './../../assets/outhire/outhire.svg';
import facebook from './../../assets/outhire/icon-facebook.svg';
import twitter from './../../assets/outhire/icon-twitter.svg';
import ins from './../../assets/outhire/icon-ins.svg';
import history from './../../utilities/history';

const Footer=(props) => {

    return (
        <div className="footer">
        <div className='footer-content'>
        <div className="footer-txt-wrap">
            <div className="footer-logo-wrap">
                <a href='https://outhire.ca/home/'><img alt='' className="footer-logo cursor" src={logo}/></a>
                <div className="footer-txt copy-right-txt web-copy">
                © 2023 Outhire Inc. All rights reserved
                </div>
            </div>
            <div className="footer-list-wrap">
                <div className="footer-list ml-0">
                    <div className="footer2-txt">For Candidates</div>
                    <a className="footer-link" href='/explore-jobs'><div className="footer-txt">Find a Job</div></a>
                    <a className="footer-link" href='/signup'><div className="footer-txt">Candidate Sign Up</div></a>
                    <a className="footer-link" href='/signin'><div className="footer-txt">Log In</div></a>
                </div>
                <div className="footer-list">
                    <div className="footer2-txt">For Companies</div>
                    <a className="footer-link" href='https://outhire.ca/contact/'><div className="footer-txt">Start Hiring</div></a>
                    <a className="footer-link" href='https://outhire.ca/contact/'><div className="footer-txt">Company Sign Up</div></a>
                </div>
                <div className="footer-list">
                    <div className="footer2-txt">Outhire Inc.</div>
                    <a className="footer-link" href='https://outhire.ca/about/'><div className="footer-txt">About</div></a>
                    <a className="footer-link" href='https://outhire.ca/privacy/'><div className="footer-txt">Privacy Policy</div></a>
                    <a className="footer-link" href='https://outhire.ca/terms-of-use/'><div className="footer-txt">Terms of Use</div></a>
                    <a className="footer-link" href='https://outhire.ca/faq/'><div className="footer-txt">Frequently Asked Questions</div></a>
                </div>
            </div>
        </div>
        <div className="footer-icon-wrap">
            <a href="https://www.facebook.com/OuthireCA" target="_blank"><img alt='' src={facebook} className="footer-icon"/></a>
            <a href="https://twitter.com/OuthireCA" target="_blank"><img alt='' src={twitter} className="footer-icon"/></a>
            <a href="https://www.linkedin.com/company/outhire-ca/" target="_blank"><img alt='' src={ins} className="footer-icon"/></a>
        </div>
        <div className="footer-txt copy-right-txt mo-copy">
                © 2023 Outhire Inc. All rights reserved
                </div>
        </div>
    </div>
        
    )
};

export default Footer;