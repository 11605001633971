import React, { Component } from "react";
import "./styles.css";
import dot from "./../../assets/images/dot.svg";
import check from "./../../assets/images/check.svg";
import close from "./../../assets/images/red-close.svg";
import history from "../../utilities/history";
import MyGoogleLogin from "../SignInBtns/MyGoogleLogin";
import axios from "axios";
import error from "./../../assets/images/error.svg";
import { ProfileActionCreators } from "../../actions/patient/profileAction";
import { connect } from "react-redux";
import go from './../../assets/outhire/go.svg';
import LinkedInLogin from "../SignInBtns/LinkedInLogin";

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isChecked: false,
      isPwEmpty: true,
      isPwShow: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      isEmailValid: null,
      isPasswordValid: null,
      isFirstValid: null,
      isLastValid: null,
      hasLength: null,
      hasLowercase: null,
      hasUppercase: null,
      hasSpecial: null,
      hasNumber: null,
      isPwFocus: true,
      message: "",
    };
  }

  componentDidMount() {
    this.setState({
      // isChecked: false,
      isPwEmpty: true,
      isPwShow: false,
      email: "",
      password: "",
      isEmailValid: true,
      isPasswordValid: null,
      isFirstValid: true,
      isLastValid: true,
      hasLength: null,
      hasLowercase: null,
      hasUppercase: null,
      hasSpecial: null,
      hasNumber: null,
      isPwFocus: true,
      message: "",
    });
  }

  //Checkbox onChange handler
  // toggleCheck = () => {
  //   this.setState({
  //     isChecked: !this.state.isChecked,
  //   });
  // };

  //Password input onChange handler
  handlePwChange = (e) => {
    if (e.target.value !== "") {
      this.setState({
        isPwEmpty: false,
        isPwFocus: true,
        password: e.target.value,
      });

      //Check if entered password length is between 8 to 15 characters
      if (e.target.value.length < 8 || e.target.value.length > 15) {
        this.setState({
          hasLength: false,
          isPasswordValid: false,
        });
      } else {
        this.setState({
          hasLength: true,
        });

        //Last check if all other conditions are met
        if (
          this.state.hasLowercase &&
          this.state.hasNumber &&
          this.state.hasUppercase &&
          this.state.hasSpecial
        ) {
          this.setState({
            isPasswordValid: true,
          });
        }
      }

      //Check if entered password contains a lowercase character
      if (e.target.value === e.target.value.toUpperCase()) {
        this.setState({
          hasLowercase: false,
          isPasswordValid: false,
        });
      } else {
        this.setState({
          hasLowercase: true,
        });

        //Last check if all other conditions are met
        if (
          this.state.hasLength &&
          this.state.hasNumber &&
          this.state.hasUppercase &&
          this.state.hasSpecial
        ) {
          this.setState({
            isPasswordValid: true,
          });
        }
      }

      //Check if entered password contains a uppercase character
      if (e.target.value === e.target.value.toLowerCase()) {
        this.setState({
          hasUppercase: false,
          isPasswordValid: false,
        });
      } else {
        this.setState({
          hasUppercase: true,
        });

        //Last check if all other conditions are met
        if (
          this.state.hasLength &&
          this.state.hasLowercase &&
          this.state.hasNumber &&
          this.state.hasSpecial
        ) {
          this.setState({
            isPasswordValid: true,
          });
        }
      }

      //Check if entered password contains a special character
      const specialExpression = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (specialExpression.test(e.target.value)) {
        this.setState({
          hasSpecial: true,
        });
        //Last check if all other conditions are met
        if (
          this.state.hasLength &&
          this.state.hasLowercase &&
          this.state.hasNumber &&
          this.state.hasUppercase
        ) {
          this.setState({
            isPasswordValid: true,
          });
        }
      } else {
        this.setState({
          hasSpecial: false,
          isPasswordValid: false,
        });
      }

      //Check if entered password contains a number
      if (/\d/.test(e.target.value)) {
        this.setState({
          hasNumber: true,
        });
        //Last check if all other conditions are met
        if (
          this.state.hasLength &&
          this.state.hasLowercase &&
          this.state.hasUppercase &&
          this.state.hasSpecial
        ) {
          this.setState({
            isPasswordValid: true,
          });
        }
      } else {
        this.setState({
          hasNumber: false,
          isPasswordValid: false,
        });
      }
    } else {
      this.setState({
        isPwEmpty: true,
        hasLength: false,
        hasLowercase: false,
        hasUppercase: false,
        hasSpecial: false,
        hasNumber: false,
        isPasswordValid: false,
        isPwFocus: false,
      });
    }
  };

  //Email input onChange handler
  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      isEmailValid: true,
      message: "",
    });
  };

  //Toggle to show or hide password
  toggleShow = () => {
    this.setState({
      isPwShow: !this.state.isPwShow,
    });
  };

  //Email input onBlur handler
  handleEmailOnBlur = () => {
    //Validate if the email matches the correct pattern
    const emailExpression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (!emailExpression.test(String(this.state.email).toLowerCase())) {
      this.setState({
        isEmailValid: false,
      });
    }
  };

  handleFirstChange = (e) => {
    this.setState({
      firstName: e.target.value
    }, () => {
      if(this.state.firstName != '') {
        this.setState({
          isFirstValid: true
        })
      }else {
        this.setState({
          isFirstValid: false
        })
      }
    })
  }

  handleFirstOnBlur = () => {
    if(this.state.firstName != "") {
      this.setState({
        isFirstValid: true
      })
    }else{
      this.setState({
        isFirstValid: false
      })
    }
  }

  handleLastChange = (e) => {
    this.setState({
      lastName: e.target.value
    }, () => {
      if(this.state.lastName != '') {
        this.setState({
          isLastValid: true
        })
      }else{
        this.setState({
          isLastValid: false
        })
      }
    })
  }

  handleLastOnBlur = () => {
    if(this.state.lastName != "") {
      this.setState({
        isLastValid: true
      })
    }else{
      this.setState({
        isLastValid: false
      })
    }
  }

  //Password input onBlur handler
  handlePwOnBlur = (e) => {
    if (e.target.value === "") {
      this.setState({
        isPwEmpty: true,
        hasLength: false,
        hasLowercase: false,
        hasUppercase: false,
        hasSpecial: false,
        hasNumber: false,
        isPwFocus: false,
      });
    } else if (!this.state.isPasswordValid) {
      this.setState({
        isPwFocus: false,
      });
    }
  };

  register = () => {
    const payload = {
      email: this.state.email,
      password: this.state.password,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      user_type: "candidate",
      status: "unverified"
    };

    const apiUrl = "api/v1/signup";

    axios.post(apiUrl, payload).then((result) => {
      if (result.data.status === "error") {
        this.setState({ message: result.data.message });
      } else {
        history.push(
          "/registrationconfirmation?email="+payload.email
        );
      }
    });
  }

  render() {
    return (
      <div className="signup-form">
        <div className="title-txt font-medium size-2em text-center mb-60">Registration</div>
    
        <div className="main-form">
        <div className="form-group">
            <input
              className="input-box size-1em"
              type="text"
              name="first"
              placeholder="First Name"
              style={{
                borderColor: this.state.isFirstValid ? "#E8ECF4" : "#f47160",
              }}
              onChange={this.handleFirstChange}
              onBlur={this.handleFirstOnBlur}
            />
            {!this.state.isFirstValid && (
              <div className="error-msg">
                First name can not be empty
              </div>
            )}
          </div>
          <div className="form-group mt-16">
            <input
              className="input-box size-1em"
              type="text"
              name="first"
              placeholder="Last Name"
              style={{
                borderColor: this.state.isLastValid ? "#E8ECF4" : "#f47160",
              }}
              onChange={this.handleLastChange}
              onBlur={this.handleLastOnBlur}
            />
            {!this.state.isLastValid && (
              <div className="error-msg">
                Last name can not be empty
              </div>
            )}
          </div>
          <div className="form-group mt-16">
            <input
              className="input-box size-1em"
              type="email"
              name="email"
              placeholder="Email"
              style={{
                borderColor: this.state.isEmailValid ? "#E8ECF4" : "#f47160",
              }}
              onChange={this.handleEmailChange}
              onBlur={this.handleEmailOnBlur}
            />
            {!this.state.isEmailValid && (
              <div className="error-msg">
                Please enter email format as example@gmail.com
              </div>
            )}
          </div>
          {this.state.message != "" && this.state.message != "success" && (
            <div className="error-wrap mt-16">
              <div className="error-icon-wrap">
                <img src={error} className="error-icon" />
              </div>
              <div className="error-txt-wrap">
                <div className="error-txt height-auto">
                  {this.state.message}
                </div>
              </div>
            </div>
          )}
          <div className="form-group mt-18 mb-12">
            <div className="pw-wrap" style={{
                  borderColor: this.state.isPwFocus ? "#E8ECF4" : "#f47160",
                }}>
              <input
                className="input-box-pw size-1em"
                name="password"
                placeholder="Password"
                type={this.state.isPwShow ? "text" : "password"}
                onChange={this.handlePwChange}
                onBlur={this.handlePwOnBlur}
              />
              {!this.state.isPwEmpty && !this.state.isPwShow && (
                <a className="show-txt" onClick={this.toggleShow}>
                  Show
                </a>
              )}
              {!this.state.isPwEmpty && this.state.isPwShow && (
                <a className="show-txt" onClick={this.toggleShow}>
                  Hide
                </a>
              )}
            </div>
          </div>
          <div className="pw-hints-wrap">
            <div className="pw-hint-title font-regular size-small">
              Your password must contain:
            </div>
            <div className="pw-hints-list">
              <div className="left-list">
                {/* Password length check */}
                <div className="list-wrap mb-5">
                  <div className="dot-wrap">
                    <img
                      src={
                        this.state.hasLength == null
                          ? dot
                          : this.state.hasLength
                          ? check
                          : close
                      }
                      className="dot-icon"
                    />
                  </div>
                  <div
                    className="list-txt font-regular size-small"
                    style={{
                      color:
                        this.state.hasLength == null
                          ? "#607890"
                          : this.state.hasLength
                          ? "#4ba08c"
                          : "#f47160",
                    }}
                  >
                    8-15 characters
                  </div>
                </div>
                {/* Password uppercase character check */}
                <div className="list-wrap mb-5">
                  <div className="dot-wrap">
                    <img
                      src={
                        this.state.hasUppercase == null
                          ? dot
                          : this.state.hasUppercase
                          ? check
                          : close
                      }
                      className="dot-icon"
                    />
                  </div>
                  <div
                    className="list-txt font-regular size-small"
                    style={{
                      color:
                        this.state.hasUppercase == null
                          ? "#607890"
                          : this.state.hasUppercase
                          ? "#4ba08c"
                          : "#f47160",
                    }}
                  >
                    1 uppercase
                  </div>
                </div>
                {/* Password number check */}
                <div className="list-wrap">
                  <div className="dot-wrap">
                    <img
                      src={
                        this.state.hasNumber == null
                          ? dot
                          : this.state.hasNumber
                          ? check
                          : close
                      }
                      className="dot-icon"
                    />
                  </div>
                  <div
                    className="list-txt font-regular size-small"
                    style={{
                      color:
                        this.state.hasNumber == null
                          ? "#607890"
                          : this.state.hasNumber
                          ? "#4ba08c"
                          : "#f47160",
                    }}
                  >
                    1 number
                  </div>
                </div>
              </div>
              <div className="right-list">
                {/* Password lowercase character check */}
                <div className="list-wrap mb-5 right">
                  <div className="dot-wrap">
                    <img
                      src={
                        this.state.hasLowercase == null
                          ? dot
                          : this.state.hasLowercase
                          ? check
                          : close
                      }
                      className="dot-icon"
                    />
                  </div>
                  <div
                    className="list-txt font-regular size-small"
                    style={{
                      color:
                        this.state.hasLowercase == null
                          ? "#607890"
                          : this.state.hasLowercase
                          ? "#4ba08c"
                          : "#f47160",
                    }}
                  >
                    1 lowercase
                  </div>
                </div>
                {/* Password special character check */}
                <div className="list-wrap mb-5 right">
                  <div className="dot-wrap">
                    <img
                      src={
                        this.state.hasSpecial == null
                          ? dot
                          : this.state.hasSpecial
                          ? check
                          : close
                      }
                      className="dot-icon"
                    />
                  </div>
                  <div
                    className="list-txt font-regular size-small"
                    style={{
                      color:
                        this.state.hasSpecial == null
                          ? "#607890"
                          : this.state.hasSpecial
                          ? "#4ba08c"
                          : "#f47160",
                    }}
                  >
                    1 special character
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16">
            <button
              className="btn btn-solid btn-full font-18"
              type="submit"
              disabled={
                this.state.isEmailValid && this.state.isPasswordValid && this.state.isFirstValid && this.state.isLastValid
                  ? false
                  : true
              }
              onClick={this.register}
            >
              Register
            </button>
          </div>
          <div className="divide-wrap height-20px">
                        <div className="divider-or"></div>
                        <div className="middle-txt-or size-1em font-small text-center"> <div className='margin-txt-or'>Or</div> </div>
                        <div className="divider-or"></div>
                    </div>
                    <LinkedInLogin onUserType={this.handleUserType}/>
        </div>

        {/* <div className="bottom-wrap mt-45">
          <div className="font-regular size-1em text-center mb-small">
            Already have an account?
          </div>
          <div className="link-wrap">
            {" "}
            <a className="signup-link" onClick={() => history.push("/signin")}>
              Login
            </a>
          </div>
        </div> */}
        <div className="bottom-wrap mt-24 row  w-sign-351">
                        <div className="bottom-sign-in"> Already have an account?</div>
                        <div className="bottom-sign-in ml-8"> <a className="cursor-on" onClick={() => history.push('/signin')}> Login here</a></div>
                        <img alt='' className="go-sign-icon cursor-on ml-8" src={go} onClick={() => history.push('/signin')}/>
                    </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.patient.user,
  };
};

export default connect(mapStateToProps)(SignUpForm);
