import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import home from './../../assets/outhire/Frame2.png'
import employer1 from './../../assets/outhire/candidate1.svg'
import home4 from './../../assets/outhire/home4.svg'
import home5 from './../../assets/outhire/home5.svg'
import home6 from './../../assets/outhire/home6.svg'
import num1 from './../../assets/outhire/num1.svg'
import num2 from './../../assets/outhire/num2.svg'
import num3 from './../../assets/outhire/num3.svg'
import num4 from './../../assets/outhire/num4.svg'
import icon_group1 from './../../assets/outhire/icon-group1.svg'
import icon_group2 from './../../assets/outhire/icon-group2.svg'
import go from './../../assets/outhire/go.svg'
// import logo from './../../assets/outhire/outhire.svg';
import facebook from './../../assets/outhire/icon-facebook.svg';
import twitter from './../../assets/outhire/icon-twitter.svg';
import ins from './../../assets/outhire/icon-ins.svg';
import better from './../../assets/outhire/better.svg';
// import networking from './../../assets/outhire/networking.svg';
// import transparency from './../../assets/outhire/transparency.svg';
// import updates from './../../assets/outhire/updates.svg';
import Footer from "../../components/Landing/Footer";
import resume from './../../assets/outhire/1resume.svg';
import white from './../../assets/outhire/white.svg';
import networking from './../../assets/outhire/2networking.svg';
import transparency from './../../assets/outhire/3transparency.svg';
import updates from './../../assets/outhire/4updates.svg';
import solid_arrow from './../../assets/outhire/solid-arrow.svg';
import solid_prev from './../../assets/outhire/solid-prev.svg';
import AnimatedNumbers from "react-animated-numbers";
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';
import can_lines from './../../assets/outhire/can-lines.svg';
import cresume from './../../assets/outhire/cresume.svg';
import cnetworking from './../../assets/outhire/cnetworking.svg';
import ctransparency from './../../assets/outhire/ctransparency.svg';
import cupdates from './../../assets/outhire/cupdates.svg';

class Candidates extends Component {
    state = {
      index: 0,
      detailExanded: false
    };

    toggleNext = () => {
        if(this.state.index < 3){
            this.setState({
                index: this.state.index + 1,
                detailExanded: false
            })
        }else{
            this.setState({
                index: 0,
                detailExanded: false
            })
        }
    }

    
    togglePrev = () => {
        if(this.state.index >= 1){
            this.setState({
                index: this.state.index - 1,
                detailExanded: false
            })
        }else{
            this.setState({
                index: 3,
                detailExanded: false
            })
        }
    }

    toggleLearn = () => {
        this.setState ({
            detailExanded: true
        })
    }
  
    render() {
      return (
        <div className="App">
          <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap candidate-bg">
            <img className="white-poligon" src={white} alt=''/>
                <div className="first-wrap candidate-first">
                {/* <div className="diagonal-box"></div> */}
                
                <div className="main-first-wrap mb-32">
                
                    <div className="first-text-wrap candidate-first-text">
                        <div className="web-nav">
                        <div className="home-title w-full">Find your dream job,</div>
                        <div className="home-title w-full">fast</div>
                        </div>
                        <div className="mo-wrap">
                        <div className="home-title w-full">Find your</div>
                        <div className="home-title w-full">dream job, fast</div>
                        </div>
                        <div className="animation-txt-wrap web-home-txt">
                        <div className="home-body-txt w-496">Outhire helps accounting & finance professionals land the perfect role</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/explore-jobs')}>Find Jobs</button>
                        </div>
                        </div>
                    </div>
                    <img alt='' className="home-img1" src={home}/>
                </div>
                   
                </div>
                <div className="main-bottom-wrap">
                <div className="full-w-card mo-show mt-emy">
                <div className="animation-txt-wrap">
                        <div className="home-body-txt">Outhire helps accounting & finance professionals land the perfect role</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/explore-jobs')}>Find Jobs</button>
                        </div>
                        </div>
                </div>
                <div className="home-card-wrap mb-75 p-54  pb-0">
                    <div className="row text-left  mo-center mo-column">
                    <div className="home-card-group mo-img">
                            <img src={employer1} alt='' className="home-group-img employer-img"/>
                        </div>
                        <div className="home-card-txt-wrap sans-font">
                            <div className="home-bold-txt padding-employer2 w-445 mo-bold-txt">The perfect role could be waiting for you right now</div>
                            <div className="line-row candidate-line-row  padding-employer2 mo-open-role">
                   <div className="icon-txt w-fit pop-font cursor" onClick={() => history.push('/explore-jobs')}>See open roles</div>
                   <img alt='' className="go-icon" src={go} onClick={() => history.push('/explore-jobs')}/>
                   </div>
                        </div>
                        <div className="home-card-group home-card-employer1">
                            <img src={employer1} alt='' className="home-group-img employer-img"/>
                        </div>
                    </div>
                </div>
                <div className="home-card-wrap mb-75">
                <div className="home-sub-title w-813 mb-64 mo-sub-title">Sign up in minutes and be considered for roles within 24 hours</div>
                    <div className="row text-left card-list-wrap  mo-column">
                        <div className="card-txt-list text-center ml-5p mo-row">
                            <div className="num-img-wrap">
                            <img alt='' className="num-img" src={num1}/>
                            </div>
                            <div className="card-txt-body card-body-sm w-160">After meeting you, we build your Visual Resume to make your strengths stand out</div>
                        </div>
                        <div className="can-lines-wrap">
                            <img src={can_lines} alt='' className="can-lines-img"/>
                        </div>
                        <div className="card-txt-list text-center  mo-row">
                        <div className="num-img-wrap">
                        <img alt='' className="num-img" src={num2}/>
                        </div>
                            <div className="card-txt-body card-body-sm w-160">We notify you when a position aligns with your unique skills</div>
                        </div>
                        <div className="can-lines-wrap">
                            <img src={can_lines} alt='' className="can-lines-img"/>
                        </div>
                        <div className="card-txt-list text-center  mo-row">
                        <div className="num-img-wrap">
                        <img alt='' className="num-img" src={num3}/>
                        </div>
                            <div className="card-txt-body card-body-sm w-160">We schedule the interview and help you prepare for it</div>
                        </div>
                        <div className="can-lines-wrap">
                            <img src={can_lines} alt='' className="can-lines-img"/>
                        </div>
                        <div className="card-txt-list text-center mr-5p  mo-row">
                        <div className="num-img-wrap">
                        <img alt='' className="num-img" src={num4}/>
                        </div>
                            <div className="card-txt-body card-body-sm w-160">We make it easy for you to sign off and start work, hassle-free</div>
                        </div>
                    </div>
                </div>
                <div className="home-card-wrap mb-75">
                <div className="home-sub-title w-no mb-64 sans-font mo-2line">More opportunities, less headache</div>
                    {/* <div className="row text-left card-list-wrap">
                        <div className="card-txt-list text-center ml-5p">
                            <img alt='' className="flow-img" src={better}/>
                            <div className="card-txt-title w-191">Better than a resume</div>
                            <div className="card-txt-body card-body-sm w-160 no-height">Our unique Visual Resume showcases your abilities in the brightest light
</div>
                        </div>
                        <div className="card-txt-list text-center">
                            <img alt='' className="flow-img" src={networking}/>
                            <div className="card-txt-title w-191">No need for networking</div>
                            <div className="card-txt-body card-body-sm w-160 no-height">Quit scanning for jobs and stay focused on honing your skills</div>
                        </div>
                        <div className="card-txt-list text-center">
                            <img alt='' className="flow-img" src={transparency}/>
                            <div className="card-txt-title w-191">Complete transparency</div>
                            <div className="card-txt-body card-body-sm w-160 no-height">Have clarity on salary & job expectations, with no surprises</div>
                        </div>
                        <div className="card-txt-list text-center mr-5p">
                            <img alt='' className="flow-img" src={updates}/>
                            <div className="card-txt-title w-191">Instant updates </div>
                            <div className="card-txt-body card-body-sm w-160 no-height">We keep in touch frequently so you don’t play the waiting game  </div>
                        </div>
                    </div> */}
                    <div className={this.state.index==0?"row circle-img-wrap web-nav":this.state.index==1?"row circle-img-wrap move-left1 web-nav":this.state.index==2?"row circle-img-wrap move-left2 web-nav":"row circle-img-wrap move-left3 web-nav"}>
                        <button className="img-btn1" onClick={()=>this.setState({index:0})}></button>
                        <button className="img-btn2" onClick={()=>this.setState({index:1})}></button>
                        <button className="img-btn3" onClick={()=>this.setState({index:2})}></button>
                        <button className="img-btn4" onClick={()=>this.setState({index:3})}></button>
                    <img src={resume} alt='' className="circle-img"/> 
                    <img src={networking} alt='' className="circle-img"/>
                    <img src={transparency} alt='' className="circle-img"/>
                    <img src={updates} alt='' className="circle-img"/>
                    </div>
                    <div className="flex"><div className={this.state.index==0?"row circle-img-wrap mo-wrap f-nowrap":this.state.index==1?"row circle-img-wrap move-left1 mo-wrap f-nowrap":this.state.index==2?"row circle-img-wrap move-left2 mo-wrap f-nowrap":"row circle-img-wrap move-left3 mo-wrap f-nowrap"}>
                    <img src={cresume} alt='' className="circle-img"/> 
                    <img src={cnetworking} alt='' className="circle-img"/>
                    <img src={ctransparency} alt='' className="circle-img"/>
                    <img src={cupdates} alt='' className="circle-img"/>
                   
                    </div>
                    {this.state.index !== 0 && (
                        <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                    )}
                    <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                    </div>
                    {this.state.index == 0 && (
                        <div className="relative-container">
                        {/* <img src={resume} alt='' className="circle-img"/> */}
                        <div className="home-sub-title w-no mt-47">Better than a resume</div>
                        <div className="row margin-center">
                            <div className="circle-txt-wrap">
                                <div className="relative-container">
                                <div className="circle-txt">
                                Our unique visual resume showcases your abilities in the brightest light
                                </div>
                                <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                                </div>
                                {!this.state.detailExanded && (
                                    <a className="circle-link" onClick={this.toggleLearn}>Learn more</a>
                                )}
                               {this.state.detailExanded && (
                                <div>
                                    <br/>
                                     <div className="circle-txt circle-txt1">
                                 Elevator pitches – in combinations with our write-up services, you have the opportunity of to make a personality impression on companies’. If you ever thought to yourself that you would be perfect for a position if only the hiring manager saw you, well with our Visual Resume process, the spotlight is yours.
                                     </div>
                                </div>
                               )}
                            </div>
                            {/* <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/> */}
                        </div>
                        {/* <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/> */}
                      
                        </div>
                    )}
                     
                    {this.state.index == 1 && (
                        <div className="relative-container">
                        {/* <img src={networking} alt='' className="circle-img"/> */}
                        <div className="home-sub-title w-no mt-47">No need for networking</div>
                        <div className="row margin-center">
                            {/* <img src={solid_prev} alt='' className="solid-next" onClick={this.togglePrev}/> */}
                            <div className="circle-txt-wrap">
                            <div className="relative-container">
                                <div className="circle-txt">
                                Quit scanning for jobs and stay focused on honing your skills
                                </div>
                                <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                      <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                                </div>
                                {!this.state.detailExanded && (
                                    <a className="circle-link" onClick={this.toggleLearn}>Learn more</a>
                                )}
                               {this.state.detailExanded && (
                                <div>
                                    <br/>
                                     <div className="circle-txt circle-txt1">
Set your expectations – get the most extensive job descriptions available on the market to ensure you know exactly what you are getting into. Our job descriptions are a cornerstone of how thoroughly we scope out positions on behalf of our candidates.
                                                                 </div>
                                </div>
                               )}
                            </div>
                            {/* <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/> */}
                        </div>
                       
                        </div>
                    )}

{this.state.index == 2 && (
                        <div className="relative-container">
                        {/* <img src={transparency} alt='' className="circle-img"/> */}
                        <div className="home-sub-title w-no mt-47">Complete transparency</div>
                        <div className="row margin-center">
                            {/* <img src={solid_prev} alt='' className="solid-next" onClick={this.togglePrev}/> */}
                            <div className="circle-txt-wrap">
                            <div className="relative-container">
                                <div className="circle-txt">
                                Have clarity on salary & job expectations, with no surprises
                                </div>
                                <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                      <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                                </div>
                                {!this.state.detailExanded && (
                                    <a className="circle-link" onClick={this.toggleLearn}>Learn more</a>
                                )}
                               {this.state.detailExanded && (
                                <div>
                                    <br/>
                                     <div className="circle-txt circle-txt1">
Get your worth – get detailed job compensation details* and when ready, pick the compensation you want to be presented at. Our process enforces that from the outset, companies’ can only move forward to interviewing candidates if they are willing to pay the compensation asked. Save time and avoid the stresses of multiple rounds of interviews concluding with a low-ball offer.
                                                                 </div>
                                </div>
                               )}
                            </div>
                            {/* <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/> */}
                        </div>
                        <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                      <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                        </div>
                    )}

{this.state.index == 3 && (
                        <div className="relative-container">
                        {/* <img src={updates} alt='' className="circle-img"/> */}
                        <div className="home-sub-title w-no mt-47">Instant updates</div>
                        <div className="row margin-center">
                            {/* <img src={solid_prev} alt='' className="solid-next" onClick={this.togglePrev}/> */}
                            <div className="circle-txt-wrap">
                            <div className="relative-container">
                                <div className="circle-txt">
                                We keep in touch frequently so you don’t play the waiting game
                                </div>
                                <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                      <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                                </div>
                                {!this.state.detailExanded && (
                                    <a className="circle-link" onClick={this.toggleLearn}>Learn more</a>
                                )}
                               {this.state.detailExanded && (
                                <div>
                                    <br/>
                                     <div className="circle-txt circle-txt1">
Ghosts no more – from your first phone screen to signing your offer, we give real time updates via our Candidate Portal. Avoid one of the most dreaded aspects of job seeking, the void of the unknown!
                                                                 </div>
                                </div>
                               )}
                            </div>
                            {/* <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/> */}
                        </div>
                        <img src={solid_prev} alt='' className="solid-prev" onClick={this.togglePrev}/>
                      <img src={solid_arrow} alt='' className="solid-next" onClick={this.toggleNext}/>
                        </div>
                    )}
                    
                </div>
                <div className="full-w-card ptb-28">
                    <div className="row blue-wrap web-wrap">
                        <div className="blue-txt-wrap">
                            <div className="blue-title row"><AnimatedNumbers
        includeComma
        animateToNumber={3}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><div className="ml-8"> days</div></div>
                            <div className="blue-body-txt">Time from company <br/>interview to job offer</div>
                        </div>
                        <div className="blue-txt-wrap">
                            <div className="blue-title row">  <AnimatedNumbers
        includeComma
        animateToNumber={72}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">Promotions within 18 months <br/>for Outhire placed Candidates</div>
                        </div>
                        <div className="blue-txt-wrap">
                            <div className="blue-title row">
                                <AnimatedNumbers
        includeComma
        animateToNumber={89}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">% of candidates presented <br/>hired within 3 weeks</div>
                        </div>
                    </div>
                    <div className="mo-wrap">
                    <div className="blue-wrap mb-24">
                        <div className="blue-txt-wrap">
                            <div className="blue-title row"><AnimatedNumbers
        includeComma
        animateToNumber={3}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><div className="ml-8"> days</div></div>
                            <div className="blue-body-txt">Time from company <br/>interview to job offer</div>
                        </div>
                    </div>
                    <div className="blue-wrap mb-24">

                        <div className="blue-txt-wrap">
                            <div className="blue-title row">  <AnimatedNumbers
        includeComma
        animateToNumber={72}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">Promotions within 18 months <br/>for Outhire placed Candidates</div>
                        </div>
                    </div>
                    <div className="blue-wrap">

                        <div className="blue-txt-wrap">
                            <div className="blue-title row">
                                <AnimatedNumbers
        includeComma
        animateToNumber={89}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">% of candidates presented <br/>hired within 3 weeks</div>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="full-w-card ptb-48 mo-px-0">
                    <div className="home-title text-center w-no mb-48">The Outhire Visual Resume</div>
                    <iframe className="web-nav" src="https://player.vimeo.com/video/336080384?h=67678a06c5&color=ffffff&byline=0&portrait=0&badge=0" width="1024" height="576" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <iframe className="mo-wrap mo-frame" src="https://player.vimeo.com/video/336080384?h=67678a06c5&color=ffffff&byline=0&portrait=0&badge=0" width="318" height="179" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="home-card-wrap">
                    <div className="home-title w-no mo-311">Your next big career move starts here</div>
                   <div className="line-row">
                   <button className='btn-save btn-home sans-font' onClick={() => history.push('/portal')}>Sign up now</button>
                   </div>
                </div>
                </div>
                
            </div>
            <Footer/>
          </div>
        </div>
      );
    }
  }
  
  export default Candidates;

