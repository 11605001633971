import React, { Component } from "react";
import history from '../../utilities/history';
import "./Home.css";
import NavBar from '../../components/NavBar/TopNavBar';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

class Home extends Component {
    state = {
      
    };
  
    render() {
      return (

<div className="App">
<div className="content-wrap">
  <NavBar />
</div>
<div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
    <div className="max-width">
    <div className="main-body-wrap candidate-bg pb-0 web-nav">
   
        <div className="first-wrap content-center coloum ">
        <img className="white-poligon home-white" src={white} alt=''/>
        {/* <div className="diagonal-box"></div> */}
        {/* <img className="white-poligon" src={white} alt=''/> */}
            {/* <img alt='' className="home-img candidate-home" src={home}/>
            <div className="first-text-wrap">
                <div className="home-title w-496">Find your dream job, fast</div>
                <div className="home-body-txt w-496">Outhire helps accounting & finance professionals land the perfect role</div>
                <div className="home-btn-wrap">
                    <button className='btn-save btn-home'>Find Jobs</button>
                </div>
            </div> */}
            <div className="top-section">
            <div className="home-title w-full mb-75">Let’s find what you’re looking for</div>
            <div className="home-card-wrap mb-233 top-card home-login-wrap">
                <button className='btn-save btn-home btn-home-big mr-50 mr-0' onClick={() => history.push('/signin')}>Login</button>
                <div className="divide-wrap">
                        <div className="divider-or"></div>
                        <div className="middle-txt-or size-1em font-small text-center mr-0"> Or </div>
                        <div className="divider-or"></div>
                    </div>
                <button className='btn-cancel btn-home btn-home-big mr-0' onClick={() => history.push('/signup')}>Register</button>
        </div>
            </div>
        </div>
    </div>
    <div className="mo-wrap">
    <div className="home-title w-full mb-75 login-home-title">Let’s find what you’re looking for</div>
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
                <button className='btn-save btn-home btn-home-big mr-50 mr-0' onClick={() => history.push('/signin')}>Login</button>
                <div className="divide-wrap pxy-8">
                        <div className="divider-or"></div>
                        <div className="middle-txt-or size-1em font-small text-center mr-0"> Or </div>
                        <div className="divider-or"></div>
                    </div>
                <button className='btn-cancel btn-home btn-home-big mr-0' onClick={() => history.push('/signup')}>Register</button>
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>
      
    
    </div>
</div>
      );
    }
  }
  
  export default Home;

