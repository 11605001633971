import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import bell from './../../assets/outhire/bell.png';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import history from './../../utilities/history';
import dot from "./../../assets/images/dot.svg";
import NavBar from '../../components/NavBar/TopNavBar';
import axios from 'axios';
import setting from './../../assets/outhire/carbon_settings.svg';
import Modal from '../../components/Landing/Modal';
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import registered from './../../assets/outhire/registered.svg';
import phone_screen from './../../assets/outhire/phone-screen.svg';
import zoom_interview from './../../assets/outhire/zoom-interview.svg';
import qualified from './../../assets/outhire/qualified.svg';
import visual_resume_in_progress from './../../assets/outhire/visual-resume-in-progress.svg';
import submitted_to_employer from './../../assets/outhire/submitted-to-employer.svg';
import employer_reviewing from './../../assets/outhire/employer-reviewing.svg';
import employer_interview from './../../assets/outhire/employer-interview.svg';
import follow_up_interview from './../../assets/outhire/follow-up-interview.svg';
import verbal_offer from './../../assets/outhire/verbal-offer.svg';
import not_a_match from './../../assets/outhire/not-a-match.svg';
import appliction_withdrawn from './../../assets/outhire/application-withdrawn.svg';
import active_registered from './../../assets/outhire/active-registered.svg';
import active_phone_screen from './../../assets/outhire/active-phone-screen.svg';
import active_zoom_interview from './../../assets/outhire/active-zoom-interview.svg';
import active_qualified from './../../assets/outhire/active-qualified.svg';
import active_visual_resume_in_progress from './../../assets/outhire/active-visual-resume-in-progress.svg';
import active_submitted_to_employer from './../../assets/outhire/active-submitted-to-employer.svg';
import active_employer_reviewing from './../../assets/outhire/active-employer-reviewing.svg';
import active_employer_interview from './../../assets/outhire/active-employer-interview.svg';
import active_follow_up_interview from './../../assets/outhire/active-follow-up-interview.svg';
import active_verbal_offer from './../../assets/outhire/active-verbal-offer.svg';
import active_not_a_match from './../../assets/outhire/active-not-a-match.svg';
import active_appliction_withdrawn from './../../assets/outhire/active-application-withdrawn.svg';
import role from './../../assets/outhire/role.svg';
import task from './../../assets/outhire/task.svg';
import duration from './../../assets/outhire/duration.svg';
import skill from './../../assets/outhire/skill.svg';
import company from './../../assets/outhire/company.svg';
import opportunity from './../../assets/outhire/opportunity.svg';
import timeline from './../../assets/outhire/timeline.svg';
import compensation from './../../assets/outhire/compensation.svg';
import { connect } from 'react-redux';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import profileAvatar from './../../assets/outhire/profile-avatar.png';

const statuses = [
  {id: 6351221,
    status: 'Registered', //0
    icon: 'registered'
  },
  {id: 6351224,
    status: 'Phone Screen', //1
    icon: 'phone-screen'
  },
  {id: 6351227,
    status: 'Zoom Interview', //2
    icon: 'zoom-interview'
  },
  {id: 6351230,
    status: 'Qualified Match',  //3
    icon: 'qualified'
  },
  {id: 6351239,
    status: 'Visual Resume in Progress', //4
    icon: 'visual-resume-in-progress'
  },
  {id: 6351242,
    status: 'Submitted to Employer', //5
    icon: 'submitted-to-employer'
  },
  {id: 6351245,
    status: 'Employer Reviewing', //6
    icon: 'employer-reviewing'
  },
  {id: 6351251,
    status: 'Employer Interview', //7
    icon: 'employer-interview'
  },
  {id: 6351254,
    status: 'Follow Up Interview', //8
    icon: 'follow-up-interview'
  },
  {id: 6351260,
    status: 'Verbal Offer', //9
    icon: 'verbal-offer'
  },
  {id: 6351263,
    status: 'Successul Placement', //10
    icon: 'verbal-offer'
  },
  {id: 6351233,
    status: 'Not a Match', //11
    icon: 'not-a-match'
  },
  {id: 6351248,
    status: 'Employer Decision - Not a Match', //12
    icon: 'not-a-match'
  },
  {id: 6351257,
    status: 'Offer Declined or Rescinded', //13
    icon: 'not-a-match'
  },
  {id: 6351236,
    status: 'Withdrawn',
    icon: 'application-withdrawn' //14
  },
  {id: 6351209,
    status: 'No Contact', //15
    icon: 'none'
  },
  {id: 6351212,
    status: 'No Contact - Email Applicant', //16
    icon: 'none'
  },
  {id: 6351215,
    status: 'No Contact - Website/Direct Applicant', //17
    icon: 'none'
  },
  {id: 6351266,
    status: 'Contact Attempted - Unaware', //18
    icon: 'none'
  },
  {id: 6351218,
    status: 'Contact Attempted - Interested', //19
    icon: 'none'
  },
  {id: 6351269,
    status: 'Not Interested', //20
    icon: 'registered'
  },
]

const perks = [
  {
    id: 978038,
    title: 'Standard Health Benefits'
  },
  {
    id: 978041,
    title: 'Extended Health Benefits'
  },
  {
    id: 978044,
    title: 'RRSP'
  },
  {
    id: 978047,
    title: 'Stock Options'
  },
  {
    id: 978050,
    title: 'HSA (Health Spending Account)'
  },
  {
    id: 978053,
    title: 'LSA (Lifestyle Spending Account)'
  },
  {
    id: 978056,
    title: 'Mental Health Support'
  },
  {
    id: 978059,
    title: 'Travel Based Incentives'
  },
  {
    id: 978062,
    title: 'CPA Dues Support'
  },
  {
    id: 978065,
    title: 'CPA Program Support'
  },
  {
    id: 978068,
    title: 'Professional Development Support'
  },
  {
    id: 978071,
    title: 'Tokens Distributions'
  },
  {
    id: 978074,
    title: 'Flexible Hours'
  },
  {
    id: 978077,
    title: 'Home Office Support'
  },
  {
    id: 986375,
    title: 'Vehicle Allowance'
  },
]

class MyJobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {title: '', type: ''},
      role_detail: '',
      task: '',
      skill: '',
      company: '',
      opportunity: '',
      timeline: '',
      office: '',
      isInterestedModal: false,
      isInterested: false,
      app_status:{},
      status_type: '',
      isWithdrawModal: false,
      company_name: '',
      address: '',
      hiring_manager: '',
      video: '',
      thumbnail: '',
      salary: '',
      cash: '',
      noncash: '',
      isTimelineModal: false,
      pipeline_id: null,
      isWithdrawConfirmModal: false,
      is_withdrawing: false,
      is_copied: false,
      company_item: {},
      candidateStatus: {},
      perks: [],
      hourly_rate: null,
      start_date: null,
      avg_salary: '',
      team_size: '',
      benefits_plan: '',
      min_years: null,
      website_link: '',
      timeline_full: ''
    }
  }

  componentDidMount() {

    const apiCandidate = 'api/catstone/candidates/getCandidateById'

    const candidatePayload = {
      id: this.props.user.catsone_id
    }

    axios.post(apiCandidate, candidatePayload)
      .then((result) => {
        for (let i = 0; i < result.data._embedded.custom_fields.length; i++) {
          if(result.data._embedded.custom_fields[i].id == 342938){
            if(result.data._embedded.custom_fields[i].value == 984770){
              this.setState({ candidateStatus: {
                index: 0,
                status: 'Unregistered' 
              }});
            }else if(result.data._embedded.custom_fields[i].value == 984773){
              this.setState({candidateStatus: {
                index: 1,
                status: 'Registered' 
              }});
            }else if(result.data._embedded.custom_fields[i].value == 984776){
              this.setState({ candidateStatus: {
                index: 2,
                status: 'Verified' 
              } });
            }else if(result.data._embedded.custom_fields[i].value == 984779){
              this.setState({candidateStatus: {
                index: 3,
                status: 'Selected' 
              } });
            }
          }
        }
      })

    const apiJob = 'api/catstone/jobs/getJobById'
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

                        const payload = {
                            id: id
                        }

                        axios.post(apiJob, payload)
                            .then((result) => {
                                console.log(result)

                                this.setState({
                                  company_item: result.data._embedded.company,
                                })

                                var apiThumbnail = "api/catstone/companies/getThumbnail";
                                  var thumbnailPayload = {
                                    id: result.data.company_id
                                  }
            
                                  axios.post(apiThumbnail, thumbnailPayload)
                                    .then((res) => {
                                      this.setState({
                                        thumbnail:  res.data.url
                                      })
                                    })

                                for(let i = 0; i < result.data._embedded.custom_fields.length; i++){
                                  if(result.data._embedded.custom_fields[i].id==341462){
                                    this.setState({
                                      role_detail: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341459){
                                    this.setState({
                                      task: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341450){
                                    this.setState({
                                      skill: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341456){
                                    this.setState({
                                      company: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341474){
                                    this.setState({
                                      opportunity: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341477){
                                    this.setState({
                                      timeline: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341576){
                                    this.setState({
                                      timeline_full: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341597){
                                    this.setState({
                                      company_name: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341603){
                                    this.setState({
                                      address: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==332545){
                                    this.setState({
                                      video: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==344345){
                                    this.setState({
                                      hiring_manager: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341582){
                                    this.setState({
                                      salary: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341573){
                                    this.setState({
                                      cash: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341585){
                                    this.setState({
                                      noncash: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341471){
                                    this.setState({
                                      perks: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341579){
                                    this.setState({
                                      hourly_rate: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341468){
                                    this.setState({
                                      avg_salary: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==312654){
                                    this.setState({
                                      start_date: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341570){
                                    this.setState({
                                      team_size: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341588){
                                    this.setState({
                                      benefits_plan: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==343295){
                                    this.setState({
                                      website_link: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341591){
                                    this.setState({
                                      min_years: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  
                                  if(result.data._embedded.custom_fields[i].id==313188){
                                    if(result.data._embedded.custom_fields[i].value == 874521){
                                      result.data.type = 'Salaried Permanent';
                                    } else if(result.data._embedded.custom_fields[i].value == 874524){
                                      result.data.type = 'Salaried Contract';
                                    } else if(result.data._embedded.custom_fields[i].value == 874527){
                                      result.data.type = 'Hourly Contract';
                                    } else if(result.data._embedded.custom_fields[i].value == 874530){
                                      result.data.type = 'Hourly Permanent';
                                    
                                  }
                                }

                                  if(result.data._embedded.custom_fields[i].id==341465){
                                    switch(result.data._embedded.custom_fields[i].value[0]){
                                        case 978020:
                                            this.setState({office: 'Permanently In-Office'});
                                            break;
                                        case 978023:
                                            this.setState({office: 'Temporarily In-Office'});
                                            break;
                                        case 978026:
                                            this.setState({office: 'Temporary Hybrid'});
                                            break;
                                        case 978029:
                                            this.setState({office: 'Permanently Hybrid'});
                                            break;
                                        case 978032:
                                            this.setState({office: 'Permanently Remote'});
                                            break;
                                        case 978035:
                                            this.setState({office: 'Temporarily Remote'});
                                            break;
                                    }
                                  }
                                }
                                this.setState({
                                  job: result.data
                                }, () => {
                                  console.log(this.state.job)
                                })
                            })

                            const apiPipelines = 'api/catstone/candidates/listPipelines'
                            const candidate = {
                              id: this.props.user.catsone_id
                            }
                        
                                                axios.post(apiPipelines, candidate)
                                                    .then((res) => {
                                                      console.log(res)
                                                      if(res.data.total > 0){
                                                        for(let i = 0; i < res.data._embedded.pipelines.length; i++){
                                                          if( res.data._embedded.pipelines[i].job_id == id){
                                                            this.setState({
                                                              isInterested: true,
                                                              pipeline_id: res.data._embedded.pipelines[i].id
                                                            })
                                                            if( res.data._embedded.pipelines[i].status_id == 6351209 || res.data._embedded.pipelines[i].status_id==6351212 || res.data._embedded.pipelines[i].status_id==6351215|| res.data._embedded.pipelines[i].status_id==6351266|| res.data._embedded.pipelines[i].status_id==6351218){
                                                              var status = statuses[0];
                                                              status.index = 0;
                                                              this.setState({
                                                                app_status: status,
                                                                status_type: 'normal'
                                                              }, () => {
                                                                console.log(this.state.app_status)
                                                              })
                                                            }else if(res.data._embedded.pipelines[i].status_id == 6351269) {
                                                              var status = statuses[14];
                                                              status.index = 0;
                                                              this.setState({
                                                                app_status: status,
                                                                status_type: 'withdrawn'
                                                              }, () => {
                                                                console.log(this.state.app_status)
                                                              })
                                                            }else{
                                                              for(let j = 0; j < statuses.length; j++){
                                                                if(statuses[j].id == res.data._embedded.pipelines[i].status_id){
                                                                  if(statuses[j].id == 6351233 || statuses[j].id == 6351248){
                                                                    this.setState({
                                                                      status_type: 'not match'
                                                                    })
                                                                  }else if(statuses[j].id == 6351236){
                                                                    this.setState({
                                                                      status_type: 'withdrawn'
                                                                    })
                                                                  }else{
                                                                    this.setState({
                                                                      status_type: 'normal'
                                                                    })
                                                                    var status = statuses[j];
                                                                  status.index = j;
                                                                  this.setState({
                                                                    app_status: status
                                                                  }, () => {
                                                                    console.log(this.state.app_status)
                                                                  })
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            
                                                          }
                                                        }
                                                      }
                                                    })
  }

  openInterestedModal = () => {
    const apiPipelines = 'api/catstone/pipelines/create'
    const payload = {
      candidate_id: this.props.user.catsone_id,
      job_id: this.state.job.id
    }

                        axios.post(apiPipelines, payload)
                            .then((res) => {
                              console.log(res)
                              this.setState({
                                isInterestedModal: true,
                                isInterested: true
                              })
                            })
  }

  hideInterestedModal = () => {
    this.setState({
      isInterestedModal: false
    })
  }

  openTimeline = () => {
    this.setState({
      isTimelineModal: true
    })
  }

  hideTimeline = () => {
    this.setState({
      isTimelineModal: false
    })
  }

  openWithdrawModal = () => {
    this.setState({
      isWithdrawModal: true
    })
  }

  hideWithdrawModal = () => {
    this.setState({
      isWithdrawModal: false,
      is_withdrawing: false
    })
  }

  withdrawApplication = () => {
    this.setState({
      is_withdrawing: true
    })
    const apiPipelines = 'api/catstone/pipelines/updateStatus'
    const payload = {
      id: this.state.pipeline_id,
      body: {
        status_id: 6321083
      }
    }

                        axios.post(apiPipelines, payload)
                            .then((res) => {
                              console.log(res)
                              this.setState({
                                isWithdrawConfirmModal: true,
                                isWithdrawModal: false,
                                is_withdrawing: false
                              })
                            })
  }

  hideWithdrawConfirm = () => {
    this.setState({
      isWithdrawConfirmModal: false,
      is_withdrawing: false
    })
  }

  copyLink = () => {
    navigator.clipboard.writeText("https://portal.outhire.ca/explore-jobs/details?id=" + this.state.job.id);
    this.setState({
      is_copied: true
    })
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          
            <div className='main-canvas'>
            <div className='top-sec job-top'>
              <div className='top-left'>
               <div className='card-container'>
               <div className='card f-card'>
                  <div className='card-head'>
                    <div className='card-title job-card-title'>{this.state.job.title}</div>
                  </div>
                  <div className='info-group'>
                    <div className='info-label'>Location</div>
                    {this.state.job.location!=undefined && (
                      <div className='info-txt'>{this.state.job.location['city']}, {this.state.job.location['state']}</div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row'>
                  <div className='info-group'>
                    <div className='info-label'>Type</div>
                    {this.state.office == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.office}</div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Compensation Type</div>
                    {/* {this.state.job.type == 'H'? (
                        <div className='info-txt'>Hourly</div>
                    ) : this.state.job.type=='S'? (
                        <div className='info-txt'>Salaried</div>
                    ) : (
                        <div className='info-txt'>Unknown</div>
                    )} */}
                      {this.state.job.type != '' && this.state.job.type != undefined ? (
                      <div className='info-txt'>{this.state.job.type}</div>
                    ) : (
                      <div className='info-txt'>Not set</div>
                    )}
                  </div>
                  </div>
                  <div className='info-divider'></div>
                </div>
               </div>
              </div>
              <div>
              {this.state.status_type == 'normal' ? (
                <div className='top-status-card'>
                <div className='status-title'>Application Status</div>
               {(this.state.app_status.index >= 1 && this.state.app_status.index <= 20) ? (
                 <div className='status-row-wrap'>
                 <div className='status-iten-wrap'>
                   <div className='status-icon-wrap'>
                     <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 1].icon+".svg"}/>
                   </div>
                   
                   <div className='a-status-txt'>{statuses[this.state.app_status.index - 1].status}</div>
                 </div>
                 <div className='a-status-step'></div>
                 <div className='status-iten-wrap'>
                   <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                   <div className='a-status-txt'>{this.state.app_status.status}</div>
                 </div>
                 <div className='a-status-step'></div>
                 <div className='status-iten-wrap'>
                   <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index + 1].icon+".svg"}/>
                   <div className='a-status-txt'>{statuses[this.state.app_status.index + 1].status}</div>
                 </div>
               </div>
               ): this.state.app_status.index == 0 ?(
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                  <div className='a-status-txt'>{this.state.app_status.status}</div>
                </div>
                <div className='a-status-step'></div>
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index + 1].icon+".svg"}/>
                  <div className='a-status-txt'>{statuses[this.state.app_status.index + 1].status}</div>
                </div>
                <div className='a-status-step'></div>
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index +2 ].icon+".svg"}/>
                  <div className='a-status-txt'>{statuses[this.state.app_status.index + 2].status}</div>
                </div>
              </div>
               ) : this.state.app_status.index >18 &&(
                 <div className='status-row-wrap'>
                 <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 2].icon+".svg"}/>
                    <div className='a-status-txt'>{statuses[this.state.app_status.index -2].status}</div>
                  </div>
                  <div className='a-status-step'></div>
                  
                  <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 1].icon+".svg"}/>
                    <div className='a-status-txt'>{statuses[this.state.app_status.index -1].status}</div>
                  </div>
                  <div className='a-status-step'></div>
                  <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                    <div className='a-status-txt'>{this.state.app_status.status}</div>
                  </div>
                  
                  
                  
                </div>
               )}
              </div>
              ) : this.state.status_type == 'not match' ? (
                <div className='top-status-card'>
                <div className='status-title'>Application Status</div>
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap status-center'>
                  <img alt='' className='status-icon' src={"images/active-not-a-match.svg"}/>
                  <div className='a-status-txt'>Not a Match</div>
                </div>
              </div>
              </div>
              ) : this.state.status_type == 'withdrawn' && (
                <div className='top-status-card'>
                <div className='status-title'>Application Status</div>
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap status-center'>
                  <img alt='' className='status-icon' src={"images/application-withdrawn.svg"}/>
                  <div className='a-status-txt'>Application Withdrawn</div>
                </div>
              </div>
              </div>
              )}
              {/* <div className='top-status-card'>
                <div className='status-title'>Application Status</div>
                <div className='status-row-wrap'>
                  <div className='status-iten-wrap'>
                  <div className='status-icon-wrap'>
                    <img alt='' className='status-icon' src={registered}/>
                    </div>
                  </div>
                  <div className='a-status-step'></div>
                  <div className='status-iten-wrap'>
                  <div className='status-icon-wrap'>
                    <img alt='' className='status-icon' src={registered}/>
                    </div>
                  </div>
                  <div className='a-status-step'></div>
                  <div className='status-iten-wrap'>
                  <div className='status-icon-wrap'>
                    <img alt='' className='status-icon' src={registered}/>
                    </div>
                  </div>
                </div>
                <div className='status-txt-row'>
                <div className='a-status-txt'>Registered</div>
                <div className='a-status-txt'>Phone-Screen</div>
                <div className='a-status-txt'>Zoom Interview</div>
                </div>
              </div> */}
              </div>
              <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={this.props.user.thumbnail} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            {this.state.candidateStatus.index > 2 || [7,8,9,10].indexOf(this.state.app_status.index) !== -1 && (
              <div className='card-wrap-full web-nav'>
              <div className="card detail-card">
              <div className='card-head'>
                    <div className='card-title job-card-title'>{this.state.company_name} </div>
                  </div>
                  <div className='cm-row'>
                      {this.state.thumbnail != '' && (
                        <div className='cm-img-wrap'>
                        <img alt='' src={this.state.thumbnail} className="cm-img" />
                      </div>
                      ) }
                      <div className='cm-txt-wrap'>
                      <div className='info-group mt-0'>
                    <div className='info-label mt-0'>Address</div>
                    {this.state.address != null && this.state.address != '' ? (
                     <div className='info-txt'>{this.state.address} </div>
                    ) : (
                      (this.state.company_item.address!=null && this.state.company_item.address!=undefined ) ? (
                        <div className='info-txt'>{this.state.company_item.address.street} </div>
                      ) : (
                        <div className='info-txt'>Not set </div>
                      )
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row'>
                  <div className='info-group'>
                    <div className='info-label'>Hiring Manager</div>
                    {this.state.hiring_manager == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.hiring_manager}</div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Video Introduction</div>
                    {this.state.video == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'><a className='link' href={this.state.video} target="_blank">{this.state.video}</a></div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Company Website</div>
                    {this.state.website_link == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'><a className='link' href={this.state.video} target="_blank">{this.state.website_link}</a></div>
                    )}
                  </div>
                  </div>
                  <div className='info-divider'></div>
                      </div>
                    </div>
              </div>
              </div>
            )}
           
           
{this.state.candidateStatus.index > 2 || [7,8,9,10].indexOf(this.state.app_status.index) !== -1 && (
              <div className='card-wrap mo-wrap mo-card-wrap-full'>
              <div className="card detail-card">
              <div className='card-head row'>
                    <div className='card-title job-card-title'>{this.state.company_name} </div>
                    {this.state.thumbnail != '' && (
                        <div className='cm-img-wrap'>
                        <img alt='' src={this.state.thumbnail} className="cm-img" />
                      </div>
                      ) }
                  </div>
                  <div className='cm-row'>
                      <div className='cm-txt-wrap'>
                      <div className='info-group mt-0 info-video-group'>
                    <div className='info-label mt-0'>Address</div>
                    {this.state.address != null && this.state.address != '' ? (
                     <div className='info-txt'>{this.state.address} </div>
                    ) : (
                      (this.state.company_item.address!=null && this.state.company_item.address!=undefined ) ? (
                        <div className='info-txt'>{this.state.company_item.address.street} </div>
                      ) : (
                        <div className='info-txt'>Not set </div>
                      )
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row mo-flex-wrap'>
                  <div className='info-group info-video-group'>
                    <div className='info-label'>Hiring Manager</div>
                    {this.state.hiring_manager == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.hiring_manager}</div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='info-group info-video-group'>
                    <div className='info-label'>Video Introduction</div>
                    {this.state.video == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'><a className='link' href={this.state.video} target="_blank">{this.state.video}</a></div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='info-group info-video-group'>
                    <div className='info-label'>Company Website</div>
                    {this.state.website_link == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'><a className='link' href={this.state.video} target="_blank">{this.state.website_link}</a></div>
                    )}
                    <div className='info-divider'></div>
                  </div>

                  </div>
                      </div>
                    </div>
              </div>
              </div>
            )}
            <div className='bottom-sec mt-0 mo-bottom'>
            <div className='card-container mo-card mo-card-container'>
               <div className='card f-card'>
                  <div className='card-head'>
                    <div className='card-title job-card-title'>{this.state.job.title}</div>
                  </div>
                  <div className='info-group'>
                    <div className='info-label'>Location</div>
                    {this.state.job.location!=undefined && (
                      <div className='info-txt'>{this.state.job.location['city']}, {this.state.job.location['state']}</div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row'>
                  <div className='info-group'>
                    <div className='info-label'>Type</div>
                    {this.state.office == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.office}</div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Compensation Type</div>
                    {/* {this.state.job.type == 'H'? (
                        <div className='info-txt'>Hourly</div>
                    ) : this.state.job.type=='S'? (
                        <div className='info-txt'>Salaried</div>
                    ) : (
                        <div className='info-txt'>Unknown</div>
                    )} */}
                      {this.state.job.type != '' && this.state.job.type != undefined ? (
                      <div className='info-txt'>{this.state.job.type}</div>
                    ) : (
                      <div className='info-txt'>Not set</div>
                    )}
                  </div>
                  </div>
                  <div className='info-divider'></div>
                </div>
               </div>
               {this.state.status_type == 'normal' ? (
                <div className='top-status-card mo-status'>
                <div className='status-title'>Application Status</div>
               {(this.state.app_status.index >= 1 && this.state.app_status.index <= 18) ? (
                 <div className='status-row-wrap'>
                 <div className='status-iten-wrap'>
                   <div className='status-icon-wrap'>
                     <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 1].icon+".svg"}/>
                   </div>
                   
                   <div className='a-status-txt'>{statuses[this.state.app_status.index - 1].status}</div>
                 </div>
                 <div className='a-status-step'></div>
                 <div className='status-iten-wrap'>
                   <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                   <div className='a-status-txt'>{this.state.app_status.status}</div>
                 </div>
                 <div className='a-status-step'></div>
                 <div className='status-iten-wrap'>
                   <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index + 1].icon+".svg"}/>
                   <div className='a-status-txt'>{statuses[this.state.app_status.index + 1].status}</div>
                 </div>
               </div>
               ): this.state.app_status.index == 0 ?(
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                  <div className='a-status-txt'>{this.state.app_status.status}</div>
                </div>
                <div className='a-status-step'></div>
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index + 1].icon+".svg"}/>
                  <div className='a-status-txt'>{statuses[this.state.app_status.index + 1].status}</div>
                </div>
                <div className='a-status-step'></div>
                <div className='status-iten-wrap'>
                  <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index +2 ].icon+".svg"}/>
                  <div className='a-status-txt'>{statuses[this.state.app_status.index + 2].status}</div>
                </div>
              </div>
               ) : this.state.app_status.index >18 &&(
                 <div className='status-row-wrap'>
                 <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 2].icon+".svg"}/>
                    <div className='a-status-txt'>{statuses[this.state.app_status.index -2].status}</div>
                  </div>
                  <div className='a-status-step'></div>
                  
                  <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/"+statuses[this.state.app_status.index - 1].icon+".svg"}/>
                    <div className='a-status-txt'>{statuses[this.state.app_status.index -1].status}</div>
                  </div>
                  <div className='a-status-step'></div>
                  <div className='status-iten-wrap'>
                    <img alt='' className='status-icon' src={"images/active-"+this.state.app_status.icon+".svg"}/>
                    <div className='a-status-txt'>{this.state.app_status.status}</div>
                  </div>
                  
                  
                  
                </div>
               )}
              </div>
              ) : this.state.status_type == 'not match' ? (
                <div className='top-status-card mo-status'>
                <div className='status-title'>Application Status</div>
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap status-center'>
                  <img alt='' className='status-icon' src={"images/active-not-a-match.svg"}/>
                  <div className='a-status-txt'>Not a Match</div>
                </div>
              </div>
              </div>
              ) : this.state.status_type == 'withdrawn' && (
                <div className='top-status-card mo-status'>
                <div className='status-title'>Application Status</div>
                <div className='status-row-wrap'>
                 
                <div className='status-iten-wrap status-center'>
                  <img alt='' className='status-icon' src={"images/application-withdrawn.svg"}/>
                  <div className='a-status-txt'>Application Withdrawn</div>
                </div>
              </div>
              </div>
              )}
              <div className='card-wrap'>
              <div className='card detail-card'>
                  <div className='card-head'>
                    <img alt='' className='detail-icon' src={role}/>
                    <div className='card-title job-card-title'>The Role </div>
                  </div>
                  <div className='info-group'>
                    {this.state.role_detail!='' && this.state.role_detail!=null && (
                      (this.state.role_detail).split('\n').length==1 ? (
<div className='info-txt'>{this.state.role_detail}</div>
                      ) : (
                        Object.keys((this.state.role_detail).split('\n')).map((key) => (
                          ((this.state.role_detail).split('\n')[key]).startsWith('•') ? (
                            <div className='row'>
                            <img src={dot} alt='' className='dot-list'/>
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key].split('•')[1]}</div>
                            </div>
                          ): ((this.state.role_detail).split('\n')[key]).startsWith('--') ? (
                            <div className='row'>
                            <img src={dot} alt='' className='dot-list'/>
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key].split('--')[1]}</div>
                            </div>
                          ) : (
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key]}</div>
                          )
                          
                        ))
                      )
                      
                    )}
                  </div>
                  {(this.state.candidateStatus.index > 0 || [0,1,2,3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && (
                    <div className='info-group'>
                    <div className='info-label'>Team Size</div>
                    {this.state.team_size != '' && this.state.team_size!=null && (
                     (this.state.team_size).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.team_size}</div>
                                            ) : (
                                              Object.keys((this.state.team_size).split('\n')).map((key) => (
                                                ((this.state.team_size).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.team_size).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.team_size).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.team_size).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.team_size).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}

                  </div>
                  )}

{(this.state.candidateStatus.index > 1 || [3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && this.state.job.type.includes('Salaried') && (
                    <div className='info-group'>
                    <div className='info-label'>Minimum Years of Experience</div>
                    {this.state.min_years == "" ? (
                   <div className='info-txt'>Not set</div>
               ) : (
                 <div className='info-txt'>{this.state.min_years}</div>
               )}

                  </div>
                  )}
                </div>
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={task}/>
                    <div className='card-title job-card-title'>The Tasks</div>
                  </div>
                  <div className='info-group'>
                    {/* <img src={dot} alt='' className='dot-list'/> */}
                    {this.state.task!='' && this.state.task!=null && (
                     (this.state.task).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.task}</div>
                                            ) : (
                                              Object.keys((this.state.task).split('\n')).map((key) => (
                                                ((this.state.task).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.task).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                    
                  </div>
                </div>
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={skill}/>
                    <div className='card-title job-card-title'>The Skill Set</div>
                  </div>
                   <div className='info-group'>
                    {/* <img src={dot} alt='' className='dot-list'/> */}
                    {this.state.skill!='' && this.state.skill!=null && (
                     (this.state.skill).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.skill}</div>
                                            ) : (
                                              Object.keys((this.state.skill).split('\n')).map((key) => (
                                                ((this.state.skill).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.skill).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                    
                  </div>
                </div>
              </div>

              <div className='card-wrap'>
              {this.state.job.type.includes('Hourly') && (
               <div className='card detail-card'>
               <div className='card-head'>
               <img alt='' className='detail-icon' src={duration}/>
                 <div className='card-title job-card-title'>The Duration</div>
               </div>
               <div className='info-group'>
                 <div className='info-label'>The Duration</div>
               {this.state.job.duration == "" ? (
                   <div className='info-txt'>Not set</div>
               ) : (
                 <div className='info-txt'>{this.state.job.duration}</div>
               )}
                 <div className='info-divider'></div>
               </div>
             </div>
             )}
              {this.state.job == 'S' || (this.state.candidateStatus.index > 0 || [0,1,2,3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && (
                <div className='card detail-card'>
                <div className='card-head'>
                <img alt='' className='detail-icon' src={compensation}/>
                  <div className='card-title job-card-title'>Compensation</div>
                </div>
                {this.state.job.type.includes('Salaried') ? (
                  (this.state.candidateStatus.index > 0 || [0,1,2,3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) ? (
                    <div className='row'>
                   <div className='info-group'>
                  <div className='info-label mt-0'>Average Salary</div>
                  {(this.state.avg_salary == '' || this.state.avg_salary == null)? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.avg_salary}</div>
                  )}
                   
                </div>
                <div className='info-group ml-64'>
                  <div className='info-label mt-0'>Cash Bonus</div>
                  {this.state.cash == '' || this.state.cash == null? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.cash}</div>
                  )}
                </div>
                  
                  </div>
                  ) : (
                    <div className='info-group'>
                  <div className='info-label mt-0'>Average Salary</div>
                  {(this.state.avg_salary == '' || this.state.avg_salary == null)? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.avg_salary}</div>
                  )}
                   
                </div>
                  )
                  ) : (
                    <div className='row'>
                    <div className='info-group'>
                  <div className='info-label mt-0'>Minimum Hourly Rate</div>
                  {(this.state.hourly_rate == '' || this.state.hourly_rate == null)? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.hourly_rate}</div>
                  )}
                   
                </div>
                {/* <div className='info-group ml-64'>
                  <div className='info-label mt-0'>End Date</div>
                  {this.state.start_date == '' || this.state.start_date == null? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.start_date}</div>
                  )}
                </div> */}
                 <div className='info-group ml-64'>
                  <div className='info-label mt-0'>Start Date</div>
                  {this.state.job.start_date == '' || this.state.job.start_date == null? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.job.start_date}</div>
                  )}
                </div>
                   
                   </div>
                  )}
               
                  
                <div className='info-divider'></div>
                {this.state.job.type.includes('Salaried') && (this.state.candidateStatus.index > 1 || [3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && (
                  <div className='row'>
                  <div className='info-group'>
                <div className='info-label mt-0'>Salary Range</div>
                {(this.state.salary == '' || this.state.salary == null)? (
                    <div className='info-txt'>Not set</div>
                ): (
                    <div className='info-txt'>{this.state.salary}</div>
                )}
                 
              </div>
              <div className='info-group ml-64'>
                <div className='info-label mt-0'>Non Cash Bonus</div>
                {this.state.noncash == '' || this.state.noncash == null? (
                    <div className='info-txt'>Not set</div>
                ): (
                    <div className='info-txt'>{this.state.noncash}</div>
                )}
              </div>
                 
                 </div>
                )}
                 <div className='info-divider'></div>

                {this.state.job.type.includes('Salaried') && (this.state.candidateStatus.index > 1 || [3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && (
                  <div className='info-group'>
                  <div className='info-label'>Benefits Plan</div>
                  {this.state.benefits_plan != '' && this.state.benefits_plan!=null && (
                   (this.state.benefits_plan).split('\n').length==1 ? (
                    <div className='info-txt'>{this.state.benefits_plan}</div>
                                          ) : (
                                            Object.keys((this.state.benefits_plan).split('\n')).map((key) => (
                                              ((this.state.benefits_plan).split('\n')[key]).startsWith('•') ? (
                                                <div className='row'>
                                                <img src={dot} alt='' className='dot-list'/>
                                                <div className='info-txt' key={key}>{(this.state.benefits_plan).split('\n')[key].split('•')[1]}</div>
                                                </div>
                                              ): ((this.state.benefits_plan).split('\n')[key]).startsWith('--') ? (
                                                <div className='row'>
                                                <img src={dot} alt='' className='dot-list'/>
                                                <div className='info-txt' key={key}>{(this.state.benefits_plan).split('\n')[key].split('--')[1]}</div>
                                                </div>
                                              ) : (
                                                <div className='info-txt' key={key}>{(this.state.benefits_plan).split('\n')[key]}</div>
                                              )
                                            ))
                                          )
                  )}

                </div>
                )}
                 <div className='info-divider'></div>
               
                {(this.state.perks.length > 0 && this.state.job.type.includes('Salaried')) && (
                  <div className='info-group'>
                  <div className='info-label'>Perks</div>
                  {Object.keys(perks).map((key) => (
                    Object.keys(this.state.perks).map((k) => (
                      perks[key].id == this.state.perks[k] && (
                        <div className='row'>
                        <img src={dot} alt='' className='dot-list'/>
                        <div className='info-txt' key={k}>{perks[key].title}</div>
                        </div>
                      )
                    ))
                  ))}
                
                </div>
                )} 
              </div>
              )}
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={company}/>
                    <div className='card-title job-card-title'>Company Overview </div>
                  </div>
                  <div className='info-group'>
                  {this.state.company != '' && this.state.company!=null && (
                     (this.state.company).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.company}</div>
                                            ) : (
                                              Object.keys((this.state.company).split('\n')).map((key) => (
                                                <div className='info-txt'>{(this.state.company).split('\n')[key]}</div>
                                              ))
                                            )
                    )}
                  </div>
                </div>
                {this.state.job.type.includes('Salaried') && (
                   <div className='card detail-card'>
                   <div className='card-head'>
                   <img alt='' className='detail-icon' src={opportunity}/>
                     <div className='card-title job-card-title'>The Opportunity</div>
                   </div>
                   <div className='info-group'>
                   {this.state.opportunity != '' && this.state.opportunity!=null && (
                      (this.state.opportunity).split('\n').length==1 ? (
                       <div className='info-txt'>{this.state.opportunity}</div>
                                             ) : (
                                               Object.keys((this.state.opportunity).split('\n')).map((key) => (
                                                 ((this.state.opportunity).split('\n')[key]).startsWith('•') ? (
                                                   <div className='row'>
                                                   <img src={dot} alt='' className='dot-list'/>
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key].split('•')[1]}</div>
                                                   </div>
                                                 ): ((this.state.opportunity).split('\n')[key]).startsWith('--') ? (
                                                   <div className='row'>
                                                   <img src={dot} alt='' className='dot-list'/>
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key].split('--')[1]}</div>
                                                   </div>
                                                 ) : (
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key]}</div>
                                                 )
                                               ))
                                             )
                     )}
                   </div>
                 
                 </div>
                )}

                {this.state.job.type.includes('Salaried') && (
                  <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={timeline}/>
                    <div className='card-title job-card-title'>Expectations Timeline </div>
                  </div>
                  <div className='info-group'>
                    {this.state.timeline != '' && this.state.timeline!=null && (
                     (this.state.timeline).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.timeline}</div>
                                            ) : (
                                              Object.keys((this.state.timeline).split('\n')).map((key) => (
                                                ((this.state.timeline).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.timeline).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                  </div>
                  {(this.state.candidateStatus.index > 0 || [0,1,2,3,4,5,6,7,8,9,10].indexOf(this.state.app_status.index) !== -1) && (
                    <a className='detail-see-more' onClick={this.openTimeline}>See more</a>
                  )}
                 
                </div>

                )}
                
                {this.state.status_type == 'normal' && ( <div className='bottom-btns-wrap'>
              <button className='btn-save btn-long n-px' type="button" onClick={this.openWithdrawModal}>Withdraw Application</button>
            </div>)}
              </div>
            </div>
           
          </div>
         
          
        </div>
        <Modal show={this.state.isInterestedModal} handleClose={this.hideInterestedModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Thank you!</div>
            </div>
            <div className='n-modal-txt'>Thank you for letting us know you’re interested.
             </div>
             <div className='n-modal-txt'>Our staff will be reaching out to you if you’re the right fit. We’ll be in touch within 48 hours</div>
          </div>
        </Modal>

        <Modal show={this.state.isWithdrawConfirmModal} handleClose={this.hideWithdrawConfirm}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Sorry to see you go</div>
            </div>
            <div className='n-modal-txt'>Your application has been withdrawn.
             </div><br/>
             <div className='n-modal-txt'>If you know someone that might be a good candidate for the role, please share the following link: </div><br/>
             <a className='job-link-url' href={"https://portal.outhire.ca/explore-jobs/details?id=" + this.state.job.id} target="_blank"> {"https://portal.outhire.ca/explore-jobs/details?id=" + this.state.job.id}</a>
             <button className='btn-save' onClick={this.copyLink}>Copy Link</button>
             {this.state.is_copied && (
              <div className='copied-txt'>Copied to clipboard</div>
             )}
          </div>
        </Modal>
        <Modal show={this.state.isWithdrawModal} handleClose={this.hideWithdrawModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Withdraw Application</div>
            </div>
            <div className='n-modal-txt'>Your application will be withdrawn.
             </div><br/>
             <div className='n-modal-txt'>Please click to confirm </div><br/>
            
            {this.state.is_withdrawing ? (
 <button className='btn-save' onClick={this.withdrawApplication} disabled={this.state.is_withdrawing}>Confirming...</button>
            ) : (
              <button className='btn-save' onClick={this.withdrawApplication}>Confirm</button>
            )}
          </div>
        </Modal>

        <Modal show={this.state.isTimelineModal} handleClose={this.hideTimeline}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title card-title job-card-title'>Expectations Timeline</div>
            </div>
             <div className='n-modal-group'>
             <div className='info-group'>
                    {this.state.timeline_full != '' && this.state.timeline_full!=null && (
                     (this.state.timeline_full).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.timeline_full}</div>
                                            ) : (
                                              Object.keys((this.state.timeline_full).split('\n')).map((key) => (
                                                ((this.state.timeline_full).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline_full).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.timeline_full).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline_full).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.timeline_full).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                  </div>
             </div>
          </div>
        </Modal>
      </div>
    );
  }
}


//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(MyJobDetails);