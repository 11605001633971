import React, { Component } from 'react';
import './styles.css';
// import logo from './../assets/images/logo.png';
import doctor from './../assets/images/computer.svg';
import history from './../utilities/history';
import SignUpForm from '../components/SignUpForm/SignUpForm';
import NavBar from '../components/NavBar/TopNavBar';
import welcome from './../assets/outhire/welcome.png';
import Footer from "../components/Landing/Footer";
import white from './../assets/outhire/white.svg';
import OuthireMobileMenu from '../components/NavBar/TopMobileMenu';
import menu from './../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../assets/outhire/outhire.svg';

class SignUp extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="container">
         <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="candidate-bg web-nav">
            <div className="first-wrap content-center coloum">
              {/* <div className="sign-diagonal"></div> */}
              <img className="white-poligon" src={white} alt=''/>
              <div className="top-section">
            <div className="home-card-wrap mb-100 top-card home-login-wrap">
            <SignUpForm />
        </div>
            </div>
            </div>
          </div>
          <div className="mo-wrap">
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
    <SignUpForm />
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer-relative">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>

      </div>
      </div>
    );
  }
}

export default SignUp;