import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import home from './../../assets/outhire/Frame3small.png'
import employer1 from './../../assets/outhire/employer1.svg'
import home4 from './../../assets/outhire/home4.svg'
import home5 from './../../assets/outhire/home5.svg'
import home6 from './../../assets/outhire/home6.svg'
import icon_group1 from './../../assets/outhire/icon-group1.svg'
import icon_group2 from './../../assets/outhire/icon-group2.svg'
import go from './../../assets/outhire/go.svg'
import facebook from './../../assets/outhire/icon-facebook.svg';
import twitter from './../../assets/outhire/icon-twitter.svg';
import ins from './../../assets/outhire/icon-ins.svg';
import register from './../../assets/outhire/demo1.svg';
import call from './../../assets/outhire/scope2.svg';
import evaluates from './../../assets/outhire/select3.svg';
import interview from './../../assets/outhire/interview4.svg';
import offer from './../../assets/outhire/offer5.svg';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import map from './../../assets/outhire/map-without-text.svg';
import testing from './../../assets/outhire/testing.svg';
import bias from './../../assets/outhire/bias.svg';
import mission from './../../assets/outhire/mission.svg';
import guarantee from './../../assets/outhire/guarantee.svg';
import technical from './../../assets/outhire/technical.svg';
import dot from './../../assets/outhire/dot.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Virtual } from "swiper";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

const slides = Array.from({ length: 3 }).map(
    (el, index) => `Slide ${index + 1}`
  );

class Employers extends Component {
    state = {
      selected: 1,
      selectedApproach: 'demo'
    };

    onSelectedChange(index){
        this.setState({
            selected: index
        })
    }

    select(approach){
        this.setState({
            selectedApproach: approach
        })
    }
  
    render() {
      return (
        <div className="App">
          <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap employer-bg">
            <img className="white-poligon home-white" src={white} alt=''/>
                <div className="first-wrap emloyer-first">
                {/* <div className="diagonal-box"></div> */}
               
                <div className="main-first-wrap">
                
                    <div className="first-text-wrap">
                        <div className="home-title">Get the Outhire advantage</div>
                        <div className="animation-txt-wrap web-home-txt">
                        <div className="home-body-txt w-496 fix-496">Hire quality accounting & finance professionals without the hassle</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/contact')}>Start Hiring</button>
                        </div>
                        </div>
                        
                    </div>
                    <img alt='' className="home-img employer-main " src={home}/>
                </div>
                  
                </div>
                <div className="main-bottom-wrap employer-bottom-bg">
                <div className="full-w-card mo-show mt-emy">
                <div className="animation-txt-wrap">
                        <div className="home-body-txt">Hire quality accounting & finance professionals without the hassle</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/contact')}>Start Hiring</button>
                        </div>
                        </div>
                </div>
                <div className="home-card-wrap mb-75 p-54">
                    <div className="row text-left mo-center mo-column">
                    <div className="home-card-group mo-img">
                            <img src={employer1} alt='' className="home-group-img employer-img"/>
                        </div>
                        <div className="home-card-txt-wrap">
                            <div className="home-bold-txt padding-employer2 mo-bold-txt">Our unique screen & screen process yields better results in less time, for any type of role you need filled</div>
                        </div>
                        <div className="home-card-group home-card-employer1">
                            <img src={employer1} alt='' className="home-group-img employer-img"/>
                        </div>
                    </div>
                </div>
                <div className="home-card-wrap mb-75 pxy-35 mo-no-bg">
                    {/* <div className="row text-left card-list-wrap">
                        <div className="card-txt-list ml-5p">
                        <div className="card-txt-title">Contracts and Projects</div>
                            <div className="card-txt-body">Access top talent for contract work in ERP implementation, IFRS compliance, M&A post integration, big data prcoess setup or process automation.</div>
                            <div className="card-txt-bottom">
                                <div className="card-bottom cursor" onClick={() => history.push('/contact')}>Free consulation</div>
                                <img alt='' className="go-icon" src={go} onClick={() => history.push('/contact')}/>
                            </div>
                        </div>
                        <div className="card-txt-list">
                            <div className="card-txt-title">Hourly & temporary</div>
                            <div className="card-txt-body">Fulfill a part-time need for situations like FTE headcount reduction, sick leave coverage, year-end closing, audit compliance or interim CFO.</div>
                            <div className="card-txt-bottom">
                                <div className="card-bottom cursor" onClick={() => history.push('/contact')}>Free consulation</div>
                                <img alt='' className="go-icon" src={go} onClick={() => history.push('/contact')}/>
                            </div>
                        </div>
                        <div className="card-txt-list mr-5p">
                            <div className="card-txt-title">Permanent hires</div>
                            <div className="card-txt-body">Home in on the right skill-set for those key permanent hires like newly created positions, confidential hires, maternity & paternity leave hires or replacement hires.</div>
                            <div className="card-txt-bottom">
                                <div className="card-bottom cursor" onClick={() => history.push('/contact')}>Free consulation</div>
                                <img alt='' className="go-icon" src={go} onClick={() => history.push('/contact')}/>
                            </div>
                        </div>
                    </div> */}
                    <div className="row carosel-card-wap web-nav">
                    <Carousel centerMode={true} selectedItem={this.state.selected} showStatus={false} 
                    onChange={(index)=>this.onSelectedChange(index)} 
                    onClickItem={(index)=>this.onSelectedChange(index)} 
                    centerSlidePercentage={this.state.selected==1?31.1:this.state.selected==2?34.45:33.05}
                    infiniteLoop={true}>
                    <div className={this.state.selected==0? "carosel-card":"carosel-card inactive-carosel"}>
                            <div className="carosel-head">
                                <div className="carosel-title">Hourly and Temporary</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Fulfill a part-time need for situations:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">FTE headcount reduction</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">sick leave coverage</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">year-end closing</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">Audit compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">interim CFO</div>
                            </div>
                            </div>
                           
                        </div>
                        <div className={this.state.selected==1? "carosel-card":"carosel-card inactive-carosel"}>
                            <div className="carosel-head">
                                <div className="carosel-title">Contracts and Projects</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Access top talent for contract work:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">ERP implementation</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">IFRS compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">M&A post integration</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">big data process setup</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">process automation</div>
                            </div>                         
                            </div>
                           
                        </div>
                        <div className={this.state.selected==2? "carosel-card":"carosel-card inactive-carosel"}>
                            <div className="carosel-head">
                                <div className="carosel-title">Permanent Hires</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Home in on the right skill-set for those key permanent hires:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">newly created positions</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">confidential hires </div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">maternity & paternity leaves</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">replacement hires</div>
                            </div>
                            </div>
                           
                        </div>
            </Carousel>
                    {/* <Swiper
     slidesPerView={3}
     loop={true}
    pagination={{ clickable: true}}
    modules={[Pagination]}
    className="home-slider"
    // speed={3000}
    spaceBetween={31}
    >
      <SwiperSlide> <div className="carosel-card inactive-carosel">
                            <div className="carosel-head">
                                <div className="carosel-title">Hourly and Temporary</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Fulfill a part-time need for situations:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">FTE headcount reduction</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">sick leave coverage</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">year-end closing</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">Audit compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">interim CFO</div>
                            </div>
                            </div>
                           
                        </div></SwiperSlide>
      <SwiperSlide> <div className="carosel-card">
                            <div className="carosel-head">
                                <div className="carosel-title">Contracts and Projects</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Access top talent for contract work:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">ERP implementation</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">IFRS compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">M&A post integration</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">big data process setup</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">process automation</div>
                            </div>                         
                            </div>
                           
                        </div></SwiperSlide>
      <SwiperSlide><div className="carosel-card inactive-carosel">
                            <div className="carosel-head">
                                <div className="carosel-title">Permanent Hires</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Home in on the right skill-set for those key permanent hires:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">newly created positions</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">confidential hires </div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">maternity & paternity leaves</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">replacement hires</div>
                            </div>
                            </div>
                           
                        </div></SwiperSlide>
    </Swiper> */}
                   
                       
                    
                        

                        </div>
                        <div className="row carosel-card-wap mo-wrap pxy-0">
                    <Carousel
                     centerMode={false} 
                    selectedItem={this.state.selected} showStatus={false} 
                    onChange={(index)=>this.onSelectedChange(index)} 
                    onClickItem={(index)=>this.onSelectedChange(index)} 
                    // centerSlidePercentage={37.5}
                    // infiniteLoop={true}
                    >
                    <div className= "carosel-card">
                            <div className="carosel-head">
                                <div className="carosel-title">Hourly and Temporary</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Fulfill a part-time need for situations:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">FTE headcount reduction</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">sick leave coverage</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">year-end closing</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">Audit compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">interim CFO</div>
                            </div>
                            </div>
                           
                        </div>
                        <div className="carosel-card">
                            <div className="carosel-head">
                                <div className="carosel-title">Contracts and Projects</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Access top talent for contract work:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">ERP implementation</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">IFRS compliance</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">M&A post integration</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">big data process setup</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">process automation</div>
                            </div>                         
                            </div>
                           
                        </div>
                        <div className="carosel-card">
                            <div className="carosel-head">
                                <div className="carosel-title">Permanent Hires</div>
                            </div>
                            <div className="carosel-txt-wrap"> 
                            <div className="carosel-txt">Home in on the right skill-set for those key permanent hires:</div><br/>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">newly created positions</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">confidential hires </div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">maternity & paternity leaves</div>
                            </div>
                            <div className="row">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="carosel-txt">replacement hires</div>
                            </div>
                            </div>
                           
                        </div>
            </Carousel>
                        </div>
                        <div className="carosel-bottom-txt">Let us help you with your hiring needs</div>
                        <button className="btn-home btn-save web-show mr-0" onClick={()=> history.push('/contact')}>Book a Demo</button>
                </div>

                <div className="home-card-wrap mb-75 bg-transparent">
                <div className="home-sub-title w-no mb-64 sans-font">Our simple, effective approach</div>
                <div className="mo-approach-wrap">
                    <div className="approach-item mt-0">
                        <div className="approach-title">Demo</div>
                        <div className="approach-txt">Sign up in minutes and we’ll be in touch in 24 hours</div>
                        <img className="scroll-img" src={register} alt=''/>
                    </div>
                    <div className="approach-item">
                        <div className="approach-title">Scope</div>
                        <div className="approach-txt">Connect with us virtually to discuss your goals & needs</div>
                        <img className="scroll-img" src={call} alt=''/>
                    </div>
                    <div className="approach-item">
                        <div className="approach-title">Select</div>
                        <div className="approach-txt">We vet a list of options and you assess their visual profiles</div>
                        <img className="scroll-img" src={evaluates} alt=''/>
                    </div>
                    <div className="approach-item">
                        <div className="approach-title">Interview</div>
                        <div className="approach-txt">We schedule, suggest questions, and debrief asap for decision-making</div>
                        <img className="scroll-img" src={interview} alt=''/>
                    </div>
                    <div className="approach-item">
                        <div className="approach-title">Offer</div>
                        <div className="approach-txt">You find the one and we assemble an offer aligned with your goals</div>
                        <img className="scroll-img" src={offer} alt=''/>
                    </div>
                </div>
                <div className="row scroll-canvas">
                    <div className="scroll-left">
                        <div className="row ml-118">
                            <div className={this.state.selectedApproach==='demo'?"circle border-circle cursor": "circle cursor"}  onClick={()=> this.select('demo')}></div>
                            <div className={this.state.selectedApproach==='demo'? "scroll-txt active-scroll ml-60 txt-left cursor" : "scroll-txt ml-60 txt-left cursor" } onClick={()=> this.select('demo')}>Demo</div>
                        </div>
                        <div className="scroll-bar "></div>
                        <div className="row">
                        <div className={this.state.selectedApproach==='scope'? "scroll-txt text-right cursor active-scroll": "scroll-txt text-right cursor"} onClick={()=> this.select('scope')}>Scope</div>
                            <div className={this.state.selectedApproach==='scope'?"circle border-circle cursor ml-56": "circle  ml-56 cursor"}  onClick={()=> this.select('scope')}></div>
                           
                        </div>
                        <div className="scroll-bar"></div>
                        <div className="row ml-120">
                            <div className={this.state.selectedApproach==='select'?"circle border-circle cursor": "circle cursor"}  onClick={()=> this.select('select')}></div>
                            <div className={this.state.selectedApproach==='select'? "scroll-txt active-scroll ml-60 txt-left cursor" : "scroll-txt ml-60 txt-left cursor" } onClick={()=> this.select('select')}>Select</div>
                        </div>
                        <div className="scroll-bar"></div>
                        <div className="row">
                        <div className={this.state.selectedApproach==='interview'? "scroll-txt text-right cursor active-scroll": "scroll-txt text-right cursor"}onClick={()=> this.select('interview')}>Interview</div>
                            <div className={this.state.selectedApproach==='interview'?"circle border-circle cursor ml-56": "circle  ml-56 cursor"}  onClick={()=> this.select('interview')}></div>
                           
                        </div>
                        <div className="scroll-bar"></div>
                        <div className="row ml-120">
                            <div className={this.state.selectedApproach==='offer'?"circle border-circle cursor": "circle cursor"}  onClick={()=> this.select('offer')}></div>
                            <div className={this.state.selectedApproach==='offer'? "scroll-txt active-scroll ml-60 txt-left cursor" : "scroll-txt ml-60 txt-left cursor" } onClick={()=> this.select('offer')}>Offer</div>
                        </div>
                    </div>
                    {this.state.selectedApproach === 'demo'? (
                        <div className="scroll-right">
                        <img className="scroll-img" src={register} alt=''/>
                        <div className="scroll-img-txt">Sign up in minutes and we’ll be in touch in 24 hours</div>
                    </div>
                    ) : this.state.selectedApproach === 'scope' ? (
                        <div className="scroll-right">
                        <img className="scroll-img" src={call} alt=''/>
                        <div className="scroll-img-txt">Connect with us virtually to discuss your goals & needs</div>
                    </div>
                    ) : this.state.selectedApproach === 'select'? (
                        <div className="scroll-right">
                        <img className="scroll-img" src={evaluates} alt=''/>
                        <div className="scroll-img-txt">We vet a list of options and you assess their visual profiles </div>
                    </div>
                    ): this.state.selectedApproach === 'interview' ? (
                      <div className="scroll-right">
                        <img className="scroll-img" src={interview} alt=''/>
                        <div className="scroll-img-txt">We schedule, suggest questions, and debrief asap for decision-making</div>
                    </div>
                    ): (
                        <div className="scroll-right">
                        <img className="scroll-img" src={offer} alt=''/>
                        <div className="scroll-img-txt">You find the one and we assemble an offer aligned with your goals</div>
                    </div>
                    )}
                </div>
                    {/* <div className="row text-left card-list-wrap">
                        <div className="card-txt-list text-center ml-20 mr-0">
                            <img alt='' className="flow-img" src={register}/>
                            <div className="card-txt-title">Register</div>
                            <div className="card-txt-body flow-txt no-height">Sign up in minutes and we’ll be in touch in 24 hours</div>
                        </div>
                        <div className="card-txt-list text-center">
                            <img alt='' className="flow-img" src={call}/>
                            <div className="card-txt-title">Call</div>
                            <div className="card-txt-body flow-txt no-height">Connect with us virtually to discuss your goals & needs</div>
                        </div>
                        <div className="card-txt-list text-center">
                            <img alt='' className="flow-img" src={evaluates}/>
                            <div className="card-txt-title">Evaluate</div>
                            <div className="card-txt-body flow-txt no-height">We vet a list of options and you access their visual profiles</div>
                        </div>
                        <div className="card-txt-list text-center">
                            <img alt='' className="flow-img" src={interview}/>
                            <div className="card-txt-title">Interview</div>
                            <div className="card-txt-body flow-txt no-height">We schedule, suggest questions, and debrief asap for decision-making</div>
                        </div>
                        <div className="card-txt-list text-center mr-20">
                            <img alt='' className="flow-img" src={offer}/>
                            <div className="card-txt-title">Register</div>
                            <div className="card-txt-body flow-txt no-height">You find the one and we assemble an offer aligned with your goals</div>
                        </div>
                    </div> */}
                </div>
                {/* <div className="full-w-card portal-bg web-map">
                    <div className="map-wrap">
                    <div className="map-title">Where we operate:</div>
                    <img alt='' src={map} className="map-img"/>
                    <div className="map-txt-wrap">
                        <div className="map-body-wrap">
                            <div className="map-body-txt">Toronto</div>
                            <div className="map-body-txt">Ottawa</div>
                            <div className="map-body-txt">Calgary</div>
                            <div className="map-body-txt">Vancouver</div>
                        </div>
                    </div>
                    </div>  
                </div> */}
                <div className="full-w-card mo-map">
                    <div className="map-wrap">
                    <div className="map-title">Where we operate:</div>
                    <img alt='' src={map} className="map-img"/>
                    <div className="map-txt-wrap">
                        <div className="map-body-wrap">
                            <div className="map-body-txt">Toronto</div><div className="map-txt-divider"></div>
                            <div className="map-body-txt">Ottawa</div><div className="map-txt-divider"></div>
                            <div className="map-body-txt">Calgary</div><div className="map-txt-divider"></div>
                            <div className="map-body-txt">Vancouver</div>
                        </div>
                    </div>
                    </div>  
                </div>
                <div className="full-w-card ptb-48 web-section ">
                    <div className="difference-wrap">
                        <div className="difference-bg">
                            <div className="difference-center-wrap">
                            <div className="difference-center">The Outhire Difference</div>
                            </div>
                        </div>
                        <div className="difference-card card1">
                            <img alt='' className="diff-icon" src={testing}/>
                            <div className="diff-card-title">Rigorous Testing</div>
                            <div className="diff-card-txt">We interview and test every candidate. No exceptions. Every candidate we present is highly qualified for the role, the only thing we want from you is to decide who works best with your team. see more</div>
                        </div>
                        <div className="difference-card card2">
                            <img alt='' className="diff-icon" src={bias}/>
                            <div className="diff-card-title">Reduce Bias</div>
                            <div className="diff-card-txt">We highlight the real skills and achievements that matter to you, a majority of our Visual Resume will draw from the interview and deep conversations that show what a candidate can really do. see more</div>
                        </div>
                        <div className="difference-card card3">
                            <img alt='' className="diff-icon" src={mission}/>
                            <div className="diff-card-title">Mission Driven</div>
                            <div className="diff-card-txt">We have a passion for the work you and your company does, its part of the reason why we are able to find the right talent for your team. When you work with us, you are not only working with an agency, you’re working with fans who will spread your message like you would to candidates. see more</div>
                        </div>
                        <div className="difference-card card4">
                            <img alt='' className="diff-icon" src={guarantee}/>
                            <div className="diff-card-title">Industry Leading</div>
                            <div className="diff-card-title"> Guarantee</div>
                            <div className="diff-card-txt">When we help companies make a hire, we do so as if we are a part of the company and are invested in their success. That’s why we back up our recommendations with a satisfaction guarantee for up to 6 months post hiring. see more</div>
                        </div>
                        <div className="difference-card card5">
                            <img alt='' className="diff-icon" src={technical}/>
                            <div className="diff-card-title">Technical Testing</div>
                            <div className="diff-card-txt">No one enjoys taking standardized tests. That’s why we developed our in-house Excel test which is incorporated into our interview process and has proven to provide better insight, less pressure (and cheating) and better outcomes for hiring decisions. see more</div>
                        </div>
                    </div>  
                </div>
                <div className="full-w-card mo-section ">
                    <div className="mo-difference-wrap">
                    <div className="home-title text-center w-100">The Outhire Difference</div>
                        <div className="mo-difference-card">
                            <img alt='' className="diff-icon" src={testing}/>
                            <div className="diff-card-title">Rigorous Testing</div>
                            <div className="diff-card-txt">We interview and test every candidate. No exceptions. Every candidate we present is highly qualified for the role, the only thing we want from you is to decide who works best with your team. see more</div>
                        </div>
                        <div className="mo-difference-card">
                            <img alt='' className="diff-icon" src={bias}/>
                            <div className="diff-card-title">Reduce Bias</div>
                            <div className="diff-card-txt">We highlight the real skills and achievements that matter to you, a majority of our Visual Resume will draw from the interview and deep conversations that show what a candidate can really do. see more</div>
                        </div>
                        <div className="mo-difference-card">
                            <img alt='' className="diff-icon" src={mission}/>
                            <div className="diff-card-title">Mission Driven</div>
                            <div className="diff-card-txt">We have a passion for the work you and your company does, its part of the reason why we are able to find the right talent for your team. When you work with us, you are not only working with an agency, you’re working with fans who will spread your message like you would to candidates. see more</div>
                        </div>
                        <div className="mo-difference-card">
                            <img alt='' className="diff-icon" src={guarantee}/>
                            <div className="diff-card-title">Industry Leading</div>
                            <div className="diff-card-title"> Guarantee</div>
                            <div className="diff-card-txt">When we help companies make a hire, we do so as if we are a part of the company and are invested in their success. That’s why we back up our recommendations with a satisfaction guarantee for up to 6 months post hiring. see more</div>
                        </div>
                        <div className="mo-difference-card">
                            <img alt='' className="diff-icon" src={technical}/>
                            <div className="diff-card-title">Technical Testing</div>
                            <div className="diff-card-txt">No one enjoys taking standardized tests. That’s why we developed our in-house Excel test which is incorporated into our interview process and has proven to provide better insight, less pressure (and cheating) and better outcomes for hiring decisions. see more</div>
                        </div>
                    </div>  
                </div>
                <div className="home-card-wrap ">
                    <div className="home-title w-896">Stop wasting time & resources and start finding better talent</div>
                   <div className="line-row">
                   <button className='btn-save btn-home sans-font'  onClick={() => history.push('/contact')}>Start Now</button>
                   </div>
                </div>
                </div>
                
            </div>
            <Footer/>
          </div>
        </div>
      );
    }
  }
  
  export default Employers;

