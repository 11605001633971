import { Types } from '../../constants/actionTypes';

const initialState = {
    //registration personal info form
    patient: {
        email: '',
        first_name: '',
        last_name: '',
        initial: '',
        birthDay: null,
        birthMonth: null,
        birthYear: null,
        sex: '',
    },
    //registration contact info form
    contact: {
        cell_no: '',
        home_no: '',
        unit: '',
        street: '',
        city: '',
        province: '',
        postal_code: '',
    },
    //registration health card info form
    health: {
        hc_type: '',
        hc_number: '',
        hc_xy: '',
        hc_iss: null,
        hc_exp: null,
    },
    //registration form state
    is_person_filled: false,
    is_contact_filled: false,
    is_health_filled: false,
    //sign in email
    email: '',
    //retrieved patient object from db
    retrieved_patient: {
        email: '',
        firstName: '',
        lastName: '',
        initial: '',
        dob: null,
        gender: '',
        cellPhone: '',
        homePhone: '',
        unitNumber: '',
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
        hc_type: '',
        healthCardNumber: '',
        hc_xy: '',
        hc_iss: null,
        hc_exp: null,
        fam_dr_name: '',
        fam_dr_cell: '',
        fam_dr_fax: '',
        fam_dr_street: '',
        fam_dr_city: '',
        fam_dr_postal: '',
        em_name: '',
        em_relationship: '',
        em_cell: '',
        em_home: '',
        medication: '',
        illness: '',
        allergies: '',
        phar_id: ''
    },
    user: {
        email: '',
        password: '',
        user_type: '',
        status: '',
        is_subscribed: null,
        avartar: '',
        demographicNo: null,
        providerNo: null
    },
    doctor: {
        email: '',
        providerNo: '',
        firstName: '',
        lastName: ''
    }
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.UPDATE_BASIC_INFO:
            return {
                ...state,
                patient: action.payload.patient
            }
        case Types.UPDATE_CONTACT_INFO:
            return {
                ...state,
                contact: action.payload.contact
            }
        case Types.UPDATE_HEALTH_INFO:
            return {
                ...state,
                health: action.payload.health
            }
        case Types.UPDATE_PERSON_FILLED:
            return {
                ...state,
                is_person_filled: action.payload.is_person_filled
            }
        case Types.UPDATE_CONTACT_FILLED:
            return {
                ...state,
                is_contact_filled: action.payload.is_contact_filled
            }
        case Types.UPDATE_HEALTH_FILLED:
            return {
                ...state,
                is_health_filled: action.payload.is_health_filled
            }
        case Types.UPDATE_EMAIL:
            return {
                ...state,
                email: action.payload.email
            }
        case Types.UPDATE_PATIENT:
            return {
                ...state,
                retrieved_patient: action.payload.retrieved_patient
            }
        case Types.UPDATE_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case Types.UPDATE_DOCTOR:
            return {
                ...state,
                doctor: action.payload.doctor
            }
        default:
            return state;

    }
}

export default profileReducer;