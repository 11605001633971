import React, { Component } from 'react';
import './styles.css';
import history from './../../utilities/history';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import NavBar from '../../components/NavBar/TopNavBar';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import terms from './../../assets/outhire/terms.png';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

class Terms extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="container">
         <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap">
            <div className="first-wrap content-center coloum mo-first-wrap">
              {/* <div className="sign-diagonal"></div> */}
              <img className="white-poligon long-white" src={white} alt=''/>
              <div className="top-section mo-mt-0">
            <div className="home-title w-full mb-75  mt-single">Terms of Use</div>
            <div className="terms-card mb-100 top-card">
            <div className='terms-title mb-16'>Last Updated: 11/29/2022</div>
                <div className='terms-body'>
                Outhire inc. (“Outhire”, “us”, “we”, “our”) owns and operates the website located at <a className='here-link' target="_blank" href="/">www.outhire.ca</a>  (“Website”) and provides the Outhire services described on the Website (“Services”). These terms of use (“Terms”) apply to all users of the website, including users who submit any information through the website or Services, users who use the Services, including End Users (defined below), and users who simply view the content on or available through this website (“you”, “your”). 
                </div><br/>
                <div className='terms-body'>
                By using the Services, you indicate your acceptance of these Terms. If you do not accept these terms, then do not use the Outhire website or services. If you are an agent or employee of an end user (“Authorized User”), you represent and warrant that (i) the individual accepting these Terms is duly authorized to accept the Terms on such entity's behalf and to bind such entity, and (ii) such entity has full power, corporate or otherwise, to enter into these Terms and perform its obligations hereunder.
                </div><br/>
                <div className='terms-body'>
                PLEASE NOTE THAT THE OUTHIRE WEBSITE AND SERVICES ARE NOT PROVIDED OR OFFERED FOR INDIVIDUALS OR BUSINESSES IN THE PROVINCE OF QUEBEC. IF YOU ARE LOCATED IN QUEBEC, YOU ARE NOT PERMITTED AND SHOULD NOT USE THE WEBSITE OR SERVICES.
                </div><br/>
                <div className='terms-body'>
                We may change these Terms from time to time. We encourage you to review these Terms each time you use the Services to see if the Terms have been changed, as indicated by the “Last Updated” set out above. we may at our discretion choose to highlight the fact that we made material changes by posting a notice on our Website and/or by sending you a notice, as appropriate. Any non-material change (such as clarifications) to these Terms will become effective on the date the change is posted and any material changes will become effective thirty (30) days from their posting on the Website.
                </div><br/>
                <div className='terms-body'>
                The Outhire Privacy Policy governs our use and processing of your information and hereby forms a part of these Terms. The latest version of our Privacy Policy can be located at <a className='here-link' target="_blank" href="/privacy">www.outhire.ca/privacy</a>. These Terms, together with any order form you enter into with Outhire, form the agreement between you and Outhire (“Agreement”).
                </div><br/>
                <div className='terms-body ml-8'>
                1. <span className='text-underline'>The Services.</span> You may use Outhire as follows:
                </div><br/>
                <div className='terms-body ml-100 row'>
                <div>a.</div> <div className='ml-8'><span className='text-underline'>As a user of the Website.</span> You may browse our available services and view select job openings by visiting the Website. 
                </div></div>
                <div className='terms-body ml-100 row'>
                <div>b.</div> <div  className='ml-8'><span className='text-underline'>As a candidate.</span> You may access the Outhire Services as a candidate by creating a Outhire candidate account through the Website. 
                </div></div>
                <div className='terms-body ml-100 row'>
                <div>c.</div> <div  className='ml-8'><span className='text-underline'>As an employer</span>  (“End User”). You may access the Outhire Services as an End User by creating an Outhire employer account through the Website. Your continued and full use of the Services as an End User may require you to enter into an order form agreed to by you and Outhire. 
                </div></div>
                <div className='terms-body ml-100 row'>
                <div>d.</div> <div  className='ml-8'> <span className='text-underline'>For consulting work.</span> You may engage Outhire to perform development or other professional services, in which case you will enter into an order form or statement work, as applicable, detailing the fees, terms and scope of all such consulting work. 
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>2.</div> <div className='ml-8'><span className='text-underline'>Outhire Account.</span>  You are responsible for safeguarding the Outhire account username and password that you use to access the Services and you are responsible for any activities or actions under your Outhire account, including the actions of Authorized Users. You agree to keep your Outhire credentials secure, to prevent unauthorized access to or use of the Services, and to notify Outhire promptly upon becoming aware of any such unauthorized access or use. Outhire will not be liable for any loss or damage arising from your failure to comply with these requirements. In the event of a dispute regarding the Outhire account owner, Outhire reserves the right to verify email addresses and domains and/or request documentation to determine or confirm account ownership. If Outhire is unable to reasonably determine the rightful Outhire account owner, without prejudice to our other rights and remedies, Outhire may suspend or disable an Outhire account until resolution has been determined.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>3.</div> <div className='ml-8'><span className='text-underline'>License of Use.</span>   The Services and Website, and information and materials they contain, are the property of Outhire and its licensors, and are protected from unauthorized copying and dissemination by copyright law, trademark law, and other intellectual property laws. Subject to this Agreement, including all use restrictions in the Terms and payment of any applicable Fees (defined below), Outhire grants you a non-transferable, non-exclusive, limited license to use the Services for your internal business purposes during the Term (defined below).
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>4.</div> <div className='ml-8'><span className='text-underline'>Intellectual Property Rights.</span>   Outhire owns and shall retain all right, title and interest (including without limitation all patent rights, copyrights, trademark rights, trade secret rights and other intellectual property rights, together “Intellectual Property Rights”), in and to Outhire’s Website, Services, trademarks, branding, and materials including any and all modifications, derivatives, improvements and enhancements to the same. You acknowledge and agree that you have no right, license or authorization with respect to any of the technology underlying the Services or Website (including any Intellectual Property Rights therein) except as expressly set forth in these Terms. All other rights in and to the Website and Services are expressly reserved by Outhire and its licensors.
                </div></div><br/>
                <div className='terms-body ml-8'>
                5. <span className='text-underline'>Data Rights.  </span>
                </div><br/>  
                <div className='terms-body ml-32 row'>
                <div>a.</div> <div className='ml-8'><span className='text-underline'>User Data.</span>  While using the Website or Services, you may provide or submit to us certain information or data about yourself or your organization, your employment and education, and other information related to your employment goals and/or needs and your use of the Services (“User Data”). As between Outhire and you, you shall exclusively own all User Data and have the right to upload to the Services. You hereby grant Outhire a non-exclusive, royalty-free, worldwide license to copy, translate, adapt, distribute or otherwise use or exploit the User Data to provide the Outhire Website and Services to you and to meet our obligations to you.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>b.</div> <div className='ml-8'><span className='text-underline'>Usage Data.</span> In addition, we may collect and process certain statistical and technical information that is generated when you use the Website or Services (“Usage Data”). You hereby grant Outhire a non-exclusive, royalty-free, worldwide license to copy, translate, adapt, distribute or otherwise use or exploit the Usage Data and/or User Data to create aggregate statistics, analyze market trends, improve the Outhire Website and Service, and for benchmarking purposes, provided that any User Data and/or Usage Data used for such purposes is aggregated and anonymized.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>c.</div> <div className='ml-8'><span className='text-underline'> Your Responsibilities.</span> You are responsible for any and all User Data and for obtaining all necessary licenses, permissions and consents to ensure User Data can be processed in accordance with this Agreement. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of the User Data. 
                </div></div><br/>
                {/* Paragraph without bullets --- Start */}
                <div className='terms-body ml-8 row'>
                <div>6.</div> <div className='ml-8'><span className='text-underline'>Feedback.  </span>  If you provide Outhire with any suggestions, comments, enhancement requests or other feedback relating to any aspect of the Website and/or Service ("Feedback"), you grant us (and our agents) a perpetual, worldwide, fully transferable, sub-licensable, non-revocable, royalty-free, license (with the right to sublicense) to use, copy, modify, transmit, display and distribute any Feedback. You represent and warrant that you have all the rights, power and authority necessary to grant the rights to Feedback granted to Outhire herein. You agree that: (a) Outhire is not subject to any confidentiality obligations in respect to the Feedback, (b) the Feedback is not confidential or proprietary information of you or any third party and you have all of the necessary rights to disclose the Feedback to Outhire, and (c) you are not entitled to receive any compensation or re-imbursement of any kind from Outhire or any of the other users of the Website and/or Services in respect of the Feedback.
                </div></div><br/>
                 {/* Paragraph without bullets --- End */}
                <div className='terms-body ml-8'>
                7. <span className='text-underline'>Restrictions on Use of the Services. </span> In using the Website and/or Services, you shall not:
                </div><br/>  
                <div className='terms-body ml-32 row'>
                <div>a.</div> <div className='ml-8'>copy any content unless expressly permitted to do so herein; 
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>b.</div> <div className='ml-8'>upload, post, email, transmit or otherwise make available any material that:
                </div></div>
                <div className='terms-body ml-64 row'>
                <div className='bullet-i'>i.</div> <div className='ml-8'>is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, pornographic, libelous, invasive of another's privacy, hateful, or racially or ethnically objectionable, encourages criminal behavior, gives rise to civil liability, violates any law, or is otherwise objectionable;
                </div></div>
                <div className='terms-body ml-60 row'>
                <div className='bullet-i'>ii.</div> <div className='ml-8'>you do not have a right to make available under any law or under a contractual relationship; 
                </div></div>
                <div className='terms-body ml-56 row'>
                <div className='bullet-i'>iii.</div> <div className='ml-8'>infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party (including privacy rights);  
                </div></div>
                <div className='terms-body ml-56 row'>
                <div className='bullet-i'>iv.</div> <div className='ml-8'>is or contains unsolicited or unauthorized advertising, solicitations for business, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;  
                </div></div>
                <div className='terms-body ml-60 row'>
                <div className='bullet-i'>v.</div> <div className='ml-8'>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or data or the Website or that of any users or viewers of the Website and/or Services or that compromises another Website and/or Services user’s privacy;  
                </div></div>
                <div className='terms-body ml-56 row'>
                <div className='bullet-i'>vi.</div> <div className='ml-8'>contains any falsehoods or misrepresentations or create an impression that you know is incorrect, misleading, or deceptive, or any material that could damage or harm minors in any way; 
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>c.</div> <div className='ml-8'>impersonate any person or entity or misrepresent their affiliation with a person or entity; 
                </div></div>
                {/* line with bullets --Starts */}
                <div className='terms-body ml-32 row'>
                <div>d.</div> <div className='ml-8'>forge headers or otherwise manipulate identifiers in order to disguise the origin of any material transmitted to or through the Website and/or Services or impersonate another person or organization; 
                </div></div>
                {/* line with bullets --Ends */}
                <div className='terms-body ml-32 row'>
                <div>e.</div> <div className='ml-8'>interfere with or disrupt the Website and/or Services or servers or networks connected to the Website and/or Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Website and/or Services or probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>f.</div> <div className='ml-8'>intentionally or unintentionally violate any applicable local, state, national or international law or regulation;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>g.</div> <div className='ml-8'>build a competitive product or service;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>h.</div> <div className='ml-8'>collect or store personal data about other Website and/or Services users or viewers;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>i.</div> <div className='ml-8'>license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Website and/or Services;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>j.</div> <div className='ml-8'>modify, translate, make derivative works of, decompile, disassemble, reverse engineer or attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques or algorithms of the Website and/or Services by any means whatsoever;
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>k.</div> <div className='ml-8'>use the Website and/or Services in a manner that utilizes the resources of the Website and/or Services more heavily than would be the case for an individual person using a conventional web browser.
                </div></div>
                <br/>

                {/* With normal a/b/c bullets --- Start*/}
                <div className='terms-body ml-8'>
                8. <span className='text-underline'>Payment Terms for Services. </span> Unless otherwise provided in an order form, the payment terms for Services or any Outhire professional services shall be as follows:
                </div><br/>  
                <div className='terms-body ml-32 row'>
                <div>a.</div> <div className='ml-8'>Fees. There are no charges for creating an Outhire employer or candidate account. End Users are eligible for a free initial consultation with Outhire. Following the consultation, if the End User wishes to gain full access to the Services, the End User shall enter into an order form with Outhire outlining the terms, the applicable fees due for the Services ("Fees") and all other applicable amounts, charges and taxes set out on the applicable order form.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>b.</div> <div className='ml-8'>Invoicing and Late Payments. Outhire shall invoice End User for any Fees due under the Agreement. Invoiced amounts shall be due and payable in full within such thirty (30) days after the date of the invoice. Any amounts not paid within such thirty-day period shall be subject to interest at the lesser of 1.5% per month or the maximum allowed by applicable law, which interest shall be immediately due and payable. All payments to Outhire are non-refundable, unless otherwise provided under the Agreement.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>c.</div> <div className='ml-8'>Taxes. All Fees are exclusive of any and all taxes, duties or levies assessed by applicable governmental authorities. All such taxes, duties and levies (exclusive of any taxes based upon Outhire’s net income) shall be assumed by and paid for by End User, regardless of whether included in any invoice sent to End User at any time by Outhire.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>d.</div> <div className='ml-8'>Suspension of Service. If any of End User's invoices is thirty (30) days or more overdue, in addition to any of its other rights or remedies, Outhire reserves the right to suspend the Services provided to End User until such amounts are paid in full.
                </div></div>
                <br/>
                 {/* With normal a/b/c bullets --- End*/}
                 <div className='terms-body ml-8 row'>
                <div>9.</div> <div className='ml-8'><span className='text-underline'>Links & Third-Party Websites. </span> The Website may link with other websites or platforms that are not owned or controlled by Outhire. For example, in connection with the Outhire digital profile, Outhire candidates may link their LinkedIn profiles with their Outhire account. In no event shall any reference to any third-party, third-party product or service be construed as an approval or endorsement by Outhire of that third-party, third-party product or service. Outhire is also not responsible for the content of any linked websites or any material available to you through a third-party, third-party product or service. Any third-party websites or services accessed by you through the Website and/or Services or linked by you to your Outhire account are subject to the terms and conditions of those websites and/or services and you are solely responsible for determining those terms and conditions and complying with them. You hereby release Outhire from all liability and/damages that may arise from your use of such third-party websites, receipt of services from any such third parties, or reliance on the information available or services provided by any such third parties.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>10.</div> <div className='ml-8'><span className='text-underline'>Support. </span> Any support issues with the Website or Services which you cannot resolve should be notified to us. We will use commercially reasonable efforts to make the Website and Services available during the Term except for: (a) planned downtime; or (b) any unavailability caused by circumstances beyond Outhire reasonable control, including without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labour problems, or Internet service provider failures or delays.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>11.</div> <div className='ml-8'><span className='text-underline'>Confidential Information. </span> “Confidential Information” means any and all information, including User Data, disclosed by either you or Outhire to the other party which is marked "confidential" or "proprietary", or which the recipient knows or has reason to know is regarded by the disclosing party as such. "Confidential Information" does not include any information that the receiving party can demonstrate by its written records: (a) was known to it prior to its disclosure hereunder by the disclosing party; (b) is or becomes known through no wrongful act of the receiving party; (c) has been rightfully received from a third party authorized to make such a disclosure; or (d) is independently developed by the receiving party; (e) has been approved for release by the disclosing party's prior written authorization. Neither you or Outhire will use any Confidential Information of the disclosing party except as necessary to exercise its rights or perform its obligations hereunder or as expressly authorized in writing by the other party. Each party shall use the same degree of care to protect the disclosing party's Confidential Information as it uses to protect its own confidential information of like nature, but in no circumstances less than reasonable care. Neither party shall disclose the other party's Confidential Information to any person or entity other than its officers, employees, consultants and legal advisors who need access to such Confidential Information in order to effect the intent of the Agreement, and who have entered into written confidentiality agreements with it as least as restrictive as those under the Agreement. Upon any termination of the Agreement, the receiving party will promptly return to the disclosing party or destroy, at the disclosing party's option, all of the disclosing party's Confidential Information.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>12.</div> <div className='ml-8'><span className='text-underline'>DISCLAIMER OF REPRESENTATIONS, WARRANTIES AND CONDITIONS. </span> TO THE GREATEST EXTENT PERMITTED BY LAW, THE SERVICES AND WEBSITE ARE PROVIDED ON AN "AS IS" BASIS AND THERE ARE NO WARRANTIES, REPRESENTATIONS OR CONDITIONS, EXPRESS OR IMPLIED, WRITTEN OR ORAL, ARISING BY STATUTE, OPERATION OF LAW, COURSE OF DEALING, USAGE OF TRADE OR OTHERWISE, REGARDING THEM OR ANY OTHER PRODUCT OR SERVICE PROVIDED UNDER THIS AGREEMENT OR IN CONNECTION WITH THIS AGREEMENT BY OUTHIRE. OUTHIRE DISCLAIMS ANY IMPLIED WARRANTIES OR CONDITIONS OF QUALITY, MERCHANTABILITY, MERCHANTABLE QUALITY, DURABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. OUTHIRE DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE OR SERVICES SHALL MEET ANY OR ALL OF YOUR PARTICULAR REQUIREMENTS, THAT THE WEBSITE AND/OR SERVICES WILL OPERATE ERROR-FREE OR UNINTERRUPTED OR THAT ALL ERRORS OR DEFECTS ON THE WEBSITE AND/OR IN THE SERVICES CAN BE FOUND OR CORRECTED. IT IS YOUR SOLE DECISION TO ENTER INTO AN EMPLOYMENT OR CONTRACTOR RELATIONSHIP IN CONNECTION WITH THE SERVICES AND OUTHIRE DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES ASSOCIATED WITH YOUR DECISION TO DO SO.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>13.</div> <div className='ml-8'><span className='text-underline'>LIMITATION OF LIABILITY. </span> OUTHURE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, LOSS OF DATA OR OTHER SUCH PECUNIARY LOSS) ARISING OUT OF OR RELATED TO THE USE OF, RELIANCE ON, OR INABILITY TO USE THE SERVICES EVEN IF OUTHIRE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL OUTHIRE’S AGGREGATE LIABILITY FOR DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED (A) FIFTY CANADIAN DOLLARS ($50). THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENSION PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>14.</div> <div className='ml-8'><span className='text-underline'>INDEMNIFICATION. </span> YOU SHALL INDEMNIFY AND HOLD OUTHIRE AND ITS SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, AGENTS, AND EMPLOYEES HARMLESS FROM ALL CLAIMS, ACTIONS, PROCEEDINGS, DEMANDS, DAMAGES, LOSSES, COSTS, AND EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES), INCURRED IN CONNECTION WITH YOUR BREACH OF THIS AGREEMENT, YOUR VIOLATION OF ANY LAW OR THE RIGHTS OF A THIRD PARTY, OR RESULTING FROM YOUR ACTIONS AS AN AUTHORIZED REPRESENTATIVE.
                </div></div><br/>
                <div className='terms-body ml-8'>
                15. <span className='text-underline'>Term & Termination. </span>
                </div><br/>  
                <div className='terms-body ml-32 row'>
                <div>a.</div> <div className='ml-8'>Term. The Agreement will be effective on the date you are first entitled to use the Website and/or Services by agreeing to these Terms (“Effective Date”). The term of the Agreement will begin upon the Effective Date and shall continue for as long as you use the Website and/or Services, unless earlier terminated sooner ("Term").                </div></div>
                <div className='terms-body ml-32 row'>
                <div>b.</div> <div className='ml-8'>Termination. Outhire may, under certain circumstances and without prior notice, immediately terminate the Agreement or your ability to access the Website and/or Services, or portions thereof. Cause for termination shall include, but not be limited to, (a) breaches or violations of the Agreement (including, without limitation, non-payment of any Fees) which have not been cured within thirty (30) days of notice to you by us, (b) requests by law enforcement or other government agencies, (c) a request by you, (d) discontinuance or material modification to the Website or Services (or any part thereof), (e) unexpected technical, security or legal issues or problems, and/or (f) participation by you, directly or indirectly, in fraudulent or illegal activities; or (g) or any reason at our sole discretion. Any termination of these Terms by Outhire shall be in addition to any and all other rights and remedies that Outhire may have.
                </div></div>
                <div className='terms-body ml-32 row'>
                <div>c.</div> <div className='ml-8'>Effect of Termination. Your rights to use and access the Website and Services, including all licenses granted by Outhire to you herein, will immediately terminate upon termination or expiration of this Agreement. Sections 3-5, 7, and 10-14 of these Terms shall survive the expiration or termination of this Agreement. Upon termination of the Agreement, Outhire reserves the right (but will have no obligation) to remove or to terminate Outhire accounts or reclaim Outhire account usernames.                </div></div>
                <br/>
                <div className='terms-body ml-8 row'>
                <div>16.</div> <div className='ml-8'><span className='text-underline'>Availability & Updates. </span> Outhire may alter, suspend, or discontinue this Website or the Services at any time and for any reason or no reason, without notice. The Website and/or Outhire Service may be unavailable from time to time due to maintenance or malfunction of computer or network equipment or other reasons. Outhire may periodically add or update the information and materials on this Website or update the Services without notice.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>17.</div> <div className='ml-8'><span className='text-underline'>Security. </span> Information sent or received over the internet is generally unsecure and Outhire cannot and does not make any representation or warranty concerning security of any communication to or from the Website or Services, or any representation or warranty regarding the interception by third parties of User Data or other information you submit to us via the Website or Services.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>18.</div> <div className='ml-8'><span className='text-underline'>Governing Law; Dispute Resolution. </span> These Terms will be governed by the laws of the Province of Ontario and the federal laws applicable therein. Neither the United States Convention on Contracts for the International Sales of Goods nor the Uniform Computer Information Transactions Act will apply to these Terms. You and Outhire shall use all reasonable endeavors to discuss and resolve any dispute which may arise out of or in connection with these Terms. If you and Outhire cannot resolve the dispute within ten (10) business days, you and Outhire will attempt to settle it in good faith by mediation. To initiate the mediation, either Outhire or you must give notice in writing to the other party requesting a mediation. A copy of the request should be sent to ADR Chambers. The mediation will take place in Toronto, Ontario and the language of the mediation will be English. The mediation shall be governed by and construed and take effect in accordance with the substantive law of the Province of Ontario. If the dispute is not settled by mediation within thirty (30) days of commencement of the mediation or within such further period as you and Outhire may agree in writing, the dispute shall be referred to and finally resolved by binding arbitration at ADR Chambers. The arbitration shall be governed by the applicable rules of the Arbitration Act (Ontario), and arbitration proceedings shall take place in Toronto, Ontario before one (1) arbitrator.  In the event you and Outhire are unable to agree as to the appointment of an arbitrator for any reason, then such arbitrator shall be selected randomly by ADR Chambers. You shall bear your own legal costs in connection with a mediation and/or arbitration under these Terms.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>19.</div> <div className='ml-8'><span className='text-underline'>General. </span> This Agreement constitutes the entire agreement between you and Outhire relating to the Website and/or Services. If any part of these Terms is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions. The failure of Outhire to exercise or enforce any right or provision under these Terms shall not constitute a waiver of such right or provision. Any waiver of any right or provision by Outhire must be in writing and shall only apply to the specific instance identified in such writing. You may not assign the Terms, or any rights or licenses granted hereunder, whether voluntarily, by operation of law, or otherwise, without Outhire’s prior written consent.
                </div></div><br/>
                <div className='terms-body ml-8 row'>
                <div>20.</div> <div className='ml-8'><span className='text-underline'>Contact. </span> If you have any questions about these Terms, please contact us at: <a className='here-link' target="_blank" href="/contact">www.outhire.com/contact</a>.
                </div></div><br/>

        </div>
            </div>
            </div>
          </div>
          <Footer/>

      </div>
      </div>
    );
  }
}

export default Terms;