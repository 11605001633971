import update from 'immutability-helper'
import { memo, useCallback, useState } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import { ItemTypes } from './ItemTypes.js'
const Container = memo(function Container({updateDust, dustbin}) {
  const [dustbins, setDustbins] = useState(dustbin)
  const [boxes, setBoxes] = useState([
    { name: 'Variable Compensation', type: 'priority' },
    { name: 'Health Benefits', type: 'priority' },
    { name: 'Minimal Overtime', type: 'priority' },
    { name: 'Mentorship', type: 'priority' },
    { name: 'Pre-Approved Experience', type: 'priority' },
    { name: 'Professional Development Support', type: 'priority' },
    { name: 'Title Growth', type: 'priority' },
    { name: 'Technical Skills Growth', type: 'priority' },
    { name: 'Industry Specialization', type: 'priority' },
    { name: 'Direct Reports', type: 'priority' },
    { name: 'Flexible Hours', type: 'priority' },
    { name: 'Remote Work', type: 'priority' }
  ])
  const [droppedBoxNames, setDroppedBoxNames] = useState([])
  function isDropped(boxName) {
    console.log(droppedBoxNames.indexOf(boxName) > -1)
    return droppedBoxNames.indexOf(boxName) > -1
  }
  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item
      if(dustbin[index].lastDroppedItem == null){
        setDroppedBoxNames(
            update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
          )
      }else{
        setDroppedBoxNames(
            update(droppedBoxNames, name && { [index] : {$set: name} }),
          )
      }
      
      setDustbins(
        update(dustbin, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )

      setBoxes([
        { name: 'Variable Compensation', type: 'priority' },
        { name: 'Health Benefits', type: 'priority' },
        { name: 'Minimal Overtime', type: 'priority' },
        { name: 'Mentorship', type: 'priority' },
        { name: 'Pre-Approved Experience', type: 'priority' },
        { name: 'Professional Development Support', type: 'priority' },
        { name: 'Title Growth', type: 'priority' },
        { name: 'Technical Skills Growth', type: 'priority' },
        { name: 'Industry Specialization', type: 'priority' },
        { name: 'Direct Reports', type: 'priority' },
        { name: 'Flexible Hours', type: 'priority' },
        { name: 'Remote Work', type: 'priority' }
      ])

      updateDust(update(dustbin, {
        [index]: {
          lastDroppedItem: {
            $set: item,
          },
        },
      }))
    },
    [droppedBoxNames, dustbin, boxes],
  )


  return (
    <div>
      <div style={{ overflow: 'hidden', clear: 'both' }}>
        {dustbin.map(({ accepts, lastDroppedItem }, index) => (
          <div className='dustbin-wrap'>
            <div className='dustbin-index'>{index + 1}.</div>
            <Dustbin
            accept={accepts}
            lastDroppedItem={lastDroppedItem}
            onDrop={(item) => handleDrop(index, item)}
            key={index}
          />
          </div>
        ))}
      </div>

      <div style={{ overflow: 'hidden', clear: 'both' }} className="box-wrap">
        {boxes.map(({ name, type }, index) => (
          <Box
            name={name}
            type={type}
            isDropped={isDropped(name)}
            key={index}
          />
        ))}
      </div>
    </div>
  )
})

export { Container as default }
