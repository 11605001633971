import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import bell from './../../assets/outhire/bell.png';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import history from './../../utilities/history';
import dot from "./../../assets/images/dot.svg";
import NavBar from '../../components/NavBar/TopNavBar';
import axios from 'axios';
import guest from './../../assets/outhire/guest.png';
import role from './../../assets/outhire/role.svg';
import task from './../../assets/outhire/task.svg';
import duration from './../../assets/outhire/duration.svg';
import skill from './../../assets/outhire/skill.svg';
import company from './../../assets/outhire/company.svg';
import opportunity from './../../assets/outhire/opportunity.svg';
import timeline from './../../assets/outhire/timeline.svg';
import compensation from './../../assets/outhire/compensation.svg';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';

const perks = [
  {
    id: 978038,
    title: 'Standard Health Benefits'
  },
  {
    id: 978041,
    title: 'Extended Health Benefits'
  },
  {
    id: 978044,
    title: 'RRSP'
  },
  {
    id: 978047,
    title: 'Stock Options'
  },
  {
    id: 978050,
    title: 'HSA (Health Spending Account)'
  },
  {
    id: 978053,
    title: 'LSA (Lifestyle Spending Account)'
  },
  {
    id: 978056,
    title: 'Mental Health Support'
  },
  {
    id: 978059,
    title: 'Travel Based Incentives'
  },
  {
    id: 978062,
    title: 'CPA Dues Support'
  },
  {
    id: 978065,
    title: 'CPA Program Support'
  },
  {
    id: 978068,
    title: 'Professional Development Support'
  },
  {
    id: 978071,
    title: 'Tokens Distributions'
  },
  {
    id: 978074,
    title: 'Flexible Hours'
  },
  {
    id: 978077,
    title: 'Home Office Support'
  },
  {
    id: 986375,
    title: 'Vehicle Allowance'
  },
]

class ExploreJobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {title: '', type: ''},
      role_detail: '',
      task: '',
      skill: '',
      company: '',
      opportunity: '',
      timeline: '',
      office: '',
      avg_salary: '',
      perks: [],
    }
  }

  componentDidMount() {
    const apiJob = '../api/catstone/jobs/getJobById'
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

                        const payload = {
                            id: id
                        }

                        axios.post(apiJob, payload)
                            .then((result) => {
                                console.log(result)
                                for(let i = 0; i < result.data._embedded.custom_fields.length; i++){
                                  if(result.data._embedded.custom_fields[i].id==341462){
                                    this.setState({
                                      role_detail: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341459){
                                    this.setState({
                                      task: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341450){
                                    this.setState({
                                      skill: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341468){
                                    this.setState({
                                      avg_salary: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341471){
                                    this.setState({
                                      perks: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341456){
                                    this.setState({
                                      company: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341474){
                                    this.setState({
                                      opportunity: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341477){
                                    this.setState({
                                      timeline: result.data._embedded.custom_fields[i].value
                                    })
                                  }

                                  if(result.data._embedded.custom_fields[i].id==341465){
                                    switch(result.data._embedded.custom_fields[i].value[0]){
                                        case 978020:
                                            this.setState({office: 'Permanently In-Office'});
                                            break;
                                        case 978023:
                                            this.setState({office: 'Temporarily In-Office'});
                                            break;
                                        case 978026:
                                            this.setState({office: 'Temporary Hybrid'});
                                            break;
                                        case 978029:
                                            this.setState({office: 'Permanently Hybrid'});
                                            break;
                                        case 978032:
                                            this.setState({office: 'Permanently Remote'});
                                            break;
                                        case 978035:
                                            this.setState({office: 'Temporarily Remote'});
                                            break;
                                    }
                                  }

                                  if(result.data._embedded.custom_fields[i].id==313188){
                                    if(result.data._embedded.custom_fields[i].value == 874521){
                                      result.data.type = 'Salaried Permanent';
                                    } else if(result.data._embedded.custom_fields[i].value == 874524){
                                      result.data.type = 'Salaried Contract';
                                    } else if(result.data._embedded.custom_fields[i].value == 874527){
                                      result.data.type = 'Hourly Contract';
                                    } else if(result.data._embedded.custom_fields[i].value == 874530){
                                      result.data.type = 'Hourly Permanent';
                                    
                                  }
                                }

                                }
                                this.setState({
                                  job: result.data
                                }, () => {
                                  
                                })
                            })
  }

  render() {
    return (
      <div className="container">
        
        <div className="d-flex justify-left">
          <div className='side-bar web-nav'>
            <div className='menu-logo-wrap'>
              <img src={logo} alt="" className='menu-logo' /></div>
            <div className='side-menu'>
            <div className='menu-list active-menu' onClick={()=> history.push('/explore-jobs')}>
                <div className='icon-wrap'>
                  <img src={job} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Job Postings</div>
              </div>
              <div className='menu-list' onClick={() => history.push('/signup')}>
                <div className='icon-wrap'>
                  <img src={profile} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Sign Up</div>
              </div>
            </div>
          </div>
          
            <div className='main-canvas'>
            
            <div className='top-sec job-top'>
              <div className='top-left'>
               <div className='card-container'>
               <div className='card f-card'>
                  <div className='card-head'>
                    <div className='card-title'>{this.state.job.title}</div>
                  </div>
                  <div className='info-group'>
                    <div className='info-label'>Location</div>
                    {this.state.job.location!=undefined && (
                      <div className='info-txt'>{this.state.job.location['city']}, {this.state.job.location['state']}</div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row'>
                  <div className='info-group'>
                    <div className='info-label'>Type</div>
                    {this.state.office == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.office}</div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Compensation Type</div>
                    {this.state.job.type != '' && this.state.job.type != undefined ? (
                      <div className='info-txt'>{this.state.job.type}</div>
                    ) : (
                      <div className='info-txt'>Not set</div>
                    )}
                  </div>
                  </div>
                  <div className='info-divider'></div>
                </div>
               </div>
              </div>
              <div className='top-btn'>
                <button className='btn-save mb-top-btn' onClick={() => history.push('/signin')}>I'm Interested</button>
                {/* <button className='btn-save' onClick={()=>history.push('/referrals')}>Refer a Friend</button> */}
              </div>
              <div className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>

                <div className='side-bar mo-side-bar'>
            <div className='menu-logo-wrap'>
              <img src={logo} alt="" className='menu-logo' /></div>
            <div className='side-menu'>
              <div className='menu-list' onClick={()=> history.push('/explore-jobs')}>
                <div className='icon-wrap'>
                  <img src={job} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Job Postings</div>
              </div>
              <div className='menu-list' onClick={()=> history.push('/signup')}>
                <div className='icon-wrap'>
                  <img src={profile} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Sign Up</div>
              </div>
            </div>
          </div>
                </Menu>
              </div>
              <div className='top-right'>
              {/* <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={()=>history.push('/settings')}/>
                </div>
                <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon'/>
                </div> */}
                <div className='guest-avatar-wrap'>
                  <img src={guest} alt='' className='guest-avatar'/>
                  <div className='guest-text'>Guest</div>
                </div>
              </div>
            </div>
            <div className='bottom-sec mt-0 mo-bottom-ex'>
            <div className='card-container mo-card mo-card-container'>
               <div className='card f-card'>
                  <div className='card-head'>
                    <div className='card-title job-card-title'>{this.state.job.title}</div>
                  </div>
                  <div className='info-group'>
                    <div className='info-label'>Location</div>
                    {this.state.job.location!=undefined && (
                      <div className='info-txt'>{this.state.job.location['city']}, {this.state.job.location['state']}</div>
                    )}
                    <div className='info-divider'></div>
                  </div>
                  <div className='row'>
                  <div className='info-group'>
                    <div className='info-label'>Type</div>
                    {this.state.office == ''? (
                        <div className='info-txt'>Not set</div>
                    ): (
                        <div className='info-txt'>{this.state.office}</div>
                    )}
                  </div>
                  <div className='info-group ml-64'>
                    <div className='info-label'>Compensation Type</div>
                    {/* {this.state.job.type == 'H'? (
                        
                    ) : this.state.job.type=='S'? (
                        <div className='info-txt'>Salaried</div>
                    ) : (
                        <div className='info-txt'>Unknown</div>
                    )} */}
                    {this.state.job.type != '' && this.state.job.type != undefined ? (
                      <div className='info-txt'>{this.state.job.type}</div>
                    ) : (
                      <div className='info-txt'>Not set</div>
                    )}
                  </div>
                  </div>
                  <div className='info-divider'></div>
                </div>
               </div>
               <div className='mo-top-btn'>
                <button className='btn-save mb-top-btn' onClick={() => history.push('/signin')}>I'm Interested</button>
                {/* <button className='btn-save mr-0' onClick={()=>history.push('/referrals')}>Refer a Friend</button> */}
              </div>
              <div className='card-wrap'>
              <div className='card detail-card'>
                  <div className='card-head'>
                    <img alt='' className='detail-icon' src={role}/>
                    <div className='card-title job-card-title'>The Role </div>
                  </div>
                  <div className='info-group'>
                    {this.state.role_detail!='' && this.state.role_detail!=null && (
                      (this.state.role_detail).split('\n').length==1 ? (
<div className='info-txt'>{this.state.role_detail}</div>
                      ) : (
                        Object.keys((this.state.role_detail).split('\n')).map((key) => (
                          ((this.state.role_detail).split('\n')[key]).startsWith('•') ? (
                            <div className='row'>
                            <img src={dot} alt='' className='dot-list'/>
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key].split('•')[1]}</div>
                            </div>
                          ): ((this.state.role_detail).split('\n')[key]).startsWith('--') ? (
                            <div className='row'>
                            <img src={dot} alt='' className='dot-list'/>
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key].split('--')[1]}</div>
                            </div>
                          ) : (
                            <div className='info-txt' key={key}>{(this.state.role_detail).split('\n')[key]}</div>
                          )
                          
                        ))
                      )
                      
                    )}
                  </div>

                </div>
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={task}/>
                    <div className='card-title job-card-title'>The Tasks</div>
                  </div>
                  <div className='info-group'>
                    {/* <img src={dot} alt='' className='dot-list'/> */}
                    {this.state.task!='' && this.state.task!=null && (
                     (this.state.task).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.task}</div>
                                            ) : (
                                              Object.keys((this.state.task).split('\n')).map((key) => (
                                                ((this.state.task).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.task).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.task).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                    
                  </div>
                </div>
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={skill}/>
                    <div className='card-title job-card-title'>The Skill Set</div>
                  </div>
                   <div className='info-group'>
                    {/* <img src={dot} alt='' className='dot-list'/> */}
                    {this.state.skill!='' && this.state.skill!=null && (
                     (this.state.skill).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.skill}</div>
                                            ) : (
                                              Object.keys((this.state.skill).split('\n')).map((key) => (
                                                ((this.state.skill).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.skill).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.skill).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                    
                  </div>
                </div>
              </div>

              <div className='card-wrap'>
              {this.state.job.type.includes('Hourly') && (
               <div className='card detail-card'>
               <div className='card-head'>
               <img alt='' className='detail-icon' src={duration}/>
                 <div className='card-title job-card-title'>The Duration</div>
               </div>
               <div className='info-group'>
                 <div className='info-label'>The Duration</div>
               {this.state.job.duration == "" ? (
                   <div className='info-txt'>Not set</div>
               ) : (
                 <div className='info-txt'>{this.state.job.duration}</div>
               )}
                 <div className='info-divider'></div>
               </div>
             </div>
             )}

                <div className='card detail-card'>
                <div className='card-head'>
                <img alt='' className='detail-icon' src={compensation}/>
                  <div className='card-title job-card-title'>Compensation</div>
                </div>
                {this.state.job.type.includes('Salaried') && (
                    <div className='info-group'>
                  <div className='info-label mt-0'>Average Salary</div>
                  {(this.state.avg_salary == '' || this.state.avg_salary == null)? (
                      <div className='info-txt'>Not set</div>
                  ): (
                      <div className='info-txt'>{this.state.avg_salary}</div>
                  )}
                   
                </div>
                  )}
               
              
                 <div className='info-divider'></div>
               
                {(this.state.perks !=undefined && this.state.perks.length > 0 && this.state.job.type.includes('Salaried')) && (
                  <div className='info-group'>
                  <div className='info-label'>Perks</div>
                  {Object.keys(perks).map((key) => (
                    Object.keys(this.state.perks).map((k) => (
                      perks[key].id == this.state.perks[k] && (
                        <div className='row'>
                        <img src={dot} alt='' className='dot-list'/>
                        <div className='info-txt' key={k}>{perks[key].title}</div>
                        </div>
                      )
                    ))
                  ))}
                
                </div>
                )} 
              </div>
              
                <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={company}/>
                    <div className='card-title job-card-title'>Company Overview </div>
                  </div>
                  <div className='info-group'>
                  {this.state.company != '' && this.state.company!=null && (
                     (this.state.company).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.company}</div>
                                            ) : (
                                              Object.keys((this.state.company).split('\n')).map((key) => (
                                                <div className='info-txt'>{(this.state.company).split('\n')[key]}</div>
                                              ))
                                            )
                    )}
                  </div>
                </div>
                {this.state.job.type.includes('Salaried') && (
                   <div className='card detail-card'>
                   <div className='card-head'>
                   <img alt='' className='detail-icon' src={opportunity}/>
                     <div className='card-title job-card-title'>The Opportunity</div>
                   </div>
                   <div className='info-group'>
                   {this.state.opportunity != '' && this.state.opportunity!=null && (
                      (this.state.opportunity).split('\n').length==1 ? (
                       <div className='info-txt'>{this.state.opportunity}</div>
                                             ) : (
                                               Object.keys((this.state.opportunity).split('\n')).map((key) => (
                                                 ((this.state.opportunity).split('\n')[key]).startsWith('•') ? (
                                                   <div className='row'>
                                                   <img src={dot} alt='' className='dot-list'/>
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key].split('•')[1]}</div>
                                                   </div>
                                                 ): ((this.state.opportunity).split('\n')[key]).startsWith('--') ? (
                                                   <div className='row'>
                                                   <img src={dot} alt='' className='dot-list'/>
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key].split('--')[1]}</div>
                                                   </div>
                                                 ) : (
                                                   <div className='info-txt' key={key}>{(this.state.opportunity).split('\n')[key]}</div>
                                                 )
                                               ))
                                             )
                     )}
                   </div>
                 
                 </div>
                )}

                {this.state.job.type.includes('Salaried') && (
                  <div className='card detail-card'>
                  <div className='card-head'>
                  <img alt='' className='detail-icon' src={timeline}/>
                    <div className='card-title job-card-title'>Expectations Timeline </div>
                  </div>
                  <div className='info-group'>
                    {this.state.timeline != '' && this.state.timeline!=null && (
                     (this.state.timeline).split('\n').length==1 ? (
                      <div className='info-txt'>{this.state.timeline}</div>
                                            ) : (
                                              Object.keys((this.state.timeline).split('\n')).map((key) => (
                                                ((this.state.timeline).split('\n')[key]).startsWith('•') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key].split('•')[1]}</div>
                                                  </div>
                                                ): ((this.state.timeline).split('\n')[key]).startsWith('--') ? (
                                                  <div className='row'>
                                                  <img src={dot} alt='' className='dot-list'/>
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key].split('--')[1]}</div>
                                                  </div>
                                                ) : (
                                                  <div className='info-txt' key={key}>{(this.state.timeline).split('\n')[key]}</div>
                                                )
                                              ))
                                            )
                    )}
                  </div>
                </div>
                )}
                
              </div>
            </div>
          </div>
         
          
        </div>
      </div>
    );
  }
}

export default ExploreJobDetails;