import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import tony from './../../assets/outhire/tony.jpg';
import profile from './../../assets/outhire/person.svg';
import yes from './../../assets/outhire/yes.svg';
import no from './../../assets/outhire/no.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from '../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import moment from "moment";
import AdminMenu from '../../components/NavBar/AdminMenu';
import { connect } from 'react-redux';
import slogo from './../../assets/outhire/slogo.png';


class ModifyUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
     users: [],
     
     candidate_id: null,
     isNotificationModal: false,
     is_nolink: null,
     is_linked: null,
     is_unlink: null,
     isModifyModal: false,
     email: '',
     last_name: '',
     first_name: '',
     linkedin: '',
     phone: '',
     postal_code: '',
     selected_user: {},
     catsone_id: null,
     candidate: null,
     is_modified: false,
     is_deleted: false,
     is_disabled: false,
     isWarningMoadl: false,
     warning: '',
     isModifyError: false
    }
  }

  componentDidMount() {
    this.getUsers();

  }

  getUsers() {
    const apiUsers = '/api/v1/users'


    axios.get(apiUsers)
        .then((result) => {
            console.log(result)
            var users = [];
            for(let i = 0; i < result.data.length; i ++){
                if(result.data[i].catsone_status == 2 && result.data[i].catsone_id != undefined){
                    users.push(result.data[i]);
                    this.setState({
                      users: users.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      })
                    })
                  }

            }
        })
  }

  selectUser(user){
    this.setState({
      selected_user: user
    })
  }

  linkUser = () => {
    const apiUser = "/api/v1/edit-user"; 
          var newData = this.state.selected_user;
          newData.catsone_id = Number(this.state.candidate_id);
          newData.catsone_status = 2;
          newData.modified_by = "Tony K";
          axios.post(apiUser, newData).then((result) => {
              console.log(result);
              this.setState({
                selected_user: {},
                candidate_id: null
              })
              this.setState({
                isNotificationModal: true,
                is_linked: true
              }, () => {
                this.setState({
                  linked: [],
                  nolink: []
                }, ()=> {
                  this.getUsers();
                })
                
              });
            });
  }

  hideNotificationModal = () => {
    this.setState({
      isNotificationModal: false,
     is_modified: null,
     is_disabled: null,
     is_deleted: null,
     warning: ''
    })
  }

  handleCandidateId = (e) => {
    this.setState({
      candidate_id: e.target.value
    })
  }

openModify(catsone_id, user){

this.setState({
  selected_user: user,
  catsone_id: catsone_id
})

  const apiCandidate = '../api/catstone/candidates/getCandidateById'

  const payload = {
    id: catsone_id
  }

  axios.post(apiCandidate, payload)
    .then((result) => {
      console.log(result)
      if(result.data.status == 'error'){
        this.setState({
          isModifyError: true
        })
      }else{
        this.setState({
          candidate: result.data,
          email: result.data.emails['primary'],
          first_name: result.data.first_name,
          last_name: result.data.last_name,
          postal_code: result.data.address['postal_code'],
          linkedin: result.data.social_media_urls.length == 0 ? '' : result.data.social_media_urls[0]
        }, () => {
          this.setState({
            isModifyModal: true
          })
        })
  
        for (let i = 0; i < result.data._embedded.custom_fields.length; i++) {
          switch (result.data._embedded.custom_fields[i].id) {
            case 343262:
              this.setState({ phone: result.data._embedded.custom_fields[i].value });
              break;
          }
        }
      }
    })
}

hideModifyModal = () => {
  this.setState({
    isModifyModal: false,
    selected_user: null,
    candidate: null,
    catsone_id: null,
    is_deleted: null,
    is_modified: null,
    is_disabled: null
  })
}

deleteAccount = () => {
  const payload = {
    _id: this.state.selected_user.id
};
console.log(payload)

axios.delete('../api/v1/delete', {
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload
  })
.then((res) => {
    console.log(res);
    this.getUsers();
    this.setState({
      isWarningMoadl: false,
      warning: '',
      isNotificationModal: true,
      is_deleted: true
    })
})
}

disableAccount = () => {
  const apiUser = "../api/v1/edit-user"; 
  var newData = this.state.selected_user;
  newData.catsone_status = 0;
  newData.modified_by = this.props.admin.first_name + ' ' + this.props.admin.last_name;

  newData.modified_by =this.props.admin.first_name + ' ' + this.props.admin.last_name;;
  axios.post(apiUser, newData).then((result) => {
      console.log(result);
      this.getUsers();
      this.setState({
        selected_user: {},
        isWarningMoadl: false,
        warning: '',
        isNotificationModal: true,
        is_disabled: true
      })
    });

}

savePersonal = () => {
  var newData = this.state.candidate;

  const apiEmail = '../api/catstone/candidates/createPrimaryEmail'

  const emailPayload = {
    id: this.state.catsone_id,
    email: this.state.email,
    is_primary: true
  }

  axios.post(apiEmail, emailPayload)
    .then((res) => {
      console.log(res)
    })

    newData.address['postal_code'] = this.state.postal_code;
    newData.first_name = this.state.first_name;
    newData.last_name = this.state.last_name;
    // if(newData.social_media_urls.length > 0 ){
    //   if(newData.social_media_urls[0].split('https://www.')[1] != this.state.linkedin && newData.social_media_urls[0].split('https://www.')[1] != ''){
    //   // newData.social_media_urls.spice(0,0, 'https://www.' + this.state.linkedin)
    //   newData.social_media_urls[0] = 'https://www.' + this.state.linkedin;
    // }

    // }else{
    //   newData.social_media_urls.push('https://www.' + this.state.linkedin);
    // }

    if(newData.social_media_urls.length > 0) {
      if(newData.social_media_urls[0] !== this.state.linkedin){
        newData.social_media_urls[0] = this.state.linkedin
      }else{
        newData.social_media_urls.push(this.state.linkedin)
      }
    }
    
    const apiCandidate = '../api/catstone/candidates/updateCandidateById'

    const payload = {
      id: this.state.catsone_id,
      candidate: newData
    }

    console.log(payload)

    axios.post(apiCandidate, payload)
      .then((res) => {
        console.log(res)
      })

      const apiCustom = '../api/catstone/candidates/updateCustomField'

      const phonePayload = {
        id: this.state.catsone_id,
        custom_id: 343262,
        data: {
          value: Number(this.state.phone)
        }
      }
  
      axios.post(apiCustom, phonePayload)
      .then((res) => {
        const apiUser = "../api/v1/edit-user"; 
        var newData = this.state.selected_user;
        newData.modified_by =this.props.admin.first_name + ' ' + this.props.admin.last_name;
        axios.post(apiUser, newData).then((result) => {
            console.log(result);
            this.setState({
              selected_user: {},
              isModifyModal: false
            }, () => {
              this.setState({
                
                  isNotificationModal: true,
                  is_modified: true
              })
            })
           
          });

      });

}

handleEmailChange = (e) => {
  this.setState({
    email: e.target.value
  })
}

handleCellChange = (e) => {
  this.setState({
    phone: e.target.value
  })
}

handlePostalChange = (e) => {
  this.setState({
    postal_code: e.target.value
  })
}

handleFirstChange = (e) => {
  this.setState({
    first_name: e.target.value
  })
}

handleLastChange = (e) => {
  this.setState({
    last_name: e.target.value
  })
}

handleLinkedinChange = (e) => {
  this.setState({
    linkedin: e.target.value
  })
}

openWarningModal(action){
  this.setState({
    isModifyModal: false,
    isWarningMoadl: true,
    warning: action
  })
}

hideWarningModal = () => {
  this.setState({
    isWarningMoadl: false,
    warning: ''
  })
}

hideModidyErrorMOdal = () => {
  this.setState({
    isModifyError: false
  })
}


  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
         <AdminMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Modify Users</div>
                {/* <div className='body-title mt-32'>Refer headings text</div>
                <div className='body-txt mt-10'>Additional text about referrals</div> */}
              </div>
              <div className='top-right'>
              {/* <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={()=>history.push('/settings')}/>
                </div>
                <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon'/>
                </div> */}
                <div className='sm-avatar-wrap  bg-white'>
                  <img src={slogo} alt='' className='sm-avatar'/>
                </div>
              </div>
            </div>
            <div className='bottom-container'>
              <div className='table-card'>
                <div class="block-wrap-vh block-full-wrap">
                  <div className='block-header'>Candidates</div>
                {/* <div className="block-tab">
                    <div className={this.state.tab === "pending" ? "appt-title-wrap tab-active" : "appt-title-wrap"} onClick={() => this.switchTab("pending")}><span className="appt-title">Pending</span></div>
                    <div className={this.state.tab === "linked" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("linked")}><span className="appt-title">Linked</span></div>
                    <div className={this.state.tab === "nolink" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("nolink")}><span className="appt-title">Do Not Link</span></div>
                  </div> */}
                  <div className="block-divider mb-60"></div>

                       <div className='c-table'>
                       <div className='table-header'>
                         <div className='table-head name-head'>Last name</div>
                         <div className='table-head method-head'>First name</div>
                         <div className='table-head email-head'>Email</div>
                         <div className='table-head method-head'>Sign in method</div>
                         <div className='table-head method-head'>CATSONE ID</div>
                         <div className='table-head method-head'>Actions</div>
                         <div className='table-head method-head'>Modified Date</div>
                       </div>
                      <div className='table-data-wrap'>
                      {Object.keys(this.state.users).map((key) => (
                        <div className='table-body p-3'>
                        <div className='table-head name-head'>{this.state.users[key].last_name}</div>
                        <div className='table-head method-head'>{this.state.users[key].first_name}</div>
                        <div className='table-head email-head'>{this.state.users[key].email}</div>
                        {(this.state.users[key].linkedin_id != null && this.state.users[key].linkedin_id != undefined) ? (
                         <div className='table-head method-head'>LinkedIn</div>
                        ) : (
                         <div className='table-head method-head'>Email</div>
                        )}
                        <div className='table-head method-head'>{this.state.users[key].catsone_id}</div>
                        <div className='table-head method-head'>
                          <div className='table-btn-wrap'>
                            <button className='btn-action' onClick={()=> this.openModify(this.state.users[key].catsone_id, this.state.users[key])}>Modify Account</button>
                          </div>
                         
                        </div>
                        <div className='table-head method-head'>{moment(this.state.users[key].updatedAt).format("YYYY-MM-DD")} by {this.state.users[key].modified_by}</div>
                      </div>
                      ))}
                     
                       </div>
                       </div>
                  
                  </div>
              </div>
            </div>
           
          </div>
        </div>
        <Modal show={this.state.isNotificationModal} handleClose={this.hideNotificationModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Success!</div>
            </div>
           {this.state.is_modified && (
             <div className='n-modal-txt'>The account has been modified. </div>
           )}
           {this.state.is_deleted && (
             <div className='n-modal-txt'>The account is now deleted. 
             </div>
           )}
           {this.state.is_disabled && (
             <div className='n-modal-txt'>This account is now disabled
             </div>
           )}
          </div>
        </Modal>
        <Modal show={this.state.isModifyModal} handleClose={this.hideModifyModal}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Modify User Account</div>
            </div>
           <div className='m-modal-content'>
           <div className='o-modal-title'>Personal Information</div>
           <div className='info-group'>
                      <div className='info-label'>First Name</div>
                      <input className='p-input'type="text"  value={this.state.first_name} placeholder="Enter first name" onChange={this.handleFirstChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Last Name</div>
                      <input className='p-input'type="text"  value={this.state.last_name} placeholder="Enter last name" onChange={this.handleLastChange} />
                    </div>
           <div className='info-group'>
                      <div className='info-label'>Email</div>
                      <input className='p-input' value={this.state.email} placeholder="example@example.com" onChange={this.handleEmailChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>LinkedIn</div>
                      <input className='p-input'type="text"  value={this.state.linkedin} placeholder="eg. https://www.linkedin.com/example" onChange={this.handleLinkedinChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Phone number</div>
                      <input className='p-input'type="number"  value={this.state.phone} placeholder="Enter primry phone number" onChange={this.handleCellChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Postal code</div>
                      <input className='p-input' value={this.state.postal_code} placeholder="Enter postal code" onChange={this.handlePostalChange} />
                    </div>
                    <div className='o-modal-title mt-16'>Account Management</div>
                    <div className='row'>
                      <button className='btn-sm-light mr-10' onClick={() => this.openWarningModal('delete')}>Delete Account</button>
                      <button className='btn-sm-light' onClick={() => this.openWarningModal('disabled')}>Disable Account</button>
                    </div>

                    <div className='row mt-24 float-right'>
                      <button className='btn-save' onClick={this.savePersonal}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.hideModifyModal}>Cancel</button>
                    </div>
           </div>
          </div>
        </Modal>
        <Modal show={this.state.isWarningMoadl} handleClose={this.hideWarningModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Warning!</div>
            </div>
            <div className='n-modal-txt'>This action cannot be undone.</div><br/>
            <div className='n-modal-txt'>Click 'Proceed' to continue</div><br/>
             <br/>
              {this.state.warning == 'delete'? (
                <button className='btn-save' onClick={this.deleteAccount}>Proceed</button>
              ) : this.state.warning == 'disabled' &&(
                <button className='btn-save' onClick={this.disableAccount}>Proceed</button>
              )}
          </div>
        </Modal>
        <Modal show={this.state.isModifyError} handleClose={this.hideModidyErrorMOdal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Oops, something went wrong!</div>
            </div>
            <div className='n-modal-txt'>We've detected this user's Catsone ID is invalid, therefore we're not unable to modify this user.</div><br/>
            <div className='n-modal-txt'>Please go to Dashboard and unlink&update this user's Catsone ID to a valid ID before you start this action.</div><br/>
          </div>
        </Modal>
      </div>
      
    );
  }
}

//map stored state to props access
const mapStateToProps = (state) => {
  return {
      admin: state.candidate.outhire_admin
  }
}

export default connect(mapStateToProps)(ModifyUsers);