import React, { Component } from 'react';
import './styles.css';
import history from './../../utilities/history';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import NavBar from '../../components/NavBar/TopNavBar';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import terms from './../../assets/outhire/PrivacyPolicy.png';
import dot from './../../assets/outhire/dot.svg';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

class Privacy extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="container">
         <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap">
            <div className="first-wrap content-center coloum mo-first-wrap">
              {/* <div className="sign-diagonal"></div> */}
              <img className="white-poligon long-white" src={white} alt=''/>
              <div className="top-section mo-mt-0">
            <div className="home-title w-full mb-75  mt-single">Privacy Policy</div>
            <div className="terms-card mb-100 top-card">
            <div className='terms-title mb-16'>Last Updated: 11/29/2022</div>

              {/* Bold Title --- Starts */}
                 <div className='terms-body terms-bold'>Introduction</div>
                {/* Bold Title --- Ends */} 

                {/* Normal Paragraph without bullets --- Starts */}
                <div className='terms-body'>
                Outhire Inc. ("Outhire", “we”, “us”, “our”), a Canadian registered company, is committed to protecting your privacy. We make our website, <a className='here-link' target="_blank" href="/">www.outhire.ca</a> (“Website”), available to you, together with our portal (“Platform”) and other recruitment, interview, and talent matching services as further described and made available on our Website (collectively with Website and Platform, the "Services"). 
                </div><br/>
                {/* Normal Paragraph without bullets --- Ends */}
                <div className='terms-body'>
                This privacy policy (“Privacy Policy”) describes how we collect, store, use, and distribute Personal Information (as defined below). For the purposed of this Privacy Policy, "Personal Information" means any information related to an identifiable individual or that may be used to identify an individual. Please read the following carefully to understand our practices regarding Personal Information, including Personal Information of visitors to our Website, employers who use the Website and/or Services to seek suitable candidates (“Customers”) and users seeking to search, apply and interview for jobs ("Candidates"). The use of “you” and “your” refers to Customers, Candidates and any other visitors or users of the Website and/or Services, as applicable.
                </div><br/>
                <div className='terms-body'>
                We may change this Privacy Policy from time to time. We encourage you to review this Privacy Policy each time you use our Services to see if the policy has been changed, as indicated by the “Last Updated” set out above. We may at our discretion choose to highlight the fact that we made material changes by posting a notice on our Website and/or by sending you a notice, as appropriate. Any non-material change (such as clarifications) to this Privacy Policy will become effective on the date the change is posted and any material changes will become effective thirty (30) days from their posting on the Website. Unless stated otherwise, our current Privacy Policy applies to all Personal Information that we have about you.
                </div><br/>

                <div className='terms-body terms-bold'>Consent</div>
                <div className='terms-body'>
                By using the Website, Platform or our Services you consent to the use of your Personal Information as described in this Privacy Policy. Except as set forth in this Privacy Policy, your Personal Information will not be used for any other purpose without your consent. We will not actively collect Personal Information for the purpose of sale or marketing of such Personal Information in a way that specifically identifies an individual. In other words, we don’t sell customer lists.  You may withdraw your consent to our processing of your Personal Information at any time. However, withdrawing consent may result in your inability to continue using the Website and/or the Services.
                </div><br/>

                <div className='terms-body terms-bold'>Information You Provide Us</div>
                <div className='terms-body'>
                You may give us Information by:
                </div>

                {/* Bullets with underline text --- starts */}
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Using the Services as a Customer.</span> As a Customer, or agent or employee of a Customer, you may provide us with certain Personal Information when you sign up for or use the Services, or when we have audio or video calls with you. The type of Personal Information collected includes your name, company email address, phone number, payment or billing information, Outhire account username and password, any Personal Information you disclose to us via email, and any Personal Information you disclose to us or to Candidates via during audio or video calls.
                </div></div>
                {/* Bullets with underline text --- ends */}
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Using the Services as a Candidate.</span> As a Candidate, you may provide us with Personal Information when you sign up for or use the Services, when you link social media profiles (ie LinkedIn) with your Outhire account, when you have audio or video calls with us, or when you submit a video recording to us to build your Outhire digital profile. The type of Personal Information collected includes your name, email address, phone number, Outhire account username and password, address, personal identification documents, your references and any Personal Information about them that you provide to us, Personal Information you disclose to us via email, during audio or video calls, or in in video recordings you submit to us, any photos you submit to us, any Personal Information you make available to us in your LinkedIn profile (if you choose to link your LinkedIn profile through Outhire), any Personal Information in your resume; and any Personal Information in your transcripts.
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Using the contact form on our Website.</span> You may submit feedback, consultation requests, comments, questions or requests to be contacted by Outhire via the Website (“Feedback”). The types of Personal Information collected includes your name; email address; phone number; and Personal Information in any comments that you disclose to us.
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Supplying us with goods or services.</span> If you are a supplier of Outhire, the information we collect from you includes a contact name; email address; business address; and telephone number.
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Applying to work with us.</span> If you apply for a job with us, the Personal Information collected includes your name; email address; phone number; address; your references and any Personal Information about them that you provide to us; information you disclose to us via email, during audio or video calls, or in in video recordings you submit to us; any photos you submit to us; information you make available to us in your LinkedIn profile; your resume; and your transcripts.
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div><span className='text-underline'>Using Outhire’s professional services.</span> If you engage Outhire to perform development work or other one-time services, the Personal Information collected includes your name, email address, phone number, address and billing and payment information.
                </div></div>

                <br/>
                <div className='terms-body'>
                If you submit your resume to us, we ask you to only include information relevant to the position you’re applying for, and your employment history and education (including, without limitation, certifications and/or degrees obtained, previous work experience, relevant awards, etc). If you submit Feedback, video or audio recordings, or any other materials to us in connection with your use of the Services, we ask that you do not disclose sensitive Personal Information (including, without limitation, gender, height, weight, medical information, religion, philosophical or political beliefs, etc) in your submitted materials. 
                </div><br/>

                <div className='terms-body terms-bold'>Information We Collect From Your Use of the Outhire Services</div>
                <div className='terms-body'>
                Outhire may collect certain information when you use the Services, such as internet protocol addresses. This information is logged to help diagnose technical problems, to administer our Website and to improve the quality of the Services.
                </div><br/>
                <div className='terms-body'>
                We may use cookies to track content usage and traffic on the Services. A cookie is a feature of your web browser that consists of a text file that is placed on your hard disk by a web server. The Services use cookies to help it compile aggregate statistics about usage of the Website, such as how many individuals visit the Website, how long individuals spend viewing the Website, and what pages of the Website are viewed most often. This information is used to improve the content and operation of the Website. You can set your browser to notify you when you are sent a cookie. This gives you the chance to decide whether or not to accept it. Please note that if you disable cookies, you may not be able to take advantage of all the features of the Website.
                </div><br/>
                <div className='terms-body'>
                We also use tracking technologies, such as beacons, scripts and tags. These technologies are used by us to analyze trends, such as Website user navigation and inputs on the Services, and to gather statistical information about our Website visitors and Services userbase. This information will be kept confidential but aggregate and anonymized statistics that do not personally identify an individual may be made available to third parties we engage to provide the Services. We may receive reports based on the use of these technologies by our third-party partners on an individual and aggregated basis. The different browser you can use to access the Services may offer its own management tools for removing these types of tracking technologies, but please note that if you disable some or all of these tracking technologies, you may not be able to take advantage of all the features of the Services.
                </div><br/>

                <div className='terms-body terms-bold'>How We Use Information We Collect</div>
                <div className='terms-body'>
                We may use information we collect in the following ways:
                </div><br/>
                 {/* Bullets without underline text --- starts */}
                 <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To administer and improve our Services;
                </div></div>
                {/* Bullets without underline text --- ends */}
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To provide you with the services or development work you request;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To set up employer or candidate accounts on the Platform, as applicable;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To develop Candidates’ Outhire digital profiles;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To send Candidates job opportunities that may be of interest to the particular Candidate;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To administer human resource services for Customers, such as background checks for Candidates and putting together employment offers for Candidates;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To match Customers with Candidates, set up and conduct interviews, and provide feedback;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To deliver information about our products and Services, offers, promotions, reports and other information that may be of interest to you, where you have subscribed to receive them;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>For internal operations, including troubleshooting, data analysis, testing, and security purposes;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>For compiling market trends, statistics and assessments; and
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>To meet our legal obligations.
                </div></div><br/>
                
                <div className='terms-body terms-bold'>Who We Share Your Information With</div>
                <div className='terms-body'>
                We may share your Personal Information with third parties, such as our selected business partners, suppliers and sub-contractors, or the performance of any of our contractual obligations. These third parties may include our web hosting providers and our IT service providers. 
                </div><br/>
                <div className='terms-body'>
                We have put in place contractual and other organizational safeguards with our sub-processors to provide a reasonable level of security and protection for your Personal Information. Our sub-contractors may have limited access to Personal Information solely for the purpose of helping us to improve the Services and they will be subject to contractual restrictions prohibiting them from using such Personal Information for any other purpose.
                </div><br/>
                <div className='terms-body'>
                In addition, we may disclose your Personal Information to third parties without your consent:
                </div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>In connection with a corporate re-organization, a merger or amalgamation with another entity, a sale of all or a substantial portion of our assets or shares, including any due diligence exercise carried out in relation to the same, provided that the Personal Information disclosed continues to be used for the purposes permitted by this Privacy Policy by the entity acquiring the Personal Information;
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>If we have reason to believe that disclosing this Personal Information is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other Services users, or anyone else (including the rights or property of anyone else) that could be harmed by such activities; or
                </div></div>
                <div className='terms-body ml-8 row'>
                <img alt='' src={dot} className="bullet-dot flex-start"/><div>If we believe in good faith that such disclosure is required by and in accordance with the law.
                </div></div><br/>
                <div className='terms-body'>
                Our servers and those of our sub-processors are located in Canada and/or the United States and accordingly your Personal Information may be available to the Canadian or United States governments or their agencies under a lawful order, irrespective of the safeguards we have put in place for the protection of your Personal Information.
                </div><br/>

                <div className='terms-body terms-bold'>Data Retention and Deletion</div>
                <div className='terms-body'>
                We will keep your Personal Information for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain Personal Information as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally identifiable data, Outhire account recovery, or if required by law. All retained Personal Information will remain subject to the terms of this Privacy Policy. You have the right to ask us to remove your Personal Information from our databases, and we will do so to the best of our ability but it may not be possible to completely delete all of your Personal Information due to technological and legal constraints (for example, when your Personal Information involves another individual).
                </div><br/>
                <div className='terms-body terms-bold'>Access and Accuracy</div>
                <div className='terms-body'>
                You have the right to access the Personal Information we hold about you in order to verify the Personal Information we have collected in respect to you and to have a general account of our uses of that Personal Information. Upon receipt of your written request, we will provide you with a copy of your Personal Information although in certain limited circumstances, we may not be able to make all relevant Personal Information available to you, such as where that Personal Information also pertains to another individual. In such circumstances we will provide reasons for the denial to you upon request. We will try to deal with all requests for access and modifications in a timely manner.
                </div><br/>
                <div className='terms-body'>
                We will make every reasonable effort to keep your Personal Information accurate and up-to-date, and we will provide you with mechanisms to update, correct, delete or add to your Personal Information as appropriate. As appropriate, this amended Personal Information will be transmitted to those parties to which we are permitted to disclose your Personal Information. Having accurate Personal Information about you enables us to give you the best possible service.
                </div><br/>
                <div className='terms-body terms-bold'>Security</div>
                <div className='terms-body'>
                We use commercially reasonable efforts to store and maintain your Personal Information in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Personal Information that you provide to us. We have implemented procedures designed to limit the dissemination of your Personal Information to only such designated staff, third parties and sub-contractors as are reasonably necessary to carry out the stated purposes we have communicated to you. You are also responsible for helping to protect the security of your Personal Information. For instance, never give out your Services account information or password to third parties.
                </div><br/>
                <div className='terms-body terms-bold'>Contact Us</div>
                <div className='terms-body'>
                If you want to enforce any of your rights under this Privacy Policy or if you have any questions regarding this Privacy Policy or your Personal Information, please contact our Data Protection Officer per the information below.
                </div>
                <div className='terms-body'>
                Name: Tony Kwok
                </div>
                <div className='terms-body'>
                Email address: <span className='text-underline'><a href="mailto:tony@outhire.ca" className='here-link' target="_blank">tony@outhire.ca</a></span>
                </div>
                <div className='terms-body'>
                Subject line: Re Privacy Policy
                </div>
                <br/>              
                {/* <img className="terms-img" src={terms} alt=''/> */}
        </div>
            </div>
            </div>
          </div>
          <Footer/>

      </div>
      </div>
    );
  }
}

export default Privacy;