import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import home from './../../assets/outhire/home-img.svg'
import home1 from './../../assets/outhire/home1.svg'
import home2 from './../../assets/outhire/home2.svg'
import home3 from './../../assets/outhire/home3.svg'
import home4 from './../../assets/outhire/home4.svg'
import home5 from './../../assets/outhire/home5.svg'
import home6 from './../../assets/outhire/home6.svg'
import icon_group1 from './../../assets/outhire/icon-group1.svg'
import icon_group2 from './../../assets/outhire/icon-group2.svg'
import go from './../../assets/outhire/go.svg'
// import logo from './../../assets/outhire/outhire.svg';
import facebook from './../../assets/outhire/icon-facebook.svg';
import twitter from './../../assets/outhire/icon-twitter.svg';
import ins from './../../assets/outhire/icon-ins.svg';
import alarm from './../../assets/outhire/alarm.svg';
import person1 from './../../assets/outhire/person1.png';
import person2 from './../../assets/outhire/person2.png';
import person3 from './../../assets/outhire/person3.png';
import person4 from './../../assets/outhire/person4.png';
import person5 from './../../assets/outhire/person5.png';
import person6 from './../../assets/outhire/person6.png';
import person7 from './../../assets/outhire/person7.png';
import bp1 from './../../assets/outhire/bp1.png';
import bp2 from './../../assets/outhire/bp2.png';
import bp3 from './../../assets/outhire/bp3.png';
import bp4 from './../../assets/outhire/bp4.png';
import bp5 from './../../assets/outhire/bp5.png';
import bp6 from './../../assets/outhire/bp6.png';
import bp7 from './../../assets/outhire/bp7.png';
import email from './../../assets/outhire/email.svg';
import ins_sm from './../../assets/outhire/ins-sm.svg';
import AboutModal from '../../components/Landing/AboutModal';
import Modal from '../../components/Landing/Modal';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import authentic from './../../assets/outhire/authentic.svg';
import accountable from './../../assets/outhire/user 1.svg';
import transparency from './../../assets/outhire/transparency 1.svg';
import check from './../../assets/outhire/gradient-check.svg';
import add from './../../assets/outhire/gradient-add.svg';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

class About extends Component {
    state = {
      isPersonModal: false,
      selectedPerson: null,
      isJoinModal: false
    };

    hidePersonModal= () => {
        this.setState({
            isPersonModal: false,
            
        })
    }

    openPersonModal(person) {
        this.setState({
            selectedPerson: person,
            isPersonModal: true
        })
    }

    openJoinModal = () => {
        this.setState({
            isJoinModal: true
        })
        document.body.style.overflow = 'hidden';
    }

    hideJoinModal = () => {
        this.setState({
            isJoinModal: false
        })
        document.body.style.overflow = 'unset';
    }
  
    render() {
      return (
        <div className="App about-m">
          <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap">
            <img className="white-poligon" src={white} alt=''/>
                <div className="first-wrap coloum about-c mo-first-wrap">
                    {/* <div className="diagonal-box over-diagonal"></div> */}
                    {/* <img alt='' className="home-img" src={home}/> */}
                   
                    <div className="about-first-wrap">
                    <div className="first-text-wrap w-full ">
                        <div className="home-title w-full web-nav">Imagine a better way to hire</div>
                        <div className="mo-wrap">
                        <div className="home-title w-full">Imagine a better</div>
                        <div className="home-title w-full">way to hire</div>
                        </div>
                        <div className="animation-txt-wrap">
                        <div className="about-body-txt mb-75 mo-center">We  built a solution to a constant problem</div>
                        </div>
                      
                    </div>
                    </div>
                    <div className="home-card-wrap mb-75 overlap-card mo-about-card">
                            <div className="icon-text-wrap ml-alarm-card">
                                <img alt='' className="home-icon alarm-icon" src={alarm} />
<div>
<div className="about-title w-673">Accounting & finance professionals </div>
                                <div className="about-title w-673 txt-tilt">don’t have a lot of time</div>
</div>
                                </div>
                                <div className="ho-center text-left mo-ho-center">
                                
                                <div className="about-content-txt over-lap">
                            With a finance background ourselves, we knew there must be a way to expedite the hiring process so it wasn’t such a destroyer of hours; a way to leverage technology and our network of industry connections to match quality candidates with quality opportunities. So we created Outhire. With a unique Visual Resume tool and robust filtering system, Outhire makes it easier for candidates and employers to find the perfect fit.
                            </div>
                                <div className="home-card-btn-wrap mt-16">
                            <button className='btn-save btn-home' onClick={() => history.push('/candidates')}>Learn More</button>
                            </div>
                                </div>
                                

                          
                </div>
                </div>
                <div className="full-w-card ptb-48">
                    <div className="value-title">Our Values</div>
                    <div className="row value-icons-wrap web-nav">
                        <div className="value-icon-wrap">
                           <div className="value-icon">
                           <img alt=''src={authentic}/>
                           </div>
                            <div className="value-icon-txt">Authentic</div>
                        </div>
                        <div className="value-icon-wrap">
                        <div className="value-icon">
                        <img alt='' src={accountable}/>
                        </div>
                
                            <div className="value-icon-txt">Accountable</div>
                        </div>
                        <div className="value-icon-wrap">
                        <div className="value-icon">
                        <img alt='' src={transparency}/>
                        </div>
                            <div className="value-icon-txt">Transparent</div>
                        </div>
                    </div>
                    <div className="value-icons-wrap mo-wrap">
                        <div className="value-icon-wrap mb-mo-vicons">
                           <div className="value-icon">
                           <img alt=''src={authentic}/>
                           </div>
                            <div className="value-icon-txt">Authentic</div>
                        </div>
                        <div className="row1">
                        <div className="value-icon-wrap">
                        <div className="value-icon">
                        <img alt='' src={accountable}/>
                        </div>
                
                            <div className="value-icon-txt">Accountable</div>
                        </div>
                        <div className="value-icon-wrap ml-36">
                        <div className="value-icon">
                        <img alt='' src={transparency}/>
                        </div>
                            <div className="value-icon-txt">Transparent</div>
                        </div>
                        </div>
                    </div>
                    <div className="value-body">
                    We’re on a mission to creating the best hiring experience possible and it starts with ensuring candidates get a more transparent and bias free application process. 
                    </div>
                </div>

                <div className="home-card-wrap mb-75 pd-about mo-person-card-wrap">
                    <div className="row person-row">
                        <div className="percon-wrap mb-94">
                            <div className="person-inner relative">
                            <img alt="" src={bp1} className="person-img" onClick={()=>this.openPersonModal('person1')}/>
                            <div class="middle">
    <button className="btn-save mr-0" onClick={()=>this.openPersonModal('person1')}>Learn more</button>
  </div>
                            <div className="person-name">Tony Kwok</div>
                            <div className="person-name">Co-Founder</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/tonykwokcpa/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:tony@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap mb-94">
                        <div className="person-inner relative">
                            <img alt="" src={bp2} className="person-img" onClick={()=>this.openPersonModal('person2')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person2')}>Learn more</button>
  </div>
                            <div className="person-name">Gary Fogelman</div>
                            <div className="person-name">Co-Founder</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/garyfogelmanca/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:gary@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap mb-94">
                        <div className="person-inner relative">
                            <img alt="" src={bp3} className="person-img" onClick={()=>this.openPersonModal('person3')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person3')}>Learn more</button>
  </div>
                            <div className="person-name">Teddie Chau</div>
                            <div className="person-name">Associate Partner</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/teddiechau/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:teddie@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap mb-94">
                        <div className="person-inner relative">
                            <img alt="" src={bp4} className="person-img" onClick={()=>this.openPersonModal('person4')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person4')}>Learn more</button>
  </div>
                            <div className="person-name">Janet Asher</div>
                            <div className="person-name">Advisor, Talent & Culture</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/janet-asher-1373298/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:janet@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap">
                        <div className="person-inner relative">
                            <img alt="" src={bp5} className="person-img" onClick={()=>this.openPersonModal('person5')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person5')}>Learn more</button>
  </div>
                            <div className="person-name">SuJung Rim</div>
                            <div className="person-name">Recruitment Associate</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/sujungr/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:sujung@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap">
                        <div className="person-inner relative">
                            <img alt="" src={bp6} className="person-img" onClick={()=>this.openPersonModal('person6')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person6')}>Learn more</button>
  </div>
                            <div className="person-name">Lauren Kennedy</div>
                            <div className="person-name">Recruitment Associate</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/lauren-kennedy-ba315a63/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:lauren@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                        <div className="percon-wrap">
                        <div className="person-inner relative">
                            <img alt="" src={bp7} className="person-img" onClick={()=>this.openPersonModal('person7')}/>
                            <div class="middle">
    <button className="btn-save mr-0"  onClick={()=>this.openPersonModal('person7')}>Learn more</button>
  </div>
                            <div className="person-name">Jessica Waito</div>
                            <div className="person-name">Recruitment Associate</div>
                            <div className="person-icon-wrap">
                            <a href="https://www.linkedin.com/in/jessica-waito-15045721a/" target="_blank"><img alt='' src={ins_sm} className="person-icon"/></a>
                            <a href="mailto:jessica@outhire.ca" target="_blank"><img alt='' src={email} className="person-icon"/></a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-w-card ptb-48">
                    <div className="home-title text-center w-no mb-48">Our Story</div>
                    <iframe className="web-nav" src="https://player.vimeo.com/video/336080384?h=67678a06c5&color=ffffff&byline=0&portrait=0&badge=0" width="1024" height="576" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <iframe className="mo-wrap mo-frame" src="https://player.vimeo.com/video/336080384?h=67678a06c5&color=ffffff&byline=0&portrait=0&badge=0" width="318" height="179" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="home-card-wrap mb-75">
                    <div className="home-title w-859">Join our team!
</div>
                   <div className="line-row">
                   <div className="icon-txt connect-link w-fit cursor" onClick={this.openJoinModal}>Find out more</div>
                   <img alt='' className="go-icon" src={go} onClick={this.openJoinModal}/>
                   </div>
                </div>
                <div className="home-card-wrap">
                    <div className="home-title w-859">Save yourself the hassle and leave the search to us
</div>
                   <div className="line-row">
                   <div className="icon-txt connect-link w-fit cursor" onClick={() => history.push('/contact')}>Let's connect</div>
                   <img alt='' className="go-icon" src={go} onClick={() => history.push('/contact')}/>
                   </div>
                </div>
            </div>
           <Footer/>
          </div>
          <AboutModal show={this.state.isPersonModal} handleClose={this.hidePersonModal}>

          {this.state.selectedPerson == 'person1' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Tony Kwok</div>
                    <div className="person-top-txt">Co-Founder</div>
                </div>
            <img src={bp1} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">Aiming for hire</div>
                <div className="person-modal-txt">

It’s not essential for your recruiter to have summited Kilimanjaro, but it helps. Tony Kwok is an ambitious person. He hikes. He sprints. He’s traveled alone abroad. He loves a difficult board game. The man lives to be challenged. 

</div>
                <div className="person-modal-txt">One thing Tony doesn’t find challenging is building relationships with people. He’s always had an instinctive ability to reach out, get to know new faces, and help them achieve their goals. Mostly, he loves helping companies find their dream hire. The ability to make meaningful connections between people and organizations should be more than a subset of an HR team. Recruiters are specialists, and Tony wanted to create a business that honours this philosophy. So he did.</div>
            </div>
          </div>
          )}

            {this.state.selectedPerson == 'person2' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Gary Fogelman</div>
                    <div className="person-top-txt">Co-Founder</div>
                </div>
            <img src={bp2} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">On a path to success</div>
                <div className="person-modal-txt">

Gary Fogelman has expertise in many areas: accounting and finance, assessing talent in accounting and finance, finding the perfect fit for a professional role, building a business, and supporting a family.

</div>
                <div className="person-modal-txt">But he also loves cycling, on deserted roads and remote trails, away from the hectic city and alone with his thoughts. And that peace of mind is powerful because Gary isn’t your average recruiter. He wants to do more than assess your skill set. He wants to take sincere interest in your goals, and he wants to give you advice you can trust. When you’re looking for the right role, you want a guy like Gary pondering your next steps while he rides through the forest.</div>
            </div>
          </div>
          )}

{this.state.selectedPerson == 'person3' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Teddie Chau</div>
                    <div className="person-top-txt">Associate Partner</div>
                </div>
            <img src={bp3} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">Making the dream team</div>
                <div className="person-modal-txt">

In basketball and in life, Teddie Chau is a guy you want on your team. He loves a game that calls for strategic thinking, he’s got a relentless drive to solve problems, and he’s always finding ways to get the best out of everyone. Having earned a degree in psychology and worked across multiple industries around the world, Teddie’s got a penchant for building long-lasting relationships with people. He champions mental, physical, and professional growth, both for himself and those around him. Teddie knows that in the professional world, the type of people who are meant to lead a team can be hard to find, so he leverages his network to help companies find their next MVP.

</div>
                
            </div>
          </div>
          )}

{this.state.selectedPerson == 'person4' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Janet Asher</div>
                    <div className="person-top-txt">Advisor, Talent & Culture</div>
                </div>
            <img src={bp4} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">The value of good support</div>
                <div className="person-modal-txt">

Janet Asher loves to see people succeed. As a mother, friend, and trusted advisor to the recruitment associates at Outhire, Janet’s goal is to help others accomplish theirs. With a background in finance, she enables her team to provide their clients with insights into a niche market, access to desirable roles & candidates, and a positive interview process. Sometimes her support is as simple as showing kindness to others or sharing a laugh with colleagues, and sometimes her support saves an organization tens (or even hundreds) of thousands of dollars by avoiding bad hires. It’s good to have Janet in our corner.

</div>
        
            </div>
          </div>
          )}

{this.state.selectedPerson == 'person5' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">SuJung Rim</div>
                    <div className="person-top-txt">Recruitment Associate</div>
                </div>
            <img src={bp5} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">The search for better</div>
                <div className="person-modal-txt">

Whether she’s hitting the gym or a pilates class, SuJung Rim understands the value of self-betterment. When it comes to her role as Recruitment Associate at Outhire, she loves helping candidates better themselves by finding them the role they’ve been working towards. And what motivates her the most is her fellow colleagues. Everyone on her team comes from a different background, but they’re all focused on the same outcome – to help people secure the perfect role, and get them one step closer to achieving their life goals.

</div>
                
            </div>
          </div>
          )}

{this.state.selectedPerson == 'person6' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Lauren Kennedy</div>
                    <div className="person-top-txt">Recruitment Associate</div>
                </div>
            <img src={bp6} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">The joy of the journey</div>
                <div className="person-modal-txt">

Whether she’s camping or canoeing or trekking through Ontario’s backcountry, Lauren Kennedy loves to journey into the great outdoors. But that’s not the only journey she’s passionate about. As Recruitment Associate at Outhire, Lauren helps talented people move further along on their career path, by understanding their unique skills & interests and finding the perfect professional role to match. If there’s one thing that Lauren has learned from hiking trails and recruiting talent, it’s that tenacity can take you to some pretty amazing places.

</div>
            
            </div>
          </div>
          )}

{this.state.selectedPerson == 'person7' && (
            <div className="person-modal-content">
                <div className="person-modal-label">
                    <div className="person-top-txt">Jessica Waito</div>
                    <div className="person-top-txt">Recruitment Associate</div>
                </div>
            <img src={bp7} alt='' className="modal-person"/>
            <div className="person-modal-txt-wrap">
                <div className="person-modal-title">The key to a good coach</div>
                <div className="person-modal-txt">

Jessica Waito attributes much of her success in the corporate world to her background in gymnastics. Being a gymnast gave her confidence, grit, and the ability to push through challenges. As a coach, she learned to see potential in people who may not see it themselves. Now that she’s a Recruitment Associate, Jessica is trained to see potential in candidates that would have been otherwise overlooked by the algorithmic nature of today’s labour market, and with the right personalized guidance, she helps people set their sights on the perfect role and really stick the landing.

</div>
                
            </div>
          </div>
          )}
                   
        </AboutModal>
        <Modal show={this.state.isJoinModal} handleClose={this.hideJoinModal}>
          <div className="o-modal-content text-left">
            <div className='o-modal-header'>
              <div className='join-head'>Join our team!</div><br/>
            </div>
            <div className='join-txt w-918'>Is recruitment your dream role? Just kidding, all of us have fallen into this from other disciplines. The easiest transition would be directly from an accounting background but that isn’t the only way. If you’re interested in potentially making a career switch here are a few things to consider.
             </div><br/>
             <div className="row ml-join mb-16">
                <img alt='' src={check} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">People oriented?</div>
                    <div className="join-txt w-770">Do you prefer working with people more than with numbers or anything else. Can you see yourself making your day revolve around people and learning their stories so you can present them better than they present themselves.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={check} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Deadline driven?</div>
                    <div className="join-txt w-770">Do you excel under pressure, we are committed to the deadlines we set with the companies we work with. As such, we do whatever it takes to make these deliverables. Will you thrive under this pressure?</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={check} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Comfortable with uncertainty?</div>
                    <div className="join-txt w-770">Unlike a software service or a manufactured widget, our services deal with people as a resource and as a client and no two people are identical. Will you be adaptable when things don’t go as expected?</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={check} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Curious?</div>
                    <div className="join-txt w-770">We learn everyday in this role. At most other roles, it may be a goal but at Outhire, it’s a necessity to continuously learn. Whether it's about a new technical standard, a type of position or something as simple as what it's like to transition to Canada from another country. The only constant is change.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={check} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Tough? </div>
                    <div className="join-txt w-770"> It's not about how many times you get knocked down, it's how you get back up. Resilience and perseverance will be foundations of how you keep the success you’ve built in our field.</div>
                </div>
             </div>
             <div className='join-head mt-24'>What we offer:</div><br/>
             <div className="row ml-join mb-16">
                <img alt='' src={add} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Technology</div>
                    <div className="join-txt w-770">We equip our staff with the latest hardware and software to ensure they are able to do their work at optimal efficiency. Macbooks, high-fidelity microphones, 4K webcams, 2K ergonomic monitors, a plethora of head hunting tools - if it will help you achieve higher levels of success, we’ll get it.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={add} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Flexible hybrid work model.</div>
                    <div className="join-txt w-770">We have a dedicated office with identical equipment to what you will be given to work with at home. Come in as much or as little as you like. We generally have everyone coordinated to come in on the same days but never as a requirement.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={add} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Fun team.</div>
                    <div className="join-txt w-770">Hate to brag but we have a team you’ll actually want to work with. We built an environment free of egos, heavy on the dose of fun and focused on the mission. Check out the bios of our team members and some of the activities we’ve had the in past.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={add} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Professional development and mentorship.</div>
                    <div className="join-txt w-770">Our industry is notorious for throwing new hires into the “deep end”, “fire”, or through “the gauntlet”. We don’t take that approach. We built a process using technology and dedicated mentorship and coaching to ensure that if you are joining our team, you will get the best chance to step forward.</div>
                </div>
             </div>
             <div className="row ml-join mb-16">
                <img alt='' src={add} className="join-icon"/>
                <div className="join-txt-wrap">
                    <div className="join-title mb-8">Get paid!</div>
                    <div className="join-txt w-770">We offer one of the most lucrative performance bonuses and are one of the few agencies to include a revenue share bonus on top of your base salary. Our work is tough, so we make the rewards; both qualitative and numerical, worth it!</div>
                </div>
             </div>
             <div className="right-txt">Connect with us <span><a href='/contact' className="here-link">here</a></span></div>
          </div>
        </Modal>
        </div>
      );
    }
  }
  
  export default About;

