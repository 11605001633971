import React, { useEffect } from 'react';
import './styles.scss';
import logo from './../../assets/outhire/outhire.svg';
import history from './../../utilities/history';

const NavBar = () => {

    const [scrolled, setScrolled] = React.useState(false);
    const pathname = window.location.pathname;


    // const handleScroll = () => {
    //     const offset = window.scrollY;
    //     if(offset > 0){
    //         setScrolled(true);
    //     }
    //     else{
    //         setScrolled(false);
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll)
    // })

    // let navbarClasses = ['navbar'];
    // if(scrolled){
    //     navbarClasses.push('scrolled');
    // }
    // {navbarClasses.join(" ")}
    return (
        <header className="navbar web-nav">
            <div className="nav-wrap">
            <div className="home-logo-wrap">
              <a href='https://outhire.ca/home/'><img className="logo" src={logo} /></a>
            </div>
            <nav className="navigation">
                <ul className="nav-list-wrap">
                <a href='https://outhire.ca/home/' className='nav-list-link'><li className='nav-list-txt' onClick="window.location.href='https://outhire.ca/home/';" >Home</li></a>
                {/* <a href='https://outhire.ca/employers/' className='nav-list-link'><li className={pathname == '/employers' ? 'nav-list-txt active-nav' : 'nav-list-txt'}>Companies</li></a>
                <a href='https://outhire.ca/candidates/' className='nav-list-link'><li className={pathname == '/candidates' ? 'nav-list-txt active-nav' : 'nav-list-txt'}>Candidates</li></a>
                <a href='https://outhire.ca/about/' className='nav-list-link'><li className={pathname == '/about' ? 'nav-list-txt active-nav' : 'nav-list-txt'}>About</li></a> */}
                <li className={pathname == '/explore-jobs' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/explore-jobs')}>Explore Jobs</li>
                <li className={pathname == '/' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/')}>
                    {/* <div className='beta-badge'>BETA</div> */}
                    Portal</li>
                <a href='https://outhire.ca/contact/' className='nav-list-link'><li><button className='demo-btn cursor' onClick="window.location.href='https://outhire.ca/contact/';" >Book a Demo</button></li></a>
                </ul>
            </nav>
            {/* <div className="nav-btn-wrap">
                <button className="font-20 nav-sign-btn btn" 
                onClick={() => history.push('/signup')}>Sign Up</button>
                <button className="btn btn-solid btn-nav font-20"
                 onClick={() => history.push('/signin')}>Book Now</button>
            </div> */}
            </div>
        </header>
    )
};

export default NavBar;