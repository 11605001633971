import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import bell from './../../assets/outhire/bell.png';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import history from './../../utilities/history';
import dot from "./../../assets/images/dot.svg";
import NavBar from '../../components/NavBar/TopNavBar';
import axios from 'axios';
import media from './../../assets/outhire/media1.png';
import spinner from './../../assets/images/icons/Spinner.svg';
import setting from './../../assets/outhire/carbon_settings.svg';
import slogo from './../../assets/outhire/slogo.png';
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import { connect } from 'react-redux';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import profileAvatar from './../../assets/outhire/profile-avatar.png';

class AllJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: []
    }
  }

  componentDidMount() {
    const apiJobs = 'api/catstone/jobs'
    const payload = {
      per_page: 25,
      page: 1,
      data: {
        "field": "status_id",
        "filter": "exactly",
        "value": 6065969
      }
    }
    let activeJobs = [];
    axios.post(apiJobs, payload)
      .then((result) => {
        console.log(result);
        if (result.data != null && result.data != undefined) {
          var total = result.data.total;
          var page = total / 25;
          var reminder = total % 25;
          if (reminder > 0) {
            page = Math.round(page + 0.5)
          }
          console.log(page);
          for(let i = 0; i < result.data._embedded.jobs.length; i++){
            if(result.data._embedded.jobs[i]._embedded.tags!=null && result.data._embedded.jobs[i]._embedded.tags!=undefined){
              result.data._embedded.jobs[i].industry = result.data._embedded.jobs[i]._embedded.tags[0].title;
              if(result.data._embedded.jobs[i]._embedded.tags.length > 1){
                switch (result.data._embedded.jobs[i]._embedded.tags[1].title){
                  case 'Agriculture 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80';
                  break;
                  case 'Blockchain 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                  break;
                  case 'Financial Services 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1485920694980-9c5db37b0d44?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                  break;
                  case 'Infrastructure 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1585110587043-36d457f3e803?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                  break;
                  case 'Private Equity 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560264418-c4445382edbc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                  break;
                  case 'Retail 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560264280-88b68371db39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                  break;
                  case 'Technology 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80';
                  break;
                  case 'Technology 2': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                  break;
                  case 'Real Estate 1': 
                  result.data._embedded.jobs[i].cover = 'https://plus.unsplash.com/premium_photo-1663133608066-c0bc906f9577?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                  break;
                  case 'Real Estate 2': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1097&q=80';
                  break;
                  case 'Real Estate 3': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1527199768775-bdabf8b32f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                  break;
                  case 'Other 1': 
                  result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                  break;
                }
              }
              this.setState({
                jobs: result.data._embedded.jobs
              })
            }else{
              result.data._embedded.jobs[i].industry = 'Industry not set';
              this.setState({
                jobs: result.data._embedded.jobs
              })
            }
          }
         
          // for (let i = 0; i < result.data._embedded.jobs.length; i++) {
          //   var apiThumbnail = "api/catstone/companies/getThumbnail";
          //   var thumbnailPayload = {
          //     id: result.data._embedded.jobs[i].company_id
          //   }

          //   axios.post(apiThumbnail, thumbnailPayload)
          //     .then((res) => {
          //       result.data._embedded.jobs[i].company_thumbnail = res.data.url;
          //       this.setState({
          //         jobs: result.data._embedded.jobs
          //       })
          //     })

          //     const apiJob = 'api/catstone/jobs/getJobById'

          //     const jobPayload = {
          //       id: result.data._embedded.jobs[i].id
          //     }

          //     axios.post(apiJob, jobPayload)
          //       .then((resJob) => {
          //         console.log(resJob)
          //         for (let j = 0; j < resJob.data._embedded.custom_fields.length; j++) {
          //           switch (resJob.data._embedded.custom_fields[j].id) {
          //             case 342953:
          //               result.data._embedded.jobs[i].description = resJob.data._embedded.custom_fields[j].value;
          //               break;
          //           }
          //         }
          //         this.setState({
          //           jobs: this.state.jobs.concat(result.data._embedded.jobs)
          //         })
          //       })
          // }


          // for(let i = 0; i < result.data._embedded.jobs.length; i++){
          //   if(result.data._embedded.jobs[i].status_id == 6065969){
          //     activeJobs.push(result.data._embedded.jobs[i]);
          //     this.setState({
          //       jobs: activeJobs
          //     })
          //   }
          // }

          if (page > 1) {
            for (let i = 2; i <= page; i++) {
              var newPayload = {
                per_page: 25,
                page: i,
                data: {
                  "field": "status_id",
                  "filter": "exactly",
                  "value": 6065969
                }
              }

              console.log(newPayload);
              axios.post(apiJobs, newPayload)
                .then((result) => {
                  console.log(result);
                  if (result.data != null && result.data != undefined) {
                    this.setState({
                      jobs: result.data._embedded.jobs
                    })
                    // for (let i = 0; i < result.data._embedded.jobs.length; i++) {
                    //   var apiThumbnail = "api/catstone/companies/getThumbnail";
                    //   var thumbnailPayload = {
                    //     id: result.data._embedded.jobs[i].company_id
                    //   }

                    //   axios.post(apiThumbnail, thumbnailPayload)
                    //     .then((res) => {
                    //       result.data._embedded.jobs[i].company_thumbnail = res.data.url;
                    //       this.setState({
                    //         jobs: this.state.jobs.concat(result.data._embedded.jobs)
                    //       })
                    //     })

                    //   const apiJob = 'api/catstone/jobs/getJobById'

                    //   const jobPayload = {
                    //     id: result.data._embedded.jobs[i].id
                    //   }

                    //   axios.post(apiJob, jobPayload)
                    //     .then((resJob) => {
                    //       console.log(resJob)
                    //       for (let j = 0; j < resJob.data._embedded.custom_fields.length; j++) {
                    //         switch (resJob.data._embedded.custom_fields[j].id) {
                    //           case 342953:
                    //             result.data._embedded.jobs[j].description = resJob.data._embedded.custom_fields[j].value;
                    //             break;
                    //         }
                    //       }
                    //       this.setState({
                    //         jobs: this.state.jobs.concat(result.data._embedded.jobs)
                    //       })
                    //     })
                    // }

                  }

                })
            }
          }



        }
      })
  }

  openJob = (id) => {
    history.push('/job-details?id=' + id)
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Welcome back, {this.props.user.first_name}</div>
              </div>
              <div className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </div>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={this.props.user.thumbnail} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            <div className='bottom-sec mt-0 wrap jb-cards'>
              {this.state.jobs.length > 0 ? Object.keys(this.state.jobs).map((key) => (
                <div className='job-card' key={key}>
                  <div className='jb-card-head row'>
                    {(this.state.jobs[key].company_thumbnail == null || this.state.jobs[key].company_thumbnail == undefined) ? (
                      <div className='jb-avatar1'>
                         <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    ) : (
                      <div className='jb-avatar1'>
                        {/* <img src={this.state.jobs[key].company_thumbnail} alt='' className='jb-avatar-img'/> */}
                        <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    )}
                    <div className='jb-title-wrap'>
                      <div className='jb-title elipse'>{this.state.jobs[key].title.split('-')[0]}</div>
                      {/* <div className='jb-sub-title'>{this.state.jobs[key]._embedded.company.name}</div> */}
                      <div className='jb-jb-sub-title'>{this.state.jobs[key].industry}</div>
                    </div>
                  </div>
                  <div className='jb-card-image' >
                    {/* style={{backgroundImage: `url(`+this.state.jobs[key].company_thumbnail+`)`}} */}
                    {this.state.jobs[key].cover != null && this.state.jobs[key].cover != undefined ? (
                      <img alt='' className='card-image' src={this.state.jobs[key].cover} />
                    ) : (
                      <img alt='' className='card-image' src="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" />
                    )}
                  </div>
                  <div className='jb-card-content'>
                    <div className='jb-title elipis1-txt'>{this.state.jobs[key].title}</div>
                    <div className='jb-sub-title'>{this.state.jobs[key].location.city}, {this.state.jobs[key].location.state}</div>
                    {(this.state.jobs[key].description == '' || this.state.jobs[key].description == null) ? (
                      <div className='jb-description'><span className='elipis-txt'>No description preview</span></div>
                    ): (
                      <div className='jb-description'><span className='elipis-txt'>{this.state.jobs[key].description}</span></div>
                    )}
                    <div className=' jb-card-btn'>
                      <button className='btn-save mr-0' onClick={() => this.openJob(this.state.jobs[key].id)}>See More</button>
                    </div>
                  </div>
                </div>
              )) : (
                <div className='load-wrap'>
                  <img src={spinner} />
                  <p>Loading jobs, please wait...</p>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    );
  }
}


//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(AllJobs);