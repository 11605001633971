import React, { Component } from 'react';
import './styles.css';
import history from '../../utilities/history';
import error from './../../assets/images/error.svg';
import axios from 'axios';
import { connect } from 'react-redux';
import { ProfileActionCreators } from './../../actions/candidate/profileAction';
import LinkedInLogin from "../SignInBtns/LinkedInLogin";
import go from './../../assets/outhire/go.svg';
import Modal from '../Landing/Modal';

class SignInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isPwEmpty: true,
            isPwShow: false,
            isSigned: false,
            isExsit: true,
            isMatched: true,
            isVerified: true,
            isEmailUser: null,
            email: '',
            password: '',
            isResending: false,
            isResent: false,
            firstName: ''
        };
    }

    componentDidMount() {
        this.setState({
            isChecked: false,
            isPwEmpty: true,
            isPwShow: false,
            isSigned: false,
            isExsit: true,
            isMatched: true,
            isVerified: true,
            email: '',
            password: ''
        })
    }

    //Checkbox onChange handler
    toggleCheck = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    //Password input onChange handler
    handlePwChange = (e) => {
        if (e.target.value !== '') {
            this.setState({
                isMatched: true,
                isPwEmpty: false,
                password: e.target.value
            });
        } else {
            this.setState({
                isMatched: true,
                isPwEmpty: true
            });
        }
    }

    //Email input onChange handler
    handleEmailChange = (e) => {
        this.setState({
            isExsit: true,
            email: e.target.value
        });
    }

    //Toggle to show or hide password
    toggleShow = () => {
        this.setState({
            isPwShow: !this.state.isPwShow
        });
    }

    googleSignIn = () => {
        const googleInApi = '/auth/google';
        history.push(googleInApi)


    }


    handleUserType = (value) => {
        this.setState({
            isEmailUser: value
        })
    }

    signin = (event) => {
        event.preventDefault();
        const apiUrl = 'api/v1/signin';

        const payload = {
            email: this.state.email,
            password: this.state.password
        }

        axios.post(apiUrl, payload)
            .then((result) => {
                if (result.data.status === 'error') {
                    if (result.data.message == '01') {
                        this.setState({
                            isExsit: false
                        })
                    } else if (result.data.message == '02') {
                        this.setState({
                            isMatched: false
                        })
                    } else if (result.data.message == '03') {
                        this.setState({
                            isVerified: false,
                            firstName: result.data.data
                        })
                    }
                    this.setState({ message: result.data.message });
                } else {
                    //history.push('/candidate');
                    if(result.data.data.user.user_type == 'candidate'){
                        
                        
                        if(result.data.data.user.catsone_status == 2){
                            const apiCandidate = 'api/catstone/candidates/getCandidateById'

                            const payload = {
                              id: result.data.data.user.catsone_id
                            }
                        
                            axios.post(apiCandidate, payload)
                              .then((res) => {
                                var user = {
                                    email: result.data.data.user.email,
                                    user_type: result.data.data.user.user_type,
                                    status: result.data.data.user.status,
                                    linkedin_id: result.data.data.user.linkedin_id,
                                    catsone_status: result.data.data.user.catsone_status,
                                    catsone_id: result.data.data.user.catsone_id,
                                    modified_by: result.data.data.user.modified_by,
                                    id: result.data.data.user._id,
                                    thumbnail: res.data._embedded.thumbnail[0].url,
                                    first_name: result.data.data.user.first_name,
                                    last_name: result.data.data.user.last_name,
                                    
                                }
                                this.props.dispatch(ProfileActionCreators.updateUser(user));
                                history.push('/candidate?id='+result.data.data.user._id + '&candidate_no='+result.data.data.user.catsone_id);
                            })
                          
                        }else{
                            history.push('/registrationsuccess');
                        }
                    }else if(result.data.data.user.user_type == 'super admin' || result.data.data.user.user_type == 'admin'){
                       
                        var admin = {
                            email: result.data.data.user.email,
                            user_type: result.data.data.user.user_type,
                            status: result.data.data.user.status,
                            modified_by: result.data.data.user.modified_by,
                            id: result.data.data.user._id,
                            first_name: result.data.data.user.first_name,
                            last_name: result.data.data.user.last_name,
                            thumbnail: ''
                        }
                        this.props.dispatch(ProfileActionCreators.updateAdmin(admin));
                        history.push('/admin/dashboard?id='+result.data.data.user._id);
                    }

                }
            })
    }

    resendVerification = () => {

        this.setState({
            isResending: true
        })
        var user = {
            email: this.state.email,
            first_name: this.state.firstName
        }

        const apiUrl = 'api/v1/send-email';

        axios.post(apiUrl, user).then((result) => {
            if(result.data.status == 'success'){
                this.setState({
                    isResending: false,
                    isResent: true,
                    isChecked: false,
                    isPwEmpty: true,
                    isPwShow: false,
                    isSigned: false,
                    isExsit: true,
                    isMatched: true,
                    isVerified: true,
                    email: '',
                    password: ''
                })
            }else{
                this.setState({
                    isResending: false,
                    isResent: false,
                    isChecked: false,
                    isPwEmpty: true,
                    isPwShow: false,
                    isSigned: false,
                    isExsit: true,
                    isMatched: true,
                    isVerified: true,
                    email: '',
                    password: ''
                })
            }
        })

    }

    hideResent = () => {
        this.setState({
            isResent: false,
            isChecked: false,
                    isPwEmpty: true,
                    isPwShow: false,
                    isSigned: false,
                    isExsit: true,
                    isMatched: true,
                    isVerified: true,
                    email: '',
                    password: ''
        })
    }

    render() {
        return (
            <div className="signin-form">
                <form className="form-wrap" onSubmit={this.signin} >
                    <div className="title-txt font-medium size-2em text-center mb-24">Welcome!</div>
                    
                    <div className="form-group">
                        <input className="input-box size-1em" type="email" name="email" style={{ borderColor: this.state.isExsit ? '#E8ECF4' : '#f47160' }} 
                        onChange={this.handleEmailChange} 
                        placeholder="Enter your email"/>
                    </div>
                    <div className="form-group mt-18 mb-24">
                        <div className="pw-wrap" style={{ borderColor: this.state.isMatched ? '#E8ECF4' : '#f47160' }}>
                            <input className="input-box-pw size-1em" 
                            name="password" type={this.state.isPwShow ? 'text' : 'password'} onChange={this.handlePwChange} 
                            placeholder="Enter your password"
                            />
                            {!this.state.isPwEmpty && !this.state.isPwShow && (
                                <a className="show-txt" onClick={this.toggleShow}>Show</a>
                            )}
                            {!this.state.isPwEmpty && this.state.isPwShow && (
                                <a className="show-txt" onClick={this.toggleShow}>Hide</a>
                            )}
                        </div>
                        <div className='forgot-link-wrap'><a className="forgot-link size-small font-regular mt-16" href="/forget-password">Forgot password?</a></div>
                    </div>
                    {!this.state.isExsit && (
                        <div className="error-wrap">
                            <div className="error-icon-wrap">
                                <img src={error} className="error-icon" />
                            </div>
                            <div className="error-txt-wrap">
                                <div className="error-txt">Sorry, we couldn't find an account with that email. <span className="txt-underline" onClick={() => history.push('/signup')}>Create an account.</span></div>
                            </div>
                        </div>
                    )}
                    {!this.state.isMatched && (
                        <div className="error-wrap">
                            <div className="error-icon-wrap">
                                <img src={error} className="error-icon" />
                            </div>
                            <div className="error-txt-wrap">
                                <div className="error-txt">Sorry, that password doesn't match the email. </div>
                            </div>
                        </div>
                    )}
                    {!this.state.isVerified && (
                        <div className="error-wrap">
                            <div className="error-icon-wrap">
                                <img src={error} className="error-icon" />
                            </div>
                            <div className="error-txt-wrap">
                                <div className="error-txt">Sorry, you must verify your email to activate your account.<span><a className='cursor resend-link' onClick={this.resendVerification}> Resend link</a></span></div>
                            </div>
                        </div>
                    )}
                    {this.state.isEmailUser==true && (
                        <div className="error-wrap">
                            <div className="error-icon-wrap">
                                <img src={error} className="error-icon" />
                            </div>
                            <div className="error-txt-wrap">
                                <div className="error-txt">Account with this LinkedIn email already exists, please sign in using password.</div>
                            </div>
                        </div>
                    )}
                    <div className="mt-16">
                        <button className="btn btn-solid btn-regular font-18" type="submit" >Login</button>
                    </div>
                    <div className="divide-wrap height-20px">
                        <div className="divider-or"></div>
                        <div className="middle-txt-or size-1em font-small text-center"> <div className='margin-txt-or'>Or</div> </div>
                        <div className="divider-or"></div>
                    </div>
                    <LinkedInLogin onUserType={this.handleUserType}/> 
                    {/* <button className='btn btn-light btn-regular font-18'>Sign in with LinkedIn</button>  */}
                    <div className="bottom-wrap mt-24 row w-sign-351">
                        <div className="bottom-sign-in">Don't have an account?</div>
                        <div className="bottom-sign-in ml-8"> <a className="cursor-on" onClick={() => history.push('/signup')}> Sign up for free</a></div>
                        <img alt='' className="go-sign-icon cursor-on ml-8" src={go} onClick={() => history.push('/signup')}/>
                    </div>
                </form>

                <Modal show={this.state.isResent} handleClose={this.hideResent}>
          <div className="o-modal-sm-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title o-center-title'>Email Sent!</div>
            </div>
            <div className='n-modal-txt'>Verification link has been resent via email successfully, please look out for our confirmation email.
             </div>
          </div>
        </Modal>
            </div>
        );
    }
}

//map stored state to props access
const mapStateToProps = (state) => {
    return {
        user: state.candidate.user
    }
}

export default connect(mapStateToProps)(SignInForm);
