import { Types } from '../../constants/actionTypes';

export const ProfileActionCreators = {
    updateBasicInfo: (patient) => ({ 
        type: Types.UPDATE_BASIC_INFO, 
        payload: { patient }
    }),
    updateContactInfo: (contact) => ({
        type: Types.UPDATE_CONTACT_INFO,
        payload: { contact }
    }),
    updateHealthInfo: (health) => ({
        type: Types.UPDATE_HEALTH_INFO,
        payload: { health }
    }),
    updatePatientAvatar: (image) => ({ 
        type: Types.UPDATE_PATIENT_AVATAR, 
        payload: { image }
    }),
    signIn: (credential) => ({
        type: Types.SIGNIN,
        payload: {credential}
    }),
    updatePersonFilled: (is_person_filled) => ({
        type: Types.UPDATE_PERSON_FILLED,
        payload: { is_person_filled }
    }),
    updateContactFilled: (is_contact_filled) => ({
        type: Types.UPDATE_CONTACT_FILLED,
        payload: { is_contact_filled }
    }),
    updateHealthFilled: (is_health_filled) => ({
        type: Types.UPDATE_HEALTH_FILLED,
        payload: { is_health_filled }
    }),
    updateEmail: (email) => ({
        type: Types.UPDATE_EMAIL,
        payload: { email }
    }),
    updatePatient: (retrieved_patient) => ({
        type: Types.UPDATE_PATIENT,
        payload: { retrieved_patient }
    }),
    updateUser: (user) => ({
        type: Types.UPDATE_USER,
        payload: {user}
    }),
    updateDoctor: (doctor) => ({
        type: Types.UPDATE_DOCTOR,
        payload: {doctor}
    })
}