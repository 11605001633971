import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import tony from './../../assets/outhire/tony.jpg';
import profile from './../../assets/outhire/person.svg';
import yes from './../../assets/outhire/yes.svg';
import no from './../../assets/outhire/no.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from '../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import moment from "moment";
import AdminMenu from '../../components/NavBar/AdminMenu';
import { connect } from 'react-redux';
import slogo from './../../assets/outhire/slogo.png';


class ManageAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
     users: [],
     selected_user: {},
     candidate_id: null,
     isNotificationModal: false,
     is_nolink: null,
     is_linked: null,
     is_unlink: null,
     isAddModal: false,
     first_name: '',
     last_name: '',
     email: '',
     password: '',
     message: '',
     is_added: null,
     is_reset: null,
     is_removed: null,
     isResetModal: false,
     new_pw: '',
     confirm_pw: '',
     isMatched: false,
     isWarningMoadl: false,
    }
  }

  componentDidMount() {
    this.getUsers();

  }

  openAdminModal = () => {
    this.setState({
      isAddModal: true
    })
  }

  getUsers() {
    const apiUsers = '/api/v1/users'


    axios.get(apiUsers)
        .then((result) => {
            console.log(result)
            var users = [];
            for(let i = 0; i < result.data.length; i ++){
                if(result.data[i].user_type == 'admin' || result.data[i].user_type == 'super admin'){
                    users.push(result.data[i]);
                    this.setState({
                      users: users.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      })
                    })
                  }

            }
        })
  }

  selectUser(user){
    this.setState({
      selected_user: user
    })
  }

  hideNotificationModal = () => {
    this.setState({
      isNotificationModal: false,
      is_reset: null,
      is_added: null,
      is_removed: null
    })
  }

  onNewChange = (e) => {
    this.setState({
      new_pw: e.target.value
    })
  }

  onConfirmChange = (e) => {
    this.setState({
      confirm_pw: e.target.value
    }, () => {
      if(this.state.confirm_pw == this.state.new_pw){
        this.setState({
          isPwMatched: true
        })
      }else{
        this.setState({
          isPwMatched: false
        })
      }
    })
    
  }

  hideAdminModal = () => {
    this.setState({
      isAddModal: false
    })
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }
  
  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }
  

  hideResetModal = () => {
    this.setState({
      new_pw: '',
      confirm_pw: '',
      isResetModal: false
    })
  }

  hideWarningModal = () => {
    this.setState({
      isWarningMoadl: false,
    })
  }

  resetPassword = () => {


    const apiUser = "../api/v1/edit-user"; 
    var newData = this.state.selected_user;
    newData.password =this.state.new_pw;
    newData.modified_by =this.props.admin.first_name + ' ' + this.props.admin.last_name;
    axios.post(apiUser, newData).then((result) => {
        console.log(result);
        this.setState({
          selected_user: {},
          isResetModal: false
        }, () => {
          this.setState({
            isNotificationModal: true,
        new_pw: '',
        confirm_pw: '',
        is_reset: true
          })
        })
       
      });
  }

  openResetModal(user){
    this.setState({
      selected_user: user,
      isResetModal: true
    })
  }

  openWarningModal(user){
    this.setState({
      isWarningMoadl: true,
      selected_user: user
    })
  }

  addAdmin(){
    const payload = {
      email: this.state.email,
      password: this.state.password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      user_type: "admin",
      status: "verified"
    };

    const apiUrl = "../api/v1/signup";
    console.log('hi')

    axios.post(apiUrl, payload)
    // setTimeout(200, () => {
      
    // })
    this.getUsers();
      this.setState({
        isAddModal: false,
        
      }, () => {
        this.setState({
          isNotificationModal: true,
        is_added: true,
        email: '',
        first_name: '',
        last_name: '',
        password: ''
        })
      })
    // .then((res) => {
    //   console.log(res)
    //   if (res.data.status === "error") {
    //     this.setState({ message: res.data.message }, ()=> {
    //       console.log(this.state.message)
    //     });
        
    //   } else {
    //     this.getUsers();
    //     this.setState({
    //       isAddModal: false,
          
    //     }, () => {
    //       this.setState({
    //         isNotificationModal: true,
    //       is_added: true,
    //       email: '',
    //       first_name: '',
    //       last_name: '',
    //       password: ''
    //       })
    //     })
    //   }
    // });
  }

  handleFirstChange = (e) => {
    this.setState({
      first_name: e.target.value
    })
  }

  handleLastChange = (e) => {
    this.setState({
      last_name: e.target.value
    })
  }

  removeAdmin = () => {
    const payload = {
      _id: this.state.selected_user.id
  };
  console.log(payload)
  
  axios.delete('../api/v1/delete', {
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload
    })
  .then((res) => {
      console.log(res);
      this.getUsers();
      this.setState({
        isWarningMoadl: false,
        isNotificationModal: true,
        is_removed: true
      })
  })
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <AdminMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Manage Admin</div>
                {/* <div className='body-title mt-32'>Refer headings text</div>
                <div className='body-txt mt-10'>Additional text about referrals</div> */}
              </div>
              <div className='top-right'>
              {/* <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={()=>history.push('/settings')}/>
                </div>
                <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon'/>
                </div> */}
                <div className='sm-avatar-wrap  bg-white'>
                  <img src={slogo} alt='' className='sm-avatar'/>
                </div>
              </div>
            </div>
            <div className='bottom-container'>
              <div className='table-card'>
                <div class="block-wrap-vh block-full-wrap">
                  <div className='row'>
                  <div className='block-header'>Admin</div>
                  <div className='float-right'>
                      <button className='btn-save' onClick={this.openAdminModal}>Add Admin</button>
                    </div>
                  </div>
                  
                {/* <div className="block-tab">
                    <div className={this.state.tab === "pending" ? "appt-title-wrap tab-active" : "appt-title-wrap"} onClick={() => this.switchTab("pending")}><span className="appt-title">Pending</span></div>
                    <div className={this.state.tab === "linked" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("linked")}><span className="appt-title">Linked</span></div>
                    <div className={this.state.tab === "nolink" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("nolink")}><span className="appt-title">Do Not Link</span></div>
                  </div> */}
                  <div className="block-divider mb-60"></div>

                       <div className='c-table'>
                       <div className='table-header'>
                         <div className='table-head name-head'>Last name</div>
                         <div className='table-head method-head'>First name</div>
                         <div className='table-head email-head'>Email</div>
                         <div className='table-head method-head'>Type</div>
                         <div className='table-head email-head'>Actions</div>
                         <div className='table-head method-head'>Modified Date</div>
                       </div>
                      <div className='table-data-wrap'>
                      {Object.keys(this.state.users).map((key) => (
                        <div className='table-body p-3'>
                        <div className='table-head name-head'>{this.state.users[key].last_name}</div>
                        <div className='table-head method-head'>{this.state.users[key].first_name}</div>
                        <div className='table-head email-head'>{this.state.users[key].email}</div>
                        <div className='table-head method-head'>{this.state.users[key].user_type}</div>
                        <div className='table-head email-head'>
                         {this.state.users[key].email != this.props.admin.email ? (
                           <div className='table-btn-wrap'>
                           <button className='btn-action mb-8 btn-long' onClick={()=>this.openResetModal(this.state.users[key])}>Reset Password</button>
                             <button className='btn-action btn-long' onClick={() => this.openWarningModal(this.state.users[key])}>Remove Admin</button>
                           </div>
                         ) : (
                          <div className='table-btn-wrap'>
                          <button className='btn-action btn-long' onClick={()=>this.openResetModal(this.state.users[key])}>Reset Password</button>
                          </div>
                         )}
                         
                        </div>
                        {(this.state.users[key].modified_by == '' || this.state.users[key].modified_by == null ) ? (
                          <div className='table-head method-head'>{moment(this.state.users[key].updatedAt).format("YYYY-MM-DD")} by System</div>
                        ) : (
                          <div className='table-head method-head'>{moment(this.state.users[key].updatedAt).format("YYYY-MM-DD")} by {this.state.users[key].modified_by}</div>
                        )}
                      </div>
                      ))}
                     
                       </div>
                       </div>
                  
                  </div>
              </div>
            </div>
           
          </div>
        </div>
        <Modal show={this.state.isNotificationModal} handleClose={this.hideNotificationModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              {this.state.is_added && (
                <div className='o-modal-title'>Admin User Added!</div>
              )}
              {this.state.is_reset && (
                <div className='o-modal-title'>Admin User Password Reset!</div>
              )}
              {this.state.is_removed && (
                <div className='o-modal-title'>Admin User Added!</div>
              )}
            </div>
           {this.state.is_added && (
             <div className='n-modal-txt'>Admin user has been added and an email has been sent.</div>
           )}
           {this.state.is_removed && (
             <div className='n-modal-txt'>The admin account has been removed
             </div>
           )}
           {this.state.is_reset && (
             <div className='n-modal-txt'>This account's password has been reset
             </div>
           )}
          </div>
        </Modal>

        <Modal show={this.state.isAddModal} handleClose={this.hideAdminModal}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Add Admin Account</div>
            </div>
           <div className='m-modal-content'>
           <div className='o-modal-title'>Personal Information</div>
           <div className='info-group'>
                      <div className='info-label'>First Name</div>
                      <input className='p-input'type="text"  value={this.state.first_name} placeholder="Enter first name" onChange={this.handleFirstChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Last Name</div>
                      <input className='p-input'type="text"  value={this.state.last_name} placeholder="Enter last name" onChange={this.handleLastChange} />
                    </div>
           <div className='info-group'>
                      <div className='info-label'>Email</div>
                      <input className='p-input' value={this.state.email} placeholder="example@example.com" onChange={this.handleEmailChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Temporary Password</div>
                      <input className='p-input' value={this.state.password} placeholder="Enter temporary password" onChange={this.handlePasswordChange} />
                    </div>

                    <div className='row mt-24 float-right'>
                      <button className='btn-save' onClick={()=>this.addAdmin()}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.hideAdminModal}>Cancel</button>
                    </div>
           </div>
          </div>
        </Modal>
        <Modal show={this.state.isResetModal} handleClose={this.hideResetModal}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Reset Password</div>
            </div>
           <div className='m-modal-content'>
           <div className='info-group'>
                      <div className='info-label'>Admin Name</div>
                      <input className='p-input'type="text"  value={this.state.selected_user.first_name + ' ' + this.state.selected_user.last_name} disabled/>
                    </div>
           <div className='info-group'>
                      <div className='info-label'>New Password</div>
                      <input className='p-input' value={this.state.new_pw}  onChange={this.onNewChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Confirm Password</div>
                      <input className='p-input' value={this.state.confirm_pw} onChange={this.onConfirmChange} />
                    </div>

                    <div className='row mt-24 float-right'>
                      <button className='btn-save' onClick={this.resetPassword} disabled={this.state.isMatched}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.hideResetModal}>Cancel</button>
                    </div>
           </div>
          </div>
        </Modal>
        <Modal show={this.state.isWarningMoadl} handleClose={this.hideWarningModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Warning!</div>
            </div>
            <div className='n-modal-txt'>This action cannot be undone.</div><br/>
            <div className='n-modal-txt'>Click 'Proceed' to continue</div><br/>
             <br/>
              {/* {this.state.warning == 'delete'? (
                <button className='btn-save' onClick={this.deleteAccount}>Proceed</button>
              ) : this.state.warning == 'disabled' &&(
                <button className='btn-save' onClick={this.disableAccount}>Proceed</button>
              )} */}
               <button className='btn-save' onClick={this.removeAdmin}>Proceed</button>
          </div>
        </Modal>
      </div>
      
    );
  }
}

//map stored state to props access
const mapStateToProps = (state) => {
  return {
      admin: state.candidate.outhire_admin
  }
}

export default connect(mapStateToProps)(ManageAdmin);