import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import bell from './../../assets/outhire/bell.png';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import history from './../../utilities/history';
import dot from "./../../assets/images/dot.svg";
import NavBar from '../../components/NavBar/TopNavBar';
import axios from 'axios';
import media from './../../assets/outhire/media1.png';
import spinner from './../../assets/images/icons/Spinner.svg';
import setting from './../../assets/outhire/carbon_settings.svg';
import slogo from './../../assets/outhire/slogo.png';
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import { connect } from 'react-redux';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import profileAvatar from './../../assets/outhire/profile-avatar.png';

class MyJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      is_loading: true
    }
  }

  componentDidMount() {
    const apiPipelines = 'api/catstone/candidates/listPipelines'
                            const candidate = {
                              id: this.props.user.catsone_id
                            }
                        
                                                axios.post(apiPipelines, candidate)
                                                    .then((res) => {
                                                      console.log(res)
                                                      var myJobs = [];
                                                      if(res.data.total > 0){
                                                        for(let i = 0; i < res.data._embedded.pipelines.length; i++){
                                                          let tempJob = res.data._embedded.pipelines[i]._embedded.job;
                                                          if( tempJob.status_id == 6065969){
                                                            const apiJob = 'api/catstone/jobs/getJobById'

                        const payload = {
                            id: tempJob.id
                        }

                        axios.post(apiJob, payload)
                            .then((result) => {
                                console.log(result)
                                if(result.data._embedded.tags!=null && result.data._embedded.tags!=undefined){
                                  result.data.industry = result.data._embedded.tags[0].title;
                                if(result.data._embedded.tags.length > 1){
                                  switch (result.data._embedded.tags[1].title){
                                    case 'Agriculture 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80';
                                    break;
                                    case 'Blockchain 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                                    break;
                                    case 'Financial Services 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1485920694980-9c5db37b0d44?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                                    break;
                                    case 'Infrastructure 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1585110587043-36d457f3e803?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                                    break;
                                    case 'Private Equity 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1560264418-c4445382edbc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                                    break;
                                    case 'Retail 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1560264280-88b68371db39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                                    break;
                                    case 'Technology 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80';
                                    break;
                                    case 'Technology 2': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                                    break;
                                    case 'Real Estate 1': 
                                    result.data.cover = 'https://plus.unsplash.com/premium_photo-1663133608066-c0bc906f9577?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                                    break;
                                    case 'Real Estate 2': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1097&q=80';
                                    break;
                                    case 'Real Estate 3': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1527199768775-bdabf8b32f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                                    break;
                                    case 'Other 1': 
                                    result.data.cover = 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                                    break;
                                  }
                                }
                              }else{
                                result.data.industry = 'Industry not set';
                              }

                                myJobs.push(result.data);
                                console.log(myJobs);
                                this.setState({
                                  jobs: myJobs,
                                  is_loading: false
                                 })
                            })
                          }else{
                            this.setState({
                              is_loading: false
                            })
                          }
                                                        }
                                                      }
                                                    })
  }

  openJob = (id) => {
    history.push('/myjob-details?id=' + id)
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Welcome back, {this.props.user.first_name}</div>
              </div>
              <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={this.props.user.thumbnail} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            <div className='bottom-sec mt-0 wrap jb-cards'>
              {this.state.jobs.length > 0 ? Object.keys(this.state.jobs).map((key) => (
                <div className='job-card' key={key}>
                  <div className='jb-card-head row'>
                    {(this.state.jobs[key].company_thumbnail == null || this.state.jobs[key].company_thumbnail == undefined) ? (
                      <div className='jb-avatar1'>
                         <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    ) : (
                      <div className='jb-avatar1'>
                        {/* <img src={this.state.jobs[key].company_thumbnail} alt='' className='jb-avatar-img'/> */}
                        <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    )}
                    <div className='jb-title-wrap'>
                      <div className='jb-title elipse'>{this.state.jobs[key].title.split('-')[0]}</div>
                      {/* <div className='jb-sub-title'>{this.state.jobs[key]._embedded.company.name}</div> */}
                      <div className='jb-jb-sub-title'>{this.state.jobs[key].industry}</div>
                    </div>
                  </div>
                  <div className='jb-card-image' >
                    {/* style={{backgroundImage: `url(`+this.state.jobs[key].company_thumbnail+`)`}} */}
                    {this.state.jobs[key].cover != null && this.state.jobs[key].cover != undefined ? (
                      <img alt='' className='card-image' src={this.state.jobs[key].cover} />
                    ) : (
                      <img alt='' className='card-image' src="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"/>
                    )}
                  </div>
                  <div className='jb-card-content'>
                    <div className='jb-title elipis1-txt'>{this.state.jobs[key].title}</div>
                    <div className='jb-sub-title'>{this.state.jobs[key].location.city}, {this.state.jobs[key].location.state}</div>
                    {(this.state.jobs[key].description == '' || this.state.jobs[key].description == null) ? (
                      <div className='jb-description'><span className='elipis-txt'>No description preview</span></div>
                    ): (
                      <div className='jb-description'><span className='elipis-txt'>{this.state.jobs[key].description}</span></div>
                    )}
                    <div className=' jb-card-btn'>
                      <button className='btn-save mr-0' onClick={() => this.openJob(this.state.jobs[key].id)}>See More</button>
                    </div>
                  </div>
                </div>
              )) : this.state.is_loading?(
                <div className='load-wrap'>
                  <img src={spinner} />
                  <p>Loading your jobs, please wait...</p>
                </div>
              ): (
                <div className='load-wrap'>
                  <p>No Jobs Added</p>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    );
  }
}


//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(MyJobs);