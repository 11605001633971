import { Types } from '../../constants/actionTypes';

const initialState = {

    candidate: {
        email: '',
        first_name: '',
        last_name: '',
        candidate_id: null
    },
    outhire_user: {
        email: '',
        password: '',
        user_type: '',
        status: '',
        linkedin_id: '',
        catsone_status: null,
        catsone_id: null,
        modified_by: '',
        id: '',
        thumbnail: '',
        first_name: '',
        last_name: ''
    },
    outhire_admin: {
        email: '',
        user_type: '',
        status: '',
        modified_by: '',
        id: '',
        thumbnail: '',
        first_name: '',
        last_name: ''
    }
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.UPDATE_CANDIDATE:
            return {
                ...state,
                candidate: action.payload.candidate
            }
        case Types.UPDATE_USER:
            return {
                ...state,
                outhire_user: action.payload.outhire_user
            }
        case Types.UPDATE_ADMIN:
            return {
                ...state,
                outhire_admin: action.payload.outhire_admin
            }
        default:
            return state;

    }
}

export default profileReducer;