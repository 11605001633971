import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import home from './../../assets/outhire/employer-main.svg'
import employer1 from './../../assets/outhire/employer1.svg'
import home4 from './../../assets/outhire/home4.svg'
import home5 from './../../assets/outhire/home5.svg'
import home6 from './../../assets/outhire/home6.svg'
import icon_group1 from './../../assets/outhire/icon-group1.svg'
import icon_group2 from './../../assets/outhire/icon-group2.svg'
import go from './../../assets/outhire/go.svg'
import facebook from './../../assets/outhire/icon-facebook.svg';
import twitter from './../../assets/outhire/icon-twitter.svg';
import ins from './../../assets/outhire/icon-ins.svg';
import register from './../../assets/outhire/register.svg';
import call from './../../assets/outhire/call.svg';
import evaluates from './../../assets/outhire/evaluate.svg';
import interview from './../../assets/outhire/interview.svg';
import offer from './../../assets/outhire/offer.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import bell from './../../assets/outhire/bell.png';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import dot from "./../../assets/images/dot.svg";
import axios from 'axios';
import media from './../../assets/outhire/media1.png';
import spinner from './../../assets/images/icons/Spinner.svg';
import setting from './../../assets/outhire/carbon_settings.svg';
import slogo from './../../assets/outhire/slogo.png';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';


class ExploreJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: []
    }
  }
    // state = {
      
    // };
 
    componentDidMount() {
      const apiJobs = 'api/catstone/jobs'
      const payload = {
        per_page: 25,
        page: 1,
        data: {
          "field": "status_id",
          "filter": "exactly",
          "value": 6065969
        }
      }
      let activeJobs = [];
      axios.post(apiJobs, payload)
        .then((result) => {
          console.log(result);
          if (result.data != null && result.data != undefined) {
            var total = result.data.total;
            var page = total / 25;
            var reminder = total % 25;
            if (reminder > 0) {
              page = Math.round(page + 0.5)
            }
            console.log(page);
            for(let i = 0; i < result.data._embedded.jobs.length; i++){
              if(result.data._embedded.jobs[i]._embedded.tags!=null && result.data._embedded.jobs[i]._embedded.tags!=undefined){
                result.data._embedded.jobs[i].industry = result.data._embedded.jobs[i]._embedded.tags[0].title;
                if(result.data._embedded.jobs[i]._embedded.tags.length > 1){
                  switch (result.data._embedded.jobs[i]._embedded.tags[1].title){
                    case 'Agriculture 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80';
                    break;
                    case 'Blockchain 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                    break;
                    case 'Financial Services 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1485920694980-9c5db37b0d44?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                    break;
                    case 'Infrastructure 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1585110587043-36d457f3e803?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                    break;
                    case 'Private Equity 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560264418-c4445382edbc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                    break;
                    case 'Retail 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1560264280-88b68371db39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                    break;
                    case 'Technology 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80';
                    break;
                    case 'Technology 2': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                    break;
                    case 'Real Estate 1': 
                    result.data._embedded.jobs[i].cover = 'https://plus.unsplash.com/premium_photo-1663133608066-c0bc906f9577?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80';
                    break;
                    case 'Real Estate 2': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1097&q=80';
                    break;
                    case 'Real Estate 3': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1527199768775-bdabf8b32f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
                    break;
                    case 'Other 1': 
                    result.data._embedded.jobs[i].cover = 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80';
                    break;
                  }
                }
                this.setState({
                  jobs: result.data._embedded.jobs
                })
              }else{
                result.data._embedded.jobs[i].industry = 'Industry not set';
                this.setState({
                  jobs: result.data._embedded.jobs
                })
              }
            }
           
            // for (let i = 0; i < result.data._embedded.jobs.length; i++) {
            //   var apiThumbnail = "api/catstone/companies/getThumbnail";
            //   var thumbnailPayload = {
            //     id: result.data._embedded.jobs[i].company_id
            //   }
  
            //   axios.post(apiThumbnail, thumbnailPayload)
            //     .then((res) => {
            //       result.data._embedded.jobs[i].company_thumbnail = res.data.url;
            //       this.setState({
            //         jobs: result.data._embedded.jobs
            //       })
            //     })
  
            //     const apiJob = 'api/catstone/jobs/getJobById'
  
            //     const jobPayload = {
            //       id: result.data._embedded.jobs[i].id
            //     }
  
            //     axios.post(apiJob, jobPayload)
            //       .then((resJob) => {
            //         console.log(resJob)
            //         for (let j = 0; j < resJob.data._embedded.custom_fields.length; j++) {
            //           switch (resJob.data._embedded.custom_fields[j].id) {
            //             case 342953:
            //               result.data._embedded.jobs[i].description = resJob.data._embedded.custom_fields[j].value;
            //               break;
            //           }
            //         }
            //         this.setState({
            //           jobs: this.state.jobs.concat(result.data._embedded.jobs)
            //         })
            //       })
            // }
  
  
            // for(let i = 0; i < result.data._embedded.jobs.length; i++){
            //   if(result.data._embedded.jobs[i].status_id == 6065969){
            //     activeJobs.push(result.data._embedded.jobs[i]);
            //     this.setState({
            //       jobs: activeJobs
            //     })
            //   }
            // }
  
            if (page > 1) {
              for (let i = 2; i <= page; i++) {
                var newPayload = {
                  per_page: 25,
                  page: i,
                  data: {
                    "field": "status_id",
                    "filter": "exactly",
                    "value": 6065969
                  }
                }
  
                console.log(newPayload);
                axios.post(apiJobs, newPayload)
                  .then((result) => {
                    console.log(result);
                    if (result.data != null && result.data != undefined) {
                      this.setState({
                        jobs: result.data._embedded.jobs
                      })
                      // for (let i = 0; i < result.data._embedded.jobs.length; i++) {
                      //   var apiThumbnail = "api/catstone/companies/getThumbnail";
                      //   var thumbnailPayload = {
                      //     id: result.data._embedded.jobs[i].company_id
                      //   }
  
                      //   axios.post(apiThumbnail, thumbnailPayload)
                      //     .then((res) => {
                      //       result.data._embedded.jobs[i].company_thumbnail = res.data.url;
                      //       this.setState({
                      //         jobs: this.state.jobs.concat(result.data._embedded.jobs)
                      //       })
                      //     })
  
                      //   const apiJob = 'api/catstone/jobs/getJobById'
  
                      //   const jobPayload = {
                      //     id: result.data._embedded.jobs[i].id
                      //   }
  
                      //   axios.post(apiJob, jobPayload)
                      //     .then((resJob) => {
                      //       console.log(resJob)
                      //       for (let j = 0; j < resJob.data._embedded.custom_fields.length; j++) {
                      //         switch (resJob.data._embedded.custom_fields[j].id) {
                      //           case 342953:
                      //             result.data._embedded.jobs[j].description = resJob.data._embedded.custom_fields[j].value;
                      //             break;
                      //         }
                      //       }
                      //       this.setState({
                      //         jobs: this.state.jobs.concat(result.data._embedded.jobs)
                      //       })
                      //     })
                      // }
  
                    }
  
                  })
              }
            }
  
  
  
          }
        })
    }
  
    openJob = (id) => {
      history.push('/explore-jobs/details?id=' + id)
    }

    render() {
      return (
        <div className="App">
          <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap employer-bg">
                <div className="first-wrap coloum ex-first relative">
                {/* <div className="diagonal-box  job-diagonal"></div> */}
                <img className="white-poligon ex-white" src={white} alt=''/>
                    {/* <img alt='' className="home-img employer-home" src={home}/> */}
                    <div className="about-first-wrap">
                    <div className="first-text-wrap">
                      <div className="web-nav">
                      <div className="home-title w-full w-718">See if there's a match for you</div>
                      </div>
                       
                        <div className="mo-wrap">
                        <div className="home-title w-full">See if there's a</div>
                        <div className="home-title w-full">match for you</div>
                        </div>
                        <div className="animation-txt-wrap web-nav">
                        <div className="about-body-txt w-496 sans-large">Explore our top accounting and finance jobs</div>   
                        </div>
                        <div className="animation-txt-wrap mo-wrap text-center">
                        <div className="about-body-txt sans-large">Explore our top accounting and finance jobs</div>   
                        {/* <div className="about-body-txt sans-large"></div>   */}
                        </div>               
                    </div>
                    </div>
                 
                    <div className='bottom-sec mt-0 wrap jb-cards home-jb-cards mo-bottom-jb'>
              {this.state.jobs.length > 0 ? Object.keys(this.state.jobs).map((key) => (
                <div className='job-card home-job-card' key={key}>
                  <div className='jb-card-head row'>
                    {(this.state.jobs[key].company_thumbnail == null || this.state.jobs[key].company_thumbnail == undefined) ? (
                      <div className='jb-avatar1'>
                         <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    ) : (
                      <div className='jb-avatar1'>
                        {/* <img src={this.state.jobs[key].company_thumbnail} alt='' className='jb-avatar-img'/> */}
                        <img src={slogo} alt='' className='jb-avatar-img' />
                      </div>
                    )}
                    <div className='jb-title-wrap'>
                      <div className='jb-title elipis-txt'>{this.state.jobs[key].title.split('-')[0]}</div>
                      {/* <div className='jb-sub-title'>{this.state.jobs[key]._embedded.company.name}</div> */}
                      <div className='jb-sub-title'>{this.state.jobs[key].industry}</div>
                    </div>
                  </div>
                  <div className='jb-card-image' >
                    {/* style={{backgroundImage: `url(`+this.state.jobs[key].company_thumbnail+`)`}} */}
                    {this.state.jobs[key].cover != null && this.state.jobs[key].cover != undefined ? (
                      <img alt='' className='card-image' src={this.state.jobs[key].cover} />
                    ) : (
                      <img alt='' className='card-image' src="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" />
                    )}
                  </div>
                  <div className='jb-card-content'>
                    <div className='jb-title elipis1-txt'>{this.state.jobs[key].title}</div>
                    <div className='jb-sub-title'>{this.state.jobs[key].location.city}, {this.state.jobs[key].location.state}</div>
                    {(this.state.jobs[key].description == '' || this.state.jobs[key].description == null) ? (
                      <div className='jb-description'><span className='elipis-txt'>No description preview</span></div>
                    ): (
                      <div className='jb-description'><span className='elipis-txt'>{this.state.jobs[key].description}</span></div>
                    )}
                    <div className=' jb-card-btn'>
                      <button className='btn-save mr-0' onClick={() => this.openJob(this.state.jobs[key].id)}>See More</button>
                    </div>
                  </div>
                </div>
              )) : (
                <div className='load-wrap'>
                  <img src={spinner} />
                  <p>Loading jobs, please wait...</p>
                </div>
              )}

            </div>
              </div>  
            
            </div> 

           <Footer/>
            
          </div>
        </div>  
     );
   }   
  }    
  export default ExploreJobs;
