import React, { Component } from 'react';
import './styles.css';
import logo from './../assets/outhire/outhire.svg';
import history from './../utilities/history';
import sent from './../assets/images/sent.png';
import { connect } from 'react-redux';
import { ProfileActionCreators } from './../actions/patient/profileAction';
import axios from "axios";

class ConfirmEmailResult extends Component {
  constructor(props){
    super(props);
    this.state = { 
        isEmailConfirmState: false, 
        linkAddrInvalid: false
    }
  }

  componentWillMount(){
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
     

    const apiUser = "/api/v1/get-user"; 
    const user = {
      email:email
      
    }

    axios.post(apiUser, user).then((result) => {
        if (result.data.status === "verified") {
          this.setState({
            linkAddrInvalid: true
          })


        } else {
          const apiUser = "/api/v1/edit-user"; 
          const user = {
              email: email,
              status: 'verified',
            
          }
      
          axios.post(apiUser, user).then((result) => {
              if (result.status === "error") {
                console.log(result.data.message);
                this.setState({
                  isEmailConfirmState: false
              })
              } else {
                  this.setState({
                      isEmailConfirmState: true
                  })        
              
              }
            });
          }      
        
        });
    
    
    
    

   
    
   
    
  }

  componentDidMount() {
   
    // this.props.dispatch(ProfileActionCreators.updatePersonFilled(false));
    // this.props.dispatch(ProfileActionCreators.updateContactFilled(false));
    // if(this.props.basic.email == '' || this.props.basic.email == null){
    //   history.push('/signin');
    // }
  }



  render() {

    let userMessage;
    if(this.state.linkAddrInvalid){

      userMessage = (
        <form className="terms-form text-center">
        <div className="sentimg-wrap mb-24">
            {/* <img src={sent}/> */}
        </div>             
        <div className="verify-email mb-56 forgetpw txt-in-wrap">Your account has already been verified.</div>
        <div className="size-1em font-regular color-primary mb-32">
            This link expired or is invalid.
        </div>
                </form>
      )

    }else{
    if (this.state.isEmailConfirmState) {
      userMessage = (
        <form className="terms-form text-center">
        <div className="sentimg-wrap mb-24">
            {/* <img src={sent}/> */}
        </div>             
        <div className="title-txt mb-24">Congratulations</div>
        <div className="size-1em font-regular color-primary mb-24">Your account is now verified.</div>
        <div className="size-1em font-regular color-primary mb-24">
            Please read the terms and conditions to continue with registration.
        </div>
        <button className='btn btn-solid btn-regular font-18 btn-mr2' onClick={() =>history.push("/terms")}>Continue</button>
        </form>
      )
    } else {
      userMessage = (
        <form className="terms-form text-center">
        <div className="sentimg-wrap mb-24">
            {/* <img src={sent}/> */}
        </div>             
        <div className="verify-email mb-56">Oops, something went wrong!</div>
      
   </form>
      )
    }
  }


 
    return (
      <div className="confirm-container bg-light full-height">
        <div className="logo-wrapper float-left">
            <img className="logo" src={logo} onClick={() => history.push('/')} />
        </div>
        <div className="home-card-wrap mb-233 top-card home-login-wrap">
    <div className="signin-form">
   {userMessage}
            </div>
            </div>
       
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      basic: state.patient.patient,
  }
}

export default connect(mapStateToProps)(ConfirmEmailResult);