import React, { Component } from 'react';
import './styles.css';
// import logo from './../assets/images/logo.png';
import doctor from './../assets/images/computer.svg';
import history from './../utilities/history';
import SignUpForm from '../components/SignUpForm/SignUpForm';
import NavBar from '../components/NavBar/TopNavBar';
import Footer from "../components/Landing/Footer";
import white from './../assets/outhire/white.svg';
import OuthireMobileMenu from '../components/NavBar/TopMobileMenu';
import menu from './../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../assets/outhire/outhire.svg';

class TermsAndCondition extends Component {
  constructor(props){
    super(props);
    this.state = {
        isTermChecked: false,
        isEligibilityChecked: false
    }
  }

  toggleTermCheck = () => {
    this.setState({
        isTermChecked: !this.state.isTermChecked
    })
  }

  toggleEligibilityCheck = () => {
    this.setState({
        isEligibilityChecked: !this.state.isEligibilityChecked
    })
  }

  finish = () => {
    if(this.state.isTermChecked && this.state.isEligibilityChecked){
        history.push('/registrationsuccess')
    }else{
        history.push('/ineligible')
    }
  }

  render() {
    return (

<div className="container">
<div className="content-wrap">
   <NavBar />
 </div>
 <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
 <div className="max-width">
   <div className="candidate-bg web-nav">
   <div className="first-wrap content-center coloum">
     {/* <div className="sign-diagonal"></div> */}
     <img className="white-poligon" src={white} alt=''/>
     <div className="top-section">
   <div className="home-card-wrap mb-100 top-card home-login-wrap px-32">
   <div className="signin-form">
                <form className="terms-form">
                    <div className="title-txt font-medium size-2em text-center mb-24">Terms and Conditions</div>
                    <div className='form-txt'>Please read Outhire’s Terms and Conditions</div>
                    <br/>
                    <div className='form-txt'>Outhire inc. (“Outhire”, “us”, “we”, “our”) owns and operates the website located at www.outhire.ca (“Website”) and provides the Outhire services described on the Website (“Services”). <span><a href='/terms-of-use' target="_blank" className="here-link">read more</a></span></div>
                    <br/>
                    <div className="check-wrapper mb-12">
            <label className="size-1em font-regular d-flex justify-left">
              <input
                type="checkbox"
                className="check-box-small"
                defaultChecked={this.state.isTermChecked}
                onChange={this.toggleTermCheck}
              /><div className='check-txt'> I have read and agree to the terms and conditions</div>
             
            </label>
            </div>
            <br/>
            <div className="check-wrapper mb-12">
            <label className="size-1em font-regular d-flex">
              <input
                type="checkbox"
                className="check-box-small"
                defaultChecked={this.state.isEligibilityChecked}
                onChange={this.toggleEligibilityCheck}
              /><div className='check-txt'> I am eligible to work in Canada without a work permit.</div>
             
            </label>
          </div>
          <br/>
          <br/>
          <button className='btn btn-solid btn-regular font-18 btn-mr' disabled={
                this.state.isTermChecked ? false : true
              }
              onClick={this.finish}>Accept and Finish Registration</button>
                </form>
            </div>
</div>
   </div>
   </div>
 </div>
 
 <div className="mo-wrap">
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
    <div className="signin-form">
                <form className="terms-form">
                    <div className="title-txt font-medium size-2em text-center mb-24">Terms and Conditions</div>
                    <div className='form-txt'>Please read Outhire’s Terms and Conditions</div>
                    <br/>
                    <div className='form-txt'>Outhire inc. (“Outhire”, “us”, “we”, “our”) owns and operates the website located at <a className='here-link' target="_blank" href="/">www.outhire.ca</a>  (“Website”) and provides the Outhire services described on the Website (“Services”). <a className='here-link' target="_blank" href="/terms-of-use">Read more</a></div>
                    <br/>
                    <div className="check-wrapper mb-12">
            <label className="size-1em font-regular d-flex justify-left">
              <input
                type="checkbox"
                className="check-box-small"
                defaultChecked={this.state.isTermChecked}
                onChange={this.toggleTermCheck}
              /><div className='check-txt'> I have read and agree to the terms and conditions</div>
             
            </label>
            </div>
            <br/>
            <div className="check-wrapper mb-12">
            <label className="size-1em font-regular d-flex">
              <input
                type="checkbox"
                className="check-box-small"
                defaultChecked={this.state.isEligibilityChecked}
                onChange={this.toggleEligibilityCheck}
              /><div className='check-txt'> I am eligible to work in Canada without a work permit.</div>
             
            </label>
          </div>
          <br/>
          <br/>
          <button className='btn btn-solid btn-regular font-18 btn-mr btn-mr-mo' disabled={
                this.state.isTermChecked ? false : true
              }
              onClick={this.finish}>Accept and Finish Registration</button>
                </form>
            </div>
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer-relative">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>

</div>
</div>
    );
  }
}

export default TermsAndCondition;