export const Types = {
    SIGNIN: 'SIGNIN',
    UPDATE_BASIC_INFO: 'UPDATE_BASIC_INFO',
    UPDATE_CONTACT_INFO: 'UPDATE_CONTACT_INFO',
    UPDATE_HEALTH_INFO: 'UPDATE_HEALTH_INFO',
    UPDATE_PATIENT_AVATAR: 'UPDATE_PATIENT_AVATAR',
    UPDATE_PERSON_FILLED: 'UPDATE_PERSON_FILLED',
    UPDATE_CONTACT_FILLED: 'UPDATE_CONTACT_FILLED',
    UPDATE_HEALTH_FILLED: 'UPDATE_HEALTH_FILLED',
    UPDATE_EMAIL: 'UPDATE_EMAIL',
    UPDATE_PATIENT: 'UPDATE_PATIENT',
    UPDATE_APPOINTMENTS_BY_PATIENT: 'UPDATE_APPOINTMENTS_BY_PATIENT',
    
    //booking action types
    UPDATE_WALKIN_REASON: 'UPDATE_WALKIN_REASON',
    UPDATE_BOOKING_PAGE: 'UPDATE_BOOKING_PAGE',
    UPDATE_DESACRIPTION: 'UPDATE_DESACRIPTION',
    UPDATE_BOOKING_DATETIME: 'UPDATE_BOOKING_DATETIME',
    UPDATE_BOOKING_MED: 'UPDATE_BOOKING_MED',
    UPDATE_BOOKING_LAB: 'UPDATE_BOOKING_LAB',
    UPDATE_BOOKING_SPECIALIST: 'UPDATE_BOOKING_SPECIALIST',
    UPDATE_SPECIALIST_REASON: 'UPDATE_SPECIALIST_REASON',
    UPDATE_DOCTOR: 'UPDATE_DOCTOR',

    //Candidate
    UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_ADMIN: 'UPDATE_ADMIN',
}