import { Types } from '../../constants/actionTypes';

const initialState = {
    walkin_reason: '',
    page_index: 0,
    description: {
        experience: '',
        how_long: '',
        frequency: '',
        action: ''
    },
    appt_datetime: {
        date: null,
        start_time: null,
        end_time: null
    },
    medication: {
        med_name: '',
        med_reason: '',
        is_current: true,
        med_period: ''
    },
    lab: {
        test: '',
        lab_reason: ''
    },
    specialist: '',
    specialist_reason: ''
}

const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.UPDATE_WALKIN_REASON:
            return {
                ...state,
                walkin_reason: action.payload.walkin_reason
            }
        case Types.UPDATE_BOOKING_PAGE:
            return {
                ...state,
                page_index: action.payload.page_index
            }
        case Types.UPDATE_DESACRIPTION:
            return {
                ...state,
                description: action.payload.description
            }
            case Types.UPDATE_BOOKING_DATETIME:
                return {
                    ...state,
                    appt_datetime: action.payload.appt_datetime
                }
            case Types.UPDATE_BOOKING_MED:
                return {
                    ...state,
                    medication: action.payload.medication
                }
            case Types.UPDATE_BOOKING_LAB:
                return {
                    ...state,
                    lab: action.payload.lab
                }
            case Types.UPDATE_BOOKING_SPECIALIST:
                return {
                    ...state,
                    specialist: action.payload.specialist
                }
            case Types.UPDATE_SPECIALIST_REASON:
                return {
                    ...state,
                    specialist_reason: action.payload.specialist_reason
                }
        default:
            return state;

    }
}

export default bookingReducer;