import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import home from './../../assets/outhire/Frame1.png'
import mo_home from './../../assets/outhire/mobile-home.svg'
import home1 from './../../assets/outhire/home1.svg'
import home2 from './../../assets/outhire/home2.svg'
import home3 from './../../assets/outhire/home3.svg'
import home4 from './../../assets/outhire/home4.svg'
import home5 from './../../assets/outhire/home5.svg'
import home6 from './../../assets/outhire/home6.svg'
import icon_group1 from './../../assets/outhire/right1.svg'
import icon_group2 from './../../assets/outhire/right2.svg'
import go from './../../assets/outhire/go.svg'
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import roles from './../../assets/outhire/recruitment.svg';
import dot from './../../assets/outhire/dot.svg';
import logo1 from './../../assets/outhire/logo-g-adventures.svg';
import logo2 from './../../assets/outhire/logo-rakuten.svg';
import logo3 from './../../assets/outhire/logo-cbre.svg';
import logo4 from './../../assets/outhire/logo-neighbourly.svg';
import logo5 from './../../assets/outhire/logo-daniels.svg';
import logo6 from './../../assets/outhire/logo-nutrien.svg';
import logo7 from './../../assets/outhire/logo-koho.svg';
import logo8 from './../../assets/outhire/logo-ledn.svg';
import logo9 from './../../assets/outhire/logo-yamaha.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore from 'swiper';
import ReactCardFlip from "react-card-flip";
import FlipNumbers from 'react-flip-numbers';
import AnimatedNumbers from "react-animated-numbers";
import { motion } from "framer-motion";
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

SwiperCore.use([Autoplay])



class Main extends Component {
    state = {
      flip: false,
      flip1: false,
      flip2: false,
      isHover: false
    };

    flipCard=()=>{
    if(!this.state.isHover){
        this.setState({
            isHover: true
          }, ()=> {
            this.setState({
                flip:true,
            })
          })
    }
    }
  
    render() {
      return (
        <div className="App">
          <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <motion.div className="main-body-wrap"
            // initial={{ scale: 0.8 }}
            // animate={{  scale: 1 }}
            // transition={{ duration: 0.8 }}
            >
            <img className="white-poligon home-white" src={white} alt=''
            />
                <div className="first-wrap big-first-wrap">
                    {/* <div className="diagonal-box"></div> */}
                   
                    <div className="main-first-wrap">
                   
                    <div className="first-text-wrap">
                        <div className="home-title">Best hiring</div> <div className="home-title">experience ever</div>
                        <div className="animation-txt-wrap web-home-txt">
                        <div className="home-body-txt">We specialize in connecting high-growth companies with the best Canadian accounting and finance professionals like no other recruitment agency</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/explore-jobs')}>Find Jobs</button>
                          <button className='btn-cancel btn-home sans-font' onClick={() => history.push('/contact')}>Start Hiring</button>
                        </div>
                        </div>
                        
                    </div>
                    <div className="home-img mac-img"><motion.img alt='' className="w-img"  src={home}
                    /></div>
                    </div>
                </div>
                <div className="main-bottom-wrap">
                <div className="full-w-card mo-show">
                <div className="animation-txt-wrap">
                        <div className="home-body-txt">We specialize in connecting high-growth companies with the best Canadian accounting and finance professionals like no other recruitment agency</div>
                        <div className="home-btn-wrap">
                          <button className='btn-save btn-home sans-font' onClick={() => history.push('/explore-jobs')}>Find Jobs</button>
                          <button className='btn-cancel btn-home sans-font' onClick={() => history.push('/contact')}>Start Hiring</button>
                        </div>
                        </div>
                </div>
                <div className="full-w-card">
                    <div className="row s-center mo-column">
                        <div className="side-txt flex-v-center">What we do:</div>
                        <div className="right-txt-wrap">
                            <div className="right-side-txt">
                            We are a new kind of recruitment agency dedicated to making the hiring experience better for candidates and companies’. We exclusively focus only on accounting and finance positions.

                            </div>
                            <div className="inner-center-btn-wrap"><button className="btn-save btn-home mr-0" onClick={()=> history.push('/contact')}>Book a Demo</button></div>
                        </div>
                    </div>
                </div>
                <div className="full-w-card ptb-48 mo-pb-0">
                    <div className="home-title text-center w-no mb-75">Trusted by</div>
                    {/* <div className="row space-between w-1360">
                       <img alt='' className="other-logo" src={logo1}/>
                       <img alt='' className="other-logo" src={logo2}/>
                       <img alt='' className="other-logo" src={logo3}/>
                       <img alt='' className="other-logo" src={logo4}/>
                       <img alt='' className="other-logo" src={logo5}/>
                    </div> */}
                        <Swiper
     slidesPerView={5}
     loop={true}
    pagination={{ clickable: true}}
    modules={[Autoplay]}
    className="home-slider web-slider"
    autoplay={{ delay: 0}}
    speed={3000}
    // breakpoints={{
    //     // when window width is >= 640px
    //     1281: {
    //       width: 1281,
    //       slidesPerView: 5,
    //     },
    //     // when window width is >= 768px
    //     1000: {
    //       width: 1000,
    //       slidesPerView: 4,
    //     },
    //     480: {
    //         width: 480,
    //         slidesPerView: 1,
    //     }
    //   }}
    >
      <SwiperSlide><img alt='' className="other-logo" src={logo1}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo2}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo3}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo4}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo5}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo6}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo7}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo8}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo9}/></SwiperSlide>
    </Swiper>
    <Swiper
     slidesPerView={1}
     loop={true}
    pagination={{ clickable: true}}
    modules={[Autoplay]}
    className="home-slider mo-slider"
    autoplay={{ delay: 0}}
    speed={3500}
    >
      <SwiperSlide><img alt='' className="other-logo" src={logo1}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo2}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo3}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo4}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo5}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo6}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo7}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo8}/></SwiperSlide>
      <SwiperSlide><img alt='' className="other-logo" src={logo9}/></SwiperSlide>
    </Swiper>

                </div>
                <div className="home-fixed-card">
                    <div className="row space-between mo-column">
                    <ReactCardFlip isFlipped={this.state.flip} 
            flipDirection="horizontal">
            <div className="flip-card cursor mo-mb-16" onClick={()=> {this.setState({flip: !this.state.flip})}} onMouseLeave={()=> this.setState({flip: false})}>
            {/* onMouseOver={this.flipCard} onMouseLeave={()=> this.setState({flip: false, isHover: false})} */}
            <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0" >PROJECT</button></div>
                        </div>
                        <div className="flip-card cursor mo-mb-16" onClick={()=> {this.setState({flip: !this.state.flip})}}  onMouseLeave={()=> this.setState({flip: false})}>
                            {/* <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0">PROJECT</button></div> */}
                            <div className="flip-txt">
                            Whether it’s a IFRS implementations, ERP systems upgrades or acquisition integration.
                            </div>
                        </div>
        </ReactCardFlip>
        <ReactCardFlip isFlipped={this.state.flip1} 
            flipDirection="horizontal">
            <div className="flip-card cursor mo-mb-16" onClick={()=> {this.setState({flip1: !this.state.flip1})}} onMouseLeave={()=> this.setState({flip1: false})}>
            {/* onMouseOver={this.flipCard} onMouseLeave={()=> this.setState({flip: false, isHover: false})} */}
            <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0">CONTRACT</button></div>
                        </div>
                        <div className="flip-card cursor mo-mb-16" onClick={()=> {this.setState({flip1: !this.state.flip})}}  onMouseLeave={()=> this.setState({flip1: false})}>
                            {/* <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0">PROJECT</button></div> */}
                            <div className="flip-txt">
                            Interim resources to fill the gap, maternity leave coverage, or year-end surge staffing.
                            </div>
                        </div>
        </ReactCardFlip>
        <ReactCardFlip isFlipped={this.state.flip2} 
            flipDirection="horizontal">
            <div className="flip-card cursor" onClick={()=> {this.setState({flip2: !this.state.flip2})}} onMouseLeave={()=> this.setState({flip2: false})}>
            {/* onMouseOver={this.flipCard} onMouseLeave={()=> this.setState({flip: false, isHover: false})} */}
            <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0">PERMANENT</button></div>
                        </div>
                        <div className="flip-card cursor" onClick={()=> {this.setState({flip2: !this.state.flip2})}}  onMouseLeave={()=> this.setState({flip2: false})}>
                            {/* <div className="inner-center-btn-wrap  xy-center"><button className="btn-save btn-home mr-0">PROJECT</button></div> */}
                            <div className="flip-txt">
                            For the key resource from Project Accountants up to CFO. We’ll help you ensure you bring on the right long-term team member.
                            </div>
                        </div>
        </ReactCardFlip>

                    </div>
                </div>
                <motion.div className="home-card-wrap mb-75 relative" 
                >
                    <div className="home-title w-no">Empowering candidates</div>
                    <div className="mo-empower-img-wrap">
                        <img src={icon_group1} alt='' className="mo-employer-img"/>
                    </div>
                    <div className="row ml-48 mo-ml-0">
                        <div className="home-card-txt-wrap text-left">
                            <div className="home-body-txt home-card-txt w-home-card-txt mo-home-card-txt">
                            Our unique visual resume improves your chance of being qualified by potential employers.
                            </div>
                            <div className="icon-text-container">
                            <div className="icon-text-wrap ">
                                <img alt='' className="home-icon" src={home1} />
                                <div className="icon-txt w-icon-txt">Present a more <span className="weight-700">impactful</span> overview of yourself and skillset</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home2} />
                                <div className="icon-txt  w-400">Spend no time <span className="weight-700">tailoring</span> your resumé or networking</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home3} />
                                <div className="icon-txt  w-icon-txt">Get updated <span className="weight-700">instantly</span> when a role aligns with your skills and interest</div>
                            </div>
                            </div>
                          
                        </div>
                        <div className="home-card-right-img-wrap">
                            <img src={icon_group1} alt='' className="home-group-right-img"/>
                        </div>
                        {/* <div className="home-card-group text-right">
                            <img src={icon_group1} alt='' className="home-group-img"/>
                        </div> */}
                    </div>
                    <div className="home-card-btn-wrap mt-36">
                            <button className='btn-save btn-home sans-font' onClick={() => history.push('/candidates')}>Learn More</button>
                            </div>
                </motion.div>
                {/* <div className="home-card-wrap mb-100">
                    <div className="home-title w-no">Empowering employers</div>
                    <div className="row text-left">
                    <div className="home-card-group left">
                            <img src={icon_group2} alt='' className="home-group-img"/>
                        </div>
                        <div className="home-card-txt-wrap">
                            <div className="home-body-txt home-card-txt mb-100">
                            We spend hours vetting and pre-qualifying candidates, so you don’t have to.
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home4} />
                                <div className="icon-txt">Hire top-level talent thanks to our rigorous search and assessment process</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home5} />
                                <div className="icon-txt">Hire confidently with risk-free trials and replacement guarantees</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home6} />
                                <div className="icon-txt">Hire for all staffing needs, from hourly to permanent</div>
                            </div>
                            <div className="home-card-btn-wrap">
                            <button className='btn-save btn-home' onClick={() => history.push('/employers')}>Start Hiring</button>
                            </div>
                        </div>
                       
                    </div>
                </div> */}
                <div className="home-card-wrap mb-75 relative">
                    <div className="home-title w-no">Empowering employers</div>
                    <div className="mo-empower-img-wrap">
                        <img src={icon_group2} alt='' className="mo-employer-img"/>
                    </div>
                    <div className="row ml-48 mo-ml-0">
                        <div className="home-card-txt-wrap text-left">
                            <div className="home-body-txt home-card-txt w-home-card-600 mo-home-card-txt">
                            We spend hours vetting and pre-qualifying candidates, so you don’t have to.
                            </div>
                            <div className="icon-text-container">
                            <div className="icon-text-wrap ">
                                <img alt='' className="home-icon" src={home4} />
                                <div className="icon-txt w-icon-txt">Hire top-level talent thanks to our <span className="weight-700">rigorous</span> search and assessment process</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home5} />
                                <div className="icon-txt  w-icon-txt">Hire confidently with <span className="weight-700">risk-free</span> trials and replacement guarantees</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home6} />
                                <div className="icon-txt  w-400">Hire for <span className="weight-700">all staffing needs</span> , from hourly to permanent</div>
                            </div>
                            </div>
                          
                        </div>
                        <div className="home-card-right-img-wrap">
                            <img src={icon_group2} alt='' className="home-group-right-img"/>
                        </div>
                        {/* <div className="home-card-group text-right">
                            <img src={icon_group1} alt='' className="home-group-img"/>
                        </div> */}
                    </div>
                    <div className="home-card-btn-wrap mt-36">
                            <button className='btn-save btn-home sans-font' onClick={() => history.push('/employers')}>Learn More</button>
                            </div>
                </div>
                {/* <div className="home-card-wrap mb-75">
                    <div className="home-title w-no">Empowering employers</div>
                    <div className="row row-center">
                        <div className="home-card-txt-wrap text-center">
                            <div className="home-body-txt home-card-txt w-100">
                            We spend hours vetting and pre-qualifying candidates, so you don’t have to.
                            </div>
                           <div className="icon-text-container">
                           <div className="icon-text-wrap ">
                                <img alt='' className="home-icon" src={home4} />
                                <div className="icon-txt w-100">Hire top-level talent thanks to our rigorous search and assessment process</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home5} />
                                <div className="icon-txt w-100">Hire confidently with risk-free trials and replacement guarantees</div>
                            </div>
                            <div className="icon-text-wrap">
                                <img alt='' className="home-icon" src={home6} />
                                <div className="icon-txt w-100">Hire for all staffing needs, from hourly to permanent</div>
                            </div>
                           </div>
                            
                            <div className="home-card-btn-wrap">
                            <button className='btn-save btn-home' onClick={() => history.push('/candidates')}>Learn More</button>
                            </div>
                        </div>
                        <div className="home-card-group text-right">
                            <img src={icon_group1} alt='' className="home-group-img"/>
                        </div>
                    </div>
                </div> */}
                 <div className="full-w-card half-bg">
                   <div className="max-roles-wrap">
                   <div className="types-txt">Types of Roles</div>
                   </div>
                    <div className="row s-center mo-column">
                        <img alt='' className="role-img mr-60" src={roles}/>
                        <div className="role-card">
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">CFO</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">VP of Finance</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Director of Accounting</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Manager of Accounting</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Accounting Supervisor</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Intermediate Accountants</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Project Accountants</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Development Accountants</div>
                            </div>
                        </div>
                        <div className="role-card ml-40 mo-ml-0">
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">IFRS Implementation Consultants</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">ERP Implementation Consultants</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Acquisitions Integration Consultants</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Fractional CFO</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Controller</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Interim Controllers</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot"/>
                                <div className="roles-txt">Senior Financial Analyst</div>
                            </div>
                            <div className="row ml-47">
                                <img alt='' src={dot} className="bullet-dot special-dot"/>
                                <div className="roles-txt">Maternity Leave Coverage Contractors</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-w-card ptb-28">
                    <div className="row blue-wrap web-wrap">
                        <div className="blue-txt-wrap">
                            <div className="blue-title row"><AnimatedNumbers
        includeComma
        animateToNumber={3}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><div className="ml-8"> days</div></div>
                            <div className="blue-body-txt">Time from company <br/>interview to job offer</div>
                        </div>
                        <div className="blue-txt-wrap">
                            <div className="blue-title row">  <AnimatedNumbers
        includeComma
        animateToNumber={72}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">Promotions within 18 months <br/>for Outhire placed Candidates</div>
                        </div>
                        <div className="blue-txt-wrap">
                            <div className="blue-title row">
                                <AnimatedNumbers
        includeComma
        animateToNumber={89}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">% of candidates presented <br/>hired within 3 weeks</div>
                        </div>
                    </div>
                    <div className="mo-wrap">
                    <div className="blue-wrap mb-24">
                        <div className="blue-txt-wrap">
                            <div className="blue-title row"><AnimatedNumbers
        includeComma
        animateToNumber={3}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><div className="ml-8"> days</div></div>
                            <div className="blue-body-txt">Time from company <br/>interview to job offer</div>
                        </div>
                    </div>
                    <div className="blue-wrap mb-24">

                        <div className="blue-txt-wrap">
                            <div className="blue-title row">  <AnimatedNumbers
        includeComma
        animateToNumber={72}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">Promotions within 18 months <br/>for Outhire placed Candidates</div>
                        </div>
                    </div>
                    <div className="blue-wrap">

                        <div className="blue-txt-wrap">
                            <div className="blue-title row">
                                <AnimatedNumbers
        includeComma
        animateToNumber={89}
        fontStyle={{ fontSize: 64 }}
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
        ]}
      ></AnimatedNumbers><span>%</span></div>
                            <div className="blue-body-txt">% of candidates presented <br/>hired within 3 weeks</div>
                        </div>
                    </div>

                    </div>
                </div>
                <motion.div className="home-card-wrap"    initial="hidden"
  whileInView="visible"
  viewport={{ once: true }}>
                    <div className="home-title w-714">There’s a better way to find the right fit</div>
                   <div className="line-row">
                   <div className="icon-txt w-fit connect-link cursor" onClick={() => history.push('/about')}>Discover Outhire</div>
                   <img alt='' className="go-icon" src={go} onClick={() => history.push('/about')}/>
                   </div>
                </motion.div>
                </div>
            </motion.div>
           <Footer/>
          </div>
        </div>
      );
    }
  }

  
  export default Main;