import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import file from './../../assets/outhire/file.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from './../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import ProgressBar from "@ramonak/react-progress-bar";
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import { connect } from 'react-redux';
import moment from "moment";
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import  Priority from '../../components/DragAndDrop/Container';
import LZString from 'lz-string';
import { ProfileActionCreators } from '../../actions/candidate/profileAction';
import profileAvatar from './../../assets/outhire/profile-avatar.png';
import {Buffer} from 'buffer';
import { PassThrough } from 'stream';
import stream from 'stream';


const percentage = ["0", "25", "50", "75", "100"];
const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const postalRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPersonalEdit: false,
      isEmploymentEdit: false,
      isCompensationEdit: false,
      isCommuteEdit: false,
      isResumeEdit: false,
      candidate: {},
      email: '',
      phone: '',
      postal_code: '',
      attachments: [],
      resume: [],
      isResumeModal: false,
      type: null,
      office: null,
      balance: null,
      file: null,
      thumbnail: null,
      first_year: '',
      legal: null,
      vehicle: null,
      desired_bonus: null,
      desired_total: null,
      resume_date: null,
      current: null,
      previous: null,
      isWorkEdit: false,
      isWorkHistory: false,
      is_current: false,
      title: '',
      employer: '',
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      is_uploading: false,
      is_upload_completed: null,
      status: '',
      isWorkValid: false,
      startMonthValid: false,
      startYearValid: false,
      endMonthValid: false,
      endYearValid: false,
      endError: '',
      isCellValid: true,
      isPostalValid: true,
      priority: '',
      isPriorityEdit: false,
      dustbins: [
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null }
      ],
      isPriorityValid: false,
      isAvatarEdit: false,
      avatarFile: null,
      avatarPreview: ''
    }
  }

  updateDust = (dustbin) => {
    if(dustbin[0].lastDroppedItem != null && dustbin[1].lastDroppedItem != null && dustbin[2].lastDroppedItem != null && dustbin[3].lastDroppedItem != null && dustbin[4].lastDroppedItem != null){
        this.setState({
          isPriorityValid: true,
          priority: dustbin[0].lastDroppedItem.name + ', ' + dustbin[1].lastDroppedItem.name + ', ' + dustbin[2].lastDroppedItem.name + ', ' + dustbin[3].lastDroppedItem.name + ', ' + dustbin[4].lastDroppedItem.name 
        })
    }else{
      this.setState({
        isPriorityValid: false,
        priority: ''
      })
    }
    this.setState({
      dustbins: dustbin
    }, ()=> {
      console.log(this.state.dustbins)
    })
  }

  savePriority = () => {
    const apiCustom = 'api/catstone/candidates/updateCustomField'
    const priorityPayload = {
      id: this.props.user.catsone_id,
      custom_id: 343283,
      data: {
        value: this.state.priority
      }
    }

    axios.post(apiCustom, priorityPayload)
      .then((res) => {
        console.log(res);
        this.setState({
          isPriorityEdit: false
        })
      })
  }

  componentDidMount() {
    console.log(moment(new Date()).format('YYYY-MM-DD'))
    console.log(this.props.user)
    const apiCandidate = 'api/catstone/candidates/getCandidateById'

    const payload = {
      id: this.props.user.catsone_id
    }

    axios.post(apiCandidate, payload)
      .then((result) => {
        console.log(result);
        // axios.get(`thumbnail?url=${result.data._embedded.thumbnail[0].url}`)
        axios({
          method: 'get',
          url: `thumbnail?url=${result.data._embedded.thumbnail[0].url}`,
          responseType: 'arraybuffer'
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'image/jpeg' });
          this.setState({
            thumbnail: URL.createObjectURL(blob)
          })
        }).catch(error => {
          console.log(error);
        });

        this.setState({
          candidate: result.data,
          email: result.data.emails['primary'],
          // phone: result.data.phones['home'],
          postal_code: result.data.address['postal_code']
        })

        let user = this.props.user;
        let thumb = result.data._embedded.thumbnail[0].url;
        const tmestamp = Date.now().toString();
        const cacheImg = `${thumb}?v=${tmestamp};`
        user.thumbnail = thumb;
        console.log(cacheImg)
        this.props.dispatch(ProfileActionCreators.updateUser(user));

        for (let i = 0; i < result.data._embedded.custom_fields.length; i++) {
          switch (result.data._embedded.custom_fields[i].id) {
            case 341945:
              this.setState({ first_year: result.data._embedded.custom_fields[i].value });
              break;
            case 343022:
              this.setState({ legal: result.data._embedded.custom_fields[i].value });
              break;
            case 343028:
              this.setState({ vehicle: result.data._embedded.custom_fields[i].value });
              break;
            case 343079:
              this.setState({ desired_total: result.data._embedded.custom_fields[i].value });
              break;
            case 343082:
              this.setState({ desired_bonus: result.data._embedded.custom_fields[i].value });
              break;
            case 343031:
              this.setState({ type: result.data._embedded.custom_fields[i].value });
              break;
            case 343034:
              this.setState({ office: result.data._embedded.custom_fields[i].value });
              break;
            case 343037:
              this.setState({ balance: result.data._embedded.custom_fields[i].value });
              break;
            case 343040:
              this.setState({ resume_date: result.data._embedded.custom_fields[i].value });
              break;
            case 343283:
              this.setState({ priority: result.data._embedded.custom_fields[i].value });
              break;
            case 343262:
              if(phoneRegex.test(result.data._embedded.custom_fields[i].value)){
                this.setState({
                  isCellValid: true,
                  phone: result.data._embedded.custom_fields[i].value.replace(phoneRegex, "$1-$2-$3")
                })
              }else{
                this.setState({
                  isCellValid: false,
                  phone: result.data._embedded.custom_fields[i].value
                })
              }
              break;
            case 342938:
              if(result.data._embedded.custom_fields[i].value == 984770){
                this.setState({ status: 'Unregistered' });
              }else if(result.data._embedded.custom_fields[i].value == 984773){
                this.setState({ status: 'Registered' });
              }else if(result.data._embedded.custom_fields[i].value == 984776){
                this.setState({ status: 'Verified' });
              }else if(result.data._embedded.custom_fields[i].value == 984779){
                this.setState({ status: 'Selected' });
              }
              break;
          }
        }
      })

    // const apiAttachments = 'api/catstone/candidates/getAttachmentsById'
    // var resumeData = [];
    // axios.post(apiAttachments, payload)
    //   .then((result) => {
    //     console.log(result)
    //     if (result.data.total > 0) {
    //       for (var i = 0; i < result.data._embedded.attachments.length; i++) {
    //         if (result.data._embedded.attachments[i].is_resume) {
    //           resumeData.push(result.data._embedded.attachments[i]);
    //           this.setState({
    //             resume: resumeData
    //           })
    //         }
    //       }
    //     }
    //   })

    const apiWorkHistory = 'api/catstone/candidates/listWorkHistory';
    axios.post(apiWorkHistory, payload)
      .then((res) => {
        console.log(res);
        if (res.data.count == 0) {
          this.setState({
            current: 'Not set',
            previous: 'Not set'
          })
        } else {
          var current = '';
          var previous = '';
          for (let i = 0; i < res.data._embedded.work_history.length; i++) {
            if (res.data._embedded.work_history[i].is_current) {
              current += res.data._embedded.work_history[i].title + ', ';
              this.setState({
                current: current
              })
            } else {
              previous += res.data._embedded.work_history[i].title + ', ';
              this.setState({
                previous: previous
              })
            }
          }
        }
      })

  }

  editPersonal = () => {
    this.setState({
      isPersonalEdit: true
    })
    }

  cancelPersonal = () => {
    this.setState({
      isPersonalEdit: false
    })
  }

  editCommute = () => {
    this.setState({
      isCommuteEdit: true
    })
  }

  resetPriority = () => {
    this.setState({
      dustbins: [
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null },
        { accepts: 'priority', lastDroppedItem: null }
      ],
    })
  }

  editPriority = () => {
    if(this.state.priority != '' && this.state.priority != null && this.state.priority != undefined){
      let retrivedDustbins = [
        { accepts: 'priority', lastDroppedItem: { name: this.state.priority.split(', ')[0] } },
        { accepts: 'priority', lastDroppedItem: { name: this.state.priority.split(', ')[1] } },
        { accepts: 'priority', lastDroppedItem: { name: this.state.priority.split(', ')[2] } },
        { accepts: 'priority', lastDroppedItem: { name: this.state.priority.split(', ')[3] } },
        { accepts: 'priority', lastDroppedItem: { name: this.state.priority.split(', ')[4] } }
      ]

      this.setState({
        dustbins: retrivedDustbins,
        isPriorityEdit: true
      }, ()=> {
        console.log(this.state.dustbins)
      })
  } else{
    this.setState({
      isPriorityEdit: true
    })
  }
}

  saveCommute = () => {
    const apiCustom = 'api/catstone/candidates/updateCustomField'

    const accessPayload = {
      id: this.props.user.catsone_id,
      custom_id: 343028,
      data: {
        value: this.state.vehicle
      }
    }

    axios.post(apiCustom, accessPayload)
      .then((res) => {
        console.log(res);
        this.setState({
          isCommuteEdit: false
        })
      })
  }

  cancelCommute = () => {
    this.setState({
      isCommuteEdit: false
    })
  }

  editEmployment = () => {
    this.setState({
      isEmploymentEdit: true
    })
  }

  saveEmployment = () => {
    const apiCustom = 'api/catstone/candidates/updateCustomField'

    const firstYearPayload = {
      id: this.props.user.catsone_id,
      custom_id: 341945,
      data: {
        value: Number(this.state.first_year)
      }
    }

    const legalPayload = {
      id: this.props.user.catsone_id,
      custom_id: 343022,
      data: {
        value: this.state.legal
      }
    }

    axios.post(apiCustom, legalPayload);

    axios.post(apiCustom, firstYearPayload)
      .then((res) => {
        console.log(res);
        this.setState({
          isEmploymentEdit: false
        })
      })


  }

  cancelEmployment = () => {
    this.setState({
      isEmploymentEdit: false
    })
  }

  editCompensation = () => {
    this.setState({
      isCompensationEdit: true
    })
  }

  saveCompensation = () => {
    const apiCustom = 'api/catstone/candidates/updateCustomField'

    const desiredTotal = {
      id: this.props.user.catsone_id,
      custom_id: 343079,
      data: {
        value: Number(this.state.desired_total)
      }
    }

    const desiredBonus = {
      id: this.props.user.catsone_id,
      custom_id: 343082,
      data: {
        value: Number(this.state.desired_bonus)
      }
    }

    axios.post(apiCustom, desiredBonus);

    axios.post(apiCustom, desiredTotal)
      .then((res) => {
        console.log(res);
        this.setState({
          isCompensationEdit: false
        })
      })
  }

  saveWork = () => {
    const apiCustom = 'api/catstone/candidates/updateCustomField'

    const typePayload = {
      id: this.props.user.catsone_id,
      custom_id: 343031,
      data: {
        value: this.state.type
      }
    }

    const officePayload = {
      id: this.props.user.catsone_id,
      custom_id: 343034,
      data: {
        value: this.state.office
      }
    }

    const balancePayload = {
      id: this.props.user.catsone_id,
      custom_id: 343037,
      data: {
        value: this.state.balance
      }
    }

    axios.post(apiCustom, officePayload)

    axios.post(apiCustom, balancePayload)
      .then((res) => {
        console.log(res)
      })

    axios.post(apiCustom, typePayload)
      .then((res) => {
        console.log(res);
        this.setState({
          isWorkEdit: false
        })
      })
  }

  cancelCompensation = () => {
    this.setState({
      isCompensationEdit: false
    })
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handleCellChange = (e) => {
    e.target.value = e.target.value.replace(phoneRegex, "$1-$2-$3");

      this.setState({
          phone: e.target.value,
      })

      if(e.target.value != ''){
        if(phoneRegex.test(e.target.value)){
          this.setState({
            isCellValid: true
          })
        }else{
          this.setState({
            isCellValid:false
          })
        }
      }else{
        this.setState({
          isCellValid: true
        })
      }
  }

  handlePostalChange = (e) => {
    if(e.target.value != ''){
      if(postalRegex.test(e.target.value)){
        this.setState({
          postal_code: e.target.value,
          isPostalValid: true
        })
      }else{
        this.setState({
          postal_code: e.target.value,
          isPostalValid: false
        })
      }
    }else{
      this.setState({
        isPostalValid: true,
        postal_code: e.target.value,
      })
    }
    
  }

  editResume = () => {
    this.setState({
      isResumeEdit: true
    })
  }

  cancelResume = () => {
    this.setState({
      isResumeEdit: false
    })
  }

  openResumeModal = () => {
    this.setState({
      isResumeModal: true
    })
  }

  hideResumeModal = () => {
    this.setState({
      isResumeModal: false,
      is_uploading: false,
      is_upload_completed: null,
      file: null
    })
  }

  handleTypeOnChange = (value) => {
    if (value > 0 && value <= 25) {
      this.setState({ type: 25 })
    } else if (value > 25 && value <= 50) {
      this.setState({
        type: 50
      })
    } else if (value > 50 && value <= 75) {
      this.setState({
        type: 75
      })
    } else if (value > 75 && value <= 100) {
      this.setState(
        {
          type: 100
        }
      )
    } else {
      this.setState(
        {
          type: 0
        }
      )
    }
  }

  handleOfficeOnChange = (value) => {
    if (value > 0 && value <= 25) {
      this.setState({ office: 25 })
    } else if (value > 25 && value <= 50) {
      this.setState({
        office: 50
      })
    } else if (value > 50 && value <= 75) {
      this.setState({
        office: 75
      })
    } else if (value > 75 && value <= 100) {
      this.setState(
        {
          office: 100
        }
      )
    } else {
      this.setState(
        {
          office: 0
        }
      )
    }
  }

  handleBalanceOnChange = (value) => {
    if (value > 0 && value <= 25) {
      this.setState({ balance: 25 })
    } else if (value > 25 && value <= 50) {
      this.setState({
        balance: 50
      })
    } else if (value > 50 && value <= 75) {
      this.setState({
        balance: 75
      })
    } else if (value > 75 && value <= 100) {
      this.setState(
        {
          balance: 100
        }
      )
    } else {
      this.setState(
        {
          balance: 0
        }
      )
    }
  }

  // readFileAsync = (file) => {
  //   return new Promise((resolve, reject) => {
  //     let reader = new FileReader();
  
  //     reader.onload = () => {
  //       resolve(reader.result);
  //     };
  
  //     reader.onerror = reject;
  
  //     reader.readAsArrayBuffer(file);
  //   })
  // }
  
  // readURL = async (filedta) => {
  //   try {
  //     const file = filedta; // this is where your file data is
  //     console.log(file.name);
  //     let contentBuffer = await this.readFileAsync(file);
  //     return  contentBuffer; // Length in ArrayBuffer
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  uploadResume = () => {
    this.setState({
      is_uploading: true
    })
    const apiCandidate = 'api/catstone/candidates/uploadResumeByCandidate';

    var fr = new FileReader();
    fr.onload = () => {
      var data = fr.result;

      const payload = {
        id: this.props.user.catsone_id,
        filename: this.state.file[0].name,
        filedata: data
      }
  
      console.log(payload)
  
      axios.post(apiCandidate, payload)
        .then((res) => {
          console.log(res)
          if(res.data.status != 'error'){
            this.setState({
              is_upload_completed: true
            });
            const apiCustom = 'api/catstone/candidates/updateCustomField'
    
            const datePayload = {
              id: this.props.user.catsone_id,
              custom_id: 343040,
              data: {
                value: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1)
              }
            }
        
            axios.post(apiCustom, datePayload)
            .then((res) => {
              this.componentDidMount();
              this.setState({
                isResumeEdit: false
              })
            })
          }
        })
  };

  fr.readAsDataURL(this.state.file[0]);

  }

  // downloadResume(id) {
  //   const apiDownlod = 'api/catstone/attachments/download'
  //   const payload = {
  //     id: id
  //   }

  //   axios.post(apiDownlod, payload)
  //     .then((res) => {
  //       console.log(res)
  //     })
  // }

  savePersonal = () => {
    var newData = this.state.candidate;


    // if (this.state.candidate.phones['home'] == null || this.state.candidate.phones['home'] == undefined) {
    //   // newData.emails['primary'] = this.state.email;
    //   newData.phones['home'] = this.state.phone;
    //   newData.address['postal_code'] = this.state.postal_code;

    //   const apiCandidate = 'api/catstone/candidates/updateCandidateById'

    //   const payload = {
    //     id: this.props.user.catsone_id,
    //     candidate: newData
    //   }

    //   axios.post(apiCandidate, payload)
    //     .then((res) => {
    //       this.setState({
    //         isPersonalEdit: false
    //       })
    //     })

    // } else {
      newData.address['postal_code'] = this.state.postal_code;

      const apiCandidate = 'api/catstone/candidates/updateCandidateById'

      const payload = {
        id: this.props.user.catsone_id,
        candidate: newData
      }

      axios.post(apiCandidate, payload)
        .then((res) => {
          const apiEmail = 'api/catstone/candidates/createPrimaryEmail'

    const emailPayload = {
      id: this.props.user.catsone_id,
      email: this.state.email,
      is_primary: true
    }

    axios.post(apiEmail, emailPayload)
      .then((res) => {
        console.log(res)
        this.setState({
          isPersonalEdit: false
        })
      })
        })

        const apiCustom = 'api/catstone/candidates/updateCustomField'

        const phonePayload = {
          id: this.props.user.catsone_id,
          custom_id: 343262,
          data: {
            value: Number(this.state.phone.replace(/-/g, ""))
          }
        }
    
        axios.post(apiCustom, phonePayload);

      // const apiPhones = 'api/catstone/candidates/listPhones';
      // const phonesPayload = {
      //   id: this.props.user.catsone_id,
      // }

      // axios.post(apiPhones, phonesPayload)
      //   .then((res) => {
      //     console.log(res)
      //     for (let i = 0; i < res.data._embedded.phones.length; i++) {
      //       if (res.data._embedded.phones[i].type == 'home') {
      //         const apiUpdate = 'api/catstone/candidates/updatePhone';
      //         const updatePhonePayload = {
      //           id: this.props.user.catsone_id,
      //           phone_id: res.data._embedded.phones[i].id,
      //           phones: {
      //             number: this.state.phone,
      //             type: 'home'
      //           }
      //         }

      //         axios.post(apiUpdate, updatePhonePayload)
      //           .then((res) => {
      //             console.log(res)
      //           })
      //       }
      //     }
      //   })


  }

  handleFirstYear = (e) => {
    this.setState({
      first_year: e.target.value
    })
  }

  handleLegal(value) {
    if (value == 'yes') {
      this.setState({
        legal: false
      })
    } else if (value == 'no') {
      this.setState({
        legal: true
      })
    }
  }

  handleDisiredTotal = (e) => {
    this.setState({
      desired_total: e.target.value
    })
  }

  handleDesiredBonus = (e) => {
    if(Number(e.target.value) >= 50){
      this.setState({
        desired_bonus: 50
      })
    }else{
      this.setState({
        desired_bonus: e.target.value
      })
    }
  }

  handleVehicle(value) {
    if (value == 'yes') {
      this.setState({
        vehicle: false
      })
    } else if (value == 'no') {
      this.setState({
        vehicle: true
      })
    }
  }

  editWork = () => {
    this.setState({
      isWorkEdit: true
    })
  }

  cancelWork = () => {
    this.setState({
      isWorkEdit: false
    })
  }

  openWorkHistory(type) {

    if(type=='current'){
      this.setState({
        is_current: true,
      }, () => {
        console.log(this.state.is_current)
        this.setState({
          isWorkHistory: true
        })
      })
    }else{
      this.setState({
        is_current: false
      }, () => {
        this.setState({
          isWorkHistory: true
        })
      })
    }

    // this.setState({
    //   isWorkHistory: true
    // })
  }

  hideWorkHistoryModal = () => {
    this.setState({
      isWorkHistory: false,
      is_current: false,
      title: '',
      employer: '',
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      isWorkValid: false,
      startMonthValid: false,
      startYearValid: false,
      endMonthValid: false,
      endYearValid: false,
      endError: ''
    })
  }

  toggleCurrentCheck = () => {
    this.setState({
      is_current: !this.state.is_current
    })
  }

  checkWorkValid(){
    if(this.state.is_current){
      if(this.state.title != '' && this.state.employer != '' && this.state.startMonthValid && this.state.startYearValid){
        this.setState({
          isWorkValid: true
        })
      }else{
        this.setState({
          isWorkValid: false
        })
      }
    }else{
      if(this.state.title != '' && this.state.employer != ''){
        if(this.state.startMonthValid && this.state.startYearValid && this.state.endMonthValid && this.state.endYearValid){
          if(Number(this.state.endYear) < Number(this.state.startYear)){
            this.setState({
              endError: 'End date must be after start date',
              isWorkValid: false
            })
          }else if(Number(this.state.endYear) === Number(this.state.startYear)){
            console.log('hi')
            if(Number(this.state.endMonth) < Number(this.state.startMonth)){
              console.log(this.state.endMonth);
              console.log(this.state.startMonth);
              this.setState({
                endError: 'End date must be after start date',
                isWorkValid: false
              })
            }else{
              this.setState({
                endError: '',
                isWorkValid: true
              })
            }
          }else{
            this.setState({
              endError: '',
              isWorkValid: true
            })
          }
        }else{
          this.setState({
            isWorkValid: false
          })
        }
      }else{
        if(this.state.startMonthValid && this.state.startYearValid && this.state.endMonthValid && this.state.endYearValid){
          if(Number(this.state.endYear) < Number(this.state.startYear)){
            this.setState({
              endError: 'End date must be after start date',
              isWorkValid: false
            })
          }else if(Number(this.state.endYear) == Number(this.state.startYear)){
            if(Number(this.state.endMonth) < Number(this.state.startMonth)){
              this.setState({
                endError: 'End date must be after start date',
                isWorkValid: false
              })
            }else{
              this.setState({
                endError: '',
                isWorkValid: false
              })
            }
          }else{
            this.setState({
              endError: '',
              isWorkValid: false
            })
          }
        }else{
          this.setState({
            isWorkValid: false
          })
        }
      }
      
    }
  } 

  handleTitle = (e) => {
    this.setState({
      title: e.target.value
    }, () => {
      this.checkWorkValid();
    })
  }

  handleEmployer = (e) => {
    this.setState({
      employer: e.target.value
    }, () => {
      this.checkWorkValid();
    })
  }

  handleStartMonth = (e) => {
    if(e.target.value.toString().length <= 2){
      if(e.target.value >= 1 && e.target.value <= 12){
        if(e.target.value > 1 && e.target.value < 10){
          this.setState({
            startMonth: 0 + e.target.value,
            startMonthValid: true
          }, () => {
            this.checkWorkValid();
            
          })
        }else{
          this.setState({
            startMonth: e.target.value,
            startMonthValid: true
          }, () => {
            this.checkWorkValid();
            
          })
        }
       
  
      }else{
        this.setState({
          startMonthValid: false,
          startMonth: e.target.value,
        })
      }
    }else{
      this.setState({
        startMonthValid: false
      })
    }
  }

  handleStartYear = (e) => {
    if(e.target.value.toString().length <= 4){
      if(e.target.value >= 1950 && e.target.value.toString().length == 4 && e.target.value <= new Date().getFullYear()){
        this.setState({
          startYear: e.target.value,
          startYearValid: true
        }, () => {
          this.checkWorkValid();
        })
  
      }else{
        this.setState({
          startYearValid: false,
          startYear: e.target.value,
        })
      }
    }else{
      this.setState({
        startYearValid: false
      })
    }
    
  }

  handleEndMonth = (e) => {
    if(e.target.value.toString().length <= 2){
      if(e.target.value >= 1 && e.target.value <= 12){
        if(e.target.value > 1 && e.target.value < 10){
          this.setState({
            endMonth: 0 + e.target.value,
            endMonthValid: true
          }, () => {
            this.checkWorkValid();      
          })
        }else{
          this.setState({
            endMonth: e.target.value,
            endMonthValid: true
          }, () => {
            this.checkWorkValid();      
          })
        }
     
  
      }else{
        this.setState({
          endMonthValid: false,
          endMonth: e.target.value,
        })
      }
    }else{
      this.setState({
        endMonthValid: false
      })
    }
  }

  handleEndYear = (e) => {
    if(e.target.value.toString().length <= 4){
      if(e.target.value >= 1950 && e.target.value.toString().length == 4 && e.target.value <= new Date().getFullYear()){
        this.setState({
          endYear: e.target.value,
          endYearValid: true
        }, () => {
          this.checkWorkValid();
        })
  
      }else{
        this.setState({
          endYearValid: false,
          endYear: e.target.value,
        })
      }
    }else{
      this.setState({
        endYearValid: false
      })
    }
  }

  saveWorkHistory = () => {
    var work;
    if(this.state.is_current){
      work = {
        title: this.state.title,
        employer: {
          linked: false,
          name: this.state.employer
        },
        is_current: "true",
        start_date: new Date(Number(this.state.startYear), Number(this.state.startMonth)-1)
      }
    }else{
      work = {
        title: this.state.title,
        employer: {
          linked: false,
          name: this.state.employer
        },
        is_current: "false",
        start_date: new Date(Number(this.state.startYear), Number(this.state.startMonth)-1),
        end_date: new Date(Number(this.state.endYear), Number(this.state.endMonth)-1)
      }
    }

    const apiWork = 'api/catstone/candidates/addWorkHistory';

    const workPayload = {
      id: this.props.user.catsone_id,
      work: work
    }

    axios.post(apiWork, workPayload)
      .then((res) => {
        console.log(res);
        this.setState({
          isWorkHistory: false,
          isEmploymentEdit: false,
          title: '',
          employer: '',
          is_current: false,
          startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      isWorkValid: false,
      startMonthValid: false,
      startYearValid: false,
      endMonthValid: false,
      endYearValid: false,
      endError: ''
        })

        const payload = {
          id: this.props.user.catsone_id
        }

        const apiWorkHistory = 'api/catstone/candidates/listWorkHistory';
    axios.post(apiWorkHistory, payload)
      .then((res) => {
        console.log(res);
        if (res.data.count == 0) {
          this.setState({
            current: 'Not set',
            previous: 'Not set'
          })
        } else {
          var current = '';
          var previous = '';
          for (let i = 0; i < res.data._embedded.work_history.length; i++) {
            if (res.data._embedded.work_history[i].is_current) {
              current += res.data._embedded.work_history[i].title + ', ';
              this.setState({
                current: current
              })
            } else {
              previous += res.data._embedded.work_history[i].title + ', ';
              this.setState({
                previous: previous
              })
            }
          }
        }
      })
      })
    
  }

  handleDesiredTotallue = () => {
    if(this.state.desired_total < 30000){
      this.setState({
        desired_total: 30000
      })
    }
  }

  hidePriority = () => {
    this.setState({
      isPriorityEdit: false
    })
  }

  onFileChange = (e) => {
    this.setState({
      avatarFile: e.target.files[0],
      isAvatarEdit: true,
      avatarPreview: URL.createObjectURL(e.target.files[0])
    }, () => {
      console.log(this.state.avatarFile)
    })
  }

  editAvatar = () => {
    this.setState({
      isAvatarEdit: true
    })
  }

  cancelAvatarEdit = () => {
    this.setState({
      isAvatarEdit: false,
      avatarFile: null,
      avatarPreview: ''
    })
  }

  uploadAvatar = () => {
    const apiAvatar = 'api/catstone/candidates/uploadAttachmentByCandidate'

    // const formData = new FormData();
    // formData.append('selectedFile', new Blob([this.state.avatarFile], { type: 'image/jpg, image/png' }));

    var fr = new FileReader();
    fr.onload = () => {
      var data = fr.result;
      const compressedData = LZString.compressToUTF16(data);
      const payload = {
        id: this.props.user.catsone_id,
        filename: this.state.avatarFile.name,
        filedata: compressedData
      }
  
      console.log(payload)
  
      axios.post(apiAvatar, payload)
        .then((res) => {
          console.log(res)
  
          const CandidatePayload = {
            id: this.props.user.catsone_id,
          }
  
          const apiAttachments = 'api/catstone/candidates/getAttachmentsById'
      axios.post(apiAttachments, CandidatePayload)
        .then((result) => {
          console.log(result)
          if (result.data.total > 0) {
            var avatarId;
            for (var i = 0; i < result.data._embedded.attachments.length; i++) {
              if (result.data._embedded.attachments[i].filename == this.state.avatarFile.name) {
                avatarId = result.data._embedded.attachments[i].id;
                const apiThumbnail = 'api/catstone/candidates/changeThumbnail'
  
          const thumbanilPayload = {
            id: this.props.user.catsone_id,
            source: 'attachment',
            attachment_id: avatarId
          }

          const newThumbnailUrl = result.data._embedded.attachments[i].url;
      
          axios.post(apiThumbnail, thumbanilPayload)
          .then((res) => {
            console.log(res)
            // this.componentDidMount();
            this.setState({
              isAvatarEdit: false
            }, ()=> {
    //           const apiCandidate = 'api/catstone/candidates/getCandidateById'

    // const payload = {
    //   id: this.props.user.catsone_id
    // }

    // axios.post(apiCandidate, payload)
    //   .then((result) => {
    //     let user = this.props.user;
    //     console.log(result)
    //     console.log(result.data._embedded.thumbnail[0].url);
    //     // const imgUrl = result.data._embedded.thumbnail[0].url;
    //     // const cacheBustedImageUrl = `${imgUrl}?t=${(Date.now())}`;
    //     // user.thumbnail = cacheBustedImageUrl;
    //     // this.props.dispatch(ProfileActionCreators.updateUser(user));
    //   })
    // const currentUrl = window.location.href;
    // const hardfreshUrl = `${currentUrl}?t=${Date.now()}`;
    // window.location.href = hardfreshUrl;
    // window.location.reload(true);
             
            })
          })
                break;
              }
            }
          }
        })
        })
    }

    fr.readAsDataURL(this.state.avatarFile);
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
            {this.state.thumbnail != null && (
                <div className='top-left top-left-can'>
                <div className='wel-title'>Welcome back, {this.state.candidate.first_name}</div>
                <div className='row'>
                  {/* <div className='avatar-wrap'>
                    <img src={this.state.thumbnail} alt='' className='avatar' />
                  </div> */}
                  <div className='avatar-wrap-round'>
                    {/* {(this.state.avatarPreview != '' && this.state.avatarPreview != null && this.state.avatarPreview != undefined) ? (
                      <img src={this.state.avatarPreview} alt='' className='avatar-rount' />
                    ) : (
                      <img src={this.state.thumbnail} alt='' className='avatar-rount' />
                    )} */}
                     <img src={profileAvatar} alt='' className='avatar-rount' />
                  
                  </div>
                  {/* <label htmlFor='select-img'><img src={edit} alt='' className='avatar-edit-icon' /></label> */}
                  <input type="file" onChange={this.onFileChange} id='select-img' accept="image/*" className='select-img-input'/>
                  <div className='top-name-wrap'>
                    <div className='top-name'>{this.state.candidate.first_name}</div>
                    <div className='top-name'>{this.state.candidate.last_name}</div>
                    {this.state.candidate.social_media_urls != undefined && (
                      this.state.candidate.social_media_urls.length == 0 ? (
                        <div className='top-name'>LinkedIn Not Set</div>
                      ) : (
                        <div className='top-name'><a className='link' href={this.state.candidate.social_media_urls[0]} target="_blank">{this.state.candidate.social_media_urls[this.state.candidate.social_media_urls.length - 1].split('https://www.')[1]}</a></div>
                      )
                    )}

                  </div>
                </div>
                {this.state.isAvatarEdit && (
                  <div className='row mt-16'>
                  <button className='btn-cancel btn-edit-avatar' onClick={this.uploadAvatar} >Change</button>
                  <button className='btn-cancel mr-0 btn-edit-avatar' onClick={this.cancelAvatarEdit}>Cancel</button>
                </div>
                )}
              </div>
            )}
              <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={`/thumbnail/${this.props.user.thumbnail}`} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            <div className='bottom-sec'>
              <div className='card-wrap'>
                {this.state.isPersonalEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Personal Information</div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Email</div>
                      <input className='p-input' value={this.state.email} placeholder="example@example.com" onChange={this.handleEmailChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Phone number</div>
                      <input className='p-input'type="text"  value={this.state.phone} placeholder="Enter your cellphone number" onChange={this.handleCellChange} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Postal code</div>
                      <input className='p-input' value={this.state.postal_code} placeholder="Enter your postal code" onChange={this.handlePostalChange} />
                    </div>
                    <div className='row mt-16 float-right'>
                      <button className='btn-save' onClick={this.savePersonal} disabled={!this.state.isCellValid || !this.state.isPostalValid}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.cancelPersonal}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Personal Information</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editPersonal} />
                      </div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Email</div>
                      {(this.state.email != null && this.state.email != undefined) ? (
                        <div className='info-txt'>{this.state.email} </div>
                      ) : (
                        <div className='info-txt'>Not set </div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Phone number</div>
                      {(this.state.phone != null && this.state.phone != undefined) ? (
                        <div className='info-txt'>{this.state.phone} </div>
                      ) : (
                        <div className='info-txt'>Not set </div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Postal code</div>
                      {(this.state.postal_code != null && this.state.postal_code != '') ? (
                        <div className='info-txt'>{this.state.postal_code}</div>
                      ) : (
                        <div className='info-txt'>Not Set</div>
                      )}

                      <div className='info-divider'></div>
                    </div>
                  </div>
                )}
                {this.state.isEmploymentEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Employment Information</div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>First year of professional employment</div>
                      <input className='p-input' type="number" value={this.state.first_year} onChange={this.handleFirstYear} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Current position</div>
                      {/* <input className='p-input' value="Position 1, Position 2, Position 3,"/> */}
                      <a className='box-link' onClick={()=>this.openWorkHistory('current')}> Add current position</a>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Previous position</div>
                      <a className='box-link' onClick={()=>this.openWorkHistory('previous')}> Add previous position</a>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Are you legally eligible to work in Canada without a work permit?</div>
                      <div className='row'>
                        <div className="radio">
                          <label>
                            <input type="radio" value="Yes" checked={!this.state.legal ? true : false} onChange={() => this.handleLegal('yes')} />
                            <span className='info-txt ml-8'>Yes</span>
                          </label>
                        </div>
                        <div className="radio ml-64">
                          <label>
                            <input type="radio" value="No" checked={this.state.legal ? true : false} onChange={() => this.handleLegal('no')} />
                            <span className='info-txt ml-8'>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-16 float-right'>
                      <button className='btn-save' onClick={this.saveEmployment}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.cancelEmployment}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Employment Information</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editEmployment} />
                      </div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>First year of professional employment</div>
                      {this.state.first_year != null ? (
                        <div className='info-txt'>{this.state.first_year}</div>
                      ) : (
                        <div className='info-txt'>Not set</div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Current position(s)</div>
                      {this.state.current == null ? (
                        <div className='info-txt'>Not set</div>
                      ) : (
                        <div className='info-txt'>{this.state.current}</div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Previous position(s)</div>
                      {this.state.previous == null ? (
                        <div className='info-txt'>Not set</div>
                      ) : (
                        <div className='info-txt'>{this.state.previous}</div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Are you legally eligible to work in Canada without a work permit?</div>
                      <div className='row'>
                        <div className="radio">
                          <label>
                            <input type="radio" value="Yes" checked={!this.state.legal ? true : false} disabled />
                            <span className='info-txt ml-8'>Yes</span>
                          </label>
                        </div>
                        <div className="radio ml-64">
                          <label>
                            <input type="radio" value="No" checked={this.state.legal ? true : false} disabled />
                            <span className='info-txt ml-8'>No</span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                )}
                {this.state.isCompensationEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Compensation</div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Desired Compensation Total</div>
                      <input className='p-input' type="number" value={this.state.desired_total} onChange={this.handleDisiredTotal} min="30000" onBlur={this.handleDesiredTotallue}/>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Desired % of Total Compensation as a Bonus</div>
                      <input className='p-input' type="number" value={this.state.desired_bonus} onChange={this.handleDesiredBonus} max="50" min="0"/>
                    </div>
                    <div className='row mt-16 float-right'>
                      <button className='btn-save' onClick={this.saveCompensation} >Save</button>
                      <button className='btn-cancel mr-0' onClick={this.cancelCompensation}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Compensation</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editCompensation} />
                      </div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Desired Compensation Total</div>
                      {this.state.desired_total != null ? (
                        <div className='info-txt'>{this.state.desired_total}</div>
                      ) : (
                        <div className='info-txt'>Not set</div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Desired % of Total Compensation as a Bonus</div>
                      {this.state.desired_bonus != null ? (
                        <div className='info-txt'>{this.state.desired_bonus}</div>
                      ) : (
                        <div className='info-txt'>Not set</div>
                      )}
                      <div className='info-divider'></div>
                    </div>
                  </div>
                )}

              </div>

              <div className='card-wrap'>
                {this.state.isCommuteEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Commute preference</div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Vehicle access</div>
                      <div className='row'>
                        <div className="radio">
                          <label>
                            <input type="radio" value="Yes" checked={!this.state.vehicle ? true : false} onChange={() => this.handleVehicle('yes')} />
                            <span className='info-txt ml-8'>Yes</span>
                          </label>
                        </div>
                        <div className="radio ml-64">
                          <label>
                            <input type="radio" value="No" checked={this.state.vehicle ? true : false} onChange={() => this.handleVehicle('no')} />
                            <span className='info-txt ml-8'>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-16 float-right'>
                      <button className='btn-save' onClick={this.saveCommute}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.cancelCommute}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Commute preference</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editCommute} />
                      </div>
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Vehicle access</div>
                      <div className='row'>
                        <div className="radio">
                          <label>
                            <input type="radio" value="Yes" checked={!this.state.vehicle ? true : false} disabled />
                            <span className='info-txt ml-8'>Yes</span>
                          </label>
                        </div>
                        <div className="radio ml-64">
                          <label>
                            <input type="radio" value="No" checked={this.state.vehicle ? true : false} disabled />
                            <span className='info-txt ml-8'>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.isWorkEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Work environment</div>
                      {/* <div className='edit-wrap'>
                      <img src={edit} alt='' className='edit-icon' onClick={this.editWork}/>
                    </div> */}
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Type</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.type}
                        onChange={(value) => this.handleTypeOnChange(value)}
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>Start-up</div>
                        <div className='info-label ml-5'>Corporate</div>
                      </div>
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Office</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.office}
                        onChange={(value) => this.handleOfficeOnChange(value)}
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>In office</div>
                        <div className='info-label ml-5'>Remote</div>
                      </div>
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Balance</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.balance}
                        onChange={(value) => this.handleBalanceOnChange(value)}
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>Stable</div>
                        <div className='info-label ml-5'>Dynamic</div>

                      </div>
                    </div>
                    <div className='row mt-16 float-right'>
                      <button className='btn-save' onClick={this.saveWork}>Save</button>
                      <button className='btn-cancel mr-0' onClick={this.cancelWork}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Work environment</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editWork} />
                      </div>
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Type</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb1"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.type}
                        onChange={(value) => this.handleTypeOnChange(value)}
                        disabled
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>Start-up</div>
                        <div className='info-label ml-5'>Corporate</div>
                      </div>
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Office</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb1"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.office}
                        onChange={(value) => this.handleOfficeOnChange(value)}
                        disabled
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>In office</div>
                        <div className='info-label ml-5'>Remote</div>
                      </div>
                    </div>
                    <div className='info-group mb-24'>
                      <div className='info-label mb-20'>Balance</div>
                      <ReactSlider
                        className="customSlider"
                        trackClassName="customSlider-track"
                        thumbClassName="customSlider-thumb1"
                        markClassName="customSlider-mark"
                        marks={25}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={this.state.balance}
                        onChange={(value) => this.handleBalanceOnChange(value)}
                        disabled
                      />
                      <div className='mark-hint'>
                        <div className='mark-hint-txt w-4'>0</div>
                        <div className='mark-hint-txt w-4'>25</div>
                        <div className='mark-hint-txt w-4'>50</div>
                        <div className='mark-hint-txt w-4'>75</div>
                        <div className='mark-hint-txt'>100</div>
                      </div>
                      <div className='row mt-1'>
                        <div className='info-label w-4'>Stable</div>
                        <div className='info-label ml-5'>Dynamic</div>
                      </div>
                    </div>
                  </div>
                )}


                {this.state.isResumeEdit ? (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Resume</div>
                    </div>
                    {/* {this.state.resume.length > 0 ? (
     Object.keys(this.state.resume).map((key) => (
      <div className='resume-wrap'>
    <div className='info-txt'>{this.state.resume[key].filename}</div>
    <a className='dl-link'>Remove</a>
    <a className='dl-link'>Upload</a>
  </div>
     ))
    
  ): (
   <div className='resume-wrap'>
   <div className='row mt-16'>
                  <button className='btn-save' onClick={this.openResumeModal}>Upload</button>
                  <button className='btn-cancel' onClick={this.cancelResume}>Cancel</button>
                 </div>
 </div>
  )} */}
                    <div className='resume-wrap'>
                      <div className='row mt-16'>
                        <button className='btn-save' onClick={this.openResumeModal}>Upload</button>
                        <button className='btn-cancel mr-0' onClick={this.cancelResume}>Cancel</button>
                      </div>
                    </div>

                  </div>
                ) : (
                  <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Resume</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editResume} />
                      </div>
                    </div>
                    {this.state.resume_date != null ? (
                      //                   Object.keys(this.state.resume).map((key) => (
                      // <div className='resume-wrap'>
                      //                    <div className='info-txt'>{this.state.resume[key].filename}</div>
                      //                    <a className='dl-link cursor' onClick={()=>this.downloadResume(this.state.resume[key].id)}>Download</a>
                      //                  </div>
                      //                   ))
                      <div className='resume-wrap'>
                        <div className='info-txt'>Resume uploaded at {this.state.resume_date}</div>
                        {/* <a className='dl-link cursor' onClick={()=>this.downloadResume(this.state.resume[key].id)}>Download</a> */}
                      </div>
                    ) : (
                      <div className='resume-wrap'>
                        <div className='info-txt'>No resume uploaded</div>
                        {/* <a className='dl-link'>Upload</a> */}
                      </div>
                    )}

                  </div>
                )}

                   <div className='card'>
                    <div className='card-head'>
                      <div className='card-title'>Priority Ranking</div>
                      <div className='edit-wrap'>
                        <img src={edit} alt='' className='edit-icon' onClick={this.editPriority} />
                      </div>
                    </div>
                    {this.state.priority != null && this.state.priority != '' ? (
                      <div className='resume-wrap d-column'>
                         {/* {Object.keys(this.state.priority.split(', ')).map((key) => (
                          <div className='info-txt'>{key + 1}. {this.state.priority[key]}</div>
                         ))} */}
                         <div className='info-txt'>1. {this.state.priority.split(', ')[0]}</div>
                         <div className='info-txt'>2. {this.state.priority.split(', ')[1]}</div>
                         <div className='info-txt'>3. {this.state.priority.split(', ')[2]}</div>
                         <div className='info-txt'>4. {this.state.priority.split(', ')[3]}</div>
                         <div className='info-txt'>5. {this.state.priority.split(', ')[4]}</div>
                      </div>
                    ) : (
                      <div className='resume-wrap'>
                        <div className='info-txt'>Not set</div>
                      </div>
                    )}

                  </div>

              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.isResumeModal} handleClose={this.hideResumeModal}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Upload Resume</div>
            </div>
            <Dropzone onDrop={files => this.setState({ file: files })}>
              {({ getRootProps, getInputProps }) => (
                <div className="container">
                  <div
                    {...getRootProps({
                      className: 'dropzone',
                      onDrop: event => event.stopPropagation()
                    })}
                  >
                    <input {...getInputProps()} />
                    {(this.state.file != null && this.state.file.length >0) ? (
              <div>
              <img alt='' src={file} className="upload-icon" />
                 <p className='mb-32'>{this.state.file[this.state.file.length - 1].name}</p>
           </div>
            ) : (
              <div>
                 <img alt='' src={upload} className="upload-icon" />
                    <p className='mb-32 upload-hint'>Click here to choose a file from computer</p>
              </div>
            )}
                   
                  </div>
                </div>
              )}
            </Dropzone>
            
            <div className='modal-btn-wrap'>
              <button className='btn-save modal-btn' type="button" onClick={this.uploadResume} disabled={(this.state.file!=null && this.state.file.length>0)? false: true}>Upload</button>
            </div>
           {(this.state.file != null && this.state.file.length >0 && this.state.is_uploading) && (
             <div className='files-wrap'>
             <div className='file-wrap row'>
               <div className='s-icon-wrap'>
               <img src={file} className="s-icon" alt=''/>
               </div>
               <div className='file-txt-wrap'>
                 <div className='file-title-wrap row'>
                   <div className='file-txt'>{this.state.file[0].name}</div>
                   <div className='file-txt txt-right'>{humanFileSize(this.state.file[0].size)}</div>
                 </div>
                 <div className='progress-wrap'>
                   <ProgressBar completed={100} maxCompleted={100} className="progress-wrap" barContainerClassName='file-upload-progress' 
 labelClassName="bar-label" height={6} animateOnRender={true} bgColor="#3817c9" initCompletedOnAnimation={0}/>
                 </div>
               </div>
             </div>
           </div>
           )}
           {this.state.is_upload_completed==true&& (
            <div className='success-txt'>Your resume has been uploaded successfully.</div>
           )}
          </div>
        </Modal>
        <Modal show={this.state.isWorkHistory} handleClose={this.hideWorkHistoryModal}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Add Work History</div>
            </div>
            <div className='info-group'>
                      <div className='info-label'>Title*</div>
                      <input className='p-input' value={this.state.title} onChange={this.handleTitle} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Employer*</div>
                      <input className='p-input' value={this.state.employer} onChange={this.handleEmployer} />
                    </div>
                    <div className='info-group'>
                      <div className='info-label'>Start/End Dates*</div>
                      <div className='date-input-row'>
                      <input className='date-input month-input' placeholder='MM' type="number" required min={1} maxLength="2" max={(this.state.startYear<new Date().getFullYear() || this.state.startYear==null)?12:new Date().getMonth()+1} value={this.state.startMonth==null?'':this.state.startMonth} onChange={this.handleStartMonth} />
                      <input className='date-input year-input' placeholder='YYYY' type="number" min={1950} maxLength="4" max={new Date().getFullYear()} required value={this.state.startYear==null?'':this.state.startYear} onChange={this.handleStartYear} />
                      <div className='date-divider'></div>
                      <input className='date-input month-input' placeholder='MM' type="number" required min={1} maxLength="2" max={(this.state.endYear<new Date().getFullYear() || this.state.endYear==null)?12:new Date().getMonth()+1} value={this.state.endMonth==null?'':this.state.endMonth} onChange={this.handleEndMonth} disabled={this.state.is_current}/>
                      <input className='date-input year-input' placeholder='YYYY' type="number" min={1950} maxLength="4" max={new Date().getFullYear()} required value={this.state.endYear==null?'':this.state.endYear} onChange={this.handleEndYear} disabled={this.state.is_current}/>
                      <label className="size-1em font-regular d-flex center-check">
              <input
                type="checkbox"
                className="check-box-small"
                checked={this.state.is_current}
                onChange={this.toggleCurrentCheck}
              /><div>Current</div>
             
            </label>
                      </div>
                     {this.state.endError != '' && (
                       <div className='error-msg mt-8 font-sm'>{this.state.endError}</div>
                     )}
                    </div>
            <div className='float-right mt-32'>
            <button className='btn-save' onClick={this.saveWorkHistory} disabled={!this.state.isWorkValid}>Save</button>
                <button className='btn-cancel mr-0' onClick={this.hideWorkHistoryModal}>Cancel</button>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.isPriorityEdit} handleClose={this.hidePriority}>
          <div className="o-modal-content">
            <div className='o-modal-header'>
              <div className='o-modal-title text-center'>Rank your priorities</div>
            </div>
            <div className='priority-txt'>Drag the blocks representing priorities into the appropriate spot.</div>
<div className='priority-txt'>1 = most important</div>
<div className='priority-txt mb-24'>5 = least important</div>
            <DndProvider backend={HTML5Backend}>
					<Priority updateDust = {this.updateDust} dustbin = {this.state.dustbins}/>
				</DndProvider>
            <div className='float-right mt-32 mo-btn-center'>
            <button className='btn-save' onClick={this.savePriority} disabled={!this.state.isPriorityValid}>Save</button>
            <button className='btn-cancel mo-mr-0' onClick={this.resetPriority}>Reset</button>
                <button className='btn-cancel mr-0 mo-mt-8' onClick={this.hidePriority}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(Dashboard);