import './styles.scss';
import close from './../../assets/outhire/close-circle.svg';


const AboutModal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "overlay display-block" : "overlay display-none";

    return (
        <div className=''>
            <section className={show? "a-modal-main show2" : "a-modal-main show1"}>
                <button type="button" onClick={handleClose} className="weight-modal-close">
                    {/* <img className="w-close-icon" src={close}></img> */}
                    <img  src={close}></img> 
                </button>
                {children}
                
            </section>
            <div className={showHideClassName}></div>
        </div>
    );
};

export default AboutModal;