import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from './../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import { connect } from 'react-redux';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import profileAvatar from './../../assets/outhire/profile-avatar.png';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
     candidate: {},
     curent_pw: '',
     new_pw: '',
     confirm_pw: '',
     isDeletionModal: false,
     isModificationModal: false,
     isPwMatched: false,
     isPwModal: false,
     thumbnail: '',
     error: null
    }
  }

  componentDidMount() {
    console.log(this.props.user)
    if(this.props.user!= undefined && this.props.user != null){
      const apiCandidate = 'api/catstone/candidates/getCandidateById'

                        const payload = {
                            id: this.props.user.catsone_id
                        }

                        axios.post(apiCandidate, payload)
                            .then((result) => {
                                console.log(result)
                                this.setState({
                                  candidate: result.data,
                                  thumbnail: result.data._embedded.thumbnail[0].url
                                })
                            })
    }else{
      history.push('/signin')
    }

  }

  onCurrentChange = (e) => {
    this.setState({
      curent_pw: e.target.value,
      error: null
    })
  }

  onNewChange = (e) => {
    this.setState({
      new_pw: e.target.value
    })
  }

  onConfirmChange = (e) => {
    this.setState({
      confirm_pw: e.target.value
    }, () => {
      if(this.state.confirm_pw == this.state.new_pw){
        this.setState({
          isPwMatched: true
        })
      }else{
        this.setState({
          isPwMatched: false
        })
      }
    })
    
  }

  hideDeletionModal = () => {
    this.setState({
      isDeletionModal: false
    })
  }

  hideModificationModal = () => {
    this.setState({
      isModificationModal: false
    })
  }

  hidePwModal = () => {
    this.setState({
      isPwModal: false
    })
  }

  cancelUpdate = () => {
    this.setState({
      curent_pw: '',
      new_pw: '',
      confirm_pw: ''
    })
  }

  updatePassword = () => {
    const apiUrl = "api/v1/changePswd";

    const payload = {
      email: this.props.user.email,
      password: this.state.curent_pw,
      newPswd: this.state.new_pw,
    };

    axios.post(apiUrl, payload).then((result) => {
      if (result.data.status === "error") {
        // this.setState({ message: result.data.message });
        console.log(result)
        this.setState({
          error: result.data.message,
          curent_pw: ''
        })
      } else {
        this.setState({
          isPwModal: true,
          curent_pw: '',
      new_pw: '',
      confirm_pw: ''
        })
      }
    });
  }

  sendRequest(request){
    var email = {};
   if(request == 'deletion'){
    email = {
      "receiver": "info@outhire.ca",
      "subject": "Account Deletion Request",
      "body":  "<p>Hi Team" +
      ",</p>" +
      "<p>We've got an account deletion request from candidate portal, please view the info below: <p><br/>" +
      "<p>Candidate Name: "+this.props.user.first_name + ' ' + this.props.user.last_name+"</p>" +
      "<p>Email: "+this.props.user.email + "</p> " +
      "<p>Request: Account Deletion</p><br/> " +
      "<p>Please go to the admin portal for this action.</p><br/> " +
      "<p>Cheers.</p>",
    }
    this.setState({
      isDeletionModal: true
    })
   }else{
    email = {
      "receiver": "info@outhire.ca",
      "subject": "Account Modifiction Request",
      "body":  "<p>Hi Team" +
      ",</p>" +
      "<p>We've got an account modification request from candidate portal, please view the info below: <p><br/>" +
      "<p>Candidate Name: "+this.props.user.first_name + ' ' + this.props.user.last_name+"</p>" +
      "<p>Email: "+this.props.user.email + "</p> " +
      "<p>Request: Account Deletion</p><br/> " +
      "<p>Please go to the admin portal for this action.</p><br/> " +
      "<p>Cheers.</p>",
    }
    this.setState({
      isModificationModal: true
    })
   }
    axios.post("/api/avaros/send-email", email).then((res)=>{
      console.log(res);
    })
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Welcome back, {this.props.user.first_name}</div>
                <div className='row'>
                <div className='avatar-wrap-round'>
                  {/* <img src={this.state.thumbnail} alt='' className='avatar-rount' /> */}
                  <img src={profileAvatar} alt='' className='avatar-rount' /> 
                  </div>
                  <div className='top-name-wrap'>
                    <div className='top-name'>{this.state.candidate.first_name}</div>
                    <div className='top-name'>{this.state.candidate.last_name}</div>
                    {this.state.candidate.social_media_urls != undefined && (
                      this.state.candidate.social_media_urls.length == 0 ? (
                        <div className='top-name'>LinkedIn Not Set</div>
                      ) : (
                        <div className='top-name'><a className='link' href={this.state.candidate.social_media_urls[0]} target="_blank">{this.state.candidate.social_media_urls[0].split('https://www.')[1]}</a></div>
                      )
                    )}
                  
                  </div>
                </div>
              </div>
              <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={this.props.user.thumbnail} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            <div className='bottom-sec'>
              {(this.props.user.linkedin_id == undefined || this.props.user.linkedin_id==null || this.props.user.linkedin_id=='') && (
                <div className='card-wrap'>
                <div className='card'>
                <div className='card-head'>
                  <div className='card-title'>Manage Password</div>
                  <div className='edit-wrap'>
                    {/* <img src={edit} alt='' className='edit-icon' onClick={this.editPersonal}/> */}
                  </div>
                </div>
                <div className='info-group'>
                  <div className='info-label'>Current password</div>
                  <input className='p-input' value={this.state.curent_pw} onChange={this.onCurrentChange}/>
                  {this.state.error == '02' && (
                    <div className='error-txt w-full'>Sorry, the password you entered is wrong</div>
                  )}
                </div>
                <div className='info-group'>
                  <div className='info-label'>New password</div>
                  <input className='p-input' value={this.state.new_pw} onChange={this.onNewChange}/>
                </div>
                <div className='info-group'>
                  <div className='info-label'>Confirm new password</div>
                  <input className='p-input' value={this.state.confirm_pw} onChange={this.onConfirmChange}/>
                  {!this.state.isPwMatched && this.state.confirm_pw != '' && (
                    <div className='error-txt w-full'>New password doesn't match</div>
                  )}
                </div>
                <div className='row mt-16 float-right'>
                <button className='btn-save' onClick={this.updatePassword} disabled={!this.state.isPwMatched || this.state.curent_pw == ''}>Save</button>
                <button className='btn-cancel mr-0' onClick={this.cancelUpdate}>Cancel</button>
               </div>
              </div>
              
            </div>
              )}

              <div className='card-wrap'>
             
                <div className='card'>
                  <div className='card-head'>
                    <div className='card-title'>Account Management</div>
                    <div className='edit-wrap'>
                      {/* <img src={edit} alt='' className='edit-icon'/> */}
                    </div>
                  </div>
                 <div className='row am-wrap'>
                  <button className='btn-account cursor' onClick={() => this.sendRequest('deletion')}>Request account deletion</button>
                  <button className='btn-account cursor mo-account-mt' onClick={() => this.sendRequest('modification')}>Request account modification</button>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.isDeletionModal} handleClose={this.hideDeletionModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Request account deletion</div>
            </div>
             <div className='n-modal-txt'>A staff from Outhire will be in contact with you regarding your request to delete your account.
             </div>
          </div>
        </Modal>
        <Modal show={this.state.isModificationModal} handleClose={this.hideModificationModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Request modification</div>
            </div>
             <div className='n-modal-txt'>A staff from Outhire will be in contact with you regarding your request to modification your account.
             </div>
          </div>
        </Modal>
        <Modal show={this.state.isPwModal} handleClose={this.hidePwModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Password Reset!</div>
            </div>
             <div className='n-modal-txt'>Your password has been updated successfully.
             </div>
          </div>
        </Modal>
      </div>
    );
  }
}

//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(Settings);