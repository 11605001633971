import React, { Component } from 'react';
import './styles.css';
import history from './../../utilities/history';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import NavBar from '../../components/NavBar/TopNavBar';
import Footer from "../../components/Landing/Footer";
import white from './../../assets/outhire/white.svg';
import dot from './../../assets/outhire/dot.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';

class Faq extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="container">
         <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="main-body-wrap">
            <div className="first-wrap content-center coloum mo-first-wrap">
              {/* <div className="sign-diagonal"></div> */}
              <img className="white-poligon" src={white} alt=''/>
              <div className="top-section mo-mt-0">
            <div className="home-title w-full mb-75  mt-single">Frequently Asked Questions</div>
            <div className="terms-card mb-100 top-card d-column pxy">
            <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I apply for a job?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Applying to become a member of Outhire’s talent network is simple.
                    <div className="row ml-47 ml-0">
                                <img alt='' src={dot} className="bullet-dot flex-start"/>
                                <div className="faq-txt ml-0 ">Once your application is approved continue to creating a profile and upload your current resume.</div>
                            </div>
                            <div className="row ml-47 ml-0">
                                <img alt='' src={dot} className="bullet-dot flex-start"/>
                                <div className="faq-txt ml-0 ">Approved members, will see additional details for each job opening and have the option to apply.</div>
                            </div>
                            <div className="row ml-47 ml-0">
                                <img alt='' src={dot} className="bullet-dot flex-start"/>
                                <div className="faq-txt ml-0 ">Should you be qualified for the role or become qualified for different role in the future, someone from our team will reach out to you.</div>
                            </div>
                            <div className="row ml-47 ml-0">
                                <img alt='' src={dot} className="bullet-dot flex-start"/>
                                <div className="faq-txt ml-0 ">Throughout the process, you will be updated in real-time via the Status bar and receive email notifications at each stage of the process.</div>
                            </div>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What differentiates you from other agencies?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Outhire was founded with the goal of delivering a best-in-class hiring experience.
Our process is built around the needs of candidates and employers and further
elevated with intelligent technology integration. We reinvented the hiring process
by eliminating tailored resumes, doing away with application forms, giving real-
time feedback, and offering tailored insights and preparation.
                    </div><br/>
                    <div className='faq-txt'>
                    Our unique presentation, called the Outhire Visual Resume, is all about YOU
combining a custom infographic with key information from our interview plus a
video showcase of your communication and presentation skills.
                    </div><br/>
                    <div className='faq-txt'>
                    If you ever thought “If I could just get in front of the hiring manager, I would get
this job.” now you can with our simple, modern process that presents you at your
very best to employers.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why can’t I see which company I am applying to?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    We provide the name of the company once you have been screened and
selected to move forward. We work hard to build partnerships with employers to
ensure we understand their teams and objectives and communicate this to our
candidates. Our exclusive searches, where employers are conducting searches
only through us, often include a recorded introduction from the hiring manager!
That’s why we have such a detailed write-up for each position that goes well
beyond what a typical list of tasks might show. We do this in-place of sharing the
company name for two simple reasons.
                    </div><br/>
                    <div className='faq-txt'>
1. We only work on a select number of opportunities for unique organizations at any given time.
                    </div>
                    <div className='faq-txt'>
                    2. It helps our selected employers eliminate unwanted attention of other recruiters.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does your interview process work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Simply apply via email or login via LinkedIn, and should you qualify, we will
contact you for a phone screen and set the next steps from there.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why don’t you forward resumes to employers?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    We only present your Visual Resume to employers with your consent and after
establishing it is a close fit. From there you have the opportunity to set the salary
expectations we communicate.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What cities do you service?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Our digital-first approach provides an employer base that extends to the
provinces of British Columbia, Alberta, and Ontario. We are physically
headquartered in Toronto, Ontario.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What industries do you specialize in?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    We are industry-agnostic and are instead specialized in finance and accounting
roles. That being said, our recent employers are concentrated in construction,
technology, and high-growth companies across several industries.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the cost of Outhire’s services to a candidate?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    With the rise in career consultants and other niche employment services you may
be asking what we charge to our candidates? Zilch! We understand switching
jobs is stressful so we aim to offer the benefits of these services at zero charge.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are the videos I record secure?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Your videos are stored via a SOC 2 Type 2 compliant third-party apps and are
only presented as a part of your Visual Resume for opportunities where you want
to move forward.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the Outhire interview process like?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Our interview process is based on building a great understanding of you, so we
can assist you at any point in your career and best represent you to any
opportunities you are interested in.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I get a copy of my Visual Resume?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Our Visual Resumes are proprietary to our process and, as such, not shared with
candidates. You always have the opportunity to review it on request with your
recruiter prior to submission.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need to meet you in-person?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Never. We operate with a digital-first mindset and have built our process to
operate most effectively remotely while also offering candidates greater flexibility
for their schedules.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I be considered for multiple opportunities?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className='faq-txt faq-box'>
                    Yes, you can. For full transparency, we inform hiring managers when there are
other employers you are being presented to by Outhire.
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>
            </div>
            </div>
          </div>
          <Footer/>

      </div>
      </div>
    );
  }
}

export default Faq;