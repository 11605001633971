import { memo } from 'react'
import { useDrop } from 'react-dnd';
import './styles.css';
const style = {
  height: '80px',
  width: '287px',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginBottom: '1.5rem',
  color: '#000',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  border: '1px dashed #2F2E41'
}
export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = '#fff'
  if (isActive) {
    backgroundColor = '#F9EEFB'
  } else if (canDrop) {
    backgroundColor = '#f8f9fb'
  }
  return (
    <div ref={drop} style={{ ...style, backgroundColor }} data-testid="dustbin">
      {isActive
        ? 'Release to drop'
        : ''}

      {lastDroppedItem && (
        <div className={(lastDroppedItem.name == 'Variable Compensation' || lastDroppedItem.name == 'Minimal Overtime'
        || lastDroppedItem.name == 'Pre-Approved Experience'
        || lastDroppedItem.name == 'Technical Skills Growth' || lastDroppedItem.name == 'Industry Specialization'
        )? 'last-dropped smaller-txt' : lastDroppedItem.name == 'Professional Development Support' ? 'smaller-pd last-dropped' :'last-dropped'}>{lastDroppedItem.name}</div>
      )}
    </div>
  )
})
