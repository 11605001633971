import './styles.scss';
import close from './../../assets/outhire/close-circle.svg';


const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <button type="button" onClick={handleClose} className="weight-modal-close">
                    <img className="w-close-icon" src={close}></img>
                </button>
                {children}
                
            </section>
        </div>
    );
};

export default Modal;