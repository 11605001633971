import React, { useState } from 'react';
import axios from 'axios';
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import icon from '../../assets/outhire/linkedin.png';
import './styles.css';
import history from '../../utilities/history';
import { connect } from 'react-redux';
import { ProfileActionCreators } from './../../actions/candidate/profileAction';
import Modal from './../Landing/Modal';

function LinkedInLogin(props) {
  const [c, setCode] = useState("");
  const [error, setError] = useState(false);
  const onCorrect = (code) => {   
    console.log(c); 

    //Get Access Token 
    var email = '';
    
    // var tokenPayload = {
    //   code: code,
    //   grant_type: "authorization_code",
    //   redirectUri:`${window.location.origin}/linkedin`,
    //   client_id: "86r388uu3b2ds4",
    //   client_secret: "JHmv70P6YTe3gkzh"
    // }

    axios.get("/api/linkedin/accessToken?grant_type=authorization_code&code="+code+"&client_id=86r388uu3b2ds4&client_secret=JHmv70P6YTe3gkzh")
    .then((res)=> {
      console.log(res);
      var linkedinUser = res.data;
      if(linkedinUser.email != undefined && linkedinUser.email != null){
        email = linkedinUser.email.elements[0]['handle~'].emailAddress;
        console.log(email);

        const apiCheck = '/api/v1/get-user';
        axios.post(apiCheck, {email:email})
          .then((result) => {
            if(result.data != null){
              if(result.data.linkedin_id){
                if(result.data.catsone_status == 2){
                  const apiCandidate = 'api/catstone/candidates/getCandidateById'
    
                  const payload = {
                    id: result.data.catsone_id
                  }
              
                  axios.post(apiCandidate, payload)
                    .then((res) => {
                      var user = {
                          email: result.data.email,
                          user_type: result.data.user_type,
                          status: result.data.status,
                          linkedin_id: result.data.linkedin_id,
                          catsone_status: result.data.catsone_status,
                          catsone_id: result.data.catsone_id,
                          modified_by: result.data.modified_by,
                          id: result.data._id,
                          thumbnail: res.data._embedded.thumbnail[0].url,
                          first_name: result.data.first_name,
                          last_name: result.data.last_name,
                      }
                      props.dispatch(ProfileActionCreators.updateUser(user));
                      history.push('/candidate?id='+result.data._id + '&candidate_no='+result.data.catsone_id + '&linedin_id=' + result.data.linkedin_id);
                  })
                
              }else{
                  history.push('/registrationsuccess');
              }
              
              }else{
                //send error messge to sign in using email and password
                setError(true)
              }
            }else{
              if(linkedinUser.profile.id != undefined && linkedinUser.profile.id != null){
                const payload = {
                  email: email,
                  password: randomPassword(32),
                  first_name: linkedinUser.profile.localizedFirstName,
                  last_name: linkedinUser.profile.localizedLastName,
                  user_type: "candidate",
                  status: "unverified",
                  linkedin_id: linkedinUser.profile.id
                };
            
                const apiUrl = "api/v1/signup";
            
                axios.post(apiUrl, payload).then((result) => {
                  if (result.data.status === "error") {
                    this.setState({ message: result.data.message });
                  } else {
                    history.push(
                      "/registrationconfirmation?email="+payload.email
                    );
                  }
                });
              }
            }
          })
      }
    })
    
  };

  const onFailure = (res) => {
    setCode(res);
    console.log(c)
    console.log('Login failed: res:', res);
   
  };

  const onAllow = (code) => {
    console.log('hi')
    setCode(code)
  }

  const { linkedInLogin } = useLinkedIn({
    clientId: '86r388uu3b2ds4',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: onCorrect,
    onError: onFailure,
    scope: 'r_emailaddress r_liteprofile'
  });

  const signinWithLinkedin = () => {

    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86r388uu3b2ds4&scope=r_liteprofile%20r_emailaddress&redirect_uri=${window.location.origin}/auth/linkedin/callback`
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState(null);

  const handleLinkedInSignIn = async () => {
    try {
      const { data } = await axios.post("/auth/linkedin");
      setUser(data);
      console.log(user);
      setIsSignedIn(true);
    } catch (err) {
      // setError(err.response.data);
      console.log(err)
    }
  };

  
  return (
    // <LinkedIn
    //   clientId="86r388uu3b2ds4"
    //   redirectUri={`${window.location.origin}/linkedin`}
    //   onSuccess={onSuccess}
    //   onError={onFailure}
    //   scope="r_emailaddress r_liteprofile"
    // >
    //   {({ linkedInLogin }) => (
    //     // <img
    //     //   onClick={linkedInLogin}
    //     //   src={linkedin}
    //     //   alt="Sign in with Linked In"
    //     //   style={{ maxWidth: '180px', cursor: 'pointer' }}
    //     // />
    //     <button className='btn btn-light btn-regular font-18 btn-icon' onClick={linkedInLogin} type="button">
    //       <img src={icon} className="icon-btn"/><span className='icon-btn-txt'>Sign in with LinkedIn</span>
    //     </button>
    //   )}
    // </LinkedIn>
    <div>
      <button className='btn btn-light btn-regular font-18 btn-icon' onClick={linkedInLogin} type="button">
          <img src={icon} className="icon-btn"/><span className='icon-btn-txt'>Sign in with LinkedIn</span>
    </button>
    <Modal show={error} handleClose={() => setError(false)}>
              <div className="appt-modal-content">
              <div className="appt-modal-title inner-modal-title">User Exists!</div>
              <div className="appt-modal-body text-center refill-modal inner-modal-txt">We've detected this email has been used for another account, please sign in using email and password, thanks.</div>
              <div className="appt-modal-btn-wrap">
                <button className="btn btn-cancel inner-modal-btn" onClick={() => setError(false)}>Okay</button>
              </div>
            </div>
    </Modal>
    </div>
    
  );
}

function randomPassword(size){
  var seed=new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z',
   'a','b','c','d','e','f','g','h','i','j','k','m','n','p','Q','r','s','t','u','v','w','x','y','z','0','1',
   '2','3','4','5','6','7','8','9');
  var seedlength=seed.length;  
  var createPassword='';
  for(let i=0;i<size;i++){  
  var a=Math.floor(Math.random()*seedlength);  
     createPassword+=seed[a];  
  }  
    return createPassword;  
  }


//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.user
  }
}

export default connect(mapStateToProps)(LinkedInLogin);