import { memo } from 'react'
import { useDrag } from 'react-dnd'
const style = {
    border: '1px solid #3817C9',
    borderRadius: '8px',
  backgroundColor: 'white',
  padding: '8.5px 0',
  marginRight: '0.75rem',
  marginLeft: '0.75rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
  fontFamily: 'Oxygen',
fontStyle: 'normal',
fontWeight: '700',
fontSize: '14px',
lineHeight: '18px',
textAlign: 'center',
letterSpacing: '0.75px',

/* Deep Purple */

color: '#3817C9',
width: '130px',
height: 'fit-content',
}
export const Box = memo(function Box({ name, type, isDropped }) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type],
  )
  return (
    <div ref={drag} style={{ ...style, opacity }} data-testid="box" className={(name == 'Variable Compensation' || name == 'Minimal Overtime'
    || name == 'Pre-Approved Experience'
    || name == 'Technical Skills Growth' || name == 'Industry Specialization'
    )? ' smaller-txt mo-box' : name == 'Professional Development Support' ? 'smaller-pd mo-box' : 'mo-box'}>
      {isDropped ? <s>{name}</s> : name}
    </div>
  )
})