import { Types } from '../../constants/actionTypes';

export const ProfileActionCreators = {
    updateCandidate: (candidate) => ({
        type: Types.UPDATE_CANDIDATE,
        payload: { candidate }
    }),
    updateUser: (outhire_user) => ({
        type: Types.UPDATE_USER,
        payload: {outhire_user}
    }),
    updateAdmin: (outhire_admin) => ({
        type: Types.UPDATE_ADMIN,
        payload: { outhire_admin }
    })
}