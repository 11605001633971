import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from './../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import OuthireMenu from '../../components/NavBar/OuthireMenu';
import { connect } from 'react-redux';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu';
import OuthireMobileMenu from '../../components/NavBar/OuthireMobileMenu';
import profileAvatar from './../../assets/outhire/profile-avatar.png';


class Referrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
     candidate: {},
     referrals: [],
     is_copied: false,
     emails: [],
     email: '',
     isReferralModal: false,
     message:  'Hi Friend! It’s '+this.props.user.first_name+'. I wanted to share with you my preferred recruitment services. It’s been great to work with them.',
     user: {},
     isReferSuccessModal: false,
     emailValid: true
    }
  }

  componentDidMount() {
    const apiCandidate = 'api/catstone/candidates/getCandidateById'

                        const payload = {
                            id: this.props.user.catsone_id
                        }

                        axios.post(apiCandidate, payload)
                            .then((result) => {
                                console.log(result)
                                this.setState({
                                  candidate: result.data,
                                })
                            })

                            const apiCheck = '/api/v1/get-user';
                            axios.post(apiCheck, {email:this.props.user.email})
                            .then((result) => {
                              if(result.data!=null){
                               if(result.data.referrals != undefined && result.data.referrals != null){
                                this.setState({
                                  referrals: result.data.referrals,
                                  user: result.data
                                })
                               }
                              }})

  }

  copyLink = () => {
    navigator.clipboard.writeText("https://portal.outhire.ca/signup?referred_by=" + this.props.user.id);
    this.setState({
      is_copied: true
    })
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailValid: true
    })
  }

  openReferralModal = () => {
    this.setState({
      isReferralModal: true
    })
  }

  hideReferralModal = () => {
    this.setState({
      isReferralModal: false
    })
  }

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value
    })
  }

  hideReferSuccess = () => {
    this.setState({
      isReferSuccessModal: false,
      email: ''
    })
  }

  sendInvitation = () => {
    var emails = this.state.email.split(',');
    for(let i = 0; i < emails.length; i++){
      const emailExpression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (!emailExpression.test(String(emails[i]).toLowerCase())) {
      this.setState({
        emailValid: false,
      });
    }else{
      this.setState({
        emailValid: true
      })

      var email = {
        "receiver": emails[i],
        "subject": "Outhire - Your Friend " + this.props.user.first_name + ' ' + this.props.user.last_name + ' Wants to Refer You!',
        "body":    "<p>"+this.state.message +"</p>"+
        "<br/><p>Check the referral link below to get started with Outhire:</p>" +
        "<a target='_blank' href='https://portal.outhire.ca/signup?referred_by='"+this.props.user.id+">https://portal.outhire.ca/signup?referred_by=" + this.props.user.id + "</a>"
      }

      axios.post("/api/avaros/send-email", email).then((res)=>{
        console.log(res);
        const apiUser = "/api/v1/edit-user"; 
        var newData = this.state.user;
        var temp = [];
        if(newData.referrals != undefined && newData.referrals != null){
          temp = newData.referrals;
          if(newData.referrals.indexOf(emails[i]) == -1){
            temp.push(emails[i]);
          }
        }else{
          temp.push(emails[i]);
        }
        
       
        newData.referrals = temp;
        newData.modified_by = 'System'
        axios.post(apiUser, newData).then((result) => {
          console.log(result)
          this.componentDidMount();
          this.setState({
            isReferralModal: false,
            isReferSuccessModal: true
          })
        })
      })
    }
      
    }
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <OuthireMenu/>
          <div className='main-canvas '>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Welcome back, {this.state.candidate.first_name}</div>
                <div className='body-title mt-32'>Invite your friends to try Outhire!</div>
                <div className='body-txt mt-10'>We’ll try our best to help them find their dream job.</div>
              </div>
              <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-ham" }>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
              <div className='top-right'>
                <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={() => history.push('/settings')} />
                </div>
                {/* <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon' />
                </div> */}
                <div className='sm-avatar-wrap'>
                  {/* <img src={this.props.user.thumbnail} alt='' className='sm-avatar' /> */}
                  <img src={profileAvatar} alt='' className='sm-avatar' /> 
                </div>
              </div>
            </div>
            <div className='bottom-container'>
              <div className='mo-refer'>
              <div className='body-title mt-32'>Invite your friends to try Outhire!</div>
                <div className='body-txt mt-10'>We’ll try our best to help them find their dream job.</div>
              </div>
            <div className='body-title mt-32'>Invite a friend</div>
                <div className='mt-10 row'>
                    <input className='refer-input' placeholder='Enter email address' onChange={this.handleEmailChange} value={this.state.email}/>
                    <button className='btn-save ml-16 btn-10 btn-send' onClick={this.openReferralModal}>Send Invite</button>
                </div>
                <div className='body-title mt-32'>Your referral code:</div>
                <div className='mt-10 row am-wrap'>
                    <div className='body-title v-center mo-w-100'>https://portal.outhire.ca/signup?referred_by={this.props.user.id}</div>
                    <button className='btn-save ml-24 btn-10 mo-ml-0' onClick={this.copyLink}>Copy Link</button>
                </div>
                {this.state.is_copied && (
              <div className='copied-txt'>Copied to clipboard</div>
             )}
                <div className='body-title mt-32'>Referrals sent</div>
               
                <div className='refer-table'>
                {this.state.referrals.length > 0 ? (
                   Object.keys(this.state.referrals).slice(0, 20).map((key) => (
                    <div className='row mt-8 '>
                <div className='info-group w-35 m-w-35'>
                    <div className='info-label mo-none'>Email</div>
                    <div className='info-txt'>{this.state.referrals[key]}</div>
                    <div className='info-divider'></div>
                  </div>
                  {/* <div className='info-group w-10'>
                    <div className='info-label'>Status</div>
                    <div className='info-txt'>Accepted</div>
                    <div className='info-divider'></div>
                  </div> */}
                </div>
                   )
                )) : (
                  <div className='info-txt mt-32'>No referrals found</div>
                )}
                </div>
            </div>
           
          </div>
        </div>
        <Modal show={this.state.isReferralModal} handleClose={this.hideReferralModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-refer-title'>Refer a friend to Outhire!</div>
            </div>
            <div className='o-modal-terms-link' onClick={()=> history.push('/terms-of-use')}>Terms & Conditions
             </div><br/>
             <div className='refer-email-wrap'>
              <div className='refer-email-label'>Your friends’ emails separated with commas</div>
              <textarea className='refer-emails' value={this.state.email} onChange={this.handleEmailChange} placeholder="Enter emails"></textarea>
              {this.state.email == '' && (
                 <div className='error-txt w-full'>Please enter at least one email</div>
              )}
              {!this.state.emailValid && (
                <div className='error-txt w-full'>Please enter valid emails</div>
              )}
             </div>
             <div className='refer-email-wrap'>
              <div className='refer-mesage-label'>Message</div>
              <textarea className='refer-message' value={this.state.message} onChange={this.handleMessageChange} placeholder="Enter message">
              </textarea>
              {this.state.message == '' && (
                 <div className='error-txt w-full'>Message cannot be empty</div>
              )}
             </div>
            
              <button className='btn-save' disabled={!this.state.emailValid||this.state.message==''} onClick={this.sendInvitation}>Send</button>
          </div>
        </Modal>
        <Modal show={this.state.isReferSuccessModal} handleClose={this.hideReferSuccess}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-refer-title'>Thanks for referring your friends to Outhire. Remind your friends to check their emails!</div>
            </div>

            
              <button className='btn-save btn-refer-more' onClick={this.hideReferSuccess}>Refer more friends</button>
          </div>
        </Modal>
      </div>
    );
  }
}


//map stored state to props access
const mapStateToProps = (state) => {
  return {
      user: state.candidate.outhire_user
  }
}

export default connect(mapStateToProps)(Referrals);