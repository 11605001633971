import React, { Component } from 'react';
import './styles.css';
import logo from './../../assets/outhire/outhire.svg';
import tony from './../../assets/outhire/tony.jpg';
import profile from './../../assets/outhire/person.svg';
import yes from './../../assets/outhire/yes.svg';
import no from './../../assets/outhire/no.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import upload from './../../assets/outhire/upload_folder.png';
import bell from './../../assets/outhire/bell.png';
import setting from './../../assets/outhire/carbon_settings.svg';
import edit from './../../assets/outhire/edit.png';
import example from './../../assets/outhire/example.png';
import close from './../../assets/outhire/close-circle.png';
import history from '../../utilities/history';
import NavBar from '../../components/NavBar/TopNavBar';
import Modal from '../../components/Landing/Modal';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ReactSlider from 'react-slider';
import moment from "moment";
import AdminMenu from '../../components/NavBar/AdminMenu';
import slogo from './../../assets/outhire/slogo.png';
import { connect } from 'react-redux';


class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
     tab: 'pending',
     pending: [],
     linked: [],
     nolink: [],
     selected_user: {},
     candidate_id: null,
     isNotificationModal: false,
     is_nolink: null,
     is_linked: null,
     is_unlink: null
    }
  }

  componentDidMount() {
    // const queryParams = new URLSearchParams(window.location.search);
    // const id = queryParams.get('id');
    if(this.props.admin == undefined || this.props.admin == null || this.props.admin.email == ''){
      history.push('/signin')
    }else{
      this.getUsers();
    }
  

  }

  getUsers() {
    const apiUsers = '/api/v1/users'


    axios.get(apiUsers)
        .then((result) => {
            console.log(result)
            var pending = [];
            var linked = [];
            var nolink = [];
            for(let i = 0; i < result.data.length; i ++){
             if(result.data[i].user_type == 'candidate'){
              if(result.data[i].catsone_status == undefined || result.data[i].catsone_status == 1){
                pending.push(result.data[i]);
                this.setState({
                  pending: pending.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })
                })
              }else if(result.data[i].catsone_status == 2 && result.data[i].catsone_id != undefined){
                linked.push(result.data[i]);
                this.setState({
                  linked: linked.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })
                })
              }else if(result.data[i].catsone_status == 0){
                nolink.push(result.data[i]);
                this.setState({
                  nolink: nolink.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })
                })
              }
             }

            }
        })
  }

  switchTab(tab){
    this.setState({
      tab: tab
    });
    this.getUsers();
  }

  selectUser(user){
    this.setState({
      selected_user: user
    })
  }

  linkUser = () => {
    const apiUser = "/api/v1/edit-user"; 
          var newData = this.state.selected_user;
          newData.catsone_id = Number(this.state.candidate_id);
          newData.catsone_status = 2;
          newData.modified_by =this.props.admin.first_name + ' ' + this.props.admin.last_name;
          axios.post(apiUser, newData).then((result) => {
              console.log(result);

              var email = {
                "receiver": this.state.selected_user.email,
                "subject": "Your Outhire Account is Ready!",
                "body":    "<p>Hello "+this.state.selected_user.first_name +",</p>"+
                "<br/><p>We have created your account on our portal!</p>" +
                "<p>Please login to your account at <a target='_blank' href='https://portal.outhire.ca/signin'"+">https://portal.outhire.ca/signin" + "</a> for more information.</p><br/>" + 
                "<p>Cheers,</p>" +
                "<p>Outhire.ca</p>"
              }
        
              axios.post("/api/avaros/send-email", email).then((res)=>{
                console.log(res);
                this.setState({
                  selected_user: {},
                  candidate_id: null
                })
                this.setState({
                  isNotificationModal: true,
                  is_linked: true
                }, () => {
                  this.setState({
                    linked: [],
                    nolink: [],
                    pending: [],
                  }, ()=> {
                    this.getUsers();
                    this.switchTab('pending');
                  })
                  
                });
              })
             
            });
  }

  hideNotificationModal = () => {
    this.setState({
      isNotificationModal: false,
      is_linked: null,
      is_nolink: null,
      is_unlink: null
    })
  }

  handleCandidateId = (e) => {
    this.setState({
      candidate_id: e.target.value
    })
  }



  noLink(user){
    const apiUser = "/api/v1/edit-user"; 
          var newData = user;
          newData.catsone_status = 0;
          newData.modified_by = this.props.admin.first_name + ' ' + this.props.admin.last_name;
          axios.post(apiUser, newData).then((result) => {
              console.log(result);
              this.setState({
                selected_user: {},
                candidate_id: null
              })

              this.setState({
                isNotificationModal: true,
                is_nolink: true
              }, () => {
                this.setState({
                  nolink: [],
                  pending: [],
                }, ()=> {
                  this.getUsers();
                })
                
              });
            });
  }

  unLink(user){
    const apiUser = "/api/v1/edit-user"; 
          var newData = user;
          newData.catsone_status = 1;
          newData.modified_by = this.props.admin.first_name + ' ' + this.props.admin.last_name;
          newData.catsone_id = null;
          axios.post(apiUser, newData).then((result) => {
              console.log(result);

              this.setState({
                isNotificationModal: true,
                is_unlink: true
              }, () => {
                this.setState({
                  linked: [],
                  pending: [],
                }, ()=> {
                  this.getUsers();
                })
                
              });
            });
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex justify-left">
          <AdminMenu/>
          <div className='main-canvas'>
            <div className='top-sec'>
              <div className='top-left'>
                <div className='wel-title'>Welcome back, {this.props.admin.first_name} {this.props.admin.last_name}</div>
                {/* <div className='body-title mt-32'>Refer headings text</div>
                <div className='body-txt mt-10'>Additional text about referrals</div> */}
              </div>
              <div className='top-right'>
              {/* <div className='bell-wrap'>
                  <img src={setting} alt='' className='bell-icon mr-20' onClick={()=>history.push('/settings')}/>
                </div>
                <div className='bell-wrap'>
                  <img src={bell} alt='' className='bell-icon'/>
                </div> */}
                <div className='sm-avatar-wrap bg-white'>
                  <img src={slogo} alt='' className='sm-avatar'/>
                </div>
              </div>
            </div>
            <div className='bottom-container'>
              <div className='table-card'>
                <div class="block-wrap-vh block-full-wrap">
                  <div className='block-header'>Candidates</div>
                <div className="block-tab">
                    <div className={this.state.tab === "pending" ? "appt-title-wrap tab-active" : "appt-title-wrap"} onClick={() => this.switchTab("pending")}><span className="appt-title">Pending</span></div>
                    <div className={this.state.tab === "linked" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("linked")}><span className="appt-title">Linked</span></div>
                    <div className={this.state.tab === "nolink" ? "appt-title-wrap ml-apptitle tab-active" : "appt-title-wrap ml-apptitle"} onClick={() => this.switchTab("nolink")}><span className="appt-title">Do Not Link</span></div>
                  </div>
                  <div className="block-divider mb-60"></div>
                 
                    {this.state.tab == 'pending' && (
                       <div className='c-table'>
                       <div className='table-header'>
                         <div className='table-head name-head'>Last name</div>
                         <div className='table-head method-head'>First name</div>
                         <div className='table-head email-head'>Email</div>
                         <div className='table-head method-head'>Sign in method</div>
                         <div className='table-head action-head'>Actions</div>
                         <div className='table-head method-head'>Creation Date</div>
                       </div>
                      <div className='table-data-wrap'>
                      {Object.keys(this.state.pending).map((key) => (
                        <div className='table-body'>
                        <div className='table-head name-head'>{this.state.pending[key].last_name}</div>
                        <div className='table-head method-head'>{this.state.pending[key].first_name}</div>
                        <div className='table-head email-head'>{this.state.pending[key].email}</div>
                        {(this.state.pending[key].linkedin_id != null && this.state.pending[key].linkedin_id != undefined) ? (
                         <div className='table-head method-head'>LinkedIn</div>
                        ) : (
                         <div className='table-head method-head'>Email</div>
                        )}
                        <div className='table-head action-head'>
                          <div className='table-btn-wrap'>
                            <button className='btn-action mb-8' onClick={()=>this.selectUser(this.state.pending[key])} disabled={this.state.selected_user._id == this.state.pending[key]._id}>Add Catsone ID</button>
                            <button className='btn-action' onClick={()=>this.noLink(this.state.pending[key])}>Do Not Link</button>
                          </div>
                          {this.state.selected_user._id == this.state.pending[key]._id && (
                            <div className='input-group'>
                            <div className='tinput-label'>ID</div>
                            <div className='t-input-row'>
                              <input className='t-input' placeholder='Candidate #' onChange={this.handleCandidateId} type="number" value={this.state.candidate_id}/>
                              <img src={yes} alt='' className='t-icon' onClick={this.linkUser}/>
                              <img src={no} alt='' className='t-icon ml-12' onClick={() => this.setState({selected_user: {}})}/>
                            </div>
                          </div>
                          )}
                        </div>
                        <div className='table-head method-head'>{moment(this.state.pending[key].createdAt).format("YYYY-MM-DD")}</div>
                      </div>
                      ))}
                     
                       </div>
                       </div>
                    )}
                     {this.state.tab == 'linked' && (
                       <div className='c-table'>
                       <div className='table-header'>
                         <div className='table-head name-head'>Last name</div>
                         <div className='table-head method-head'>First name</div>
                         <div className='table-head email-head'>Email</div>
                         <div className='table-head method-head'>Sign in method</div>
                         <div className='table-head method-head'>CATSONE ID</div>
                         <div className='table-head method-head'>Actions</div>
                         <div className='table-head method-head'>Modified Date</div>
                       </div>
                      <div className='table-data-wrap'>
                      {Object.keys(this.state.linked).map((key) => (
                        <div className='table-body p-3'>
                        <div className='table-head name-head'>{this.state.linked[key].last_name}</div>
                        <div className='table-head method-head'>{this.state.linked[key].first_name}</div>
                        <div className='table-head email-head'>{this.state.linked[key].email}</div>
                        {(this.state.linked[key].linkedin_id != null && this.state.linked[key].linkedin_id != undefined) ? (
                         <div className='table-head method-head'>LinkedIn</div>
                        ) : (
                         <div className='table-head method-head'>Email</div>
                        )}
                        <div className='table-head method-head'>{this.state.linked[key].catsone_id}</div>
                        <div className='table-head method-head'>
                          <div className='table-btn-wrap'>
                            <button className='btn-action' onClick={()=>this.unLink(this.state.linked[key])}>Unlink</button>
                          </div>
                         
                        </div>
                        <div className='table-head method-head'>{moment(this.state.linked[key].updatedAt).format("YYYY-MM-DD")} by {this.state.linked[key].modified_by}</div>
                      </div>
                      ))}
                     
                       </div>
                       </div>
                    )}
                     {this.state.tab == 'nolink' && (
                       <div className='c-table'>
                       <div className='table-header'>
                         <div className='table-head name-head'>Last name</div>
                         <div className='table-head method-head'>First name</div>
                         <div className='table-head email-head'>Email</div>
                         <div className='table-head method-head'>Sign in method</div>
                         <div className='table-head action-head'>Actions</div>
                         <div className='table-head method-head'>Modified Date</div>
                       </div>
                      <div className='table-data-wrap'>
                      {Object.keys(this.state.nolink).map((key) => (
                        <div className='table-body p-3'>
                        <div className='table-head name-head'>{this.state.nolink[key].last_name}</div>
                        <div className='table-head method-head'>{this.state.nolink[key].first_name}</div>
                        <div className='table-head email-head'>{this.state.nolink[key].email}</div>
                        {(this.state.nolink[key].linkedin_id != null && this.state.nolink[key].linkedin_id != undefined) ? (
                         <div className='table-head method-head'>LinkedIn</div>
                        ) : (
                         <div className='table-head method-head'>Email</div>
                        )}
                        <div className='table-head action-head'>
                          <div className='table-btn-wrap'>
                            <button className='btn-action' onClick={()=>this.selectUser(this.state.nolink[key])} disabled={this.state.selected_user._id == this.state.nolink[key]._id}>Link</button>
                          </div>
                          {this.state.selected_user._id == this.state.nolink[key]._id && (
                            <div className='input-group'>
                            {/* <div className='tinput-label'>ID</div> */}
                            <div className='t-input-row'>
                              <input className='t-input' placeholder='Candidate #' onChange={this.handleCandidateId} type="number" value={this.state.candidate_id}/>
                              <img src={yes} alt='' className='t-icon' onClick={this.linkUser}/>
                              <img src={no} alt='' className='t-icon ml-12' onClick={() => this.setState({selected_user: {}})}/>
                            </div>
                          </div>
                          )}
                        </div>
                        <div className='table-head method-head'>{moment(this.state.nolink[key].updatedAt).format("YYYY-MM-DD")} by {this.state.nolink[key].modified_by}</div>
                      </div>
                      ))}
                     
                       </div>
                       </div>
                    )}
                  
                  </div>
              </div>
            </div>
           
          </div>
        </div>
        <Modal show={this.state.isNotificationModal} handleClose={this.hideNotificationModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Success!</div>
            </div>
           {this.state.is_linked && (
             <div className='n-modal-txt'>The account has been linked. 
             The candidate will receive an email and will be able to log into the portal.</div>
           )}
           {this.state.is_unlink && (
             <div className='n-modal-txt'>The account is no longer  linked. 
             </div>
           )}
           {this.state.is_nolink && (
             <div className='n-modal-txt'>This account has been moved to Do Not Link
             </div>
           )}
          </div>
        </Modal>
      </div>
      
    );
  }
}

//map stored state to props access
const mapStateToProps = (state) => {
  return {
      admin: state.candidate.outhire_admin
  }
}

export default connect(mapStateToProps)(AdminDashboard);