import React, { Component } from 'react';
import './styles.css';
import history from './../utilities/history';
import sent from './../assets/images/sent.png';
import { connect } from 'react-redux';
import { ProfileActionCreators } from './../actions/patient/profileAction';
import axios from "axios";
import error from './../assets/images/error.svg';
import NavBar from '../components/NavBar/TopNavBar';
import welcome from './../assets/outhire/welcome.png';
import Footer from "../components/Landing/Footer";
import white from './../assets/outhire/white.svg';
import OuthireMobileMenu from '../components/NavBar/TopMobileMenu';
import menu from './../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../assets/outhire/outhire.svg';

class ForgetPassword extends Component {
  constructor(props){
    super(props);
    this.state = { 
        isExsit: true,
        email: "",
        emailSent: false,
        isSending: false
    }
  }

  componentDidMount() {
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    isExsit: true
    })
  }

  sendEmail = () => {
    this.setState({
      isSending: true
    })
    const apiUrl = 'api/v1/signin';

        const payload = {
            email: this.state.email,
            password: ''
        }

        console.log(payload)

        axios.post(apiUrl, payload)
            .then((result) => {
              console.log(result);
                if (result.data.status === 'error') {
                    if (result.data.message == '01') {
                        this.setState({
                            isExsit: false,
                            isSending: false
                        })
                    } else{
                      console.log('hi');
                      var email = {
                        "receiver": this.state.email,
                        "subject": "You Forgot Your Password",
                        "body":    "<p>Hello " +
                        this.state.email +
                        ",</p>" +
                        "<p>We have received a request to reset your password. </p>" +
                        "<p>If you didn't initiate this, you can simply ignore this email.</p>" +
                        "<p>Click the following link to reset your password.</p>" +
                        "<a href=https://portal.outhire.ca/reset-password?email=" + this.state.email +
                        "> Reset your password</a>"
                      }

                      axios.post("/api/avaros/send-email", email).then((res)=>{
                        console.log(res);
                        if(res.data.status != "success"){
                          this.setState({
                            emailSent: false,
                            isSending: false
                          })
                        }else{
                          this.setState({
                            emailSent: true,
                            isSending: false
                          })
                        }
                      })
                    }
                  }})
  }



  render() {
    return (
      <div className="container">
         <div className="content-wrap">
            <NavBar />
          </div>
          <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
          <div className="max-width">
            <div className="candidate-bg web-nav">
            <div className="first-wrap content-center coloum">
              {/* <div className="sign-diagonal"></div> */}
              <img className="white-poligon" src={white} alt=''/>
              <div className="top-section">
            {/* <div className="home-title w-full mb-75  mt-single">Let’s find what you’re looking for</div> */}
            <div className="home-card-wrap-forgetpw mb-100 top-card">
            {!this.state.emailSent ? (
          <div>
          <div className="sentimg-wrap mb-24">
              {/* <img src={sent}/> */}
          </div>             
          <div className="forgetpw">Forgot Password</div>
          <div className="forgetpw-txt">
          Don’t worry. Please enter the email associated with your account below.
          </div>
          <input className="input-box size-1em" type="email" name="email" style={{ borderColor: this.state.isExsit ? '#c9d6e3' : '#f47160' }} onChange={this.handleEmailChange} placeholder="Enter your email"/>
          {!this.state.isExsit && (
                          <div className="error-wrap mt-16 w-444">
                              <div className="error-icon-wrap">
                                  <img src={error} className="error-icon" />
                              </div>
                              <div className="error-txt-wrap">
                                  <div className="error-txt">Sorry, we couldn't find an account with that email. Please enter correct email address associated with your account.</div>
                              </div>
                          </div>
                      )} 
          <div className="mt-16">
                          {this.state.isSending ? (
                            <button disabled className="btn btn-solid btn-regular font-18" type="button" onClick={this.sendEmail} >Sending...</button>
                          ) : (
                            <button className="btn btn-solid btn-regular font-18" type="button" onClick={this.sendEmail} >Send Email</button>
                          )}
                      </div>      
          </div>
        ): (
          <div>
          <div className="sentimg-wrap mb-24">
            {/* <img src={sent} /> */}
          </div>
          <div className="verify-txt mb-16 forgetpw">Email Successfully Sent</div>
          <div className="size-1em font-regular color-primary mb-32 text-center">
          Please reset your password using the link we provided in the email that was sent to:
          </div>
          <div className="verify-email mb-56">{this.state.email}</div>
          <div className="size-1em font-regular text-center">If you didn't receive the email, click here to <span className="resend-link" onClick={this.sendEmail}>resend</span></div>
        </div>
        )}
        </div>
            </div>
            </div>
          </div>
          <div className="mo-wrap">
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
    {!this.state.emailSent ? (
          <div>
          <div className="sentimg-wrap mb-24">
              {/* <img src={sent}/> */}
          </div>             
          <div className="forgetpw">Forgot Password</div>
          <div className="forgetpw-txt">
          Don’t worry. Please enter the email associated with your account below.
          </div>
          <input className="input-box size-1em" type="email" name="email" style={{ borderColor: this.state.isExsit ? '#c9d6e3' : '#f47160' }} onChange={this.handleEmailChange} placeholder="Enter your email"/>
          {!this.state.isExsit && (
                          <div className="error-wrap mt-16 w-444">
                              <div className="error-icon-wrap">
                                  <img src={error} className="error-icon" />
                              </div>
                              <div className="error-txt-wrap">
                                  <div className="error-txt">Sorry, we couldn't find an account with that email. Please enter correct email address associated with your account.</div>
                              </div>
                          </div>
                      )} 
          <div className="mt-16">
                          <button className="btn btn-solid btn-regular font-18" type="button" onClick={this.sendEmail} >Send Email</button>
                      </div>      
          </div>
        ): (
          <div>
          <div className="sentimg-wrap mb-24">
            {/* <img src={sent} /> */}
          </div>
          <div className="verify-txt mb-16 forgetpw">Email Sent Successfully</div>
          <div className="size-1em font-regular color-primary mb-32 width-494 text-center">
          Please reset your password using the link we provided in the email that was sent to:
          </div>
          <div className="verify-email mb-56">{this.state.email}</div>
          <div className="size-1em font-regular text-center">If you didn’t receive the email, click here to <span className="resend-link" onClick={this.sendEmail}>resend</span></div>
        </div>
        )}
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer-relative">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>

      </div>
      </div>
    );
  }
}

export default ForgetPassword;