import React  , {useEffect, useState}from 'react';
import './styles.scss';
import history from './../../utilities/history';
import { connect } from 'react-redux';
import Modal from './../Landing/Modal';
import { PURGE } from 'redux-persist';
import { ProfileActionCreators } from '../../actions/candidate/profileAction';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import down from './../../assets/outhire/down.svg';
import up from './../../assets/outhire/up.svg';

function AdminMenu(props) {

    const [signoutModal, setSignoutModal] = useState(false);
    const [jobExpand, setJobExpand] = useState(false);
    const pathname = window.location.pathname;
    console.log(pathname);

    const signout = (e) => {
     // e.PreventDefault();
       const {dispatch} = props;

       dispatch({
         type: PURGE,
         key: 'root',
         result: () => null
       })

       props.dispatch(ProfileActionCreators.updateAdmin(null));
      history.push('/');
    }

    useEffect(()=> {
      if(props.admin == null || props.admin == undefined || props.admin.id == ''){
        history.push('/signin');
      }

      console.log(props.admin)


      const onPageLoad = () => {
        setJobExpand(true)
      }

      if(document.readyState === 'complete' && pathname.includes('job')){
        onPageLoad();
      }
      // else{
      //   window.addEventListener('load', onPageLoad);
      //   return ()=> window.removeEventListener('load', onPageLoad)
      // }
    }, [])

    return (
        <div className='side-bar'>
            <div className='menu-logo-wrap'>
              <img src={logo} alt="" className='menu-logo' /></div>
              <div className='side-menu-ad'>
              <div className={pathname=='/admin/dashboard'? ' menu-list1  active-menu1' : 'menu-list1'} onClick={()=> history.push('/admin/dashboard')}>
                <div className='icon-wrap'>
                  <img src={job} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Dashboard</div>
              </div>
              <div className={pathname=='/admin/modify'? ' menu-list1  active-menu1' : 'menu-list1'} onClick={()=> history.push('/admin/modify')}>
                <div className='icon-wrap'>
                  <img src={profile} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Modify Users</div>
              </div>
              {props.admin.user_type == 'super admin' && (
                <div  className={pathname=='/admin/manage'? ' menu-list1  active-menu1' : 'menu-list1'} onClick={()=> history.push('/admin/manage')}>
                <div className='icon-wrap'>
                  <img src={referrals} alt="" className='menu-icon'/>
                </div>
                <div className='menu-txt'>Manage Admin</div>
              </div>
              )}
              <div className='menu-list1' onClick={()=>setSignoutModal(true)}>
                <div className='icon-wrap'>
                  <img src={logout} alt="" className='menu-icon' />
                </div>
                <div className='menu-txt'>Logout</div>
              </div>
            </div>
            <Modal  show={signoutModal} handleClose={() => setSignoutModal(false)}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Are you sure?</div>
            </div>
            <div className='n-modal-txt'>You will be signed out and taken back to outhire.ca
             </div>
             <br/>

              <button className='btn-save' onClick={signout}>Sign Out</button>
          </div>
        </Modal>
          </div>
    )
};

  //map stored state to props access
  const mapStateToProps = (state, ownProps) => {
    return {
        admin: state.candidate.outhire_admin,
        state
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);