import React, { Component } from "react";
import history from '../../utilities/history';
import "./styles.css";
import NavBar from '../../components/NavBar/TopNavBar';
import axios from 'axios';
import Footer from "../../components/Landing/Footer";
import OuthireMobileMenu from '../../components/NavBar/TopMobileMenu';
import menu from './../../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../../assets/outhire/outhire.svg';
import Modal from '../../components/Landing/Modal';

const how = [
    {name: 'LinkedIn'},
    {name: 'Indeed'},
    {name: 'Referral'},
    {name: 'Job Board'},
    {name: 'Social Media'}
  ]

  const reasons = [
    {name: 'Employer looking to hire'},
    {name: 'Book a demo'},
    {name: 'Candidate looking for a new role'},
    {name: 'Become a contractor with Outhire'},
    {name: 'Working at Outhire'},
    {name: 'General feedback'},
    {name: 'Others'}
  ]

class Contacts extends Component {
    state = {
     name: '',
     email: '',
     phone: '',
     comments: '',
     firstName: '',
     lastName: '',
     location: '',
     subject: '',
     isSubmmittedModal: false,
     howChecked: [],
     reasonChecked: [],
    };

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleFirstChange = (e) => {
        this.setState({
            firstName: e.target.value
        })
    }

    handleLastChange = (e) => {
        this.setState({
            lastName: e.target.value
        })
    }

    handleLocationChange = (e) => {
        this.setState({
            location: e.target.value
        })
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handlePhoneChange = (e) => {
        this.setState({
            phone: e.target.value
        })
    }

    handleCommentsChange = (e) => {
        this.setState({
            comments: e.target.value
        })
    }

    hideSubmittedModal = () => {
        this.setState({
            isSubmmittedModal: false
        })
    }

    handleSubjectChange = (e) => {
        this.setState({
            subject: e.target.value
        })
    }

    handleHowOnChange = (position) =>{
        const newHow = this.state.howChecked;
        if(this.state.howChecked.indexOf(how[position]) === -1){
            newHow.push(how[position]);
            this.setState({
                howChecked: newHow
            }, () => {
                console.log(this.state.howChecked)
            })
        }else{
            console.log('hi')
            newHow.splice(this.state.howChecked.indexOf(how[position]), 1)
            console.log(newHow)
            this.setState({
                howChecked: newHow
            }, () => {
                console.log(this.state.howChecked)
            })
        }
      }

      handleReasonOnChange = (position) =>{
        const newReason = this.state.reasonChecked;
        if(this.state.reasonChecked.indexOf(reasons[position]) === -1){
            newReason.push(reasons[position]);
            this.setState({
                reasonChecked: newReason
            }, () => {
                console.log(this.state.reasonChecked)
            })
        }else{
            newReason.splice(this.state.reasonChecked.indexOf(reasons[position]), 1)
            this.setState({
                reasonChecked: newReason
            }, () => {
                console.log(this.state.reasonChecked)
            })
        }
      }

    submitForm = () => {
        let howString = '';
        let reasonString = '';
        console.log(this.state.howChecked);
        console.log(this.state.reasonChecked);
        for(let i = 0; i < this.state.howChecked.length; i++){
            if(howString == ''){
                howString = this.state.howChecked[i].name;
            }else{
                howString = howString + ', ' + this.state.howChecked[i].name;
            }
            
        }
        for(let i = 0; i < this.state.reasonChecked.length; i++){
            if(reasonString == ''){
                reasonString = this.state.reasonChecked[i].name;
            }else{
                reasonString = reasonString + ', ' + this.state.reasonChecked[i].name;
            }
            
        }
        var email = {
            "receiver": 'info@outhire.ca',
            "subject": "Contact Form",
            "body":  "<p>Hi Outhire" +
            "!</p>" +
            "<p>You've received new contact entry, please view the following info: <p>" +
            "<p>First Name: "+ this.state.firstName +"</p> " +
            "<p>Last Name: "+ this.state.lastName +"</p>" +
            "<p>Email: "+ this.state.email +"</p> " +
            "<p>Subject: "+ this.state.subject +"</p> " +
            "<p>How did you hear about us: "+ howString +"</p> " +
            "<p>Reason for reaching out: "+ reasonString +"</p> " +
            "<p>Message: </p><p>"+ this.state.comments +"</p> "
          }

        //   var email1 = {
        //     "receiver": 'johnxu27@gmail.com',
        //     "subject": "Contact Form",
        //     "body":  "<p>Hi Outhire" +
        //     "!</p>" +
        //     "<p>You've received new contact entry, please view the following info: <p>" +
        //     "<p>First Name: "+ this.state.firstName +"</p> " +
        //     "<p>Last Name: "+ this.state.lastName +"</p>" +
        //     "<p>Email: "+ this.state.email +"</p> " +
        //     "<p>Subject: "+ this.state.subject +"</p> " +
        //     "<p>How did you hear about us: "+ howString +"</p> " +
        //     "<p>Reason for reaching out: "+ reasonString +"</p> " +
        //     "<p>Message: </p><p>"+ this.state.comments +"</p> "
        //   }
          axios.post("/api/avaros/send-email", email).then((res)=>{
            console.log(res);
            if(res.data.status != "success"){
              this.setState({
                emailSent: false
              })
            }else{
                // this.setState({
                //     isSubmmittedModal: true,
                //     howChecked: [],
                //     reasonChecked: [],
                //     firstName: '',
                //     lastName: '',
                //     email: '',
                //     subject: '',
                //     comments: ''
                // })
                window.location.replace("https://outhire.ca/thank-you/");

                // axios.post("/api/avaros/send-email", email1).then((res)=>{
                //     console.log(res);
                //   })
            }
          })
    }
  
    render() {
      return (

<div className="App">
<div className="content-wrap">
  <NavBar />
</div>
<div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
    <div className="max-width">
    <div className="contact-body-wrap">
       <div className="contact-head-wrap">
        <div className="home-title w-no">It all starts with a good connection</div>
        {/* <div className="home-title w-no">connection</div> */}
        <div className="home-body-txt contact-title-body">Ready to unlock better opportunities with Outhire? If you’ve got questions, comments or you’re raring to get started, share a few details below and we’ll get back to you quickly.
</div>
       </div>
       <div className="contact-form-wrap mb-0">
       {/* contact-home-card  */}
        <div className="home-card-wrap contact-home-card">
            <div className="contact-form txt-right">
                {/* <div className="contact-row">
                    <div className="contact-info">
                        <input placeholder="Name" className="contact-input mb-16" onChange={this.handleNameChange} value={this.state.name}/>
                        <input placeholder="Email" className="contact-input mb-16" onChange={this.handleEmailChange} value={this.state.email}/>
                        <input placeholder="Phone" className="contact-input" onChange={this.handlePhoneChange} value={this.state.phone}/>
                    </div>
                    <div className="contact-right">
                        <textarea className="contact-txtarea" placeholder="Comment" value={this.state.comments} onChange={this.handleCommentsChange}></textarea>
                    </div>
                </div>
                <div className="home-card-btn-wrap mt-40">
                            <button className='btn-submit-home btn-home btn-save' onClick={this.submitForm}>Submit</button>
                </div> */}
                <div className="contact-row mb-16">
                <div className='info-group txt-left'>
                      <div className='contact-label'>First Name<span className="red-star">*</span></div>
                      <input className='p-input contact-input' value={this.state.firstName} placeholder="Enter your first name" onChange={this.handleFirstChange} />
                    </div>
                    <div className='info-group txt-left'>
                      <div className='contact-label'>Last Name<span className="red-star">*</span></div>
                      <input className='p-input contact-input' value={this.state.lastName} placeholder="Enter your last name" onChange={this.handleLastChange} />
                    </div>
                </div>
                <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>E-mail<span className="red-star">*</span></div>
                      <input className='p-input contact-input' value={this.state.email} placeholder="Enter email address" onChange={this.handleEmailChange} />
                    </div>
                    {/* <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>Location<span className="red-star">*</span></div>
                      <input className='p-input contact-input' value={this.state.location} placeholder="Enter location" onChange={this.handleLocationChange} />
                    </div> */}
                    <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>How did you hear about us?<span className="red-star">*</span></div>
                     <div className="d-column">
                     {how.map(({name}, index) => {
                  return (
                    <label className=" mb-check w-check-wrap mt-7" key={index}>
                    <input type="checkbox"
                        className="w-check-box"
                        id={`custom-checkbox-${index}`}
                        name={name}
                        value={name}
                        defaultChecked={this.state.howChecked.indexOf(name) !== -1}
                        onChange={() =>this.handleHowOnChange(index)}
                    />
                    <span className="checkbox-wrap size-1em font-regular mt-check checkbox-txt">{name}</span>
                </label>
                  
                  )
                  }
                 
                )} 
                     </div>
                    </div>
                    <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>Subject<span className="red-star">*</span></div>
                      <input className='p-input contact-input' value={this.state.subject} placeholder="Enter subject" onChange={this.handleSubjectChange} />
                    </div>
                    <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>Reason for reaching out<span className="red-star">*</span></div>
                     <div className="d-column">
                     {reasons.map(({name}, index) => {
                  return (
                    <label className=" mb-check w-check-wrap mt-7" key={index}>
                    <input type="checkbox"
                        className="w-check-box"
                        id={`custom-checkbox-${index}`}
                        name={name}
                        value={name}
                        defaultChecked={this.state.reasonChecked.indexOf(name) !== -1}
                        onChange={() =>this.handleReasonOnChange(index)}
                    />
                    <span className="checkbox-wrap size-1em font-regular mt-check checkbox-txt">{name}</span>
                </label>
                  
                  )
                  }
                 
                )} 
                     </div>
                    </div>
                    <div className='info-group txt-left mb-16'>
                      <div className='contact-label'>Message<span className="red-star">*</span></div>
                      <textarea className="contact-txtarea" placeholder="Comment" value={this.state.comments} onChange={this.handleCommentsChange}></textarea>
                    </div>

                   <div className="left-btn mo-right">
                   <button className=' btn-home btn-save mt-40' onClick={this.submitForm}>Submit</button>
                   </div>
            </div>
        </div>
       </div>
    </div>
  
        <Footer/>
        <Modal show={this.state.isSubmmittedModal} handleClose={this.hideSubmittedModal}>
          <div className="o-modal-content text-center">
            <div className='o-modal-header'>
              <div className='o-modal-title'>Submitted Successfully!</div>
            </div>
            <div className='n-modal-txt'>Thank you for contacting us! Our team will get back to you shortly.</div><br/>
             <br/>
              {/* {this.state.warning == 'delete'? (
                <button className='btn-save' onClick={this.deleteAccount}>Proceed</button>
              ) : this.state.warning == 'disabled' &&(
                <button className='btn-save' onClick={this.disableAccount}>Proceed</button>
              )} */}
               <button className='btn-save' onClick={this.hideSubmittedModal}>Close</button>
          </div>
        </Modal>
    </div>
</div>
      );
    }
  }
  
  export default Contacts;

