import React, { Component } from 'react';
import './styles.css';
import doctor from './../assets/images/computer.svg';
import history from './../utilities/history';
import SignUpForm from '../components/SignUpForm/SignUpForm';
import NavBar from '../components/NavBar/TopNavBar';
import Footer from "../components/Landing/Footer";
import white from './../assets/outhire/white.svg';
import OuthireMobileMenu from '../components/NavBar/TopMobileMenu';
import menu from './../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../assets/outhire/outhire.svg';

class RegistrationSuccess extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (

<div className="container">
<div className="content-wrap">
   <NavBar />
 </div>
 <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
 <div className="max-width">
   <div className="candidate-bg web-nav">
   <div className="first-wrap content-center coloum">
     {/* <div className="sign-diagonal"></div> */}
     <img className="white-poligon" src={white} alt=''/>
     <div className="top-section">
   <div className="home-title w-full mb-75 mt-single"></div>
   <div className="home-card-wrap mb-100 top-card home-login-wrap px-32">
   <div className="signin-form">
                <form className="terms-form text-center">
                    <div className="title-txt font-medium size-2em text-center">Thanks for verifying</div>
                    <div className="title-txt font-medium size-2em text-center mb-24"> your email!</div>
                    <div className='form-txt oxygen-400-black'>We take your interest seriously.</div><br/>
<div className='form-txt oxygen-400-black'>We will reach out to you to discuss your job needs in the next 48 hours. In the meantime please feel free to peruse the jobs in the <a className="cursor link-underline" onClick={() => history.push('/explore-jobs')}>Explore Jobs</a> section.</div><br/>
          <br/>
          <br/>
          <button className='btn btn-solid btn-regular font-18'
              onClick={() => history.push('/signin')}>Back to Login</button>
                </form>
            </div>
</div>
   </div>
   </div>
 </div>
 
 <div className="mo-wrap">
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
    <div className="signin-form">
                <form className="terms-form text-center">
                    <div className="title-txt font-medium size-2em text-center">Thanks for verifying</div>
                    <div className="title-txt font-medium size-2em text-center mb-24"> your email!</div>
                    <div className='form-txt oxygen-400-black'>We take your interest seriously.</div><br/>
<div className='form-txt oxygen-400-black'>We will reach out to you to discuss your job needs in the next 48 hours. In the meantime please feel free to peruse the jobs in the <a className="cursor link-underline" onClick={() => history.push('/explore-jobs')}>Explore Jobs</a> section.</div><br/>
          <br/>
          <br/>
          <button className='btn btn-solid btn-regular font-18'
              onClick={() => history.push('/signin')}>Back to Login</button>
                </form>
            </div>
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer-relative">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>

</div>
</div>
    );
  }
}

export default RegistrationSuccess;