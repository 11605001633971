import React, { Component } from 'react';
import './styles.css';
import history from './../utilities/history';
import sent from './../assets/images/sent.png';
import { connect } from 'react-redux';
import { ProfileActionCreators } from './../actions/patient/profileAction';
import axios from "axios";
import error from './../assets/images/error.svg';
import dot from "./../assets/images/dot.svg";
import check from "./../assets/images/check.svg";
import close from "./../assets/images/red-close.svg";
import NavBar from '../components/NavBar/TopNavBar';
import welcome from './../assets/outhire/welcome.png';
import Footer from "../components/Landing/Footer";
import white from './../assets/outhire/white.svg';
import OuthireMobileMenu from '../components/NavBar/TopMobileMenu';
import menu from './../assets/outhire/menu.svg';
import { slide as Menu } from 'react-burger-menu'
import logo from './../assets/outhire/outhire.svg';

class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = { 
        email: "",
        //New Password
      isNewPwEmpty: true,
      isNewPwShow: false,
      newPswd: "",
      isNewPasswordValid: null,
      newHasLength: null,
      newHasLowercase: null,
      newHasUppercase: null,
      newHasSpecial: null,
      newHasNumber: null,
      isInfoValid: false,
      emailSent: false
    }
  }

  componentWillMount(){
    // const queryParams = new URLSearchParams(window.location.search);
    // const id = queryParams.get("id");
    // const email = queryParams.get("email");
     

    // const apiUser = "/api/v1/get-user"; 
    // const user = {
    //   email:email
      
    // }

    // axios.post(apiUser, user).then((result) => {
    //     if (result.data.status === "verified") {
    //       this.setState({
    //         linkAddrInvalid: true
    //       })


    //     } else {
    //       const apiUser = "/api/v1/edit-user"; 
    //       const user = {
    //           id: id,
    //           status: 'verified',
            
    //       }
      
    //       axios.post(apiUser, user).then((result) => {
    //           if (result.status === "error") {
    //             console.log(result.data.message);
    //             this.setState({
    //               isEmailConfirmState: false
    //           })
    //           } else {
    //               this.setState({
    //                   isEmailConfirmState: true
    //               })        
              
    //           }
    //         });
    //       }      
        
    //     });
    
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");

    this.setState({
        email: email
    })
     
  }

   //New Password input onChange handler
   handleNewPwChange = (e) => {
    if (e.target.value !== "") {
      this.setState({
        isNewPwEmpty: false,
        isPwFocus: true,
        newPswd: e.target.value,
        // isMatched:false
      });

      //Check if entered password length is between 8 to 15 characters
      if (e.target.value.length < 8 || e.target.value.length > 15) {
        this.setState({
          newHasLength: false,
          isNewPasswordValid: false,
          isInfoValid: false
        });

      } else {
        this.setState({
          newHasLength: true,
        });
      }

      //Check if entered password contains a lowercase character
      if (e.target.value === e.target.value.toUpperCase()) {
        this.setState({
          newHasLowercase: false,
          isNewPasswordValid: false,
          isInfoValid: false
        });

      } else {
        this.setState({
          newHasLowercase: true,
        });
      }

      //Check if entered password contains a uppercase character
      if (e.target.value === e.target.value.toLowerCase()) {
        this.setState({
          newHasUppercase: false,
          isNewPasswordValid: false,
          isInfoValid: false

        });

      } else {
        this.setState({
          newHasUppercase: true,
        });
      }

      //Check if entered password contains a special character
      const specialExpression = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (specialExpression.test(e.target.value)) {
        this.state.newHasSpecial = true;
        this.setState({
          newHasSpecial: true,
        });

      } else {
        this.setState({
          newHasSpecial: false,
          isNewPasswordValid: false,
          isInfoValid: false
        });
      }

      //Check if entered password contains a number
      if (/\d/.test(e.target.value)) {
        this.setState({
          newHasNumber: true,
        });

      } else {
        this.setState({
          newHasNumber: false,
          isNewPasswordValid: false,
          isInfoValid: false
        });
      }

      if (

        this.state.newHasLowercase &&
        this.state.newHasNumber &&
        this.state.newHasUppercase &&
        this.state.newHasSpecial &&
        this.state.newHasLength
      ) {
        this.setState({
          isNewPasswordValid: true,
          isInfoValid: true,
        });
      } else {
        this.setState({
          isInfoValid: false,
        });
      }






    } else {
      this.setState({
        isNewPwEmpty: true,
        newHasLength: false,
        newHasLowercase: false,
        newHasUppercase: false,
        newHasSpecial: false,
        newHasNumber: false,
        isNewPasswordValid: false,
        isPwFocus: false,
        isMatched: false
      });
    }
  };

    //Toggle to show or hide new password
    toggleShowNew = () => {
        this.setState({
          isNewPwShow: !this.state.isNewPwShow,
        });
      };

      resetPassword = () => {
        const apiUrl = 'api/v1/reset-password';

        const payload = {
            email: this.state.email,
            password: this.state.newPswd
        }

        axios.post(apiUrl, payload)
        .then((result) => {
            console.log(result);
            if(result.data.status = 'success'){
                this.setState({
                    emailSent: true
                })
            }
        })
      }


  render() {
    return (
      <div className="container">
      <div className="content-wrap">
         <NavBar />
       </div>
       <div className="mo-nav-bar">
          <img className="mo-logo" src={logo} onClick={() => history.push('/')} />
          <di className="mo-ham-wrap">
                {/* <img className='mo-ham' src={menu} alt=''/> */}
                <Menu customBurgerIcon={ <img src={menu} /> } burgerButtonClassName={ "mo-home-ham" } right>
                  <OuthireMobileMenu/>
                </Menu>
              </di>
          </div>
       <div className="max-width">
         <div className="candidate-bg web-nav">
         <div className="first-wrap content-center coloum">
           {/* <div className="sign-diagonal"></div> */}
           <img className="white-poligon" src={white} alt=''/>
           <div className="top-section">
         {/* <div className="home-title w-full mb-75  mt-single">Let’s find what you’re looking for</div> */}
         <div className="home-card-wrap mb-100 top-card home-login-wrap">
         {!this.state.emailSent ? (
          <div>
          <div className="sentimg-wrap mb-24">
              {/* <img src={sent}/> */}
          </div>             
          <div className=" mb-56 forgetpw">Reset Password</div>
          <div className="size-1em font-regular color-primary mb-32 width-494">
              Please enter your new pasword.
          </div>
          <div className="pw-wrap w-444">
                          <input
                            className="input-box-pw size-small"
                            name="newPswd"
                            placeholder="Type new password"
                            type={this.state.isNewPwShow ? "text" : "password"}
                            style={{
                              borderColor: this.state.isPwFocus
                                ? "#c9d6e3"
                                : "#f47160",
                            }}
                            onChange={this.handleNewPwChange}
                            onBlur={this.handlePwOnBlur}
                          />
                          {!this.state.isNewPwEmpty && !this.state.isNewPwShow && (
                            <a
                              className="show-txt"
                              onClick={this.toggleShowNew}
                            >
                              Show
                            </a>
                          )}
                          {!this.state.isPwEmpty && this.state.isNewPwShow && (
                            <a
                              className="show-txt"
                              onClick={this.toggleShowNew}
                            >
                              Hide
                            </a>
                          )}
                        </div>
          <div className="drawer-hints-wrap w-444 mt-16">
                        <div className="pw-hint-title font-regular size-small text-left">
                          Your new password must contain:
                        </div>
                        <div className="pw-hints-list">
                          <div className="left-list">
                            {/* Password length check */}
                            <div className="list-wrap mb-5">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasLength == null
                                      ? dot
                                      : this.state.newHasLength
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasLength == null
                                      ? "#607890"
                                      : this.state.newHasLength
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                8-15 characters
                              </div>
                            </div>
                            {/* Password uppercase character check */}
                            <div className="list-wrap mb-5">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasUppercase == null
                                      ? dot
                                      : this.state.newHasUppercase
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasUppercase == null
                                      ? "#607890"
                                      : this.state.newHasUppercase
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 uppercase
                              </div>
                            </div>
                            {/* Password number check */}
                            <div className="list-wrap">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasNumber == null
                                      ? dot
                                      : this.state.newHasNumber
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasNumber == null
                                      ? "#607890"
                                      : this.state.newHasNumber
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 number
                              </div>
                            </div>
                          </div>
                          <div className="right-list">
                            {/* Password lowercase character check */}
                            <div className="list-wrap mb-5 right drawer-list">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasLowercase == null
                                      ? dot
                                      : this.state.newHasLowercase
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasLowercase == null
                                      ? "#607890"
                                      : this.state.newHasLowercase
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 lowercase
                              </div>
                            </div>
                            {/* Password special character check */}
                            <div className="list-wrap mb-5 right drawer-list">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasSpecial == null
                                      ? dot
                                      : this.state.newHasSpecial
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasSpecial == null
                                      ? "#607890"
                                      : this.state.newHasSpecial
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 special character
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
          <div className="mt-16">
                          <button className="btn btn-solid btn-regular font-18" type="button" onClick={this.resetPassword} disabled={this.state.isInfoValid ? false : true}>Reset Password</button>
                      </div>      
          </div>
        ): (
          <div>
          <div className="sentimg-wrap mb-24">
            {/* <img src={sent} /> */}
          </div>
          <div className="verify-txt mb-16 forgetpw">Password Reset Successfully</div>
          <div className="size-1em font-regular color-primary mb-32 text-center">
            Your password has been reset successfully,  you can sign in using your new pasword. <span className="resend-link" onClick={()=> history.push('/signin')}>Sign In</span>
          </div>
        </div>
        )}
     </div>
         </div>
         </div>
       </div>
       <div className="mo-wrap">
    <div className="home-card-wrap mb-233 top-card home-login-wrap">
    {!this.state.emailSent ? (
          <div>
          <div className="sentimg-wrap mb-24">
              {/* <img src={sent}/> */}
          </div>             
          <div className="verify-email mb-56 forgetpw">Reset Password</div>
          <div className="size-1em font-regular color-primary mb-32 width-494">
              Please enter your new password.
          </div>
          <div className="pw-wrap w-444">
                          <input
                            className="input-box-pw size-small"
                            name="newPswd"
                            placeholder="Type new password"
                            type={this.state.isNewPwShow ? "text" : "password"}
                            style={{
                              borderColor: this.state.isPwFocus
                                ? "#c9d6e3"
                                : "#f47160",
                            }}
                            onChange={this.handleNewPwChange}
                            onBlur={this.handlePwOnBlur}
                          />
                          {!this.state.isNewPwEmpty && !this.state.isNewPwShow && (
                            <a
                              className="show-txt"
                              onClick={this.toggleShowNew}
                            >
                              Show
                            </a>
                          )}
                          {!this.state.isPwEmpty && this.state.isNewPwShow && (
                            <a
                              className="show-txt"
                              onClick={this.toggleShowNew}
                            >
                              Hide
                            </a>
                          )}
                        </div>
          <div className="drawer-hints-wrap w-444 mt-16">
                        <div className="pw-hint-title font-regular size-small text-left">
                          Your new password must contain:
                        </div>
                        <div className="pw-hints-list">
                          <div className="left-list">
                            {/* Password length check */}
                            <div className="list-wrap mb-5">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasLength == null
                                      ? dot
                                      : this.state.newHasLength
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasLength == null
                                      ? "#607890"
                                      : this.state.newHasLength
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                8-15 characters
                              </div>
                            </div>
                            {/* Password uppercase character check */}
                            <div className="list-wrap mb-5">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasUppercase == null
                                      ? dot
                                      : this.state.newHasUppercase
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasUppercase == null
                                      ? "#607890"
                                      : this.state.newHasUppercase
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 uppercase character
                              </div>
                            </div>
                            {/* Password number check */}
                            <div className="list-wrap">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasNumber == null
                                      ? dot
                                      : this.state.newHasNumber
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasNumber == null
                                      ? "#607890"
                                      : this.state.newHasNumber
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 number
                              </div>
                            </div>
                          </div>
                          <div className="right-list">
                            {/* Password lowercase character check */}
                            <div className="list-wrap mb-5 right drawer-list">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasLowercase == null
                                      ? dot
                                      : this.state.newHasLowercase
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasLowercase == null
                                      ? "#607890"
                                      : this.state.newHasLowercase
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 lowercase character
                              </div>
                            </div>
                            {/* Password special character check */}
                            <div className="list-wrap mb-5 right drawer-list">
                              <div className="dot-wrap">
                                <img
                                  src={
                                    this.state.newHasSpecial == null
                                      ? dot
                                      : this.state.newHasSpecial
                                        ? check
                                        : close
                                  }
                                  className="dot-icon"
                                />
                              </div>
                              <div
                                className="list-txt font-regular size-small"
                                style={{
                                  color:
                                    this.state.newHasSpecial == null
                                      ? "#607890"
                                      : this.state.newHasSpecial
                                        ? "#4ba08c"
                                        : "#f47160",
                                }}
                              >
                                1 special character
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
          <div className="mt-16">
                          <button className="btn btn-solid btn-regular font-18" type="button" onClick={this.resetPassword} disabled={this.state.isInfoValid ? false : true}>Reset Password</button>
                      </div>      
          </div>
        ): (
          <div>
          <div className="sentimg-wrap mb-24">
            {/* <img src={sent} /> */}
          </div>
          <div className="verify-txt mb-16 forgetpw">Password Reset Successfully</div>
          <div className="size-1em font-regular color-primary mb-32 text-center">
            Your password has been reset successfully,  you can sign in using your new pasword. <span className="resend-link" onClick={()=> history.push('/signin')}>Sign In</span>
          </div>
        </div>
        )}
        </div>
    </div>
  <div className=" login-mo">
  <Footer/>
  </div>
  <div className="mo-wrap">
    <div className="login-footer-relative">By continuing you are indicating that you agree to the <span><a className="login-footer-link" href="/terms-of-use">Terms</a></span>  and <span><a className="login-footer-link" href="/privacy">Privacy Policy</a></span>.</div>
  </div>

   </div>
   </div>
    );
  }
}

export default ResetPassword;