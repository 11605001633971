import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import patientProfile from './reducers/patient/profileReducer';
import patientAppointments from './reducers/patient/appointmentReducer';
import candidateProfile from './reducers/candidate/profileReducer';
import patientbooking from './reducers/patient/bookingReducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const rootReducer = combineReducers ({
    patient: patientProfile,
    booking: patientbooking,
    patientAppointments: patientAppointments,
    candidate: candidateProfile
});

const persistConfig = {
    key: 'root',
    storage,
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  

// const configureStore = () => {
//     return createStore(
//         persistedReducer,
//         compose(applyMiddleware(thunk))
//     );
// };

export default () => {
    let store = createStore(persistedReducer, compose(applyMiddleware(thunk)))
    let persistor = persistStore(store)
    return {store, persistor};
}