import React  , {useEffect, useState}from 'react';
import './styles.scss';
import history from './../../utilities/history';
import { connect } from 'react-redux';
import Modal from './../Landing/Modal';
import { PURGE } from 'redux-persist';
import { ProfileActionCreators } from '../../actions/candidate/profileAction';
import logo from './../../assets/outhire/outhire.svg';
import profile from './../../assets/outhire/person.svg';
import job from './../../assets/outhire/tile.svg';
import referrals from './../../assets/outhire/referral.svg';
import logout from './../../assets/outhire/logout.svg';
import down from './../../assets/outhire/down.svg';
import up from './../../assets/outhire/up.svg';

function TopMobileMenu(props) {

    const [signoutModal, setSignoutModal] = useState(false);
    const [jobExpand, setJobExpand] = useState(false);
    const pathname = window.location.pathname;
    console.log(pathname);

    const signout = (e) => {
     // e.PreventDefault();
       const {dispatch} = props;

       dispatch({
         type: PURGE,
         key: 'root',
         result: () => null
       })

       props.dispatch(ProfileActionCreators.updateUser(null));
      history.push('/');
    }

    useEffect(()=> {
      // if(props.user == null || props.user == undefined){
      //   // if(pathname != '/employers' && pathname != '/employers' && pathname != '/employers' && pathname != '/employers' && pathname != '/employers')
      //   history.push('/');
      //   console.log(props.user)
      // }


      const onPageLoad = () => {
        setJobExpand(true)
      }

      if(document.readyState === 'complete' && pathname.includes('job')){
        onPageLoad();
      }
      // else{
      //   window.addEventListener('load', onPageLoad);
      //   return ()=> window.removeEventListener('load', onPageLoad)
      // }
    }, [])

    return (
        <div className='mo-demo'>
        <ul className="column-list">
                    <a href='https://outhire.ca/home/' className='nav-list-link'><li className='nav-list-txt' onClick="window.location.href='https://outhire.ca/home/';" >Home</li></a>
                    {/* <li className='nav-list-txt' onClick={() => history.push('/')}>Home</li> */}
                    {/* <li className={pathname == '/employers' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/employers')}>Companies</li>
                    <li className={pathname == '/candidates' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/candidates')}>Candidates</li>
                    <li className={pathname == '/about' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/about')}>About</li> */}
                    <li className={pathname == '/explore-jobs' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/explore-jobs')}>Explore Jobs</li>
                    <li className={pathname == '/portal' ? 'nav-list-txt active-nav' : 'nav-list-txt'} onClick={() => history.push('/portal')}>Portal 
                    {/* <div className='beta-badge-mo'>BETA</div> */}
                    </li>
            
                </ul>
                <div className='mo-demo-btn-wrap'><a href='https://outhire.ca/contact/'><button className='demo-btn cursor' onClick="window.location.href='https://outhire.ca/contact/';" >Book a Demo</button></a></div>
                </div>

    )
};

  //map stored state to props access
  const mapStateToProps = (state, ownProps) => {
    return {
        user: state.candidate.outhire_user,
        state
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(TopMobileMenu);