import React, {Component} from 'react';
import { Router } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import Dashboard from './Candiate/Dashboard/Dashboard';
import AllJobs from './Candiate/Jobs/AllJobs';
import MyJobDetails from './Candiate/Jobs/MyJobDetails';
import Home from "./landing/Home/Home";
import SignIn from "./SignIn/SignIn";
import ConfirmEmailResult from './SignUp/ConfirmEmailResult';
import Ineligible from './SignUp/Ineligible';
import RegistrationConfirmation from './SignUp/RegistrationConfirmation';
import RegistrationSuccess from './SignUp/RegistrationSuccess';
import SignUp from "./SignUp/SignUp";
import TermsAndCondition from './SignUp/TermsAndCondition';
import history from './utilities/history';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Settings from './Candiate/Settings/Settings';
import Referrals from './Candiate/Referrals/Referrals';
import AdminDashboard from './Admin/Dashboard/AdminDashboard';
import ModifyUsers from './Admin/ModifyUsers/ModifyUsers';
import ManageAdmin from './Admin/ManageAdmin/ManageAdmin';
import Main from './landing/Main/Main';
import Employers from './landing/Employers/Employers';
import Candidates from './landing/Candidates/Candidates';
import ExploreJobs from './landing/ExploreJobs/ExploreJobs';
import About from './landing/About/About';
import Contacts from './landing/Contact/Contact';
import ScrollToTop from './components/Landing/ScrollTop';
import ExploreJobDetails from './landing/ExploreJobs/ExploreJobDetails';
import MyJobs from './Candiate/Jobs/MyJobs';
import JobDetails from './Candiate/Jobs/JobDetails';
import ForgetPassword from './SignUp/ForgetPassword';
import ResetPassword from './SignUp/ResetPassword';
import Terms from './landing/About/Terms';
import Privacy from './landing/About/Privacy';
import Faq from './landing/About/Faq';


class App extends Component {

  constructor(props){
    super(props);
 }
    state = {
      response: {}
    };
  
    render() {
      return (
        <Router history={history}> 
         <ScrollToTop>
            <Route exact path="/" component={Home} />
            <Route exact path="/portal" component={Home} />
            <Route exact path="/employers" component={Employers} />
            <Route exact path="/candidates" component={Candidates} />
            <Route exact path="/explore-jobs" component={ExploreJobs} />
            <Route path="/explore-jobs/details" component={ExploreJobDetails} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contacts} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/registrationconfirmation" component={RegistrationConfirmation} />
            <Route exact path="/linkedin" component={LinkedInCallback} />
            <Route path="/confirm-email" component={ConfirmEmailResult} />
            <Route path="/terms" component={TermsAndCondition} />
            <Route path="/registrationsuccess" component={RegistrationSuccess} />
            <Route path="/ineligible" component={Ineligible} />
            <Route path="/candidate" component={Dashboard} />
            <Route path="/myjob-details" component={MyJobDetails} />
            <Route path="/job-details" component={JobDetails} />
            <Route path="/alljobs" component={AllJobs} />
            <Route path="/myjobs" component={MyJobs} />
            <Route path="/settings" component={Settings} />
            <Route path="/referrals" component={Referrals} />
            <Route path="/admin/dashboard" component={AdminDashboard} />
            <Route path="/admin/modify" component={ModifyUsers} />
            <Route path="/admin/manage" component={ManageAdmin} />
            <Route exact path="/forget-password/" component={ForgetPassword} /> 
            <Route path="/reset-password/" component={ResetPassword} />    
            <Route path="/terms-of-use/" component={Terms} />    
            <Route path="/privacy/" component={Privacy} />    
            <Route path="/faq/" component={Faq} />   
            <Route path='/auth/google' component={() => { 
                window.location.href = 'http://telemedmd-portals.herokuapp.com/api/auth/google'; 
                return null;
            }}/>

</ScrollToTop>
        </Router>
      )
    }
  }
  
  export default App;