import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../src/utilities/history';
import { ProfileActionCreators } from '../../../src/actions/patient/profileAction';
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../utilities/refreshTokenSetup';
import { useGoogleLogin } from 'react-google-login';

const clientId = '';

function MyGoogleLogin(props) {
  const onSuccess = (res) => {      
    
    const newUser = {        
        googleId: res.profileObj.googleId,
        displayName: res.profileObj.name,
        firstName: res.profileObj.name.givenName,
        lastName: res.profileObj.name.familyName,
        avartar: res.profileObj.imageUrl,
        email:res.profileObj.email,
        user_type: 'patient',
        password: randomPassword(32),
        is_subscribed: 1,
        status: "verified",
        demographicNo: null
    }
    
         
        const apiPatient = '/api/v1/get-user';
        const email  = newUser.email;
        axios.post(apiPatient, {email:email})
        .then((res) => {
          
          if(res.data!=null){
             if(res.data.demographicNo){
               //go to dashboard

               //get patient infor'mation:
               const apiPatient = '/api/avaros/demographics/getPatientBydemoNo';

               const demoPayload = {
                 demographicNo: res.data.demographicNo
               }
           
                   axios.post(apiPatient, demoPayload)
                       .then((result) => {
                           console.log('retrieved from avaros>>>' + result.data);
                           //updatePatient
                           props.dispatch(ProfileActionCreators.updatePatient(result.data));
                           //updateUser
                           props.dispatch(ProfileActionCreators.updateUser(newUser));
                           history.push('/dashboard?id=' + res.data.demographicNo);
            
                       })
                       .catch((error) => {
                           if(error.response){
                               console.log('Response Error>>>>');
                               console.log('Response Error>>>' + error.response.status);
                               console.log('Response Error>>>' + error.response.headers);
                           }else if(error.request){
                               console.log('Request Error>>>>'+ error.request)
                           }else if(error.message){
                               console.log('Request Error>>>>'+ error.message)
                           }
                       })
             
             
             }else{

               //go to register form
               props.dispatch(ProfileActionCreators.updateUser(newUser)); 
               history.push('/register?id='+res.data.id+'&email='+res.data.email);

             }

           }else{           
             //create user
             console.log("start create user....")
             axios.post('api/v1/add-user', newUser)
             .then((res) => { 
              console.log('User is created.');
              //go to register form            
              props.dispatch(ProfileActionCreators.updateUser(newUser));                 
              history.push('/register?id='+res.data.id+'&email='+res.data.email);              

        }).catch((error) => {
          if(error.response){
              console.log('Response Error>>>>');
              console.log('Response Error>>>' + error.response.status);
          }else if(error.request){
              console.log('Request Error>>>>'+ error.request)
          }else if(error.message){
              console.log('Request Error>>>>'+ error.message)
          }
      })

       }

        }).catch((error) => {
          if(error.response){
              console.log('Response Error>>>>');
              console.log('Response Error>>>' + error.response.status);
        }else if(error.request){
              console.log('Request Error>>>>'+ error.request)
          }else if(error.message){
              console.log('Request Error>>>>'+ error.message)
          }
      })

    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
   
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Sign in with LinkedIn"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        className="btn-google"
        // style={{ marginTop: '100px' }}
        // isSignedIn={true}
      />
      
    </div>
  );




}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch
    };
}

function randomPassword(size){
  var seed=new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z',
   'a','b','c','d','e','f','g','h','i','j','k','m','n','p','Q','r','s','t','u','v','w','x','y','z','0','1',
   '2','3','4','5','6','7','8','9');
  var seedlength=seed.length;  
  var createPassword='';
  for(let i=0;i<size;i++){  
  var a=Math.floor(Math.random()*seedlength);  
     createPassword+=seed[a];  
  }  
    return createPassword;  
  }



export default connect(mapDispatchToProps)(MyGoogleLogin);