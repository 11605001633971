import { Types } from '../../constants/actionTypes';

const initialState = {
    //appointments initial state
    appointments: null
}

const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.UPDATE_APPOINTMENTS_BY_PATIENT:
            return {
                ...state,
                appointments: action.payload.appointments
            }
        default:
            return state;

    }
}

export default appointmentReducer;